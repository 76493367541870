import { Component, Input } from '@angular/core';

import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { NoteListConfig } from '@modules/observation/pages/insights/pages/detail/components';

import * as _ from 'lodash';

@Component({
  selector: 'app-observation-notes',
  templateUrl: './observation-notes.component.html',
  styleUrls: ['./observation-notes.component.scss'],
})
export class ObservationNotesComponent {

  public notes: { open: NoteListConfig; closed: NoteListConfig } = {
    open: {
      title: 'SHARED.Submitted',
      titleColorClass: 'subhead-opportunity',
      notes: [],
      observationId: null,
      newNoteType: 'created',
      showEmptyMessage: true
    },
    closed: {
      title: 'SHARED.Addressed',
      titleColorClass: 'subhead-fixed',
      notes: [],
      observationId: null,
      newNoteType: 'fixed'
    }
  };
  public isClosedNotesAvailable: boolean;

  constructor(private observationDetailService: ObservationDetailService) {
  }

  @Input() set observationId(id: number) {
    if (id) {
      this.init(id);
    }
  }

  public init(id: number): void {
    const notes = this.observationDetailService.getNotes(id);

    _.pullAt(notes.openNotes, _.findLastIndex(notes.openNotes, {type: 'text'}));

    this.notes.open.notes = notes.openNotes;
    this.notes.open.observationId = id;
    this.notes.closed.notes = notes.closedNotes;
    this.notes.closed.observationId = id;

    this.isClosedNotesAvailable = notes.displayClosedNotes;
  }

}
