import { Injectable } from '@angular/core';

import { QuestionPage } from './../../pages/question/question.page';
import { ContentListComponent } from '@modules/management/pages/details/content/components';
import {
  MeasurementFieldsModel,
  MultipleChoiceFieldsModel,
  PassFailFieldsModel,
  IncrementEntryFieldsModel,
  TextInputFieldsModel,
  DropdownSelectionModel
} from '@modules/management/pages/details/check/models';
import {
  CommsService,
  DynamicComponentCreatorService,
  FormBuilderService,
  Module,
  SettingsService,
  SubscriberService,
  UtilsService
} from '@services';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class QuestionFormService {

  public questionPage: QuestionPage;
  public sharedFields = {
    issueType: {
      containerClass: 'question-field',
      name: 'issueType',
      type: 'radio',
      default: 0,
      options: [
        {
          id: 0,
          description: this.translate.instant('MGMT_DETAILS.Allow_CA_Comment')
        },
        {
          id: 1,
          description: this.translate.instant('MGMT_DETAILS.Require_CA')
        },
        {
          id: 2,
          description: this.translate.instant('MGMT_DETAILS.Require_Comment')
        }
      ]
    },
    attachCommentOption: {
      containerClass: 'question-field',
      type: 'flipswitch',
      name: 'issueTypeFail_Comment',
      title: 'MGMT_DETAILS.Attach_Comment',
      onText: this.translate.instant('SHARED.On'),
      offText: this.translate.instant('SHARED.Off'),
      default: 0,
      value: 1
    },
    correctiveOption: {
      containerClass: 'question-field',
      type: 'flipswitch',
      name: 'issueTypeFail_Corrective',
      title: 'MGMT_DETAILS.Log_n_Corrective',
      onText: this.translate.instant('SHARED.On'),
      offText: this.translate.instant('SHARED.Off'),
      default: 0,
      value: 1
    },
  };
  private passFailFieldsModel: PassFailFieldsModel;
  private multipleChoiceFieldsModel: MultipleChoiceFieldsModel;
  private dropdownSelectionModel: DropdownSelectionModel;
  private textInputFieldsModel: TextInputFieldsModel;
  private incrementEntryFieldsModel: IncrementEntryFieldsModel;
  private measurementFieldsModel: MeasurementFieldsModel;
  private mainFields: any[] = [
    {
      containerClass: 'question-field common-label-field',
      required: true,
      inputtype: 'verbatim',
      name: 'title',
      title: 'MGMT_DETAILS.Question_Item',
      type: 'text',
      size: 20
    },
    {
      containerClass: 'question-field common-label-field',
      inputtype: 'verbatim',
      name: 'translation_title',
      title: this.translate.instant('MGMT_DETAILS.Question_Item_Translation'),
      type: 'textTranslations',
      fromID: 'translations',
      size: 20,
      maxlength: 180
    },
    {
      containerClass: 'question-field question-type question-type-selector',
      title: 'MGMT_DETAILS.Question_Item_Type',
      type: 'subtitle'
    },
    {
      containerClass: 'question-field question-type question-type-selector',
      title: '',
      name: 'type',
      type: 'radio',
      break: true,
      func: (type: any) => {
        const extension = _.includes(['multipleChoice', 'incrementEntry', 'dropdownSelection'], type.id) ? 'png' : 'svg';
        return `
          <div class="type-image">
            <img src="assets/images/questionTypes/${type.id}.${extension}">
          </div>
          <div class="question-type-title">${type.description}</div>
        `;
      },
      options: [
        {
          id: 'passFail',
          description: this.translate.instant('MGMT_DETAILS.Pass_Fail')
        },
        {
          id: 'multipleChoice',
          description: this.translate.instant('MGMT_DETAILS.Multiple_Choice')
        },
        {
          id: 'textInput',
          description: this.translate.instant('MGMT_DETAILS.Text_Input')
        },
        {
          id: 'dropdownSelection',
          description: this.translate.instant('MGMT_DETAILS.Dropdown_Selection')
        },
        {
          id: 'incrementEntry',
          description: this.translate.instant('MGMT_DETAILS.Increment_Entry')
        },
        {
          id: 'measurement',
          description: this.translate.instant('MGMT_DETAILS.Tolerance')
        }
      ],
      onChange: (value: string) => {
        this.questionPage.currentGroupType = value;
        this.showFieldsByClass(value);
        this.checkFormControlsVisibility();
        this.checkTypesAvailability();
      }
    }
  ];
  private commonFields = [
    {
      containerClass: 'question-field question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection',
      title: this.translate.instant('MGMT_DETAILS.Allow_NA'),
      name: 'allowNA',
      type: 'flipswitch',
      onText: this.translate.instant('SHARED.On'),
      offText: this.translate.instant('SHARED.Off'),
      value: 1,
      default: 0
    },
    {
      type: 'divider',
      containerClass: 'question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection'
    },
    {
      title: this.translate.instant('MGMT_DETAILS.Question_Categories'),
      type: 'subtitle',
      containerClass: 'question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection'
    },
    {
      name: 'closingCategory',
      title: this.translate.instant('SHARED.Category'),
      type: 'selectmenu',
      multiple: false,
      searchable: true,
      canClear: true,
      placeholder: this.translate.instant('SHARED.None'),
      containerClass: 'question-field question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection',
      options: []
    },
    {
      title: this.translate.instant('DASHPAGES.CLOSE_OBSERVATION_Tags'),
      type: 'subtitle',
      containerClass: 'question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection'
    },
    {
      name: 'closingTags',
      type: 'selectmenu',
      multiple: true,
      searchable: false,
      canClear: false,
      tags: true,
      placeholder: this.translate.instant('SHARED.Add_Tags'),
      default: null,
      containerClass: 'full-width question-field question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection',
      options: this.settingsService.customTags.data,
      valueProperty: 'tagID',
      func: (item: any) => item.tag
    },
    {
      type: 'divider',
      containerClass: 'question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection'
    },
    {
      title: 'MGMT_DETAILS.Reference_Image',
      type: 'subtitle',
      containerClass: 'question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection'
    },
    {
      containerClass: 'question-field question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection',
      title: 'MGMT_DETAILS.Include_Reference_Image',
      name: 'showImage',
      type: 'flipswitch',
      onText: this.translate.instant('SHARED.On'),
      offText: this.translate.instant('SHARED.Off'),
      value: 1,
      default: 0,
      onChange: (value) => this.checkReferenceImageFieldAvailability(value)
    },
    {
      containerClass: 'question-field question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection',
      name: 'reference_image',
      title: 'MGMT_DETAILS.Reference_Image',
      type: 'image',
      multiple: false,
      addButton: this.translate.instant('SHARED.Upload_Image'),
      updateButton: this.translate.instant('SHARED.Replace_Image'),
      imageSize: '75px'
    },
    {
      type: 'divider',
      containerClass: 'question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection'
    },
    {
      title: 'MGMT_DETAILS.Smart_Check_Details',
      type: 'subtitle',
      containerClass: 'question-type-field passFail multipleChoice'
    },
    {
      containerClass: 'question-field question-type-field passFail multipleChoice',
      title: 'MGMT_DETAILS.Question_Item_Importance',
      name: 'importance',
      type: 'range',
      break: true,
      showTicks: true,
      showTicksValues: true,
      max: 5,
      min: 1,
      default: 1,
      hideInput: true,
      getLegend: (value: number): string => {
        let postfix = '';

        if (value === 1) {
          postfix = ` - ${this.translate.instant('SHARED.Low')}`;
        }

        if (value === 5) {
          postfix = ` - ${this.translate.instant('SHARED.High')}`;
        }

        return `${value.toString()} ${postfix}`;
      }
    },
    {
      type: 'divider',
      containerClass: 'question-type-field passFail multipleChoice'
    },
    {
      title: 'MGMT_DETAILS.Question_Item_Help',
      type: 'subtitle',
      containerClass: 'question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection'
    },
    {
      containerClass: 'question-field question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection',
      title: 'MGMT_DETAILS.Enable_Help_Content',
      name: 'helpAvailable',
      type: 'flipswitch',
      onText: this.translate.instant('SHARED.On'),
      offText: this.translate.instant('SHARED.Off'),
      value: 1,
      default: 0,
      onChange: (value) => this.checkHelpFieldAvailability(value)
    },
    {
      containerClass: 'question-field question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection',
      title: 'MGMT_DETAILS.Help_Hint_Text',
      name: 'helpContent',
      inputtype: 'verbatim',
      type: 'text'
    },
    {
      containerClass: 'question-field question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection',
      title: this.translate.instant('MGMT_DETAILS.Help_Hint_Text_Translation'),
      name: 'translation_helpContent',
      type: 'textTranslations',
      inputtype: 'verbatim'
    },
    {
      title: 'MGMT_DETAILS.Check_Content',
      type: 'subtitle',
      containerClass: 'question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection'
    },
    {
      containerClass: 'question-field question-type-field passFail multipleChoice textInput incrementEntry measurement dropdownSelection',
      type: 'customElement',
      name: 'contentItems',
      component: ContentListComponent,
      module: Module.CONTENT
    }
  ];

  constructor(
    public translate: TranslateService,
    public dynamicComponentCreatorService: DynamicComponentCreatorService,
    private utils: UtilsService,
    private commsService: CommsService,
    private formBuilderService: FormBuilderService,
    private settingsService: SettingsService,
    private subscriber: SubscriberService
  ) {
  }

  public getFields(questionPage: QuestionPage): any {
    this.questionPage = questionPage;
    this.checkTypeFieldVisibility();
    this.defineQuestionCategories();

    this.passFailFieldsModel = new PassFailFieldsModel(this, this.translate);
    this.multipleChoiceFieldsModel = new MultipleChoiceFieldsModel(this, this.translate);
    this.textInputFieldsModel = new TextInputFieldsModel(this, this.translate);
    this.dropdownSelectionModel = new DropdownSelectionModel(this, this.translate);
    this.incrementEntryFieldsModel = new IncrementEntryFieldsModel(this, this.translate, this.formBuilderService);
    this.measurementFieldsModel = new MeasurementFieldsModel(this, this.translate);

    const modelsFields: any[] = [
      this.passFailFieldsModel.getFields(),
      this.multipleChoiceFieldsModel.getFields(),
      this.textInputFieldsModel.getFields(),
      this.dropdownSelectionModel.getFields(),
      this.incrementEntryFieldsModel.getFields(),
      this.measurementFieldsModel.getFields()
    ];

    const labelFields = _.map(modelsFields, (field) => field.shift());

    return [
      ...labelFields,
      ...this.mainFields,
      ..._.flatten(modelsFields),
      ...this.commonFields
    ];
  }

  public showFieldsByClass(className: string): void {
    const baseFieldClass = 'question-type-field';
    let fieldSelector = `#${this.questionPage.formId} .${baseFieldClass}`;
    this.setFieldsVisibility(fieldSelector, false);

    if (className) {
      fieldSelector += `.${className}`;
      this.setFieldsVisibility(fieldSelector, true);
    }

    this.passFailFieldsModel.showGroupList();
    this.multipleChoiceFieldsModel.showGroupList();
    this.textInputFieldsModel.showGroupList();
    this.dropdownSelectionModel.showGroupList();
    this.incrementEntryFieldsModel.showGroupList();
    this.measurementFieldsModel.showGroupList();

    this.checkCommonFieldsAvailability();
    this.checkTypesAvailability();
    this.prepareSettings();
  }

  public onDestroy() {
    this.passFailFieldsModel.onDestroy();
    this.multipleChoiceFieldsModel.onDestroy();
    this.textInputFieldsModel.onDestroy();
    this.dropdownSelectionModel.onDestroy();
    this.incrementEntryFieldsModel.onDestroy();
    this.measurementFieldsModel.onDestroy();
  }

  public isValid(): boolean {
    if (this.questionPage.currentGroupType === 'passFail') {
      return this.passFailFieldsModel.isValid();
    } else if (this.questionPage.currentGroupType === 'multipleChoice') {
      return this.multipleChoiceFieldsModel.isValid();
    } else if (this.questionPage.currentGroupType === 'textInput') {
      return this.textInputFieldsModel.isValid();
    } else if (this.questionPage.currentGroupType === 'measurement') {
      return this.measurementFieldsModel.isValid();
    } else if (this.questionPage.currentGroupType === 'dropdownSelection') {
      return this.dropdownSelectionModel.isValid();
    } else if (this.questionPage.currentGroupType === 'incrementEntry') {
      return this.incrementEntryFieldsModel.isValid();
    }

    return true;
  }

  public setFieldVisibility(field: HTMLElement | HTMLInputElement, isVisible: boolean): void {
    if (field) {
      field.hidden = !isVisible;
      (<HTMLInputElement>field).disabled = !isVisible;

      const questionField: HTMLElement = (<HTMLElement>field.closest('.question-field'));

      if (questionField) {
        questionField.hidden = !isVisible;
        this.disableFieldByElement('input', questionField, !isVisible);
        this.disableFieldByElement('select', questionField, !isVisible);
      }
    }
  }

  public disableFieldByElement(fieldType: string, element: HTMLElement, value: boolean) {
    const targetElement: HTMLInputElement = this.getElementBy(fieldType, element);

    if (targetElement) {
      targetElement.disabled = value;
    }
  }

  public checkFormControlsVisibility(): void {
    const formControlsElement = (<any>this.questionPage).elementRef.nativeElement.querySelector('.ui-form-controls.ui-grid-a');
    formControlsElement.hidden = !this.questionPage.currentGroupType;
  }

  public getFormData(formData: any): any {
    this.encodeTranslations(formData);
    this.getImpactField(formData);
    this.getDataByGroupType(formData);
    this.prepareCategories(formData);
    this.prepareTags(formData);

    if (_.has(formData, 'showImage')) {
      formData.showImage = formData.showImage || 0;
    }

    if (_.has(formData, 'helpAvailable')) {
      formData.helpAvailable = formData.helpAvailable || 0;
    }

    if (_.has(formData, 'allowNA')) {
      formData.allowNA = formData.allowNA || 0;
    }

    if (_.isArray(formData.images)) {
      formData.images = JSON.stringify(formData.images);
    }
  }

  public getElementBy(selector: string, targetElement?: any): any {
    const nativeElement = targetElement || (<any>this.questionPage).elementRef.nativeElement;
    return nativeElement.querySelector(selector);
  }

  public syncRequiredFields(elementRef, fields: any, requiredOptionFieldName: string) {
    _.each(fields, (value: string, fieldName: string) => {
      const requiredFieldSelector = `input[name="required_${fieldName}"]`;
      const requiredField: HTMLInputElement = this.getElementBy(requiredFieldSelector, elementRef.nativeElement);

      if (requiredField) {
        if (value === null) {
          value = this.getInputFieldValueByName(fieldName, elementRef.nativeElement);
        }
        requiredField.disabled = !value;
        (<HTMLElement>requiredField.closest('.question-field')).hidden = !value;
      }
    });

    this.checkRequiredField(elementRef.nativeElement, fields, requiredOptionFieldName);
  }

  public checkRequiredField(element: HTMLElement, targetFields: any, targetFieldName: string, requiredValues: any = {}) {
    _.each(targetFields, (value: string, fieldName: string) => {
      if (_.isUndefined(value) || _.isNull(value)) {
        value = this.getInputFieldValueByName(fieldName, element);
      }

      targetFields[fieldName] = value;

      if (!_.get(requiredValues, `required_${fieldName}`)) {
        const fieldValue = _.values(this.getInputFieldValueByName(`required_${fieldName}`, element));
        _.set(requiredValues, `required_${fieldName}`, fieldValue);
      }
    });
    requiredValues = _.filter(_.flatten(_.values(requiredValues)));

    const fieldsValues: any[] = _.values(targetFields);
    const isEnabled: boolean = _.filter(fieldsValues).length === fieldsValues.length && !requiredValues.length;
    const fieldSelector = `input[name="${targetFieldName}"]`;
    const requiredField: HTMLInputElement = this.getElementBy(fieldSelector, element);

    if (requiredField) {
      const dividerField: HTMLElement = this.getElementBy(`.${targetFieldName}_divider`, element);

      requiredField.disabled = !isEnabled;
      (<HTMLElement>requiredField.closest('.question-field')).hidden = !isEnabled;
      if (dividerField) {
        dividerField.hidden = !isEnabled;
      }
    }
  }

  public failChoiceValidation(element: HTMLElement, targetFieldName: string, value: boolean, formData: any = {}) {
    if (value) {
      const targetElement: HTMLInputElement = this.getElementBy(`[name="${targetFieldName}"]`, element);
      targetElement.checked = false;
      this.markFieldAsChanged(targetFieldName, '', formData);
    }
    this.formBuilderService.disableFieldByName(element, targetFieldName, value);
  }

  public setImage(objectId: number) {
    const imageField: any = _.find(this.commonFields, <any>{name: 'reference_image'});
    imageField.currentThumbnail = this.commsService.objectURI(objectId, true);
  }

  public markFieldAsChanged(fieldName: string, value: any = '', formData: any = {}) {
    let originalValue: any = formData[fieldName] || '';

    if (_.isArray(value) && !_.isArray(originalValue)) {
      originalValue = originalValue ? [originalValue.toString()] : [];
    }

    if (this.questionPage.questionId) {
      this.formBuilderService.markFieldAsChanged(`#questionEditForm`, fieldName, originalValue, value);
    }
  }

  public isModelAvailable(model: string): boolean {
    return this.questionPage.questionId && this.questionPage.currentGroupType === model || !this.questionPage.questionId;
  }

  public prepareQuestionType(question: any) {
    if (_.includes(['singleSelect', 'multiSelect'], question.type)) {
      question.selectionType = question.type;
      question.type = 'multipleChoice';
    }

    if (_.includes(['tolerance', 'actualMeasurement'], question.type)) {
      question.measurementType = question.type;
      question.type = 'measurement';
    }
  }

  public validFormGroups(formGroups): boolean {
    const formIds: string[] = _.map(formGroups, (group) => `${group.formConfig.prefix}Form`);

    if (formIds.length) {
      const formValidResults: boolean[] = _.filter(_.map(formIds, (formId: string) => (<any>$(`#${formId}`)).valid()));
      return formValidResults.length === formIds.length;
    } else {
      return true;
    }
  }

  private setFieldsVisibility(fieldSelector: string, isVisible: boolean) {
    const fields: HTMLElement[] = (<any>this.questionPage).elementRef.nativeElement.querySelectorAll(fieldSelector);
    _.each(fields, (field) => this.setFieldVisibility(field, isVisible));
  }

  private prepareCategories(formData): void {
    formData.closingCategory = formData.closingCategory ? formData.closingCategory : 'none:0';
  }

  private prepareTags(formData): void {
    formData.closingTags = JSON.stringify(_.filter(_.map(_.flatten([formData.closingTags]), Number)));
  }

  private getDataByGroupType(formData): void {
    const currentModel = this.getCurrentModel();

    if (currentModel && currentModel.getData) {
      formData.questionOptions = currentModel.getData();
    }

    if (this.questionPage.currentGroupType === 'multipleChoice') {
      formData.type = formData.selectionType;
      delete formData.selectionType;
    }

    if (this.questionPage.currentGroupType === 'measurement') {
      formData.type = 'tolerance';
      delete formData.measurementType;
    }
  }

  private encodeTranslations(formData): void {
    this.utils.encodeTranslations(formData, ['translation_title', 'translation_helpContent'], ['title', 'explanation']);
  }

  private getImpactField(formData): void {
    const impactField: any = _.find(this.commonFields, <any>{name: 'importance'});

    if (impactField && _.includes(['passFail', 'multipleChoice'], this.questionPage.currentGroupType)) {
      formData[impactField.name] = impactField.getValue();
    }
  }

  private checkCommonFieldsAvailability() {
    this.checkReferenceImageFieldAvailability();
    this.checkHelpFieldAvailability();
  }

  private checkReferenceImageFieldAvailability(value?: any) {
    const includeReferenceImageValue = _.isUndefined(value) ? this.getInputFieldValueByName('showImage') : value;
    this.setFieldVisibility(this.getElementBy(`#${this.questionPage.formConfig.prefix}reference_image-container`), includeReferenceImageValue);
  }

  private checkHelpFieldAvailability(value?: any) {
    const enableHelpContentValue = _.isUndefined(value) ? this.getInputFieldValueByName('helpContent') : value;
    this.setFieldVisibility(this.getElementBy(`input[name="helpContent"]`), enableHelpContentValue);
    this.setFieldVisibility(this.getElementBy(`#translation_helpContentcollapsibleItem`), enableHelpContentValue);
  }

  private getInputFieldValueByName(name: string, targetElement?: any): any {
    const fieldElement: HTMLInputElement = this.getElementBy(`input[name="${name}"]`, targetElement);
    return _.get(fieldElement, 'value');
  }

  private checkTypeFieldVisibility() {
    const typeField: any = _.find(this.mainFields, <any>{name: 'type'});
    typeField.disabled = (<any>this.questionPage).route.snapshot.paramMap.get('id');
  }

  private checkTypesAvailability() {
    if (this.questionPage.currentGroupType) {
      this.setFieldsVisibility(`.question-type-selector`, false);
      this.setFieldsVisibility(`.common-label-field`, true);
    } else {
      this.setFieldsVisibility(`.common-label-field`, false);
    }
  }

  private prepareSettings() {
    _.each(this.questionPage.questionOptions, (questionOption) => {
      const settings = _.get(questionOption, 'settings') || {};

      if (_.includes(['passFail', 'multipleChoice'], this.questionPage.currentGroupType)) {
        if (settings.usesNote) {
          settings.notes_validation = true;

          if (settings.usesNote === 2) {
            settings.required_notes_validation = true;
          }
        }

        if (settings.usesPhoto) {
          settings.photo_validation = true;

          if (settings.usesPhoto === 2) {
            settings.required_photo_validation = true;
          }
        }

        if (settings.verificationRequired) {
          settings.required_validation_field = true;
        }

        if (settings.verificationRequired) {
          settings.required_validation_field = true;
        }

      }

      if (this.questionPage.currentGroupType === 'textInput') {
        const textInputFields: string[] = ['canRepeat', 'usesBarcode', 'required'];
        if (_.intersection(_.keys(settings), textInputFields).length) {
          settings.fieldOptions = [];

          if (settings.required) {
            settings.fieldOptions.push('required');
          }

          if (settings.canRepeat) {
            settings.fieldOptions.push('multiple');
          }

          if (settings.usesBarcode) {
            settings.fieldOptions.push('barcode');
          }
        }
      }

      if (this.questionPage.currentGroupType === 'measurement') {
        if (settings.usesNote) {
          settings.fail_notes = true;

          if (settings.usesNote === 2) {
            settings.required_fail_notes = true;
          }
        }

        if (settings.verificationRequired) {
          settings.required_measurement_choice_validation_field = true;
        }
      }

      if (settings.issueType) {
        settings.issueTypeFail = settings.issueType;
        if (settings.issueTypeFail === '2' || settings.issueTypeFail === '0') {
          settings.issueTypeFail_Comment = true;
        }
        if (settings.issueTypeFail === '1' || settings.issueTypeFail === '2') {
          settings.issueTypeFail_Corrective = true;
        }
      }

      questionOption.settings = _.omit(settings, [
        'usesNote',
        'usesPhoto',
        'verificationRequired',
        'canRepeat',
        'usesBarcode'
      ]);
    });
  }

  private defineQuestionCategories(): void {
    const categoryField = _.find(this.commonFields, {name: 'closingCategory'});
    const locationId = this.subscriber.locationID();
    const observationTypes: string[] = ['category', 'pi', 'quality'];
    const titleMap = {
      category: this.translate.instant('SHARED.Unsafe_Conditions'),
      pi: this.translate.instant('SHARED.Process_Improvements'),
      quality: this.translate.instant('SHARED.Quality_Issues')
    };

    if (categoryField) {
      categoryField.options = [];

      _.each(observationTypes, (type) => {
        const categoriesByType: any[] = _.orderBy(this.settingsService.getSettingSync(type, locationId, true), 'messageTitle');
        categoryField.options = [
          ...categoryField.options,
          ..._.map(categoriesByType, (category) => {
            const observationType: string = type === 'category' ? 'condition' : type;
            return {
              id: `${observationType}:${category.messageID}`,
              description: `${titleMap[type]} - ${category.messageTitle}`
            };
          })
        ];
      });
    }
  }

  private getCurrentModel() {
    const modelsMap = {
      passFail: this.passFailFieldsModel,
      multipleChoice: this.multipleChoiceFieldsModel,
      textInput: this.textInputFieldsModel,
      incrementEntry: this.incrementEntryFieldsModel,
      dropdownSelection: this.dropdownSelectionModel,
      measurement: this.measurementFieldsModel
    };
    return modelsMap[this.questionPage.currentGroupType];
  }

}
