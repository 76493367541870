import { Injectable } from '@angular/core';

import { CommsService } from '@services';
import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';
import * as _ from 'lodash';

export interface ITaskHistory {
  activity: string;
  notes: string;
  time: number;
  userID: number;
}

export interface ITask {
  addedAt: number;
  addedBy: number;
  assignedBy: number;
  assignedTo: number[];
  assignedWhen: number;
  attachments: any[];
  description: string;
  disabledAt: number;
  disabledBy: number;
  dueBy: number;
  estimatedTime: string;
  folderID: number;
  lastUpdate: number;
  notes: any[]
  parentID: string;
  history: ITaskHistory[];
  parentType: string;
  relatedTasks: IObjectStringKeyMap<any>;
  state: number;
  taskID: number;
  title: string;
  type: TaskTypeName;
}

export interface ITaskType {
  active: number;
  addedAt: number;
  addedBy: number;
  description: string;
  disabledAt: number;
  disabledBy: number;
  lastUpdate: number;
  name: TaskTypeName;
  states: number[];
  translations: IObjectStringKeyMap<any>;
  typeID: number;
}

export interface ITaskTypeState {
  active: number;
  addedAt: number;
  addedBy: number;
  category: string;
  color: string;
  description: string;
  disabledAt: number;
  disabledBy: number;
  lastUpdate: number;
  name: string;
  stateID: number;
  translations: IObjectStringKeyMap<any>;
}

export enum TaskTypeName {
  Todo = 'todo',
  PDCA_Do = 'pdca:do',
  PDCA_Check = 'pdca:check',
  PDCA_Act = 'pdca:act'
}

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  private tasks = {
    lastRequest: null,
    data: <IObjectStringKeyMap<ITask>>{}
  };

  private taskTypes = {
    lastRequest: null,
    data: <IObjectStringKeyMap<ITaskType>>{}
  };

  private taskTypeStates = {
    lastRequest: null,
    data: <IObjectStringKeyMap<ITaskTypeState>>{}
  };

  constructor(
    private commsService: CommsService,
    private events: Events
  ) { }

  public refresh(): Promise<IObjectStringKeyMap<ITask>> {
    const requestData: any = {
      cmd: 'getTasks',
      lastRequest: this.tasks.lastRequest,
    };

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.tasks.data = response.result.tasks;
        this.tasks.lastRequest = response.result.timestamp;
        this.events.publish('ccs:tasksUpdate', true);
      }

      return this.tasks.data;
    });
  }

  public getTaskTypes() {
    return this.commsService.sendMessage({
      cmd: 'getTaskTypes',
    }, false, false).then((data) => {
      if (data?.reqStatus === 'OK') {
        this.updateTaskTypesCache(data);
      }
      return this.taskTypes.data;
    });
  }

  public updateTaskTypesCache(data) {
    this.taskTypes.data = data.result.taskTypes;
    this.taskTypes.lastRequest = data.result.timestamp;
    this.events.publish('ccs:taskTypesUpdate', true);
  }

  public getTaskStates() {
    return this.commsService.sendMessage({
      cmd: 'getTaskStates',
    }, false, false).then((data) => {
      if (data?.reqStatus === 'OK') {
        this.updateTaskStatesCache(data);
      }
      return this.taskTypeStates.data;
    });
  }

  public isTypeStatusDone(id: number) {
    return this.taskTypeStates?.data[id]?.category === 'done';
  }

  public updateTaskStatesCache(data) {
    this.taskTypeStates.data = data.result.taskStates;
    this.taskTypeStates.lastRequest = data.result.timestamp;
    this.events.publish('ccs:taskTypeStatesUpdate', true);
  }

  public getTaskStatesByTaskType(name: TaskTypeName): ITaskTypeState[] {
    const targetType = _.find(this.taskTypes.data, { name });
    let states: ITaskTypeState[] = [];

    if (targetType) {
      states = _.filter(_.map(targetType.states, (state) => {
        return _.find(this.taskTypeStates.data, { stateID: state });
      }));
    }

    return states;
  }

  public async getTaskById(id: number) {
    if (!this.tasks.data[id]) {
      await this.refresh();
    }

    return _.cloneDeep(this.tasks.data[id]);
  }

  public addTask(data: any) {
    const requestData = {
      cmd: 'addTask',
      ...data
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public updateTaskStates(states: IObjectStringKeyMap<number>) {
    const requestData = {
      tasks: {}
    };
    _.each(states, (state, taskId) => {
      requestData.tasks[taskId] = { state };
    });
    return this.updateTask(requestData);
  }

  public updateTask(data: any) {
    const requestData = {
      cmd: 'updateTask',
      ...data
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public deleteTask(ids: number[] | number) {
    const requestData = {
      cmd: 'deleteTask',
      tasks: _.flatten([ids])
    };

    return this.commsService.sendMessage(requestData, false, false);
  }
}
