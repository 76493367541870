<ion-header *ngIf="headerIsHidden" no-border>
  <app-close-button></app-close-button>
</ion-header>

<ion-content fullscreen>
  <div class="wide">
    <app-detail-header [headerData]="headerData"></app-detail-header>

    <app-detail-controls [headerData]="headerData"></app-detail-controls>
    <!--<app-closing-notes *ngIf="closingData.closeNotes" [closingData]="closingData"></app-closing-notes>-->
    <app-closing-details *ngIf="closingData.closeNotes" [closingData]="closingData"></app-closing-details>

    <!--Waiting block-->
    <div *ngIf="notes && notes.type ==='waiting'" class="detail-page-section">
      <div class="adjustment-grid">
        <ion-grid class="adjustment-grid-ion-grid">
          <ion-row>
            <ion-col class="col-border-right" size="3">
              <div class="descriptor ion-text-center" id="piTitle">
                {{ "DASHPAGES.PROCESS_DETAIL_des0" | translate }}
              </div>
              <div class="text-box-big-black" id="piDataBold">{{notes.value}}</div>
            </ion-col>
            <ion-col>
              <div class="descriptor">{{ "DASHPAGES.PROCESS_DETAIL_des1" | translate }}</div>
              <div class="adjustment-grid-inline" id="behaviorList">
                <span *ngFor="let comp of notes.reasonNotes" class="adjustment-grid-inline-item">
                  <img alt="Check" class="adjustment-icon-tick" src="assets/images/check.svg">
                  <span class="grid-box-text">{{ comp.data }}</span>
                </span>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <hr class="hr-dashed">
    </div>
    <!-- Witing block END-->

    <!-- IMAGES BLOCK-->
    <div *ngIf="notes && notes.type ==='general'" class="detail-page-section">
      <div class="headline-medium">{{ "DASHPAGES.CONDITION_DETAIL_headline_medium" | translate }}</div>
      <image-gallery-modal [inputImages]="images"></image-gallery-modal>

      <!-- General Idea/ Fix Block -->
      <div *ngIf="notes && notes.type ==='general'" class="adjustment-grid">
        <ion-grid class="adjustment-grid-ion-grid">
          <ion-row>
            <ion-col class="col-border-right" size="6">

              <div class="descriptor">
                {{ "DASHPAGES.PROCESS_DETAIL_des2" | translate }}
              </div>

              <ul *ngIf="notes.generalIdea" class="notes-list">
                <li>
                  <div class="notes-list-user-img">
                    <img [src]="notes.generalIdea.userImg" alt="User" class="user-img">
                  </div>
                  <div class="notes-list-item-content">
                    <p>
                      <span class="body-copy-bold">{{notes.generalIdea.name}}</span> |
                      <span class="detail">{{notes.generalIdea.time}}</span>
                    </p>
                    <div>{{notes.generalIdea.data}}</div>
                  </div>
                </li>
              </ul>

              <div *ngIf="!notes.generalIdea" [appAccountCanViewExcept]="['viewer']">
                <button (click)="addGeneralIdea()"
                        class="button-link grid-box-underline">{{ "DASHPAGES.PROCESS_DETAIL_des3" | translate }}</button>
              </div>
              <ul *ngIf="notes.audioIdea" class="notes-list notes-list-audio">
                <li>
                  <div class="notes-list-user-img"><img [src]="notes.audioIdea.userImg" alt="User" class="user-img">
                  </div>
                  <div class="notes-list-item-content">
                    <p>
                      <span class="body-copy-bold">{{notes.audioIdea.name}}</span> |
                      <span class="detail">{{notes.audioIdea.time}}</span>
                    </p>
                    <audio *ngIf="notes.audioIdea.type ==='audio'" class="observation-audio" controls=""
                           preload="metadata">
                      <source [src]="notes.audioIdea.data" type="audio/mp4">
                      <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                    </audio>
                  </div>
                </li>
              </ul>
            </ion-col>
            <ion-col size="6">
              <div class="descriptor">
                {{ "DASHPAGES.PROCESS_DETAIL_des4" | translate }}
              </div>
              <ul *ngIf="notes.fixIdea" class="notes-list">
                <li>
                  <div class="notes-list-user-img"><img [src]="notes.fixIdea.userImg" alt="User" class="user-img"></div>
                  <div class="notes-list-item-content">
                    <p>
                      <span class="body-copy-bold">{{notes.fixIdea.name}}</span> |
                      <span class="detail">{{notes.fixIdea.time}}</span>
                    </p>
                    <div>{{notes.fixIdea.data}}</div>
                  </div>
                </li>
              </ul>

              <div *ngIf="!notes.fixIdea" [appAccountCanViewExcept]="['viewer']">
                <button (click)="addFixIdea()"
                        class="button-link grid-box-underline">{{ "DASHPAGES.PROCESS_DETAIL_des5" | translate }}</button>
              </div>
              <ul *ngIf="notes.audioFix" class="notes-list notes-list-audio">
                <li>
                  <div class="notes-list-user-img"><img [src]="notes.audioFix.userImg" alt="User" class="user-img">
                  </div>
                  <div class="notes-list-item-content">
                    <p>
                      <span class="body-copy-bold">{{notes.audioFix.name}}</span> |
                      <span class="detail">{{notes.audioFix.time}}</span>
                    </p>
                    <audio *ngIf="notes.audioFix.type ==='audio'" class="observation-audio" controls=""
                           preload="metadata">
                      <source [src]="notes.audioFix.data" type="audio/mp4">
                      <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                    </audio>
                  </div>
                </li>
              </ul>
            </ion-col>
          </ion-row>
        </ion-grid>

      </div>
      <!-- End General Idea/Fix Block-->

      <hr class="hr-dashed">
    </div>
    <!-- IMAGES BLOCK END-->


    <!-- NOTE BLOCK-->
    <div *ngIf="notes && notes.type ==='waiting'" class="detail-page-section">
      <div class="headline-medium headline-medium-with-button">{{ "SHARED.Observation_Notes" | translate }}
        <ion-button (click)="addClosedNote()" [appAccountCanViewExcept]="['viewer']" class="page-button smaller"
                    color="secondary">
          {{ "SHARED.Add_Note" | translate }}
        </ion-button>
      </div>

      <div class="section-grid">
        <!--NEW OBSERVATION NOTES-->
        <div class="section-grid-left">
          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.createdNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img"><img [src]="note.userImg" alt="User" class="user-img">
                </div>
                <div class="notes-list-item-content">
                  <p>
                    <span class="body-copy-bold">{{note.name}}</span> |
                    <span class="detail">{{note.time}}</span>
                  </p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown"
                       class="notes-list-item-text"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <hr class="hr-dashed">
      </div>

    </div>


    <!-- DOUBLE NOTE-->
    <div *ngIf="notes && notes.type ==='general'" class="detail-page-section">
      <div class="headline-medium">{{ "SHARED.Observation_Notes" | translate }}</div>

      <div class="section-grid">

        <!--NEW OBSERVATION NOTES-->
        <div class="section-grid-left">
          <div class="observation-notes-header">
            <span class="observation-notes-subhead subhead-quality" id="noteColorCode">
              {{ "SHARED.Submitted" | translate }}
            </span>
            <ion-button (click)="addOpenNote()" [appAccountCanViewExcept]="['viewer']" class="page-button smaller"
                        color="secondary">
              {{ "SHARED.Add_Note" | translate }}
            </ion-button>
          </div>
          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.createdNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img"><img [src]="note.userImg" alt="User" class="user-img"></div>
                <div class="notes-list-item-content">
                  <p>
                    <span class="observation-u-name">{{note.name}}</span> |
                    <span>{{note.time}}</span>
                  </p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown"
                       class="notes-list-item-text"></div>
                </div>
              </li>
            </ul>
          </div>

        </div>

        <!--FIXED OBSERVATION NOTES-->

        <div *ngIf="notes.doubleNotes" class="section-grid-right">
          <div class="observation-notes-header">
            <span class="observation-notes-subhead subhead-fixed">
              {{ "SHARED.Implemented" | translate }}
            </span>
            <ion-button (click)="addClosedNote()" [appAccountCanViewExcept]="['viewer']" class="page-button smaller"
                        color="secondary">
              {{ "SHARED.Add_Note" | translate }}
            </ion-button>
          </div>
          <div class="observation-notes-body">
            <ul class="notes-list" id="observationNotes">
              <li *ngFor="let note of notes.fixedNotes; let last = last" [last]="last" appMarkNote>
                <div class="notes-list-user-img"><img [src]="note.userImg" alt="User" class="user-img"></div>
                <div class="notes-list-item-content">
                  <p>
                    <span class="observation-u-name">{{note.name}}</span> |
                    <span>{{note.time}}</span>
                  </p>
                  <audio *ngIf="note.type ==='audio'" class="observation-audio" controls="" preload="metadata">
                    <source [src]="note.data" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                  <div *ngIf="note.type ==='text'" [innerHTML]="note.data | markdown"
                       class="notes-list-item-text"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <hr class="hr-dashed">
      </div>
    </div>
    <!-- DOUBLE NOTE END-->

    <!--OBSERTVATION HISTORY-->
    <app-observation-history [observationId]="oid" [title]="'SHARED.Observation_History'"></app-observation-history>

    <!--OBSERTVATION HISTORY END-->
    <!-- <div class="section-grid-footer">
      <app-detail-controls [headerData]="headerData"></app-detail-controls>
    </div> -->
  </div>
</ion-content>

<ion-footer *ngIf="bottomNav" [appAccountCanViewExcept]="['viewer']" no-border transparent>
  <ion-toolbar>
    <app-detail-footer [footerData]="footerData"></app-detail-footer>
  </ion-toolbar>
</ion-footer>
