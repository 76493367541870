import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-closing-details',
  templateUrl: './closing-details.component.html',
  styleUrls: ['./closing-details.component.scss'],
})
export class ClosingDetailsComponent {

  @Input() closingData: any;

  constructor(private router: Router) {
  }

  public scrollTo(targetId: string): void {
    const targetElement: HTMLElement = document.getElementById(targetId);
    targetElement.scrollIntoView({behavior: 'smooth'});
  }

  public editDetails(): void {
    this.router.navigate(['pages/dashboard/close-observation', {id: this.closingData.observationID}]);
  }

  public addNote(): void {
    this.router.navigate(['pages/dashboard/closing-note', {id: this.closingData.observationID}]);
  }

}
