import { Injectable } from '@angular/core';

import {
  AccountsService,
  CertificationsService,
  FilterElementService,
  FormBuilderService,
  FormConfig,
  FormField,
  Permission,
  RolesService,
  TeamsService,
  UserdataService,
  UserService,
  ZoneService,
} from '@services';
import { ShiftService } from '@services/shift/shift.service';
import { DatePickerComponent, Select2Type } from '@shared/components';

import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

export enum FilterMenuFormField {
  Locations = 'locations',
  Shifts = 'shift',
  Teams = 'groups',
  AccountType = 'accountType',
  PermissionLevel = 'permissionLevel',
  Zones = 'zones',
  Timespan = 'timespan',
  Role = 'roles',
  Certification = 'certifications',
  Users = 'users',
  SeverityLikelihood = 'sl',
  Active = 'active',
}

export interface FilterData {
  [key: string]: any;
}

export interface FilterMenuFormConfig {
  formClass?: string;
  menuClass?: string;
  title?: string;
  description?: string;
  fields?: (FilterMenuFormField | FormField)[];
  data?: FilterData;
  extendedFieldConfig?: { [fieldKey: string]: any };
  showOptionalFieldsWhileInit?: boolean;
  resetForm?: boolean;
}

export interface ToggleEvent {
  closeEvent: (data: FilterData) => void;
  config: FilterMenuFormConfig;
}

export interface FilterConfig {
  formConfig: FormConfig;
  config: FilterMenuFormConfig;
  getFieldsHandler: () => FormField[];
}

@Injectable({
  providedIn: 'root'
})
export class FilterMenuService {

  private onToggleSubject: Subject<ToggleEvent> = new Subject<ToggleEvent>();
  public onToggle: Observable<ToggleEvent> = this.onToggleSubject.asObservable().pipe(share());

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private userdataService: UserdataService,
    private teamsService: TeamsService,
    private zoneService: ZoneService,
    private formBuilderService: FormBuilderService,
    private shift: ShiftService,
    private roleService: RolesService,
    private certificationsService: CertificationsService,
    private accountService: AccountsService,
    private filterElementService: FilterElementService
  ) {
  }

  /**
   * Opens the filter menu based on provided config.
   *
   * @param config - configuration for the filter.
   *
   * @returns - a Promise that resolves when the filter is closed.
   *
   * @example
   * Returns applied filter based on form builder fields.
   *
   * const filterConfig: FilterMenuFormConfig = {
   *   fields: [
   *     {
   *       title: 'Name',
   *       name: 'name',
   *       type: 'selectmenu',
   *       options: [...],
   *     },
   *     {
   *       title: 'Options',
   *       name: 'options',
   *       type: 'checkbox',
   *       options: [...],
   *     }
   *   ]
   * };
   * const appliedFilter = async open(filterConfig);
   *
   * @example
   * Returns applied filter based on predefined fields.
   *
   * const filterConfig = { fields: [FilterMenuFormField.Locations, FilterMenuFormField.Role] };
   * const appliedFilter = async open(filterConfig);
   */
  public open(config: FilterMenuFormConfig): Promise<any> {
    return new Promise((resolve: (data: FilterData) => void) => {
      this.onToggleSubject.next({closeEvent: (data) => resolve(data), config: _.cloneDeep(config)});
    });
  }

  public getFieldByType(type: FilterMenuFormField, {formConfig, config, getFieldsHandler}: FilterConfig): FormField {
    let field = null;
    let locationsIds = this.userdataService.locations;

    if (_.includes(config.fields, FilterMenuFormField.Locations) && _.keys(config.data?.locations).length) {
      locationsIds = config.data?.locations || this.userdataService.locations;
    }

    if (_.includes(config.fields, FilterMenuFormField.Zones) && config.data?.zones) {
      config.data.zones = _.map(config.data.zones, String);
    }

    if (type === FilterMenuFormField.Locations) {
      field = {
        title: this.translate.instant('SHARED.Locations'),
        name: 'locations',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Locations'),
        multiple: true,
        valueProperty: 'locationID',
        options: this.userService.getUserLocations(this.userdataService.locations, false),
        func: (ref) => ref.name,
        onChange: (locationIds: string[]) => {
          let ids: number[] = _.map(locationIds, Number);
          if (_.isEmpty(ids)) {
            ids = this.userdataService.locations;
          }
          const locations = _.map(ids, (id) => this.userService.getLocation(id));
          const fields = getFieldsHandler();
          this.limitFieldOptionBy('zones', formConfig, this.zoneService.getGroupedZonesByLocations(ids), fields);
          this.limitFieldOptionBy('groups', formConfig, this.filterElementService.buildTeamMenu(ids, false)?.dropDownOptions, fields);
          this.limitFieldOptionBy('shift', formConfig, this.shift.getSelectMenuObjectsByLocations(locations), fields);
          this.limitFieldOptionBy('users', formConfig, this.accountService.getUserlist(ids), fields);
        }
      };
    } else if (type === FilterMenuFormField.Shifts) {
      const locations = _.map(locationsIds, (locationId) => this.userService.getLocation(locationId));

      field = {
        title: this.translate.instant('SHARED.Shift'),
        name: 'shift',
        type: 'selectmenu',
        placeholder: this.translate.instant('OTable.All_Shifts'),
        multiple: true,
        options: this.shift.getSelectMenuObjectsByLocations(locations)
      };
    } else if (type === FilterMenuFormField.Teams) {
      field = {
        title: this.translate.instant('SHARED.Team(s)'),
        name: 'groups',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Teams'),
        multiple: true,
        options: this.filterElementService.buildTeamMenu(locationsIds, false)?.dropDownOptions,
        test: (team: any) => !_.get(team, 'disabledAt') && team.groupID !== -1,
        func: (team: any) => this.teamsService.teamName(team),
      };
    } else if (type === FilterMenuFormField.AccountType) {
      field = {
        title: this.translate.instant('OTable.Account_Type'),
        name: 'accountType',
        type: 'selectmenu',
        placeholder: this.translate.instant('OTable.All_Types'),
        multiple: true,
        options: [
          {id: 'dedicated', text: this.translate.instant('SHARED.Dedicated')},
          {id: 'shared', text: this.translate.instant('SHARED.Shared')},
          {id: 'viewer', text: this.translate.instant('SHARED.Viewer')},
          {id: 'reporting', text: this.translate.instant('SHARED.Reporting')},
          {id: 'observer', text: this.translate.instant('SHARED.Observer')}
        ],
        func: (account: any) => account.text
      };
    } else if (type === FilterMenuFormField.Active) {
      field = {
        title: this.translate.instant('REPORTING.User_Status'),
        name: 'active',
        type: 'selectmenu',
        placeholder: this.translate.instant('OTable.ALL_STATUSES'),
        multiple: true,
        options: [
          {
            id: 'active',
            text: this.translate.instant('SHARED.Active')
          },
          {
            id: 'inactive',
            text: this.translate.instant('SHARED.Inactive')
          },
        ],
        func: (active: any) => active.text
      };
    } else if (type === FilterMenuFormField.PermissionLevel) {
      field = {
        title: this.translate.instant('OTable.Permission_Level'),
        name: 'permissionLevel',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Permissions'),
        multiple: true,
        options: [
          {id: Permission.Worker, text: this.translate.instant('SHARED.Worker')},
          {id: Permission.Helper, text: this.translate.instant('SHARED.Helper')},
          {id: Permission.Supervisor, text: this.translate.instant('SHARED.Supervisor')},
          {id: Permission.Admin, text: this.translate.instant('SHARED.Administration')},
          {id: Permission.SuperAdmin, text: this.translate.instant('SHARED.Global_Administration')}
        ],
        func: (permission: any) => permission.text
      };
    } else if (type === FilterMenuFormField.Zones) {
      field = {
        title: this.translate.instant('SHARED.Zones'),
        name: 'zones',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Zones'),
        multiple: true,
        options: this.zoneService.getGroupedZonesByLocations(locationsIds)
      };
    } else if (type === FilterMenuFormField.Timespan) {
      field = {
        title: this.translate.instant('SHARED.Timespan'),
        name: 'timespan',
        type: 'customElement',
        component: DatePickerComponent,
        inputs: {
          dateConfig: {
            value: _.get(config, 'data.timespan.type'),
            range: _.get(config, 'data.timespan.range'),
            options: {
              placeholder: {
                id: '',
                text: this.translate.instant('OTable.All_History')
              },
              minimumResultsForSearch: Infinity,
              width: '100%',
              sorter: (data) => data
            },
            types: [
              Select2Type.Past7Days,
              Select2Type.Past14Days,
              Select2Type.Past30Days,
              Select2Type.Past90Days,
              Select2Type.ThisWeek,
              Select2Type.LastWeek,
              Select2Type.ThisMonth,
              Select2Type.LastMonth,
              Select2Type.ThisFiscalQuarter,
              Select2Type.ThisQuarter,
              Select2Type.LastFiscalQuarter,
              Select2Type.LastQuarter,
              Select2Type.ThisFiscalYear,
              Select2Type.ThisYear,
              Select2Type.LastFiscalYear,
              Select2Type.LastYear,
              Select2Type.All,
              Select2Type.Custom
            ]
          }
        }
      };
    } else if (type === FilterMenuFormField.Role) {
      field = {
        name: 'roles',
        title: this.translate.instant('LAYOUT.Roles'),
        placeholder: this.translate.instant('SHARED.All_Roles'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'roleID',
        options: this.roleService.roles,
        test: (ref) => !_.get(ref, 'disabledAt'),
        func: (role: any) => this.roleService.roleName(role)
      };
    } else if (type === FilterMenuFormField.Certification) {
      field = {
        title: this.translate.instant('SHARED.Certifications'),
        placeholder: this.translate.instant('SHARED.All_Certifications'),
        name: 'certifications',
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'certificationID',
        options: this.certificationsService.certifications,
        func: (certification) => this.formBuilderService.certificationName(certification),
        test: (certification) => !_.get(certification, 'disabledAt')
      };
    } else if (type === FilterMenuFormField.Users) {
      field = {
        title: this.translate.instant('SHARED.User(s)'),
        name: 'users',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('SHARED.All_Users'),
        valueProperty: 'userID',
        options: this.accountService.getUserlist(locationsIds),
        func: (ref) => this.userService.getFullname(ref.userID)
      };
    } else if (type === FilterMenuFormField.SeverityLikelihood) {
      field = {
        title: 'S + L',
        name: 'sl',
        type: 'selectmenu',
        multiple: true,
        placeholder: this.translate.instant('OTable.All_S_L'),
        selectOptions: {
          templateResult: this.filterElementService.formatState,
          templateSelection: this.filterElementService.formatSelection,
        },
        options: [
          {
            text: this.translate.instant('SHARED.Severity'),
            children: [
              {id: 'highest-S', text: 'Sev'},
              {id: 'high-S', text: this.translate.instant('OTable.Sev')},
              {id: 'medium-S', text: this.translate.instant('OTable.Sev')},
              {id: 'low-S', text: this.translate.instant('OTable.Sev')},
              {id: 'lowest-S', text: this.translate.instant('OTable.Sev')}
            ]
          },
          {
            text: 'Likelihood',
            children: [
              {id: 'highest-L', text: this.translate.instant('OTable.Lik')},
              {id: 'high-L', text: this.translate.instant('OTable.Lik')},
              {id: 'medium-L', text: this.translate.instant('OTable.Lik')},
              {id: 'low-L', text: this.translate.instant('OTable.Lik')},
              {id: 'lowest-L', text: this.translate.instant('OTable.Lik')}
            ]
          }
        ]
      };
    }

    return field;
  }

  private limitFieldOptionBy(field: string, formConfig: FormConfig, options: any[], formFields: FormField[]) {
    const targetField: FormField = _.find(formFields, {name: field});

    if (targetField) {
      Object.assign(targetField, {options});
      this.formBuilderService.replaceSelectOptionsWith(`#${formConfig.prefix}${field}`, formConfig, targetField, {[field]: targetField.getValue()});
    }
  }
}
