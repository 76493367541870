import { Component, NgZone, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Events } from '@services/events/events.service';

import {
  IHeaderSetting,
  TableService,
  FilterElementService,
  ResizeHandlerService,
  LoadingService,
  Module
} from '@services';
import { ObservationTableService } from '@services/observationTableService/observation-table-service.service';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-quality-table',
  templateUrl: './quality-table.page.html',
  styleUrls: ['./quality-table.page.scss'],
})
export class QualityTablePage {

  public headerSetting: IHeaderSetting = {
    title: this.translate.instant('SHARED.Quality_Issues'),
    filter: {
      showFilter: true,
      isActive: false
    },
    showSearchField: true,
    selectDropDown: {
      dataNavMenuKey: Module.OBSERVATIONS,
      option: {
        minimumResultsForSearch: Infinity,
        placeholder: this.translate.instant('SHARED.COACHING_TABLE_placeholder'),
        width: '15em',
        closeOnSelect: true,
        sorter: (data) => data
      },
      defaultVal: null
    }
  };

  // prepare tabs
  public tabs: any = [
    {
      name: this.translate.instant('SHARED.Open'),
      selected: true,
      active: true,
      count: 0,
      id: 'qualityOpen'
    },
    {
      name: this.translate.instant('SHARED.Receiving'),
      selected: false,
      active: true,
      count: 0,
      id: 'qualityReceiving'
    },
    {
      name: this.translate.instant('DASHPAGES.QUALITY_DETAIL_des3'),
      selected: false,
      active: true,
      count: 0,
      id: 'qualityFixed'
    },
    {
      name: this.translate.instant('SHARED.Closed'),
      selected: false,
      active: true,
      count: 0,
      id: 'qualityClosed'
    }
  ];

  public preSelected: any = _.first(this.tabs);
  public filterObject = this.observationTableService.filterObject;
  public filterComps: any = [];
  public showFilterBody = false;
  public searchField: any = '';
  private tableData: any = {};
  private menuItem: any = ['location', 'team', 'user', 'zone', 'timespan'];
  private filterString: string = null;
  private clearFilter = false;
  private isNeededTableRefreshing: any = {};

  constructor(
    private observationTableService: ObservationTableService,
    private events: Events,
    private zone: NgZone,
    private filterElem: FilterElementService,
    private tableService: TableService,
    private resizeHandler: ResizeHandlerService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private elementRef: ElementRef
  ) {
  }

  ionViewWillLeave() {
    this.resizeHandler.removeResizeHandler('qualityTable');
    this.events.unsubscribe('ccs:filterObject', this.filterListener);
    this.events.unsubscribe('ccs:clearMenu', this.clearMenuListener);
    this.events.unsubscribe('ccs:observationUpdate', this.observationUpdateListener);
    this.events.unsubscribe('ccs:searchString', this.searchStringListener);

    if (this.clearFilter) {
      this.observationTableService.clearFilterObject();
    }
  }

  ionViewDidEnter() {
    const clearSearch: string = this.route.snapshot.queryParamMap.get('clearSearch');
    this.clearFilter = this.route.snapshot.queryParamMap.get('clearFilter') === 'true';
    if (clearSearch) {
      this.searchField = '';
      this.observationTableService.filterObject.searchString = null;
      $('.observation-search-field').val(null);
    } else {
      this.searchField = this.observationTableService.filterObject.searchString;
      $('.observation-search-field').val(this.searchField);
    }

    this.events.subscribe('ccs:observationUpdate', this.observationUpdateListener);
    this.events.subscribe('ccs:filterObject', this.filterListener);
    this.events.subscribe('ccs:searchString', this.searchStringListener);
    this.events.subscribe('ccs:clearMenu', this.clearMenuListener);

    this.prepData();
    this.preSelected = _.find(this.tabs, ['selected', true]);

    const activeType: string = this.route.snapshot.queryParamMap.get('activeTab');
    const activeTab = _.find(this.tabs, {id: activeType});

    if (activeTab) {
      this.toggle(activeTab, false);
      this.preSelected = activeTab;
    } else {
      this.buildTableElements(this.preSelected);
      this.markTablesForNextUpdate();
    }

    this.filterComps = this.filterElem.buildFilterObjects(this.menuItem, this.observationTableService.filterObject);
    this.headerSetting.filter.isActive = this.observationTableService.filterValuesAvailable(this.filterComps);
    this.resizeHandler.addResizeHandler('qualityTable', () => this.tableService.handleResize('.dataTables_scrollBody:visible:last', 50, null));
  }

  public toggle(tab, isTabChanging: boolean = true) {
    // find selected first
    const prevObj = _.find(this.tabs, ['selected', true]);
    prevObj.selected = false;
    this.preSelected = _.find(this.tabs, ['id', tab.id]);
    this.preSelected.selected = true;
    this.buildTableElements(tab, isTabChanging);

    if (prevObj.id !== tab.id) {
      this.observationTableService.updateRouterActiveTab(tab.id);
    }
  }

  public toggleFilterBody(val) {
    this.showFilterBody = val;
    // check if custom is active on time span
    const timeBlock: any = _.find(this.filterComps, {id: 'timespan'});
    if (timeBlock.value[0] === 'custom') {
      $(this.elementRef.nativeElement).find('#timespan select').val([]);
    }
  }

  private searchStringListener = (data) => {
    this.observationTableService.filterObject = data; // get filter elem
    this.prepData();
    this.buildTableElements(this.preSelected, false, true, true);
    this.markTablesForNextUpdate();
  };

  private observationUpdateListener = (data) => {
    if (data) {
      this.prepData();
      this.buildTableElements(this.preSelected);
      this.markTablesForNextUpdate();
    }
  };

  private filterListener = (data) => {
    this.observationTableService.filterObject = data; // get filter elem
  };

  private clearMenuListener = (data) => {
    if (data) {
      this.zone.run(() => {
        // tslint:disable-next-line:max-line-length
        this.filterComps = this.filterElem.buildFilterObjects(this.menuItem, this.observationTableService.filterObject);
        let checkFilterActive = false;
        _.each(this.filterComps, comp => {
          if (comp.value && comp.value.length > 0) {
            checkFilterActive = true;
            return false;
          }
        });
        this.headerSetting.filter.isActive = checkFilterActive;
        this.prepData();

        setTimeout(() => {
          this.buildTableElements(this.preSelected);
          this.markTablesForNextUpdate();
        });
      });
    }
  };

  private prepData() {
    // need to update end time to capture new incoming observaitons
    if (this.observationTableService.filterObject.endTime && this.observationTableService.filterObject.timespan !== 'custom') {
      const endTimeNow = Date.now();
      this.observationTableService.filterObject.endTime = endTimeNow;
    }
    if (!this.filterObject.timespan) {
      this.filterObject.timespan = 'all';
    }
    const rawData = this.observationTableService.getObservation('quality');
    this.tableData = {}; // empty out before collection


    // collect open stuff
    if (rawData.rows.new || rawData.rows.escalated) {
      let openBucket: any = [];
      if (rawData.rows.new) { // if both new and escalated exist, put them in one
        if (rawData.rows.escalated) {
          openBucket = _.concat(rawData.rows.new.items, rawData.rows.escalated.items);
        } else { // if new is present, but escalated is not.
          openBucket = rawData.rows.new.items;
        }
      } else { // if only escalated is present
        openBucket = rawData.rows.escalated.items;
      }
      // break into quality Open and quality Receiving
      this.tableData.qualityOpen = _.filter(openBucket, data => {
        if (data.subtype !== 'receiving') {
          return true;
        }
      });
    } else {
      this.tableData.qualityOpen = [];
    }

    // collect fixed and scrapped
    if (rawData.rows.fixed || rawData.rows.dropped) {
      let fixedBucket: any = [];
      if (rawData.rows.fixed) { // if both fixed and dropped exist, put them in one
        if (rawData.rows.dropped) {
          fixedBucket = _.concat(rawData.rows.fixed.items, rawData.rows.dropped.items);
        } else { // if fixed is present, but dropped is not.
          fixedBucket = rawData.rows.fixed.items;
        }
      } else { // if only dropped is present
        fixedBucket = rawData.rows.dropped.items;
      }
      this.tableData.qualityFixed = _.filter(fixedBucket, data => {
        if (data.subtype !== 'receiving') {
          return true;
        }
      });
      this.tableData.qualityReceiving = _.filter(fixedBucket, data => {
        if (data.subtype === 'receiving') {
          return true;
        }
      });
    } else {
      this.tableData.qualityFixed = [];
      this.tableData.qualityReceiving = [];
    }

    // collected closed
    if (rawData.rows.resolved) {
      this.tableData.qualityClosed = rawData.rows.resolved.items;
    } else {
      this.tableData.qualityClosed = [];
    }
    this.tabs = this.observationTableService.fillCount(this.tabs, this.tableData);
    this.filterString = this.filterObject.searchString;
  }

  private buildTableElements(tab, isTabChanging?: boolean, recalculate?: boolean, disableLoading?: boolean) {
    this.observationTableService.buildTableByTab({
      tab,
      tableData: this.tableData,
      filterString: this.filterString,
      loadingState: this.isNeededTableRefreshing,
      isTabChanging,
      recalculate,
      disableLoading
    });
  }

  private markTablesForNextUpdate() {
    _.each(this.tabs, (tab) => {
      this.isNeededTableRefreshing[tab.id] = true;
    });
  }
}
