import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DataTableOptions, TableService } from '@services';

import * as uuid from 'uuid';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnChanges {
  public tableId = `dataTable_${uuid.v4()}`;
  @Input() dataTableOptions: DataTableOptions;
  @Input() selectedIndex: number;

  private table;

  constructor(
    private tableService: TableService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.render();
    })
  }

  render() {
    this.table = this.tableService.showDataTable(`#${this.tableId}`, this.dataTableOptions);

    if (this.selectedIndex > -1) {
      this.table.row(`:eq(${this.selectedIndex})`).select();
    }
  }
}
