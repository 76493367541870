import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  AppCarousel,
  CaDetailPage,
  CaTablePage,
  ChangingTypePage,
  CheckDetailPage,
  CheckResultTablePage,
  CoachingDetailPage,
  CoachingTablePage,
  ComplimentDetailPage,
  ComplimentTablePage,
  ConditionDetailPage,
  ConditionTablePage,
  EditQualityFieldsPage,
  ExternalDataDetailPage,
  HomePage,
  IssueDetailPage,
  LocationDashboardPage,
  MarkResolvedPage,
  ObservationHomeDashboardPage,
  ProcessDetailPage,
  ProcessTablePage,
  QualityDetailPage,
  QualityTablePage,
  UnableToPerformReasonPage,
  UnknownObservationPage
} from './pages';
import { ObservationLinkGuard } from '@modules/observation/services/observationLink/observation-link.guard';
import { GuardGuard } from '@services/guard/guard.guard';
import { AccountGuard } from '@services/accountGuard/account.guard';
import { AccountTypes, Feature, IModuleAccess, Module, ModuleGuard, Permission } from '@services';
import { DataResolverService } from '@modules/observation/services/dataResolver/data-resolver.service';

import { MarkdownModule } from 'ngx-markdown';


const routes: Routes = [
  {
    path: 'main',
    component: ObservationHomeDashboardPage,
    data: {
      permission: Permission.Supervisor,
      moduleAccess: <IModuleAccess>{
        module: Module.OBSERVATIONS,
        feature: Feature.OBSERVATION_ACTIVITY
      }
    },
    canActivate: [GuardGuard, ModuleGuard]
  },
  {
    path: 'location-dashboard',
    component: LocationDashboardPage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
    },
    canActivate: [AccountGuard]
  },
  {
    path: 'condition-table',
    component: ConditionTablePage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'quality-table',
    component: QualityTablePage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'coaching-table',
    component: CoachingTablePage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'compliment-table',
    component: ComplimentTablePage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'process-table',
    component: ProcessTablePage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'home',
    component: HomePage,
    data: {
      permission: Permission.Admin,
      moduleAccess: <IModuleAccess>{
        module: Module.SITE_AWARENESS,
        feature: Feature.SITE_AWARENESS,
        subscriberAccess: {
          account: 'site_awareness_user_access',
          user: 'site_awareness_specific_users'
        }
      }
    },
    canActivate: [GuardGuard, ModuleGuard]
  },
  {
    path: 'worker',
    canActivate: [ModuleGuard],
    loadChildren: () => import('./pages/worker/worker.module').then(m => m.WorkerModule),
    data: {
      permission: Permission.Supervisor,
      moduleAccess: <IModuleAccess>{
        module: Module.WORKER_STATUS,
        feature: Feature.WORKER_STATUS
      }
    }
  },
  {
    path: 'condition-detail/:id',
    component: ConditionDetailPage,
    resolve: {tags: DataResolverService}
  },
  {
    path: 'quality-detail/:id',
    component: QualityDetailPage,
    resolve: {tags: DataResolverService}
  },
  {
    path: 'coaching-detail/:id',
    component: CoachingDetailPage,
    resolve: {tags: DataResolverService}
  },
  {
    path: 'compliment-detail/:id',
    component: ComplimentDetailPage,
    resolve: {tags: DataResolverService}
  },
  {
    path: 'process-detail/:id',
    component: ProcessDetailPage,
    resolve: {tags: DataResolverService}
  },
  {
    path: 'changing-type/:id',
    component: ChangingTypePage,
    data: {
      headerConfig: {
        isHidden: true
      }
    }
  },
  {
    path: 'app-carousel',
    component: AppCarousel,
    data: {
      headerConfig: {
        isHidden: true
      }
    }
  },
  {
    path: 'edit-quality-field/:id',
    component: EditQualityFieldsPage,
    data: {
      headerConfig: {
        isHidden: true
      },
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
    },
    canActivate: [AccountGuard]
  },
  {
    canActivate: [ObservationLinkGuard],
    path: 'observation/:id'
  },
  {
    path: 'unknown-observation',
    component: UnknownObservationPage
  },
  {
    path: 'close-observation',
    loadChildren: () => import('./pages/close-observation/close-observation.module').then(m => m.CloseObservationPageModule),
    data: {
      headerConfig: {
        isHidden: true
      }
    }
  },
  {
    path: 'mark-resolve',
    component: MarkResolvedPage,
    data: {
      headerConfig: {
        isHidden: true
      }
    }
  },
  {
    path: 'closing-note',
    loadChildren: () => import('./pages/closing-note/closing-note.module').then(m => m.ClosingNotePageModule),
    data: {
      headerConfig: {
        isHidden: true
      }
    }
  },
  {
    path: 'supervisor-dashboard',
    loadChildren: () => import('./pages/supervisor-dashboard/supervisor-dashboard.module').then(m => m.SupervisorDashboardPageModule),
    data: {
      permission: Permission.Supervisor,
      moduleAccess: <IModuleAccess>{
        module: Module.SUPERVISOR_DASHBOARD,
        feature: Feature.SUPERVISOR_DASHBOARD,
      }
    },
    canActivate: [GuardGuard, ModuleGuard]
  },
  {
    path: 'leaderboards',
    loadChildren: () => import('./pages/leaderboard/leaderboard.module').then(m => m.LeaderboardModule),
    data: {
      permission: Permission.Admin,
      moduleAccess: <IModuleAccess>{
        module: Module.LEADERBOARDS,
        feature: Feature.LEADERBOARDS,
        subscriberAccess: {
          account: 'leaderboards_user_access',
          user: 'leaderboards_specific_users'
        }
      }
    },
    canActivate: [GuardGuard, ModuleGuard]
  },
  {
    path: 'worker-zone',
    loadChildren: () => import('./pages/worker-zone/worker-zone.module').then(m => m.WorkerZonePageModule),
    canActivate: [ModuleGuard],
    data: {
      permission: Permission.Supervisor,
      moduleAccess: <IModuleAccess>{
        module: Module.WORKER_STATUS,
        feature: Feature.WORKER_STATUS,
      },
    },
  },
  {
    path: 'frontline-culture',
    loadChildren: () => import('./pages/frontline-culture/frontline-culture.module').then(m => m.FrontlineCultureModule),
    canActivate: [ModuleGuard],
    data: {
      permission: Permission.Supervisor,
      moduleAccess: <IModuleAccess>{
        module: Module.CULTURE,
        feature: Feature.CULTURE_DASHBOARD,
      },
    },
  },
  {
    path: 'worker-zone-detail',
    loadChildren: () => import('./pages/worker-zone-detail/worker-zone-detail.module').then(m => m.WorkerZoneDetailPageModule),
    canActivate: [ModuleGuard],
    data: {
      permission: Permission.Supervisor,
      moduleAccess: <IModuleAccess>{
        module: Module.WORKER_STATUS,
        feature: Feature.WORKER_STATUS,
      },
      headerConfig: {
        isHidden: true
      }
    },
  },
  {
    path: 'ca-table',
    component: CaTablePage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'ca-detail/:id',
    component: CaDetailPage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'check-detail/:id',
    component: CheckDetailPage,
    data: {
      headerConfig: {
        isHidden: true
      },
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'unable-to-perform/:id',
    component: UnableToPerformReasonPage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting],
      headerConfig: {
        isHidden: true
      }
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'check-result-table/:id/:target',
    component: CheckResultTablePage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting],
      headerConfig: {
        isHidden: true
      }
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'check-type-table/:id',
    loadChildren: () => import('./pages/check-type-table/check-type-table.module').then(m => m.CheckTypeTableModule),
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting],
      headerConfig: {
        isHidden: true
      }
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'issue-detail/:rid/:fid',
    component: IssueDetailPage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting],
      headerConfig: {
        isHidden: true
      }
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'view-validation/:rid/:questionId',
    component: IssueDetailPage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting],
      headerConfig: {
        isHidden: true
      }
    },
    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: 'preview',
    loadChildren: () => import('./pages/leaderboard/pages/preview/preview.module').then(m => m.PreviewPageModule),
  },
  {
    path: 'achievement-report',
    loadChildren: () => import('./pages/achievement-report/achievementReport.module').then(m => m.AchievementReportModule),
    data: {
      permission: Permission.Admin,
      moduleAccess: <IModuleAccess>{
        module: Module.LEADERBOARDS,
        feature: Feature.LEADERBOARDS,
        subscriberAccess: {
          account: 'leaderboards_user_access',
          user: 'leaderboards_specific_users'
        }
      },
      headerConfig: {
        isHidden: true
      }
    },
    canActivate: [GuardGuard, ModuleGuard]
  },
  {
    path: 'opportunities',
    loadChildren: () => import('./pages/insights/insights.module').then(m => m.InsightsPageModule),
    canActivate: [GuardGuard, ModuleGuard],
    resolve: {data: DataResolverService},
    data: {
      permission: Permission.Supervisor,
      moduleAccess: <IModuleAccess>{
        module: Module.OPPORTUNITIES
      }
    }
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackPageModule),
    canActivate: [GuardGuard, ModuleGuard],
    data: {
      permission: Permission.Supervisor,
      moduleAccess: <IModuleAccess>{
        module: Module.INQUIRIES
      }
    },
  },
  {
    path: 'pdca-list',
    loadChildren: () => import('../pdca/pdca.module').then(m => m.PdcaPageModule),
    canActivate: [GuardGuard, ModuleGuard],
    data: {
      permission: Permission.Supervisor,
      moduleAccess: <IModuleAccess>{
        module: Module.PDCA,
        feature: Feature.PDCA
      }
    },
  },
  {
    path: 'expand-view',
    loadChildren: () => import('./pages/supervisor-dashboard/pages/expand-view/expand-view.module').then(m => m.ExpandViewPageModule),
    data: {
      headerConfig: {
        isHidden: true
      }
    },
  },
  {
    path: 'external-detail/:id',
    component: ExternalDataDetailPage,
    data: {
      canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting],
      headerConfig: {
        isHidden: true
      }
    },

    canActivate: [AccountGuard],
    resolve: {tags: DataResolverService}
  },
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'main'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    MarkdownModule.forChild()],
  exports: [RouterModule]
})
export class DashboardRoutingModule {
}
