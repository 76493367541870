import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { Events } from '@services/events/events.service';
import { BaseService } from './../abstract-base-service/abstract-base-service.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AssetsService extends BaseService {

  public filterObject: any = {};

  asset: any = {
    data: [],
    lastRequest: null
  };

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private events: Events
  ) {
    super(commsService);
  }

  public refresh(updated: Number = 0) {
    if (updated && updated < this.asset.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.asset.lastRequest}`);
      return Promise.resolve(this.asset.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getAssets',
          includeDisabled: 1,
          lastRequest: this.asset.lastRequest,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
          }
          resolve(_.sortBy(this.asset.data, 'assetID'));
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public updateCache(data) {
    this.asset.lastRequest = data.result.timestamp;
    this.asset.data = data.result.assets;
    this.events.publish('ccs:assetsUpdate');
  }

  public clearCache() {
    this.asset.lastRequest = null;
    this.asset.data = null;
  }

  public handleAddAsset(fData) {
    fData.cmd = 'addAsset';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  public handleUpdateAsset(fData) {
    fData.cmd = 'updateAssets';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  handleDeleteAsset(fData) {
    fData.assets = JSON.stringify(fData);
    fData.cmd = 'deleteAssets';
    return this.handleRequest(fData);
  }

  public removeAssets(assetList: string[]): Promise<any> {
    const cmd = {
      cmd: 'deleteAssets',
      assets: JSON.stringify(assetList)
    };

    return this.handleRequest(cmd);
  }


  public getAssetById(id: number) {
    return _.filter(this.asset.data, ['assetID', +id]);
  }

  public getAssets(assetTypeIDs) {
    return new Promise((resolve, reject) => {
      const when = Date.now();
      this.commsService.sendMessage({
        cmd: 'getAssets',
        includeDisabled: 1,
        lastRequest: this.asset.lastRequest,
        sendTime: when,
        assetTypes: assetTypeIDs
      }, false, false).then(data => {
        if (data && data.reqStatus === 'OK') {
          this.asset.lastRequest = data.result.timestamp;
          this.asset.data = data.result.assets;
        }
        resolve(_.sortBy(this.asset.data, 'assetID'));
      }).catch((err) => {
        reject(err);
      });
    });
  }


  public getAssetsByType(assetTypeIDs?: any) {
    if (assetTypeIDs && _.isArray(assetTypeIDs)) {
      // find all of these types and return them
      let l = [];
      _.each(assetTypeIDs, id => {
        const list = _.filter(this.asset.data, {checkType: +id});
        l = _.union(l, list);
      });
      // now we have an ARRAY of matching assets; convert it to an object
      return _.keyBy(l, 'assetID');
    } else {
      return this.asset.data;
    }
  }

  public getAssetLocation(assetID: number): number {
    const a: any = this.getAssetById(assetID);
    if (a) {
      return a.location;
    } else {
      return null;
    }
  }

  public getAssetsByLocations(locationIds: number[] = []) {
    if (locationIds.length) {
      return _.filter(this.asset.data, (assetItem) => _.includes(locationIds, assetItem.location));
    } else {
      return this.asset.data;
    }
  }

  public getAssetsByZoneId(zoneId) {
    return _.filter(this.asset.data, (asset) => asset.zone === zoneId);
  }

  public getAssetsByName(name: string) {
    return _.filter(this.asset.data, (asset) => asset.name === name);
  }

}
