import { Injectable, Injector } from '@angular/core';

import {
  AssetsService,
  ChecksService,
  ObservationService,
  PermissionsService,
  ShiftService,
  TeamsService,
  UserService,
  UtilsService,
  ZoneService
} from '@services';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { DeploymentService } from '@modules/management/pages/details/check/services';
import { ReportingFormBuilderService } from '@modules/reporting/services/reporting-form-builder.service';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'lodash';

export enum IDetailReportTableType {
  Observation = 'observation',
  Worker = 'worker',
  Check = 'check',
  PPESummary = 'PPESummary'
}

@Injectable({
  providedIn: 'root'
})
export class DetailReportTableService {

  private queryParams: any = {};
  private tables: any = {
    [IDetailReportTableType.Observation]: {
      class: 'detailReport',
      rowprop: 'data-mid',
      rowvaluefrom: 'observationID',
      columns: [
        {
          id: 'observationID',
          title: this.translate.instant('SHARED.Response_ID'),
          headerClass: 'table-header'
        },
        {
          id: 'type',
          title: this.translate.instant('SHARED.TYPE'),
          headerClass: 'table-header',
          func: (type: string, observation: any) => this.translate.instant(this.observationService.typeMap[this.observationService.getProperty(observation, 'type')])
        },
        {
          id: 'subtype',
          title: this.translate.instant('SHARED.SUBTYPE'),
          headerClass: 'table-header',
          func: (type: string, observation: any) => {
            const f = this.observationService.getProperty(observation, 'subtype');
            if (this.observationService.subtypeMap[f]) {
              return this.observationService.subtypeMap[f];
            } else {
              return f;
            }
          }
        },
        {
          id: 'userID',
          title: this.translate.instant('REPORTING.CREATOR'),
          headerClass: 'table-header',
          func: (userID: number) => this.userService.getFullname(userID)
        },
        {
          id: 'created',
          title: this.translate.instant('REPORTING.CREATED'),
          func: (time) => moment(time * 1000).fromNow(),
          headerClass: 'table-header'
        },
        {
          id: 'ownerID',
          title: this.translate.instant('SHARED.OWNER'),
          func: (ownerID: number) => this.userService.getFullname(ownerID),
          headerClass: 'table-header'
        },
        {
          id: 'groupID',
          title: this.translate.instant('SHARED.TEAM'),
          func: (groupID: number) => this.teamsService.teamNameByID(groupID),
          headerClass: 'table-header'
        }
      ],
      onClickRow: (messageID: string): any => {
        const routeParams: any = {
          reportId: this.queryParams.reportId,
          headerIsHidden: true,
          prevState: this.queryParams.prevState
        };
        this.observationDetailService.navigateToDetail(this.observationService.observations.data[messageID], [], false, false, routeParams);
      }
    },
    [IDetailReportTableType.Worker]: {
      class: 'detailReport',
      rowprop: 'data-mid',
      rowvaluefrom: 'userID',
      columns: [
        {
          id: 'userID',
          title: 'User Id',
          headerClass: 'table-header'
        },
        {
          id: 'type',
          title: this.translate.instant('SHARED.TYPE'),
          headerClass: 'table-header no-vis',
          func: (val) => {
            if (val === undefined || val === null || val === '' || val === 'individual' || val === 'dedicated') {
              return 'Dedicated';
            } else if (val === 'shared') {
              return 'Shared';
            } else if (val === 'observation') {
              return 'Observation';
            } else if (val === 'reporting') {
              return 'Reporting';
            } else if (val === 'coordinator') {
              return 'Coordinator';
            } else if (val === 'wristband') {
              return 'Wristband-Only';
            } else if (val === 'observer') {
              return 'Observer';
            } else if (val === 'viewer') {
              return 'Viewer';
            }
          }
        },
        {
          id: 'username',
          title: this.translate.instant('MGMT_LIST.USERNAME'),
          headerClass: 'table-header'
        },
        {
          id: 'lastname',
          title: this.translate.instant('MGMT_LIST.LAST_NAME'),
          headerClass: 'table-header'
        },
        {
          id: 'firstname',
          title: this.translate.instant('MGMT_LIST.FIRST_NAME'),
          headerClass: 'table-header'
        },
        {
          id: 'email',
          title: this.translate.instant('MGMT_LIST.EMAIL'),
          headerClass: 'table-header'
        },
        {
          id: 'permissions',
          title: this.translate.instant('MGMT_LIST.PERMISSIONS'),
          headerClass: 'table-header',
          fromID: 'permissions',
          func: (permissions) => {
            let Tstring = '';
            if (permissions) {
              _.each(permissions, (val, key) => {
                const f: any = _.find(this.permissionsService.permissions.data, {id: key});
                if (f) {
                  Tstring += this.translate.instant(f.description) + '<br />';
                }
              });
            }
            return Tstring;
          }
        },
        {
          id: 'locations',
          title: this.translate.instant('MGMT_LIST.LOCATIONS'),
          headerClass: 'table-header',
          func: (val) => {
            if (!val.length) {
              return this.translate.instant('SHARED.All_Locations');
            } else {
              let r = '';
              _.each(val, (loc) => {
                const l = this.userService.findLocation(loc);
                if (l) {
                  if (r !== '') {
                    r += ', ';
                  }
                  r += l.name;
                }
              });
              return r;
            }
          }
        },
        {
          id: 'team',
          title: this.translate.instant('SHARED.TEAM'),
          headerClass: 'table-header',
          fromID: 'groups',
          func: (groups, row) => {
            let Tstring = '';
            if (groups) {
              groups.forEach((val) => {
                if (row.primaryGroup && val == row.primaryGroup) {
                  Tstring += '*&nbsp;';
                }
                Tstring += this.teamsService.teamNameByID(val) + '</br>';
              });
            }
            return Tstring;
          }
        },
        {
          id: 'shift',
          title: this.translate.instant('MGMT_LIST.SHIFT'),
          headerClass: 'table-header',
          func: (shift, row) => this.shift.name(shift)
        },
        {
          id: 'lastZone',
          title: this.translate.instant('MGMT_LIST.LAST_ZONE'),
          headerClass: 'table-header',
          func: (zoneID, row) => {
            let ret = 'N/A';
            if (row.lastLocation) {
              const location = this.userService.findLocation(row.lastLocation);
              if (location) {
                if (zoneID === 0) {
                  ret = location.name + '/Site-Wide';
                } else {
                  const zone = this.zoneService.findZoneRecursive(location.zones, zoneID);
                  if (zone) {
                    ret = location.name + '/' + zone.name;
                  }
                }
              }
            }
            return ret;
          }
        }
      ]
    },
    [IDetailReportTableType.Check]: {
      class: 'detailReport',
      rowprop: 'data-mid',
      rowvaluefrom: 'responseID',
      columns: this.getCheckColumns()
    },
    [IDetailReportTableType.PPESummary]: {
      class: 'detailReport',
      rowprop: 'data-mid',
      rowvaluefrom: 'gearID',
      columns: [
        {
          id: 'name',
          title: this.translate.instant('SHARED.Name'),
          headerClass: 'table-header',
          func: (name, row) => name
        },
        {
          id: 'description',
          title: this.translate.instant('SHARED.Description'),
          headerClass: 'table-header',
          func: (description, row) => description
        }
      ]
    }
  };

  constructor(
    private translate: TranslateService,
    private observationService: ObservationService,
    private userService: UserService,
    private zoneService: ZoneService,
    private teamsService: TeamsService,
    private observationDetailService: ObservationDetailService,
    private permissionsService: PermissionsService,
    private shift: ShiftService,
    private checksService: ChecksService,
    private deployment: DeploymentService,
    private assetsService: AssetsService,
    private utils: UtilsService,
    private injector: Injector,
    private reportingFormBuilderService: ReportingFormBuilderService,
    protected route: ActivatedRoute,
  ) {
  }

  public getTableConfigByType(type: IDetailReportTableType): any {

    if (type === 'check') {
      this.tables[type].columns = this.getCheckColumns();
    }

    return this.tables[type];
  }

  public applyRouteParams(queryParams: any): void {
    const reportType: string = _.get(queryParams, 'reportType');

    if (reportType === 'observation') {
      const fieldKeys: string[] = [
        'behaviors',
        'locations',
        'zones',
        'groups',
        'categories',
        'recipients',
        'targetGroups',
        'mitigations',
        'qualitycats',
        'compliments',
        'users',
        'owners',
        'creators',
        'tags',
        'picats'
      ];
      this.queryParams = this.parseRouteParams(queryParams, fieldKeys);
    } else if (reportType === 'worker') {
      this.queryParams = queryParams;
    } else if (reportType === 'check') {
      const fieldKeys: string[] = [];
      this.queryParams = this.parseRouteParams(queryParams, fieldKeys);
    } else if (reportType === 'PPESummary') {
      this.queryParams = queryParams;
    }
  }

  public getTableDataByType(type: IDetailReportTableType): Promise<any> {
    const reportInstance = this.reportingFormBuilderService.getReportInstanceByType(type);
    return Promise.resolve(reportInstance ? reportInstance.getTableItems(this.queryParams) : null);
  }

  public getCheckColumns() {
    const queryParams = this.route.snapshot.queryParams;
    let checkStatus = queryParams.checkStatus;
    let isAvailable = false;
    let isComplete = false;
    let isMissed = false;
    let statusColumns = [];
    let columns = [
      {
        id: 'checkID',
        title: this.translate.instant('SHARED.Check_Title'),
        headerClass: 'table-header',
        func: (checkID, row) => {
          const check = this.checksService.getCheck(checkID);
          let title = checkID;
          if (check && check.title) {
            title = check.title;
          }
          return title;
        }
      },
      {
        id: 'deploymentID',
        title: this.translate.instant('SHARED.Deployment_Title'),
        headerClass: 'table-header',
        func: (deploymentID, row) => {
          const deployment = this.deployment.getDeployment(deploymentID);
          let label: any = 'None';
          if (deployment) {
            label = deployment.title;
          }
          return label;
        }
      },
      {
        id: 'owner',
        title: this.translate.instant('SHARED.Responder'),
        headerClass: 'table-header',
        func: (owner, row) => this.userService.getFullname(owner)
      },
      {
        id: 'target',
        title: 'Target',
        headerClass: 'table-header',
        func: (target, row) => {
          let label = target.targetType;
          if (label === 'assets' && target.assets && target.assets.length) {
            const asset = this.assetsService.getAssetById(target.assets[0]);
            if (asset && asset.length) {
              label = asset[0].name;
            }
          } else if (label === 'zones' && target.zones && target.zones.length) {
            const loc = this.userService.findLocation(target.locations[0]);
            if (loc && loc.zones) {
              let zone;
              if (row.targetZones && row.targetZones[0]) {
                zone = this.userService.getZoneById(loc.zones, +row.targetZones[0]);
              } else {
                zone = this.userService.getZoneById(loc.zones, +target.zones[0]);
              }
              if (zone) {
                label = zone.name;
              }
            }
          } else if (label === 'users' && target.users && target.users.length) {
            label = this.userService.getFullname(target.users[0]);
          }
          return label;
        }
      },
      {
        id: 'status',
        title: this.translate.instant('SHARED.Status'),
        headerClass: 'table-header',
        func: (val, ref) => {
          let label = val;
          if (val === 'complete') {
            label = this.translate.instant('SHARED.Completed');
          } else if (val === 'groupsCompleted') {
            label = this.translate.instant('SHARED.OptionalGroups');
          } else if (val === 'available') {
            label = this.translate.instant('SHARED.Available');
          } else if (val === 'missed') {
            label = this.translate.instant('SHARED.Missed');
          } else if (val === 'skipped') {
            label = this.translate.instant('SHARED.Skipped');
          } else if (val === 'notCompleted') {
            label = this.translate.instant('SHARED.NotCompleted');
          }
          return label;
        }
      },
      {
        id: 'result',
        title: this.translate.instant('SHARED.Check_Result'),
        headerClass: 'table-header',
        func: (val, ref) => {
          let title = val;
          if (val === 'negative') {
            title = this.translate.instant('SHARED.Negative');
          } else if (val === 'neutral') {
            title = this.translate.instant('SHARED.Neutral');
          } else if (val === 'positive') {
            title = this.translate.instant('SHARED.Positive');
          } else if (val === 'unknown') {
            title = this.translate.instant('SHARED.None');
          }
          return title;
        }
      }
    ];
    if (!_.isArray(checkStatus)) {
      checkStatus = [checkStatus];
    }
    _.each(checkStatus, (status) => {
      if (status === 'available') {
        isAvailable = true;
      } else if (status === 'complete' || status === 'groupsCompleted' || status === 'skipped') {
        isComplete = true;
      } else if (status === 'missed') {
        isMissed = true;
      }
    });
    if (isMissed) {
      const missed = [{
        id: 'expiresTime',
        title: this.translate.instant('SHARED.Expires_Time'),
        headerClass: 'table-header',
        func: (expiresTime, row) => this.utils.dateTimeFormat(expiresTime, null, true)
      }];
      statusColumns = [
        ...missed,
        ...statusColumns
      ];
    }
    if (isComplete) {
      const complete = [{
        id: 'completionTime',
        title: this.translate.instant('SHARED.Completion_Time'),
        headerClass: 'table-header',
        func: (completionTime, row) => this.utils.dateTimeFormat(completionTime, null, true)
      }];
      statusColumns = [
        ...complete,
        ...statusColumns
      ];
    }
    if (isAvailable) {
      const available = [{
        id: 'availableTime',
        title: this.translate.instant('SHARED.Available_Time'),
        headerClass: 'table-header',
        func: (availableTime, row) => this.utils.dateTimeFormat(availableTime, null, true)
      }];
      statusColumns = [
        ...available,
        ...statusColumns
      ];
    }
    columns = [
      ...statusColumns,
      ...columns
    ];
    return columns;
  }

  private parseRouteParams(queryParams, fieldKeys): any {
    queryParams.endTime = +queryParams.endTime;
    queryParams.hasWorkorder = +queryParams.hasWorkorder;
    queryParams.startTime = +queryParams.startTime;

    _.each(fieldKeys, (key: string) => {
      if (_.isArray(queryParams[key])) {
        queryParams[key] = _.map(queryParams[key], Number);
      }
    });

    return queryParams;
  }

  public getExcelFormatDate(unixTime: number) {
    const date = this.utils.dateTimeFormat(unixTime);
    const dateMoment = moment(unixTime * 1000);
    return dateMoment.isValid() ? dateMoment.toDate() : date;
  }
}
