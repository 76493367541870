import { ComponentRef } from '@angular/core';

import { QuestionFormService } from '@modules/management/pages/details/check/services/question/question-form.service';
import { QuestionFormGroupListComponent } from '@modules/management/pages/details/check/components';
import { AbstractFieldsModel } from './abstractFieldsModel';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export class MeasurementFieldsModel extends AbstractFieldsModel {

  private settings: any;
  protected formConfig: any = {
    containers: true,
    prefix: 'measurement',
    fields: [
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'label',
        title: 'MGMT_DETAILS.Measure_Target',
        type: 'text',
        size: 20,
        required: true
      },
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'translation_label',
        title: this.translateService.instant('MGMT_DETAILS.Measure_Target_Translations'),
        type: 'textTranslations',
        size: 20,
        maxlength: 180
      },
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'tolerance',
        title: 'MGMT_DETAILS.Tolerance',
        type: 'text',
        size: 20,
        required: true
      },
      {
        containerClass: 'question-field validation-subtitle',
        title: 'MGMT_DETAILS.Choice_Validation',
        type: 'subtitle'
      },
      {
        containerClass: 'question-field',
        title: 'MGMT_DETAILS.Fail_Measurement',
        name: 'fail_measurement',
        type: 'flipswitch',
        value: 1,
        default: 0,
        onChange: (value, formPrefix) => {
          const getValue = !value || !this.getValidationValue();
          const elementRef = this.formGroupComponent.getGroupElementRefByFormId(`${formPrefix}Form`);
          this.questionFormService.failChoiceValidation(elementRef.nativeElement, 'required_measurement_choice_validation_field', getValue, this.settings);
          this.questionFormService.markFieldAsChanged('fail_measurement', value, this.settings);
        },
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['issue-type', 'required_measurement_choice_validation_field_divider'],
              valueFunction: (val) => !val && !this.getValidationValue()
            }
          ]
        }
      },
      {
        containerClass: 'question-field',
        title: 'MGMT_DETAILS.Fail_Notes',
        name: 'fail_notes',
        type: 'flipswitch',
        value: 1,
        default: 0,
        onChange: (value, formPrefix) => {
          const getValue = !value || !this.getValidationValue('fail_measurement');
          const elementRef = this.formGroupComponent.getGroupElementRefByFormId(`${formPrefix}Form`);
          this.questionFormService.failChoiceValidation(elementRef.nativeElement, 'required_measurement_choice_validation_field', getValue, this.settings);
          this.questionFormService.markFieldAsChanged('fail_notes', value, this.settings);
        },
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['issue-type', 'required_measurement_choice_validation_field_divider'],
              valueFunction: (val) => !val && !this.getValidationValue('fail_measurement')
            }
          ]
        }
      },
      {
        containerClass: 'question-field form-group-checkbox',
        name: 'required_measurement_choice_validation_field',
        title: '',
        disabled: true,
        type: 'checkbox',
        options: {
          checked: {
            description: 'MGMT_DETAILS.Validation_Type_Above',
            id: 1
          }
        },
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('required_measurement_choice_validation_field', value, this.settings);
        }
      },
      {
        containerClass: 'required_measurement_choice_validation_field_divider',
        type: 'divider'
      },
      {
        containerClass: 'question-field issue-type',
        title: 'MGMT_DETAILS.Documentation_Options',
        type: 'subtitle'
      },
      _.merge({}, this.questionFormService.sharedFields.attachCommentOption, {
        containerClass: 'question-field issue-type',
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('issueTypeFail_Comment', value, this.settings);
        }
      }),
      _.merge({}, this.questionFormService.sharedFields.correctiveOption, {
        containerClass: 'question-field issue-type',
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('issueTypeFail_Corrective', value, this.settings);
        }
      })
    ]
  };
  private fields = [
    {
      containerClass: 'question-type-field measurement',
      title: 'MGMT_DETAILS.Tolerance_Details',
      type: 'subtitle'
    },
    {
      containerClass: 'question-type-field measurement',
      title: 'MGMT_DETAILS.Tolerance_Target_Values',
      type: 'subtitle'
    },
    {
      type: 'subtitle',
      containerClass: 'question-type-field measurement form-group-title measurement-groups'
    }
  ];

  constructor(
    protected questionFormService: QuestionFormService,
    private translateService: TranslateService
  ) {
    super(questionFormService);
  }

  public getFields(): any[] {
    return this.fields;
  }

  public showGroupList() {
    if (this.questionFormService.isModelAvailable('measurement')) {
      const groupsElement = this.questionFormService.getElementBy(`.measurement-groups`);

      if (groupsElement && !this.formGroupComponentRef) {
        this.questionFormService.dynamicComponentCreatorService.create(
          QuestionFormGroupListComponent,
          (component, componentRef) => this.onFormGroupComponentInit(component, componentRef),
          groupsElement
        );
      }
    }
  }

  public onDestroy() {
    this.formGroupComponentRef && this.formGroupComponentRef.destroy();
    this.formGroupComponentRef = null;
  }

  public getData(): any[] {
    const settingsFields: string[] = [
      'tolerance',
      'actualMeasurement',
      'fail_measurement',
      'fail_notes',
      'required_measurement_choice_validation_field',
      'issueType',
      'issueTypeFail_Comment',
      'issueTypeFail_Corrective'
    ];

    const settingsHandler = (settingsItem: any) => {
      let issueType = '';

      if (settingsItem.issueTypeFail_Comment && settingsItem.issueTypeFail_Corrective) {
        issueType = '2';
      } else if (settingsItem.issueTypeFail_Corrective) {
        issueType = '1';
      } else if (settingsItem.issueTypeFail_Comment) {
        issueType = '0';
      }
      _.assign(settingsItem, {
        usesNote: settingsItem.fail_notes ? 2 : 0,
        verificationRequired: settingsItem.required_measurement_choice_validation_field,
        issueType
      });

      if (settingsItem.required_measurement_choice_validation_field) {
        settingsItem.usesNote = 1;
      }

      return _.omit(settingsItem, [
        'fail_notes',
        'required_measurement_choice_validation_field',
        'issueTypeFail_Comment',
        'issueTypeFail_Corrective'
      ]);
    };

    return this.getFormData(settingsFields, settingsHandler);
  }

  private onFormGroupComponentInit(component: QuestionFormGroupListComponent, componentRef: ComponentRef<QuestionFormGroupListComponent>) {
    this.onFormGroupComponentCreate(component, componentRef);

    this.formGroupComponent.disableNewGroup = true;

    const option = _.first(_.values(this.questionFormService.questionPage.questionOptions)) || {};
    this.settings = _.get(option, 'settings', {});

    _.each(this.settings, (settingItem, index: number) => {
      this.settings[index] = _.isNaN(+settingItem) ? settingItem : +settingItem;
    });

    this.formGroupComponent.groups = [{
      formConfig: this.formConfig,
      formData: option
    }];
  }

  private getValidationValue(field: 'fail_measurement' | 'fail_notes' = 'fail_notes'): number {
    const fieldConfig: any = _.find(this.formGroupComponent.groups[0].formConfig.fields, {name: field});
    return fieldConfig && fieldConfig.getValue();
  }

}
