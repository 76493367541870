import { NGXLogger } from 'ngx-logger';
import { Injectable, SecurityContext } from '@angular/core';
import { CommsService } from '@services/comms/comms.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { Observable, Observer } from 'rxjs';
import { SafetyIndexService } from '@services/safetyIndex/safety-index.service';
import { Events } from '@services/events/events.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { UtilsService } from '@services/utils/utils.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { UserService } from '@services/user/user.service';
import { TeamsService } from '@services/teams/teams.service';
import { SettingsService } from '@services/settings/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { CheckResponseService } from '@services/checkResponse/check-response.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { StorageService } from '@services/storage/storage.service';
import { CollectionItemType, ICollectionItemData } from '@services/collections/collections.service';

export enum ObservationTypes {
  Any = 'any',
  Behavior = 'behavior',
  CA = 'ca',
  Compliment = 'compliment',
  Condition = 'condition',
  PI = 'pi',
  Quality = 'quality',
}

@Injectable({
  providedIn: 'root'
})

export class ObservationService {
  public escalated = [];
  public byZone = {};
  public byLocation = {};
  public open = {};
  public workorder = {};
  public qualityOpen = {};
  public usingSSE = false;
  public caNonSanitized = [];
  public detailUpdate: any = false;
  public observations = {
    lastRequest: null,
    data: {}
  };
  public typeMap = {};
  public subtypeMap = {};
  public observationsUpdating = false;
  public partialLoad = null;
  public showView = false;
  private observableGetObservationObs: Observable<any>;
  private getObservationCheckInterval = this.subscriber.HEARTBEAT_TIME ? this.subscriber.HEARTBEAT_TIME * 1000 : 60000;
  private startUpdateObservationTimer: () => void;
  private stopUpdateObservationTimer: () => void;
  private OBSERVATION_CHECK_INTERVAL = 60000;
  private observationTimer;
  private fixed = {};
  private closed = {};
  private behavior = {};
  private compliment = {};
  private qualityFixed = {};
  private qualityReceiving = {};
  private qualityClosed = {};
  private pi = {};
  private audio: any;

  constructor(
    private logger: NGXLogger,
    private comms: CommsService,
    private subscriber: SubscriberService,
    private safetyIndex: SafetyIndexService,
    private utils: UtilsService,
    private events: Events,
    private accountService: AccountsService,
    private userService: UserService,
    private teamService: TeamsService,
    private settingsService: SettingsService,
    protected translate: TranslateService,
    private sanitizer: NgDompurifySanitizer,
    private checkResponseService: CheckResponseService,
    private userDataService: UserdataService,
    private storageService: StorageService
  ) {
    this.translateTypes();
    this.translate.onDefaultLangChange.subscribe(() => this.translateTypes());
    this.translate.onTranslationChange.subscribe(() => this.translateTypes());
  }

  clearCache(): void {
    this.OBSERVATION_CHECK_INTERVAL = 60000;

    this.observations = {
      lastRequest: null,
      data: {}
    };

    if (this.observationTimer !== null) {
      window.clearTimeout(this.observationTimer);
    }
    this.observationTimer = null;
    this.workorder = {};
    this.open = {};
    this.closed = {};
    this.escalated = [];
    this.byZone = {};
    this.byLocation = {};

  }

  public getProperty(obsref, prop) {
    const newprop = prop + 'Override';

    if (obsref.hasOwnProperty(newprop) && (obsref[newprop] || _.isNumber(obsref[newprop]) || _.isBoolean(obsref[newprop]))) {
      return obsref[newprop];
    } else {
      return obsref[prop];
    }
  }

  /**
   * updateObservations - get any relevant observations
   *
   * @returns Promise that resolves when observations are retrieved and callbacks processed
   */
  public updateObservations(updated: Number = 0): Promise<boolean> {
    if (this.observationsUpdating) {
      return Promise.resolve(false);
    } else if (updated && updated < this.observations.lastRequest) {
      this.logger.log(`local observation cache already up to date: ${updated}, ${this.observations.lastRequest}`);
      return Promise.resolve(true);
    } else {
      this.observationsUpdating = true;
      return new Promise((resolve, reject) => {
        const req: any = {
          cmd: 'getObservations',
          locations: JSON.stringify(this.userDataService.locations),
          sendTime: Date.now(),
          states: JSON.stringify(['new', 'escalated', 'dropped', 'fixed', 'workorder', 'updated', 'resolved']),
          endTime: 2000000000,    // forever in the future - required to actually retrieve a range
        };
        if (this.observations.lastRequest) {
          if (this.partialLoad) {
            req.endTime = this.partialLoad;
            req.startTime = 1;
            this.partialLoad = null;
            this.events.publish('ccs:observationView', true);
          } else {
            req.lastRequest = this.observations.lastRequest;
            req.startTime = this.observations.lastRequest;
            req.incremental = 1;
          }
        } else {
          req.startTime = req.sendTime - 14 * 24 * 60 * 60 * 1000;
          req.startTime = _.toInteger(req.startTime / 1000);
          this.partialLoad = req.startTime;
        }
        this.comms.sendMessage(req, false, false).then(async (data) => {
          let updated = false;
          if (data && data.reqStatus === 'OK') {
            this.logger.log('getObservations returned OK; message was ' + data.reqStatusText);
            this.observations.lastRequest = data.result.timestamp;
            if (_.has(data.result, 'removed')) {
              if (_.has(data.result.removed, 'archived')) {
                this.logger.debug('processing some archived observations');
                updated = true;
                _.each(data.result.removed.archived, (item) => {
                  delete this.fixed[item];
                  delete this.open[item];
                  delete this.closed[item];
                  delete this.workorder[item];
                  delete this.qualityFixed[item];
                  delete this.qualityOpen[item];
                  delete this.qualityClosed[item];
                  delete this.observations.data[item];
                });
              }
            }
            await this.utils.iterateWithDelay(data.result.observations, (ref) => {
              updated = true;
              // first, check our cache.  Do we have this already?
              if (this.observations.data[ref.observationID]) {
                // we do already have this one...
                const oldType = this.getProperty(this.observations.data[ref.observationID], 'type');
                const oldState = this.observations.data[ref.observationID].state;

                // removed any old reference
                if (oldType === 'condition') {
                  if (ref.state === 'archived') {
                    delete this.fixed[ref.observationID];
                    delete this.open[ref.observationID];
                    delete this.closed[ref.observationID];
                    delete this.workorder[ref.observationID];
                  }
                  if (oldState === 'resolved') {
                    delete this.fixed[ref.observationID];
                  } else {
                    delete this.open[ref.observationID];
                    delete this.workorder[ref.observationID];
                  }
                  // also remove fixed tasks from bucket
                  if (oldState === 'escalated' || oldState === 'new') {
                    delete this.open[ref.observationID];
                  }
                } else if (oldType === 'quality') {
                  if (ref.state === 'archived') {
                    delete this.qualityFixed[ref.observationID];
                    delete this.qualityOpen[ref.observationID];
                    delete this.qualityClosed[ref.observationID];
                  }
                  if (oldState === 'resolved' || oldState === 'dropped') {
                    delete this.qualityFixed[ref.observationID];
                  } else {
                    delete this.qualityOpen[ref.observationID];
                  }
                  // also remove fixed tasks from bucket
                  if (oldState === 'escalated' || oldState === 'new') {
                    if (ref.subtype === 'receiving') {
                      delete this.qualityReceiving[ref.observationID];
                    } else {
                      delete this.qualityOpen[ref.observationID];
                    }
                    // also remove fixed tasks from bucket
                    if (oldState === 'escalated' || oldState === 'new') {
                      if (ref.subtype === 'receiving') {
                        delete this.qualityReceiving[ref.observationID];
                      } else {
                        delete this.qualityOpen[ref.observationID];
                      }

                    }
                  }
                }
              }
              const t = this.getProperty(ref, 'type');
              let st = this.getProperty(ref, 'subtype');
              if (st === 'Production Issue') {
                st = 'production';
              }
              if (st === 'Receiving Issue') {
                st = 'receiving';
              }
              ref.type = t;
              ref.subtype = st;
              const s = ref.state;

              // build index of search items
              ref.searchIndex = this.buildSearchIndex(ref);

              // clean up the notes
              ref = this.cleanNotes(ref);

              // put it into our cache
              this.observations.data[ref.observationID] = ref;

              if (t === 'behavior' || t === 'compliment') {
                this[t][ref.observationID] = ref;
              } else if (t === 'condition') {
                // this is a condition... what state is it in?
                if (s === 'new' || s === 'updated' || s === 'escalated') {
                  this.open[ref.observationID] = ref;
                } else if (s === 'fixed') {
                  this.fixed[ref.observationID] = ref;
                } else if (s === 'resolved') {
                  this.closed[ref.observationID] = ref;
                } else if (s === 'workorder') {
                  this.workorder[ref.observationID] = ref;
                }
              } else if (t === 'quality') {
                if (s === 'new' || s === 'updated' || s === 'escalated') {
                  if (ref.subtype === 'receiving') {
                    this.qualityReceiving[ref.observationID] = ref;
                  } else {
                    this.qualityOpen[ref.observationID] = ref;
                  }

                } else if (s === 'fixed' || s === 'dropped') {
                  this.qualityFixed[ref.observationID] = ref;
                } else if (s === 'resolved') {
                  this.qualityClosed[ref.observationID] = ref;
                }
              } else if (t === 'pi') {
                this[t][ref.observationID] = ref;
              }
            });
            if (_.size(this.open)) {
              this.safetyIndex.calculateHistoricRiskByState(this.open);
            }
            if (_.size(this.workorder)) {
              this.safetyIndex.calculateHistoricRiskByState(this.workorder);
            }
            // EMIT EVENT TO LET PAGES KNOW THAT OBSERVATION BUCKETS HAVE BEEN UPDATED
            this.events.publish('ccs:observationUpdate', true);
            this.logger.log('there are now ' + _.keys(this.observations.data).length + ' observations cached');
          }
          this.observationsUpdating = false;
          if (this.partialLoad) {
            this.logger.log('starting a load of the rest of the observations');
            this.updateObservations();
          }
          // EMIT EVENT TO LET PAGES KNOW THAT OBSERVATION BUCKETS HAVE BEEN UPDATED
          if (updated) {
            this.events.publish('ccs:observationUpdate', true);
            this.logger.log('there are now ' + _.keys(this.observations.data).length + ' observations cached');
            this.storageService.store('observations', this.observations);
          }
          resolve(updated);
        })
          .catch((err) => {
            this.observationsUpdating = false;
            this.logger.error('There was an error getting Observations: ' + err);
            reject(err);
          });
      });
    }
  }

  public rebuildCache(data) {
    _.each(data, ref => {
      // first, check our cache.  Do we have this already?
      if (this.observations.data[ref.observationID]) {
        // we do already have this one...
        const oldType = this.getProperty(this.observations.data[ref.observationID], 'type');
        const oldState = this.observations.data[ref.observationID].state;

        // removed any old reference
        if (oldType === 'condition') {
          if (ref.state === 'archived') {
            delete this.fixed[ref.observationID];
            delete this.open[ref.observationID];
            delete this.closed[ref.observationID];
            delete this.workorder[ref.observationID];
          }
          if (oldState === 'resolved') {
            delete this.fixed[ref.observationID];
          } else {
            delete this.open[ref.observationID];
            delete this.workorder[ref.observationID];
          }
          // also remove fixed tasks from bucket
          if (oldState === 'escalated' || oldState === 'new') {
            delete this.open[ref.observationID];
          }
        } else if (oldType === 'quality') {
          if (ref.state === 'archived') {
            delete this.qualityFixed[ref.observationID];
            delete this.qualityOpen[ref.observationID];
            delete this.qualityClosed[ref.observationID];
          }
          if (oldState === 'resolved' || oldState === 'dropped') {
            delete this.qualityFixed[ref.observationID];
          } else {
            delete this.qualityOpen[ref.observationID];
          }
          // also remove fixed tasks from bucket
          if (oldState === 'escalated' || oldState === 'new') {
            if (ref.subtype === 'receiving') {
              delete this.qualityReceiving[ref.observationID];
            } else {
              delete this.qualityOpen[ref.observationID];
            }
            // also remove fixed tasks from bucket
            if (oldState === 'escalated' || oldState === 'new') {
              if (ref.subtype === 'receiving') {
                delete this.qualityReceiving[ref.observationID];
              } else {
                delete this.qualityOpen[ref.observationID];
              }

            }
          }
        }
      }
      const t = this.getProperty(ref, 'type');
      let st = this.getProperty(ref, 'subtype');
      if (st === 'Production Issue') {
        st = 'production';
      }
      if (st === 'Receiving Issue') {
        st = 'receiving';
      }
      ref.type = t;
      ref.subtype = st;
      const s = ref.state;

      // build index of search items
      ref.searchIndex = this.buildSearchIndex(ref);

      // clean up the notes
      ref = this.cleanNotes(ref);

      // put it into our cache
      this.observations.data[ref.observationID] = ref;

      if (t === 'behavior' || t === 'compliment') {
        this[t][ref.observationID] = ref;
      } else if (t === 'condition') {
        // this is a condition... what state is it in?
        if (s === 'new' || s === 'updated' || s === 'escalated') {
          this.open[ref.observationID] = ref;
        } else if (s === 'fixed') {
          this.fixed[ref.observationID] = ref;
        } else if (s === 'resolved') {
          this.closed[ref.observationID] = ref;
        } else if (s === 'workorder') {
          this.workorder[ref.observationID] = ref;
        }
      } else if (t === 'quality') {
        if (s === 'new' || s === 'updated' || s === 'escalated') {
          if (ref.subtype === 'receiving') {
            this.qualityReceiving[ref.observationID] = ref;
          } else {
            this.qualityOpen[ref.observationID] = ref;
          }

        } else if (s === 'fixed' || s === 'dropped') {
          this.qualityFixed[ref.observationID] = ref;
        } else if (s === 'resolved') {
          this.qualityClosed[ref.observationID] = ref;
        }
      } else if (t === 'pi') {
        this[t][ref.observationID] = ref;
      }
    });
    if (_.size(this.open)) {
      this.safetyIndex.calculateHistoricRiskByState(this.open);
    }
    if (_.size(this.workorder)) {
      this.safetyIndex.calculateHistoricRiskByState(this.workorder);
    }
    // EMIT EVENT TO LET PAGES KNOW THAT OBSERVATION BUCKETS HAVE BEEN UPDATED
    this.events.publish('ccs:observationUpdate', true);
    this.logger.log('there are now ' + _.keys(this.observations.data).length + ' observations cached');
  }

  /**
   *
   * @param obs a reference to an observation object
   * @param selectors a reference to a selector object; each key is an aspect of an observation.
   * The key's values are values that must be present in the matching observation
   * @param selectors.states - array of strings that represents the states to allow
   * @param selectors.obstype - array of strings that represent the observation types to allow
   * @param selectors.hasWorkorder - boolean that indicates if a workorder is required
   * @param selectors.locations - array of integers representing locations in which the observation must have been made
   * @param selectors.zones - array of integers representing zones in which the observation must have been made
   * @param selectors.creatorGroups - array of integers representing creator groups to which the observation must be associated
   * @param selectors.groups - array of integers representing owner groups to which the observation must be associated
   * @param selectors.creators - array of integers representing users who must have created the observation
   * @param selectors.owners - array of integers representing users who must own the observation
   * @param selectors.severities - array of strings of severity band names in which the observation's severity must live
   * @param selectors.likelihoods - array of strings of likelihood band names in which the observation's likelihood must live
   * @param selectors.categories - array of integers representing the allowed condition categories
   * @param selectors.behaviors - array of integers representing the allowed behavior categories
   * @param selectors.mitigations - array of integers representing the allowed mitigation categories
   * @param selectors.qualitycats - array of integers representing the allowed quality categories
   * @param selectors.compliments - array of integers representing the allowed compliment categories
   * @param selectors.recipients - array of integers representing the allowed recipients (for compliments)
   * @param selectors.targetGroups - array of integers representing the allowed recipients groups (for compliments)
   * @param selectors.tags - array of integers representing the allowed recipients tags
   *
   */
  public checkObservation(obs, selectors): any {
    const type: string = this.getProperty(obs, 'type');
    // look at all the relevant selectors

    // obs type
    if (selectors.obstype && selectors.obstype.length) {
      if (!_.includes(selectors.obstype, type)) {
        return false;
      }
    }
    // now states
    if (selectors.states && selectors.states.length) {
      const matchList = [];
      _.forEach(selectors.states, (item) => {
        if (item === 'open') {
          matchList.push('new', 'escalated', 'updated');
        }
        if (item === 'fixed') {
          matchList.push('fixed');
        }
        if (item === 'closed') {
          matchList.push('resolved');
        }
        if (item === 'workorder') {
          matchList.push('workorder');
        }
      });
      if (matchList.length && !_.includes(matchList, obs.state)) {
        // it didn't match one of those states
        return false;
      }

      if (_.includes(selectors.states, 'unassigned')) {
        // did we only want ones that are unassigned?
        if (obs.ownerID !== 0) {
          return false;
        }
      }
    }
    // is unassigned
    if (_.has(selectors, 'ownershipStatus') && selectors.ownershipStatus.length) {
      if (selectors.ownershipStatus.length === 1) {
        if (selectors.ownershipStatus[0] === 'assigned' && obs.ownerID === 0) {
          return false;
        } else if (selectors.ownershipStatus[0] === 'unassigned' && obs.ownerID !== 0) {
          return false;
        }
      }
    }

    // has a work order

    if (selectors.hasWorkorder && obs.state !== 'workorder') {
      return false;
    }

    // location filter
    if (selectors.locations && selectors.locations.length) {
      if (!_.includes(selectors.locations, obs.locationID)) {
        return false;
      } else {
        let zonesIds: any = [0];

        _.each(selectors.locations, (locationId: number) => {
          const location: any = _.find(this.userService.locations.data, <any>{locationID: locationId});
          const currentZonesIds: any = [];
          _.each(location.zones, zone => {
            if (!zone.disabledAt) {
              currentZonesIds.push(zone.zoneID);
            }
            // now look for subzones within these zones
            _.each(zone.zones, subZone => {
              if (!subZone.disabledAt) {
                currentZonesIds.push(subZone.zoneID);
              }
            });
          });
          // const currentZonesIds: number[] = _.map(_.reject(location.zones, 'disabledAt'), 'zoneID');
          zonesIds = [...zonesIds, ...currentZonesIds];
        });

        if (!_.includes(zonesIds, obs.zoneID)) {
          return false;
        }
      }
    }

    // zone filter
    if (selectors.zones && selectors.zones.length) {
      const zsig = obs.zoneID ? obs.zoneID.toString() : obs.locationID + ':' + obs.zoneID;
      if (!_.includes(selectors.zones, zsig)) {
        return false;
      }
    }

    // owner team filter
    if (selectors.creatorGroups && selectors.creatorGroups.length) {
      if (!_.includes(selectors.creatorGroups, this.creatorTeam(obs))) {
        return false;
      }
    }

    // team filter
    if (selectors.groups && selectors.groups.length) {
      if (!_.includes(selectors.groups, obs.groupID)) {
        return false;
      }
    }

    // creator filter
    if (selectors.creators && selectors.creators.length) {
      if (!_.includes(selectors.creators, obs.userID)) {
        return false;
      }
    }

    // owner filter
    if (selectors.owners && selectors.owners.length) {
      if (!_.includes(selectors.owners, obs.ownerID)) {
        return false;
      }
    }

    // users filter
    if (selectors.users && selectors.users.length) {
      if (!(_.includes(selectors.users, obs.ownerID) || _.includes(selectors.users, obs.userID))) {
        return false;
      }
    }

    // severity filter
    if (type === 'condition' && selectors.severities && selectors.severities.length) {
      const s = this.bandName(this.getProperty(obs, 'severity'));
      if (!_.includes(selectors.severities, s)) {
        return false;
      }
    }

    // likelihood filter
    if (type === 'condition' && selectors.likelihoods && selectors.likelihoods.length) {
      const l = this.bandName(this.getProperty(obs, 'likelihood'));
      if (!_.includes(selectors.likelihoods, l)) {
        return false;
      }
    }

    // recipients filter
    if (_.get(selectors, 'recipients', []).length) {
      if (!_.intersection(selectors.recipients, obs.recipients).length) {
        return false;
      }
    }

    // team filter
    if (_.get(selectors, 'targetGroups', []).length) {
      if (!_.intersection(selectors.targetGroups, obs.groups).length) {
        return false;
      }
    }

    // creator roles filter
    if (_.get(selectors, 'creatorRole', []).length) {
      if (!_.intersection(_.map(selectors.creatorRole, Number), this.getCreatorRoles(obs)).length) {
        return false;
      } else {
        obs.roles = _.intersection(_.map(selectors.creatorRole, Number), this.getCreatorRoles(obs));
      }
    }

    // categories
    if (type === 'condition' && _.get(selectors, 'categories', []).length ||
      type === 'pi' && _.get(selectors, 'picats', []).length ||
      type === 'behavior' && _.get(selectors, 'behaviors', []).length ||
      type === 'behavior' && _.get(selectors, 'mitigations', []).length ||
      type === 'quality' && _.get(selectors, 'qualitycats', []).length ||
      type === 'compliment' && _.get(selectors, 'compliments', []).length) {
      if (!_.intersection(_.map(selectors.categories, (c) => +c), obs.categories).length &&
        !_.intersection(_.map(selectors.picats, (c) => +c), obs.categories).length &&
        !_.intersection(_.map(selectors.behaviors, (c) => +c), obs.categories).length &&
        !_.intersection(_.map(selectors.mitigations, (c) => +c), obs.categories).length &&
        !_.intersection(_.map(selectors.qualitycats, (c) => +c), obs.categories).length &&
        !_.intersection(_.map(selectors.compliments, (c) => +c), obs.categories).length) {
        return false;
      }
    }

    // tag filter
    if (_.get(selectors, 'tags', []).length) {
      if (!_.intersection(selectors.tags, obs.tags).length) {
        return false;
      }
    }

    // excludeDisabledTeams filter
    if (_.has(selectors, 'excludeDisabledTeams')) {
      if (obs.groupID) {
        const team = this.teamService.get(obs.groupID);
        if (team && team.disabledAt) {
          return false;
        }
      }
    }

    // dateCreated filter
    if (_.get(selectors, 'dateCreated', []).length) {
      if (selectors.dateCreated) {
        const timeByTimespan = this.utils.timespan(selectors.dateCreated);
        const created = obs.created * 1000;
        if (selectors.dateCreated !== 'custom') {
          if (!(timeByTimespan.startTime <= created && created <= timeByTimespan.endTime)) {
            return false;
          }
        } else {
          if (!(timeByTimespan.startTime <= created && created <= timeByTimespan.endTime && selectors.dateCreatedStart <= created && created <= selectors.dateCreatedEnd)) {
            return false;
          }
        }
      } else {
        return false;
      }
    }

    // dateFixed filter
    if (_.get(selectors, 'dateFixed', []).length) {
      if (selectors.dateFixed && _.find(obs.history, ['activity', 'fixed'])) {
        const timeByTimespan = this.utils.timespan(selectors.dateFixed);
        const fixed = _.get(_.find(obs.history, ['activity', 'fixed']), 'time') * 1000;
        if (selectors.dateFixed !== 'custom') {
          if (!(timeByTimespan.startTime <= fixed && fixed <= timeByTimespan.endTime)) {
            return false;
          }
        } else {
          if (!(timeByTimespan.startTime <= fixed && fixed <= timeByTimespan.endTime && selectors.dateFixedStart <= fixed && fixed <= selectors.dateFixedEnd)) {
            return false;
          }
        }
      } else {
        return false;
      }
    }

    // dateClosed filter
    if (_.get(selectors, 'dateClosed', []).length) {
      const timeByTimespan = this.utils.timespan(selectors.dateClosed);
      const closed = _.get(_.find(obs.history, ['activity', 'resolved']), 'time') * 1000;
      if (selectors.dateClosed !== 'custom') {
        if (!(timeByTimespan.startTime <= closed && closed <= timeByTimespan.endTime)) {
          return false;
        }
      } else {
        if (!(timeByTimespan.startTime <= closed && closed <= timeByTimespan.endTime && selectors.dateClosedStart <= closed && closed <= selectors.dateClosedEnd)) {
          return false;
        }
      }
    }

    return true;
  }

  /**
   * Find all of the observations that match selection criteria
   * Then organize them by the primary (and possibly secondary) columns
   *
   * If there are graphing options, extract that information also
   *
   * @param opts - an object containing filter and grouping options for the search. Options are as described in checkObservation above, plus the following:
   * @param opts.startTime - optional start of the timespan to look at
   * @param opts.endTime - optional end of timespan to look at
   * @param opts.period - optional period to divide the timespan into (see calendarInterval for values)
   * @param opts.timespan - optional timespan name to use to derive startTime and endTime
   * @param opts.teamIds - optional array of team id
   *
   * @param primaryGrouping - an object containing the field information to use to group the observation.  Properties required include fieldName, fieldRequired, and fieldFunc
   * @param primaryGrouping.fieldName - the name of the observation field to primarily group observations by (e.g., 'type').
   * @param primaryGrouping.fieldRequired - a boolean indicating that there must be a value for the primary grouping field for the observation to be included.
   * @param primaryGrouping.fieldFunc - an optional function that takes the value of the field and returns a reference to an array with a translated value and label to use for that value.
   * @param secondaryGrouping - an object containing the field information to use to group the observations. Same properties as primaryGrouping.
   */

  public findInIntervals(opts: any, primaryGrouping: any, secondaryGrouping: any = null): any {

    const intervals = this.calendarInterval(opts.startTime, opts.endTime, opts.period, opts.timespan);

    let obs = this.observations.data;
    if (opts.teamIds) {
      obs = _.filter(obs, (observation: any) => _.includes(opts.teamIds, observation.groupID));
    }

    const primary = {};
    const primaryKeys = {};
    const secondaryKeys = {};

    // accumulate information about every observation OPENED in the range of the report
    _.forEach(obs, (ref: any) => {

      // string check
      if (opts.searchString) {
        let matched = false;
        _.each(ref.searchIndex, (index: string) => {
          if (_.includes(index, opts.searchString)) {
            matched = true;
          }
        });
        if (matched === false) {
          return;
        }

      }

      const ctime = ref.created * 1000;
      if ((opts.startTime && ctime < opts.startTime) || (opts.endTime && ctime > opts.endTime)) {
        // this one is out of our range
        return;
      }
      if (!this.checkObservation(ref, opts)) {
        // this one doesn't match the filters
        return;
      }

      // function to accumulate an individual item
      const addItem = (p, s) => {
        let target = null;

        if (p[0] !== undefined) {
          // we have a key
          if (!_.has(primary, p[0])) {
            // we don't have this bucket yet
            primary[p[0]] = {extras: {}, items: [], label: p[1], intervals: {}};
            primary[p[0]][primaryGrouping.fieldName] = p[1];
            primaryKeys[p[1]] = p[0];
            if (secondaryGrouping) {
              // if there is a secondary accumulator, create a bucket for them
              primary[p[0]].secondary = {};
            } else {
              // there is no secondary - populate the intervals
              if (opts.interval !== 'none') {
                _.each(intervals, (iref, counter) => {
                  primary[p[0]].intervals['p' + counter] = {extras: {}, items: []};
                });
              }
            }
          }
          if (secondaryGrouping) {
            if (s[0] !== undefined) {
              // we have a key
              if (!_.has(secondaryKeys, s[1])) {
                // remember it
                secondaryKeys[s[1]] = s[0];
              }

              if (!_.has(primary[p[0]].secondary, s[0])) {
                // we don't have this bucket yet
                primary[p[0]].secondary[s[0]] = {extras: {}, items: [], label: s[1], intervals: {}};
                // remember the primary and secondary labels
                primary[p[0]].secondary[s[0]][primaryGrouping.fieldName] = p[1];
                primary[p[0]].secondary[s[0]][secondaryGrouping.fieldName] = s[1];
                // add in the interval buckets too
                if (opts.interval !== 'none') {
                  _.each(intervals, (iref, counter) => {
                    primary[p[0]].secondary[s[0]].intervals['p' + counter] = {extras: {}, items: []};
                  });
                }
              }
              primary[p[0]].secondary[s[0]].items.push(ref);
              target = primary[p[0]].secondary[s[0]];
            }
          } else {
            primary[p[0]].items.push(ref);
            target = primary[p[0]];
          }

          if (opts.interval !== 'none') {
            _.forEach(intervals, (iref, counter) => {
              // in each interval, add the data into the primary and secondary accumulators
              if (ctime >= iref.start && ctime <= iref.end) {
                target.intervals['p' + counter].items.push(ref);
                // we found a bucket for this observation
                return false;
              } else {
                return true;
              }
            });
          }
        }
      };

      // hold the primary key and label
      // get the primary field value from the record
      let pvList = _.get(ref, primaryGrouping.fieldName);
      if (pvList === undefined) {
        if (primaryGrouping.fieldRequired) {
          pvList = null;
        }
      }
      if (!_.isArray(pvList)) {
        pvList = [pvList];
      }
      _.each(pvList, (pv) => {
        let p = [];
        if (pv == null) {
          return;
        }
        if (primaryGrouping.hasOwnProperty('fieldFunc')) {
          // use the field function to derive the key and label
          // vrom the primary value
          p = primaryGrouping.fieldFunc(pv, ref);
        } else {
          // there is no translation function.  let's just use the id as
          // the key and the value
          p = [pv, pv];
        }
        if (secondaryGrouping) {
          // hold the primary key and label
          let s = [];
          // get the primary field value from the record
          let svList = _.get(ref, secondaryGrouping.fieldName);
          if (svList === undefined) {
            if (secondaryGrouping.fieldRequired) {
              svList = null;
            }
          }
          if (!_.isArray(svList)) {
            svList = [svList];
          }
          _.each(svList, (sv) => {
            if (sv == null) {
              return;
            }
            if (secondaryGrouping.hasOwnProperty('fieldFunc')) {
              // use the field function to derive the key and label
              // vrom the primary value
              s = secondaryGrouping.fieldFunc(sv, ref);
            } else {
              // there is no translation function.  let's just use the id as
              // the key and the value
              s = [sv, sv];
            }
            if (p && s) {
              addItem(p, s);
            }
          });
        } else {
          if (p) {
            addItem(p, [null, null]);
          }
        }
      });
    });

    // build an object of the accumulated data
    const r = {
      rows: primary,
      intervals,
      primaryKeys,
      secondaryKeys
    };
    return r;
  }

  /**
   *
   * @param start - an optional start time for the interval
   * @param end - an optional end time for the interval
   * @param interval - an optional name for an interval; legal values are none, hours, days, weeks, months, quarters, years.  Default is none.
   * @param timespan - an optional name for a span of time.  legal values are all, today, yesterday, 7days,
   * 180days, 90days, 30days, 365days, thisweek, lastweek, thismonth, lastmonth, thisquarter, lastquarter, thisyear, lastyear
   *
   * If starttime and endtime are not provided and a timespan is provided, the utils method timespan is used to calculate the times.
   */
  public calendarInterval(start: number | string = null, end: number | string = null, interval: string = 'none', timespan: string = 'all', format?: string): any {
    const locale = this.userDataService.getLanguage();
    const formatMap = {
      days: locale === 'en' ? 'MMM DD, YYYY' : 'DD MMM YYYY',
      weeks: locale === 'en' ? 'MMM DD, YYYY' : 'DD MMM YYYY',
      months: locale === 'en' ? 'MMM DD, YYYY' : 'DD MMM YYYY',
      quarters: locale === 'en' ? 'MMM DD, YYYY' : 'DD MMM YYYY',
      years: 'YYYY',
      hours: 'MMM DD HH:mm',
      'half-hours': 'MMM DD HH:mm',
      'quarter-hours': 'MMM DD HH:mm',
    };

    const intervals = [];
    let index = 0;
    if (typeof start === 'string') {
      start = parseInt(start, 10);
    }
    if (typeof end === 'string') {
      end = parseInt(end, 10);
    }

    if (!start && !end) {
      if (timespan === 'all') {
        start = 0;
        end = Date.now();
      } else {
        const f = this.utils.timespan(timespan);
        start = f.startTime;
        end = f.endTime;
      }
    }
    let d: number = start;

    if (interval === 'none') {
      intervals[0] = {label: 'All', start: d, end};
      return intervals;
    }

    if (format === undefined) {
      // get the format from the mapping
      format = formatMap[interval];
    }

    if (!format) {
      this.logger.log('no format for interval: ' + interval);
    }

    let s = moment(d);
    // if the timespan is one of the relative ones, use partial months
    if (_.indexOf(['7days', '14days', '28days', '30days', '90days', '180days', '365days'], timespan) > -1 && _.indexOf(['months', 'quarters', 'years'], interval) > -1) {
      if (interval === 'months') {
        intervals[index++] = {label: s.startOf('date').format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(1, 'M').startOf('month');
      } else if (interval === 'quarters') {
        intervals[index++] = {label: s.startOf('date').format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(1, 'Q').startOf('quarter');
      } else if (interval === 'years') {
        intervals[index++] = {label: s.format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(12, 'M').startOf('year');
      }
      d = parseInt(s.format('x'), 10);
    }
    while (d < end) {
      if (index) {
        // set the end of the previous interval
        intervals[index - 1].end = d - 1;
        intervals[index - 1].endSecs = this.utils.toSeconds(d - 1);
      }
      s = moment(d);
      if (interval === 'hours') {
        intervals[index] = {label: s.format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(1, 'h');
      } else if (interval === 'half-hours') {
        intervals[index] = {label: s.format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(30, 'm');
      } else if (interval === 'quarter-hours') {
        intervals[index] = {label: s.format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(15, 'm');
      } else if (interval === 'days') {
        intervals[index] = {label: s.format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(1, 'd');
      } else if (interval === 'weeks') {
        intervals[index] = {label: s.format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(1, 'w');
      } else if (interval === 'months') {
        intervals[index] = {label: s.format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(1, 'M');
      } else if (interval === 'quarters') {
        intervals[index] = {label: s.format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(1, 'Q');
      } else if (interval === 'years') {
        intervals[index] = {label: s.format(format), start: d, startSecs: this.utils.toSeconds(d)};
        s.add(12, 'M');
      }
      // get the timestamp for the next interval value
      d = parseInt(s.format('x'), 10);
      index++;
    }
    if (index) {
      // the last interval should end at the end
      intervals[index - 1].end = end;
      intervals[index - 1].endSecs = this.utils.toSeconds(<number>end - 1);
    }
    return intervals;
  }

  /**
   * getObservation - return a handle to an observation object
   *
   * @param observationID - the ID of the observation to fetch
   * @param force - true forces to get data from backend.
   * @returns Promise that resolves with the observation handle.
   */

  getObservation(observationID: number, force = false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.observations.data && this.observations.data[observationID] && !force) {
        resolve(this.observations.data[observationID]);
      } else {
        this.comms.sendMessage({
          cmd: 'getObservations',
          observations: JSON.stringify([observationID]),
          sendTime: Date.now()
        }, false, false).then((data) => {
          if (data && data.reqStatus === 'OK') {
            this.observations.data[observationID] = data.result.observations[0];
            resolve(data.result.observations[0]);
          } else {
            reject(this.translate.instant('COMMONT_SERVICE.Failed_to_retrieve') + observationID);
          }
        })
          .catch((err) => {
            reject(this.translate.instant('COMMONT_SERVICE.call_failed') + err);
          });
      }
    });
  }

  getObservationByUUID(uuid: string, force = false): Promise<any> {
    return new Promise((resolve, reject) => {
      const ref = _.find(this.observations.data, <any>{uuid});
      if (ref && !force) {
        resolve(ref);
      } else {
        this.comms.sendMessage({
          cmd: 'getObservations',
          uuids: JSON.stringify([uuid]),
          sendTime: Date.now()
        }, false, false).then((data) => {
          if (data && data.reqStatus === 'OK') {
            this.observations.data[data.result.observations[0].observationID] = data.result.observations[0];
            resolve(data.result.observations[0]);
          } else {
            reject(this.translate.instant('COMMONT_SERVICE.Failed_to_retrieve') + uuid);
          }
        })
          .catch((err) => {
            reject(this.translate.instant('COMMONT_SERVICE.call_failed') + err);
          });
      }
    });
  }

  // making updateObservation observable
  observableUpdateObservation(): Observable<any> {
    if (!this.observableGetObservationObs) {
      this.observableGetObservationObs = new Observable<any>((observer: Observer<any>) => {
        let timer: any;

        const refresh = () => {
          timer = setTimeout(() => {
            if (!this.usingSSE) {
              this.updateObservations().then((data) => {
                observer.next(data);
                refresh();
              }).catch((err) => {
                this.logger.log('updateObservations rejected: ' + JSON.stringify(err));
                refresh();
              });
            }
          }, this.getObservationCheckInterval);
        };

        this.updateObservations().then((data) => {
          observer.next(data);
        });
        refresh();

        this.stopUpdateObservationTimer = () => {
          clearTimeout(timer);
          this.logger.log('stopping the observation timer');
        };

        this.startUpdateObservationTimer = () => {
          this.logger.log('(re)starting the observation timer');
          clearTimeout(timer);
          refresh();
        };
      });
    }
    return this.observableGetObservationObs;
  }

  /**
   * startUpdating - start a timer
   */

  startUpdating() {
    if (this.startUpdateObservationTimer) {
      this.startUpdateObservationTimer();
    } else {
      this.observableUpdateObservation().subscribe();
    }
  }

  /**
   * stopUpdating - stop checking the server for messages
   */
  stopUpdating() {
    // tslint:disable-next-line:no-unused-expression
    this.stopUpdateObservationTimer && this.stopUpdateObservationTimer();
  }

  /**
   * initialize - set up the object
   *
   */
  initialize(args?) {
    this.clearCache();
    this.logger.log('Initialized');
  }

  buildSearchIndex(ref) {
    const retObj = [];

    // insert searchable items in here, everything lower cased and should be stringed.

    // 1. ID
    retObj.push(_.toLower(ref.observationID.toString()));

    // 2. Creator
    const creator = this.accountService.fullname(+(ref.userID));
    retObj.push(_.toLower(creator));

    // 3. owner
    const owner = this.accountService.fullname(+(ref.ownerID));
    retObj.push(_.toLower(owner));

    // 4. Location
    const locObj = this.userService.findLocation((ref.locationID));
    if (locObj) {
      retObj.push(_.toLower(locObj.name));
    }

    // 5. Zone
    const zoneLocation = this.userService.findLocation(ref.locationID);
    const zoneDetails = this.userService.findAnyZone(zoneLocation, ref.zoneID);
    if (zoneDetails && zoneDetails.name) {
      retObj.push(_.toLower(zoneDetails.name));
    }

    // 6. Team
    retObj.push(_.toLower(this.teamService.teamNameByID(ref.groupID)));

    // 7. Notes
    let retStr = '';
    _.each(ref.notes, nObj => {
      retStr += _.toLower(nObj.value) + ' ';
    });
    if (retStr.length > 0) {
      retObj.push(retStr);
    }

    // 8. Type
    retObj.push(_.toLower(ref.subtype));

    // 9. Categories
    if (!_.isEmpty(ref.categories)) {
      let retString = '';
      _.each(ref.categories, data => {
        const catNote = _.find(this.settingsService.compliments.data, ['messageID', data]);
        if (catNote) {
          retString += _.toLower(catNote.messageTitle) + ' ';
        }

      });
      retObj.push(retString);
    }

    // 10 Participants
    if (!_.isEmpty(ref.groups) || !_.isEmpty(ref.recipients)) {
      let retString = '';
      //
      if (!_.isEmpty(ref.groups)) {
        _.each(ref.groups, gid => {
          // tslint:disable-next-line:max-line-length
          const tName = this.teamService.teamNameByID(gid);
          if (tName) {
            retString += _.toLower(tName) + '';
          }
        });
      } else {
        _.each(ref.recipients, part => {
          const rName = this.accountService.fullname(part);
          if (rName) {
            retString += _.toLower(rName) + '';
          }
        });
      }
      retObj.push(retString);
      //
    }

    // 11 Workorder
    if (ref.workorder) {
      retObj.push(_.toLower(ref.workorder));
    }

    // 12 created time
    let fixedDate = _.find(ref.history, ['activity', 'fixed']);
    if (!fixedDate) {
      // that means it was scrapped,
      fixedDate = _.find(ref.history, ['activity', 'dropped']);
    }
    if (fixedDate) {
      retObj.push(_.toLower(this.utils.dateTimeFormat(fixedDate.time, null, true)));
    }

    // 13 time open
    retObj.push(_.toLower(moment(ref.created * 1000).fromNow(true)));

    // 14 logged
    retObj.push(_.toLower(this.utils.dateTimeFormat(ref.created)));
    retObj.push(_.toLower(this.utils.dateTimeFormat(ref.created, null, true)));

    // 15 closed
    const obj = _.find(ref.history, ['activity', 'resolved']);
    if (obj) {
      retObj.push(_.toLower(this.utils.dateTimeFormat(obj.time, null, true)));
    }

    // 16 duration
    let fixedDur = _.find(ref.history, ['activity', 'fixed']);
    if (!fixedDur) {
      fixedDur = _.find(ref.history, ['activity', 'dropped']);
    }
    const created = _.find(ref.history, ['activity', 'created']);
    let tDiff = 0;
    if (fixedDur && created) {
      tDiff = fixedDur.time - created.time;
    }
    if (tDiff) {
      retObj.push(_.toLower(moment.duration(tDiff * 1000).humanize()));
    }

    // 17 tag search
    let retString = '';
    if (!_.isEmpty(ref.tags)) {
      _.each(ref.tags, gid => {
        const tagObject: any = _.find(this.settingsService.customTags.data, ['tagID', gid]);
        if (tagObject) {
          retString += _.toLower(tagObject.tag) + '';
        }
      });
      retObj.push(retString);
    }

    // 18 categories
    if (!_.isEmpty(ref.categories)) {
      let retString = '';
      _.each(ref.categories, cObj => {
        let catNote = null;
        if (ref.type === 'quality') {
          catNote = _.find(this.settingsService.qualityCats.data, ['messageID', cObj]);
        } else if (ref.type === 'condition') {
          catNote = _.find(this.settingsService.categories.data, ['messageID', cObj]);
        }
        if (catNote) {
          retString += _.toLower(catNote.messageTitle) + '';
        }
      });
      retObj.push(retString);
    }


    return retObj;
  }

  /** Adds additional search indices for CA observation types.
   CA are special since they need info from response services, we want
   these indices to be built after those services have initialized.
   The buildSearchIndex() method in observation service builds the rest of
   the indices for all observation types.
   *
   @param ref CA observation object
   */
  public addtionalCaTableTextSearchIndex(ref) {
    if (ref.caIndexUpdated) {
      return ref;
    } else {
      const depData = this.checkResponseService.getDeploymentByID(ref.deploymentID);
      if (depData) {
        const lowerData = _.toLower(depData);
        if (!ref.searchIndex) {
          ref.searchIndex = [];
        }
        ref.searchIndex.push(lowerData);
      }
      const targetInfo = this.checkResponseService.getTargetInfo(ref.targetSignature);
      if (targetInfo) {
        if (!ref.searchIndex) {
          ref.searchIndex = [];
        }
        ref.searchIndex.push(targetInfo);
      }
      ref.caIndexUpdated = true;
      return ref;
    }
  }

  public addCaSearchIndices() {
    _.forEach(this.observations.data, (ref: any) => {
      if (ref.type && ref.type === 'ca') {
        this.addtionalCaTableTextSearchIndex(ref);
      }
    });
  }

  public typeColorByLabel(label: string): string {
    const typemap = {
      condition: 'E0BF01',
      behavior: '988000', // 'C4A500',
      quality: '8800FF',
      pi: '460085', // '5F00B4',
      compliment: '07AB28',
      ca: 'BD0017',
    };
    const subtypemap = {
      waiting: typemap.pi,
      general: typemap.pi,
      production: typemap.quality,
      receiving: typemap.quality,
      defect: typemap.quality
    };

    // the label is probably translated; look it up in the map

    let ret = null;
    _.each(this.subtypeMap, (val, name) => {
      if (val === label) {
        ret = subtypemap[name];
      }
    });
    if (!ret) {
      _.each(this.typeMap, (val, name) => {
        if (val === label) {
          ret = typemap[name];
        }
      });
    }
    return ret;
  }

  public creatorTeam(obs: any, includeDisabled: boolean = true) {
    const creator = obs.userID;
    return this.accountService.primaryTeam(+creator, includeDisabled);
  }

  public getCreatorRoles(obs: any) {
    const creator = this.accountService.getByID(obs.userID);
    return creator.roles;
  }

  public openTime(obs: any) {
    if (_.has(obs, 'calculated')) {
      if (_.has(obs.calculated, 'duration')) {
        return obs.calculated.duration;
      }
    } else {
      obs.calculated = {};
    }
    const fixed = this.whenFixed(obs);
    const created = obs.created;
    let tDiff = 0;
    if (fixed && created) {
      tDiff = fixed - created;
      obs.calculated.duration = tDiff;
      return tDiff;
    } else if (created) {
      tDiff = Date.now() / 1000 - created;
      obs.calculated.duration = tDiff;
      return tDiff;
    } else {
      return null;
    }
  }

  public unassignedTime(obs: any) {
    if (_.has(obs, 'calculated')) {
      if (_.has(obs.calculated, 'unassigned')) {
        return obs.calculated.unassigned;
      }
    } else {
      obs.calculated = {};
    }
    let totalTime = 0;
    let started = 0;
    let state = 0;
    _.each(obs.history, entry => {
      // look at each entry
      if (state === 0) {
        // # we are not yet anywhere interesting.
        if (entry.activity === 'escalated') {
          started = entry.time;
          state = 1;
        }
      } else if (state === 1) {
        if (entry.activity === 'claimed' || entry.activity === 'fixed') {
          totalTime += (entry.time - started);
          state = 0;
          started = 0;
        }
      }
    });
    if (state === 1 && started) {
      // it is STILL unclaimed.
      totalTime += (Date.now() / 1000) - started;
    } else {
      // it was closed out.  We can remember it
      obs.calculated.unassigned = totalTime;
    }
    return totalTime;
  }

  public whenCreated(obs: any) {
    if (_.has(obs, 'calculated')) {
      if (_.has(obs.calculated, 'created')) {
        return obs.calculated.created;
      }
    } else {
      obs.calculated = {};
    }
    const obj = _.find(obs.history, ['activity', 'created']);
    if (obj) {
      obs.calculated.created = obj.time;
      return obj.time;
    } else {
      return 0;
    }
  }

  public whenClosed(obs: any) {
    if (_.has(obs, 'calculated')) {
      if (_.has(obs.calculated, 'closed')) {
        return obs.calculated.closed;
      }
    } else {
      obs.calculated = {};
    }
    const obj = _.find(obs.history, ['activity', 'resolved']);
    if (obj) {
      obs.calculated.closed = obj.time;
      return obj.time;
    } else {
      return 0;
    }
  }

  public whenFixed(obs: any) {
    this._addCalculated(obs);
    if (_.has(obs.calculated, 'fixed')) {
      return obs.calculated.fixed;
    }
    let fixedTime: any = {};
    fixedTime = _.find(obs.history, ['activity', 'fixed']);
    if (!fixedTime) {
      // that means it was scrapped,
      fixedTime = _.find(obs.history, ['activity', 'dropped']);
    }
    if (!fixedTime && obs.type === 'ca') {
      // maybe it was directly resolved?
      fixedTime = _.find(obs.history, ['activity', 'resolved']);
    }
    if (fixedTime) {
      obs.calculated.fixed = fixedTime.time;
      return fixedTime.time;
    } else {
      return 0;
    }
  }

  public sentiment(obs: any) {
    this._addCalculated(obs);
    if (_.has(obs.calculated, 'sentiment')) {
      return obs.calculated.sentiment;
    }
    let ret = null;
    const type = this.getProperty(obs, 'type');
    if (type !== 'compliment' && type !== 'behavior') {
      ret = obs.calculated.sentiment = -999;
    } else {
      // okay we care about the sentiment of this one
      ret = -999;
      let count = 0;
      let total = 0;
      _.each(obs.notes, noteRef => {
        if (noteRef.subtype === 'comment' && _.has(noteRef, 'sentiment') && noteRef.sentiment !== 999 && noteRef.sentiment !== -999) {
          total += noteRef.sentiment;
          count++;
        }
      });
      if (count) {
        ret = total / count;
      }
      obs.calculated.sentiment = ret;
    }
    return ret;
  }

  public addObservation(parameters: any, showLoading: boolean = true): any {
    const requestParameters = {
      cmd: 'addObservation',
      userID: this.userDataService.userID,
      ...parameters
    };

    return this.comms.sendMessage(requestParameters, false, showLoading);
  }

  public getObservationById(id: number): { [key: string]: any } {
    return this.observations.data[id];
  }

  public getCollectionData(id: number | number[]): ICollectionItemData[] {
    if (_.isInteger(id)) {
      id = [id as number];
    }
    const ret: ICollectionItemData[] = [];

    _.each(id as number[], theItem => {
      const ref = this.getObservationById(theItem);
      if (ref) {
        const notes: string[] = [];
        _.each(ref.notes, note => {
          notes.push(note.value);
        });
        const t = this.typeMap[ref.type];
        ret.push({
          source: CollectionItemType.Observation,
          item: `${theItem}`,
          type: t,
          state: ref.state,
          zone: ref.zoneID,
          locationID: ref.locationID,
          notes,
          groupID: ref.groupID,
          addedAt: ref.addedAt,
          addedBy: ref.addedBy,
          lastUpdate: ref.lastUpdate,
          tagIDs: ref.tags,
          created: ref.created,
          attachments: [...ref.attachments, ...ref.images],
          uuid: ref.uuid,
          id: CollectionItemType.Observation + ':' + theItem,
        });
      }
    });
    return ret;
  }

  /**
   *
   * @param val the value in the range of 0 to 100 to find the band name of.
   *
   * @returns the name of the bank the value is in
   */
  private bandName(val: number): string {
    let name: string;
    if (val < 60) {
      name = 'medium';
    } else if (val < 80) {
      name = 'high';
    } else {
      name = 'highest';
    }
    return name;
  }

  private cleanNotes(obs: any) {
    if (obs.notes) {
      _.each(obs.notes, (note) => {
        if (note.type === 'text') {
          note.value = this.sanitizer.sanitize(SecurityContext.HTML, note.value);
        }
      });
    }
    return obs;
  }

  private translateTypes(): void {
    this.typeMap = {
      condition: this.translate.instant('SHARED.Condition'),
      behavior: this.translate.instant('SHARED.Coaching'),
      quality: this.translate.instant('SHARED.Quality'),
      pi: this.translate.instant('REPORTING.EDIT_pi'),
      compliment: this.translate.instant('SHARED.Thumbs_Up'),
      ca: this.translate.instant('SHARED.Corrective_Actions'),
      si: this.translate.instant('SHARED.Opportunity')
    };

    this.subtypeMap = {
      general: this.translate.instant('SHARED.General_Improvement'),
      defect: this.translate.instant('COMMONT_SERVICE.Defect'),
      receiving: this.translate.instant('SHARED.Receiving'),
      production: this.translate.instant('COMMONT_SERVICE.Production'),
      waiting: this.translate.instant('SHARED.Waiting')
    };
  }

  private _addCalculated(obs: any) {
    if (!_.has(obs, 'calculated')) {
      obs.calculated = {};
    }
  }
}
