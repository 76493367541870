import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { environment } from '@env';
import * as _ from 'lodash';
import { CommsService } from '@services/comms/comms.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@services/auth/auth.service';
import { StorageService } from '@services';

@Injectable({
  providedIn: 'root'
})
export class InitializeService {

  public isInitialized = false;

  private configTokens = [
    'subscriberID',
    'endpoint',
    'servicePath',
    'subscriberName',
    'SSEURL',
    'brand'
  ];

  constructor(
    private logger: NGXLogger,
    private subscriberService: SubscriberService,
    private authService: AuthService,
    private http: HttpClient,
    private commsService: CommsService,
    private storageService: StorageService
  ) {
  }

  public async initialize(): Promise<boolean | void> {

    if (this.isInitialized) {
      this.logger.log('Already initialized');
      return Promise.resolve(true);
    } else {
      this.logger.log('Initializing');
      this.logger.debug('checking the subscriber ID');
      await this.checkSubscriberID();
      this.logger.debug('reloading comms token if one is available');
      this.storageService.restoreToken();
      this.logger.debug('refreshing the subscriber configuration from the backend');
      await this.subscriberService.refresh();
      this.isInitialized = true;
      this.logger.debug('initialization complete');
    }
  }

  public reset(): void {
    this.isInitialized = false;
  }

  public async checkSubscriberID() {
    return new Promise((resolve, reject) => {
      if (environment.data.subscriberID === 0) {
        let baseHref: string = environment.baseHref;
        if (_.last(baseHref) !== '/') {
          baseHref += '/';
        }

        const url = `${location.origin}${baseHref}assets/config/settings.json`;

        this.http.get(url).subscribe((response: any) => {
          _.each(this.configTokens, (name) => {
            const l = _.get(response, name);
            if (l) {
              if (name === 'subscriberID') {
                environment.data.subscriberID = +l;
              } else {
                environment.data[name] = l;
              }
            }
          });
          this.commsService.init({noAuth: () => this.authService.handleNoAuth()});
          resolve(true);
        }, (error: HttpErrorResponse) => {
          reject(error);
        });
      } else {
        this.commsService.init({noAuth: () => this.authService.handleNoAuth()});
        resolve(true);
      }
    });
  }

}
