import { Injectable, Injector } from '@angular/core';

import { PPEModelService } from '@modules/reporting/models/types/ppe/service';
import { BaseField, ReportBaseClass } from '@modules/reporting/models/types/base';
import { FormField, GearService, GeartypesService } from '@services';

import * as _ from 'lodash';
import { SelectFiltersFormComponent } from '@modules/reporting/components';

import { awaitHandler } from '@utils/awaitHandler';

@Injectable()
export class PPEReport extends ReportBaseClass {

  public reportType = 'PPESummary';

  private PPEModelService: PPEModelService = this.injector.get(PPEModelService);
  public reportColumnOptions = this.PPEModelService.getColumns();
  public reportFieldOptions = this.PPEModelService.getFieldOptions();
  public reportFieldOptions_temporarily = this.PPEModelService.getFieldOptions_temporarily();
  private geartypesService: GeartypesService = this.injector.get(GeartypesService);
  private gearService: GearService = this.injector.get(GearService);

  fields = [
    {
      containerClass: 'report-field PPESummary',
      title: this.translate.instant('REPORTING.EDIT_title2'),
      name: 'PPESummaryPrimaryField',
      type: 'selectmenu',
      multiple: false,
      required: true,
      originalOrder: false,
      options: this.reportFieldOptions,
      hideFieldsOnValue: {
        fieldNames: ['PPESummarySecondaryField', 'timespan', 'period', 'reportColumns', 'graphType'],
        value: 'none'
      },
      onChange: () => this.updateGraphFields('secondary')
    },
    {
      containerClass: 'report-field PPESummary',
      title: this.translate.instant('REPORTING.EDIT_title3'),
      name: 'PPESummarySecondaryField',
      canClear: true,
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.None'),
      multiple: false,
      originalOrder: false,
      required: false,
      options: this.reportFieldOptions_temporarily,
      onChange: () => this.updateGraphFields('primary')
    },
    {
      containerClass: 'report-field obsdets PPESummary',
      title: this.translate.instant('REPORTING.EDIT_Data_to_Include'),
      name: 'PPESummaryReportColumns',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.None'),
      multiple: true,
      required: true,
      options: this.reportColumnOptions,
      onChange: (values) => this.syncGraphSecondaryField('PPESummaryReportColumns', 'PPESummaryGraphSecondary', values)
    },
    this.baseFields[BaseField.Timespan],
    this.baseFields[BaseField.Period],
    this.baseFields[BaseField.GraphType],
    {
      containerClass: 'report-field obsdets graphing PPESummary',
      title: this.translate.instant('REPORTING.EDIT_Graph_by'),
      name: 'PPESummaryGraphPrimary',
      type: 'selectmenu',
      multiple: false,
      required: false,
      options: _.unionBy(this.reportFieldOptions, <any>[
        {id: 'period', description: this.translate.instant('SHARED.Period')},
        // { id: 'timespan', description: "Timespan" }
      ], 'id')
    },
    {
      containerClass: 'report-field obsdets graphing PPESummary',
      title: this.translate.instant('REPORTING.EDIT_Include_in_graph'),
      name: 'PPESummaryGraphSecondary',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.None'),
      multiple: false,
      required: false,
      options: this.reportColumnOptions
    },
    this.baseFields[BaseField.IncludingAll],
    this.baseFields[BaseField.ShowDataLabels],
    {
      containerClass: 'report-field obsdets PPESummary ccsFormSubdivider',
      title: this.translate.instant('REPORTING.EDIT_Observation_Type_Selectors_step2'),
      type: 'divider'
    },
    {
      containerClass: 'custom-data-field report-field PPESummary',
      title: this.translate.instant('SHARED.Select_Filters'),
      name: 'filtersFields',
      type: 'customElement',
      component: SelectFiltersFormComponent,
      inputs: {
        options: _.cloneDeep(this.filtersFields),
        selectedItems: []
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = <FormField>_.find(this.formConfig.fields, {name: 'filtersFields'});
          (field.componentRef as SelectFiltersFormComponent).selectedItems = selectedItems;
          this.removedFilters = _.difference(this.filters, selectedItems);
          this.filters = selectedItems;
          this.showFilters();
        }
      },
      options: _.cloneDeep(this.filtersFields)
    },
  ];
  public gearOpts: any;
  filtersFields = [
    {
      containerClass: 'report-field obsdets PPESummary',
      title: this.translate.instant('REPORTING.EDIT_Gear_Type'),
      name: 'gearTypes',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Type'),
      multiple: true,
      canDelete: true,
      valueProperty: 'id',
      options: this.geartypesService.gearTypes.data,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref: any) => ref.description,
    },
    {
      containerClass: 'report-field obsdets PPESummary',
      title: this.translate.instant('REPORTING.EDIT_Gear_Names'),
      name: 'gearNames',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Gears'),
      multiple: true,
      canDelete: true,
      valueProperty: 'gearID',
      options: this.gearService.gear.data,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref: any) => ref.name,
    },
    this.baseFields[BaseField.Locations],
    this.baseFields[BaseField.Groups]
  ];

  constructor(protected injector: Injector) {
    super(injector);
  }

  public init(reportData) {
    super.init(reportData);
    this.syncGraphSecondaryField('PPESummaryReportColumns', 'PPESummaryGraphSecondary');
  }

  public async report(theTable, theChart, report, reportId?: number) {
    this.reportData = report;
    const opts = _.cloneDeep(report.selectors);
    const reqParams: any = [];

    const timeByTimespan = this.getTimeByTimespan(opts.timespan, opts.startTime, opts.endTime);
    opts.startTime = timeByTimespan.startTime;
    opts.endTime = timeByTimespan.endTime;

    reqParams.includeLocations = 1;
    reqParams.includeGroups = 1;
    reqParams.startTime = opts.startTime;
    reqParams.endTime = opts.endTime;
    reqParams.interval = opts.period;

    const tableDef = _.merge({}, this.tableDef);

    const [gears] = await awaitHandler(this.gearService.getGearData(reqParams));
    this.gearOpts = opts;

    const r = this.findInterval(opts, gears);
    const pRef: any = _.find(this.reportFieldOptions, {id: opts.primary});
    const sRef: any = _.find(this.reportFieldOptions, {id: opts.secondary});

    if (pRef) {
      tableDef.columns.push({
        id: pRef.id,
        title: pRef.label,
        fromID: pRef.fieldName,
        headerClass: 'text-left',
        class: 'tableexport-string'
      });
    }
    if (sRef) {
      tableDef.columns.push({
        id: sRef.id,
        title: sRef.label,
        fromID: `secondary_${sRef.fieldName}`,
        headerClass: 'text-left',
        class: 'tableexport-string'
      });
      tableDef.groupBy = 0;
    }
    let counter = 0;

    // create columns for each period

    const totalGroup = opts.period === 'none' ? null : 'total';
    if (totalGroup) {
      tableDef.colgroups[totalGroup] = {title: 'TOTAL'};
    }

    if (opts.period !== 'none') {
      _.forEach(r.intervals, (ref) => {
        const item = 'p' + counter;
        if (opts.extraColumns) {
          tableDef.colgroups[item] = {title: ref.label};
          _.each(opts.extraColumns, (colname, index: number) => {
            const column = _.find(this.reportColumnOptions, {id: colname});
            tableDef.columns.push(this.addColumn(column, index, item + colname, ref.label));
          });
        }
        counter++;
      });
    }

    if (opts.extraColumns) {
      _.each(opts.extraColumns, (colname, index: number) => {
        const column = _.find(this.reportColumnOptions, {id: colname});
        tableDef.columns.push(this.addColumn(column, index, 'total' + colname, '', true));
      });
    }

    // okay - iterate over the returned data, sorting by primary and possibly secondary key
    let rowRefs = [];
    // first, order the primary labels
    const rows = _.flatten(_.values(this.filterCategories(r.rows, opts)));
    const pOrder = _.sortBy(rows, 'label');
    const significantPrimaryKeys = [];
    const significantSecondaryKeys = [];

    if (sRef) {
      // we have a secondary key
      _.each(pOrder, (pitem: any, pds) => {
        let significant = false;
        const sOrder = _.sortBy(pitem.secondary, 'label');
        _.each(sOrder, (item, sds) => {
          if (item.items.length || opts.includingAll) {
            significant = true;
            if (_.indexOf(significantSecondaryKeys, item.label) < 0) {
              significantSecondaryKeys.push(item.label);
            }
            // there were matching items
            // push this data into a row
            const d = item;
            d.totalObservations = item.items.length;
            _.each(item.extras, (value, col) => {
              d['total' + col] = value;
            });
            _.each(item.intervals, (iref, name) => {
              d[name] = iref.items.length;
              _.each(iref.extras, (value, col) => {
                d[name + col] = value;
              });
            });
            rowRefs.push(d);
          }
        });
        if (significant) {
          if (_.indexOf(significantPrimaryKeys, pitem.label) < 0) {
            significantPrimaryKeys.push(pitem.label);
          }
        }
      });
    } else {
      // we only have a primary key
      _.each(pOrder, (item: any, pds) => {
        if (item.items.length || opts.includingAll) {
          if (_.indexOf(significantPrimaryKeys, item.label) < 0) {
            significantPrimaryKeys.push(item.label);
          }
          // push this data into a row
          const d = item;
          d.totalObservations = d.items.length;
          _.each(item.extras, (value, col) => {
            d['total' + col] = value;

          });
          _.each(item.intervals, (iref, name) => {
            d[name] = iref.items.length;
            _.each(iref.extras, (value, col) => {
              d[name + col] = value;
            });
          });
          rowRefs.push(d);
        }
      });
    }

    if (theChart) {
      // okay all the rows are gathered.  Do we need to graph?
      if (opts.graphType !== 'none') {
        const PPESummaryReportFieldOptions = _.clone(this.reportFieldOptions);
        const PPESummaryReportColumnOptions = _.clone(this.reportColumnOptions);
        const keys = {
          primary: significantPrimaryKeys,
          secondary: significantSecondaryKeys
        };

        // if we are graphing by observation category, let's send in an ordered list of colors
        let graphData: any;
        const rowRefsClone = _.cloneDeep(rowRefs);
        const convertFields: string[] = ['totalaverage_registrationDuration', 'totalregistrationDuration', 'totalaverage_wearDuration', 'totalwearDuration'];
        _.forEach(rowRefsClone, (rowref) => {
          _.forEach(convertFields, (field) => {
            rowref[field] = rowref?.field ? this.utils.durationToSecs(rowref[field]) : 0;
          });
        });
        if (opts.graphPrimary === 'period') {
          graphData = this.graphService.graphdataByPeriod(opts, r, rowRefsClone, keys, PPESummaryReportFieldOptions, PPESummaryReportColumnOptions);
        } else if (opts.graphPrimary === opts.primary) {
          graphData = this.graphService.graphdataByPrimary(opts, rowRefsClone, keys, PPESummaryReportFieldOptions, PPESummaryReportColumnOptions);
        } else if (opts.secondary && opts.graphPrimary === opts.secondary) {
          graphData = this.graphService.graphdataBySecondary(opts, rowRefsClone, keys, PPESummaryReportFieldOptions, PPESummaryReportColumnOptions);
        }
        if (graphData) {
          $('.report-view-has-chart').show();
          this.drawReportGraph(theChart, graphData, opts, reportId);
        }
      } else {
        $('.report-view-has-chart').hide();
      }
    }

    if (theTable) {
      rowRefs = _.orderBy(rowRefs, 'label', ['asc', 'desc']);
      tableDef.data = rowRefs;
      tableDef.columns = this.preparationColumns(tableDef.columns);
      this.tableService.showDataTable(theTable, tableDef);
    }
  }

  public findInterval(opts, gears) {
    const intervals = this.observations.calendarInterval(opts.startTime, opts.endTime, opts.period, opts.timespan, opts.format);

    let primary: any = {};
    const gearsObj: any = {};
    const primaryKeys = {};
    const secondaryKeys = {};

    this.gearOpts = opts;

    const pRef: any = _.find(this.reportFieldOptions, {id: opts.primary});
    const sRef: any = _.find(this.reportFieldOptions, {id: opts.secondary});

    primary = this.getPrimary(opts, sRef, intervals);

    _.forEach(gears.gear, (ref: any) => {
      const gear = this.gearService.getGearByID(ref.gearID);
      gear.locations = ref.locations;
      ref.created = opts.startTime;
      ref = _.merge(ref, gear);
      if (opts.period === 'none') {
        ref.intervals = intervals;
        if (opts.primary === 'Location') {
          if (ref.locations) {
            _.forEach(ref.locations, (locObj: any, index) => {
              locObj.locationID = index;
              locObj = _.merge(locObj, gear);
              gearsObj[locObj.gearID + '_' + index + '_loc'] = locObj;
            });
          }
        } else if (opts.primary === 'Team') {
          if (ref.groups) {
            _.forEach(ref.groups, (locObj: any, index) => {
              locObj.groupID = index;
              locObj = _.merge(locObj, gear);
              gearsObj[locObj.gearID + '_' + index + '_group'] = locObj;
            });
          }
        } else {
          gearsObj[ref.gearID] = ref;
        }
        // gearsObj[ref.gearID] = ref;
      } else {
        if (ref.intervals) {
          _.forEach(ref.intervals, (intervalObj: any, index) => {
            const intervalData = gears.intervals[index];
            intervalObj.created = intervalData.start;
            intervalObj.start = intervalData.start;
            intervalObj.end = intervalData.end;
            intervalObj = _.merge(intervalObj, gear);

            if (opts.primary === 'Location') {
              if (intervalObj.locations) {
                _.forEach(intervalObj.locations, (locObj: any, locIndex) => {
                  intervalObj.locationID = locIndex;
                  gearsObj[locObj.gearID + '_' + index + '_' + locIndex + '_loc'] = intervalObj;
                });
              }
            } else if (opts.primary === 'Team') {
              if (ref.groups) {
                _.forEach(ref.groups, (locObj: any, groupIndex) => {
                  intervalObj.groupID = groupIndex;
                  gearsObj[locObj.gearID + '_' + index + '_' + groupIndex + '_group'] = intervalObj;
                });
              }
            } else {
              gearsObj[intervalObj.gearID + '_' + index] = intervalObj;
            }
          });
        }
      }
    });

    _.forEach(gearsObj, (ref: any) => {
      const ctime = ref.created * 1000 + 1;

      if (!this.gearService.checkGear(ref, opts)) {
        return;
      }

      const createItem = (key: string, label: string) => {
        const newItem: any = {
          extras: {},
          items: [],
          label,
          intervals: {},
          [pRef.fieldName]: label,
          fieldName: pRef.fieldName
        };

        primaryKeys[label] = key;

        if (sRef) {
          newItem.secondary = {};
        } else {
          if (opts.interval !== 'none') {
            _.each(intervals, (iref, counter) => {
              const iname = 'p' + counter;
              newItem.intervals[iname] = {extras: {}, items: []};
            });
          }
        }

        return newItem;
      };

      const findItem = (key: string, label: string) => {
        if (!_.has(primary, key)) {
          primary[key] = createItem(key, label);
        } else {
          const item = _.find(_.flatten([primary[key]]), <any>{label});

          if (!item) {
            primary[key] = [primary[key], createItem(key, label)];
          }
        }

        return _.find(_.flatten([primary[key]]), <any>{label});
      };

      const addItem = (p, s) => {
        if (p[0] !== undefined) {
          let target = null;
          const item: any = findItem(p[0], p[1]);
          if (sRef) {
            if (s[0] !== undefined) {
              if (!_.has(secondaryKeys, s[1])) {
                secondaryKeys[s[1]] = s[0];
              }

              if (!_.has(item.secondary, s[0])) {
                item.secondary[s[0]] = {
                  extras: {},
                  items: [],
                  label: s[1],
                  intervals: {},
                  fieldName: pRef.fieldName,
                  secondFieldName: sRef.fieldName,
                  [pRef.fieldName]: p[1],
                  [`secondary_${sRef.fieldName}`]: s[1]
                };
                if (opts.interval !== 'none') {
                  _.each(intervals, (iref, counter) => {
                    const iname = 'p' + counter;
                    item.secondary[s[0]].intervals[iname] = {extras: {}, items: []};
                  });
                }
              }
              item.secondary[s[0]].items.push(ref);
              target = item.secondary[s[0]];
            }
          } else {
            item.items.push(ref);
            target = item;
          }

          if (opts.interval !== 'none') {
            _.forEach(intervals, (iref, counter) => {
              if (ctime >= iref.start && ctime <= iref.end) {
                const iname = 'p' + counter;
                target.intervals[iname].items.push(ref);
                return false;
              } else {
                return true;
              }
            });
          }
        }
      };

      let pvList = _.get(ref, pRef.fieldName);
      if (pvList === undefined) {
        if (pRef.fieldRequired) {
          pvList = null;
        }
      }
      if (!_.isArray(pvList)) {
        pvList = [pvList];
      }
      _.each(pvList, (pv) => {
        let p = [];
        if (pv == null) {
          return;
        }
        if (pRef.hasOwnProperty('fieldFunc')) {
          p = pRef.fieldFunc(pv, ref);
        } else {
          p = [pv, pv];
        }
        if (sRef) {
          let s = [];
          let svList = _.get(ref, sRef.fieldName);
          if (svList === undefined) {
            if (sRef.fieldRequired) {
              svList = null;
            }
          }
          if (!_.isArray(svList)) {
            svList = [svList];
          }
          _.each(svList, (sv) => {
            if (sv == null) {
              return;
            }
            if (sRef.hasOwnProperty('fieldFunc')) {
              s = sRef.fieldFunc(sv, ref);
            } else {
              s = [sv, sv];
            }
            if (p && s) {
              addItem(p, s);
            }
          });
        } else {
          if (p) {
            addItem(p, [null, null]);
          }
        }
      });
    });

    const extras = {};
    let extraColumns: any[] = _.get(opts, 'extraColumns', []);
    if (!_.isArray(extraColumns)) {
      extraColumns = [extraColumns];
    }

    _.each(extraColumns, (column) => {
      const c: any = _.find(this.reportColumnOptions, {id: column});

      _.each(primary, (primaryItem: any) => {
        _.each(_.flatten([primaryItem]), (pItem) => {
          const updateIntervals = (theItem) => {
            if (opts.interval !== 'none') {
              _.each(theItem.intervals, (intervalRef) => {
                intervalRef.extras[column] = c.func(intervalRef.items);
              });
            }
          };

          pItem.extras[column] = c.func(pItem.items);
          if (opts.secondary) {
            _.each(pItem.secondary, (sItem) => {
              sItem.extras[column] = c.func(sItem.items);
              updateIntervals(sItem);
            });
          } else {
            updateIntervals(pItem);
          }
        });
      });
    });

    const r = {
      rows: primary,
      intervals,
      primaryKeys,
      secondaryKeys
    };

    return r;
  }

  public async getTableItems(queryParams: any) {
    queryParams.includingAll = true;
    const [gears] = await awaitHandler(this.gearService.getGearData(queryParams));
    const theRows = this.findInterval(queryParams, gears);
    let currentRow: any = _.find(_.flatten(_.values(_.get(theRows, 'rows'))), {label: queryParams.primaryLabel} as any) || {};
    let tableItems: any[] = [];

    if (currentRow.secondary) {
      if (queryParams.secondaryLabel) {
        if (_.isUndefined(queryParams.index)) {
          tableItems = _.get(_.find(currentRow.secondary, <any>{label: queryParams.secondaryLabel}), 'items', []);
        } else {
          currentRow = _.find(currentRow.secondary, <any>{label: queryParams.secondaryLabel});
          tableItems = _.get(_.map(currentRow.intervals, 'items'), `[${queryParams.index}]`, []);
        }
      } else {
        tableItems = _.flatten(_.map(_.filter(currentRow.secondary, <any>{[currentRow.fieldName]: currentRow[currentRow.fieldName]}), 'items'));

        if (tableItems.length === 0) {
          tableItems = _.flatten(_.map(currentRow.secondary, 'items'));
        }
      }
    } else {
      if (_.isUndefined(queryParams.index)) {
        tableItems = currentRow.items;
      } else {
        tableItems = _.get(_.map(currentRow.intervals, 'items'), `[${queryParams.index}]`, []);
      }
    }

    return _.intersectionBy(tableItems, 'gearID');
  }

}
