import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import {
  AccountsService,
  ObservationService,
  RolesService,
  SettingsService,
  ShiftService,
  TeamsService,
  UserService,
  UtilsService
} from '@services';
import moment from 'moment';
import { DetailReportTableService } from '@modules/reporting/services/detail-report-table.service';

import { ObservationTypes } from '@services/observations/observation.service';

@Injectable({
  providedIn: 'root'
})
export class ObsDetailModelService {

  private columnOptions = [
    {
      id: 'observationID',
      description: this.translate.instant('DASHPAGES.SUPERVISER_COMP_open_data0'),
      label: this.translate.instant('DASHPAGES.SUPERVISER_COMP_open_data0'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          label = collection[0].observationID;
        }
        return label;
      }
    },
    {
      id: 'location',
      description: this.translate.instant('SHARED.Location'),
      label: this.translate.instant('SHARED.Location'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          label = _.get(this.userService.findLocation(collection[0].locationID), 'name');
          if (!label) {
            label = this.translate.instant('DASHPAGES.NA');
          }

        }
        return label;
      }
    },
    {
      id: 'zone',
      description: this.translate.instant('SHARED.Zone'),
      label: this.translate.instant('SHARED.Zone'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          const locRef = this.userService.findLocation(collection[0].locationID);
          const zoneRef = this.userService.findAnyZone(locRef, collection[0].zoneID);
          if (zoneRef) {
            label = `${locRef.name}: ${zoneRef.name}`;
          } else {
            if (locRef) {
              label = locRef.name + '/Site-wide';
            } else {
              label = 'Site-wide';
            }
          }
        }
        return label;
      }
    },
    {
      id: 'type',
      description: this.translate.instant('SHARED.Observation_Type'),
      label: this.translate.instant('SHARED.Observation_Type'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          label = collection[0].type;
          if (label === 'condition') {
            label = this.translate.instant('SHARED.Unsafe_Condition');
          } else if (label === 'behavior') {
            label = this.translate.instant('SHARED.Coaching_Opportunity');
          } else if (label === 'quality') {
            label = this.translate.instant('SHARED.CHANGING_TYPE_TS_quality');
          } else if (label === 'pi') {
            label = this.translate.instant('REPORTING.EDIT_pi');
            const sub = collection[0].subtype;
            if (sub === 'waiting') {
              label += ' - ' + this.translate.instant('SHARED.Waiting');
            } else {
              label += ' - ' + this.translate.instant('SHARED.General_Improvement');
            }
          } else if (label === 'compliment') {
            label = this.translate.instant('SHARED.Thumbs-Up');
          } else if (label === 'ca') {
            label = this.translate.instant('SHARED.Corrective_Actions');
          } else if (label === 'si') {
            label = this.translate.instant('SHARED.Submission');
          }
        }
        return label;
      }
    },
    {
      id: 'status',
      description: this.translate.instant('SHARED.Status'),
      label: this.translate.instant('SHARED.Status'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          label = collection[0].state;
          if ((collection[0].type === 'pi' && collection[0].subtype !== 'general') || collection[0].type === 'coaching' || collection[0].type === 'compliment' || collection[0].type === 'behavior') {
            label = this.translate.instant('SHARED.Closed');
          } else {
            if (label === 'new' || label === 'escalated') {
              label = this.translate.instant('SHARED.Open');
            } else if (label === 'workorder') {
              label = this.translate.instant('SHARED.Work_Order');
            } else if (label === 'fixed') {
              label = this.translate.instant('SHARED.Fixed');
            } else if (label === 'resolved') {
              label = this.translate.instant('SHARED.Closed');
            }
          }
        }
        return label;
      }
    },
    {
      id: 'severity',
      description: this.translate.instant('SHARED.Severity'),
      label: this.translate.instant('SHARED.Severity'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          label = collection[0].severity;
        }
        return label;
      }
    },
    {
      id: 'likelihood',
      description: this.translate.instant('SHARED.Likelihood'),
      label: this.translate.instant('SHARED.Likelihood'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          label = collection[0].likelihood;
        }
        return label;
      }
    },
    {
      id: 'creator',
      description: this.translate.instant('SHARED.Creator'),
      label: this.translate.instant('SHARED.Creator'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          label = this.userService.getFullname(collection[0].userID);
        }
        return label;
      }
    },
    {
      id: 'creatorRole',
      description: this.translate.instant('SHARED.Creator_Role'),
      label: this.translate.instant('SHARED.Creator_Role'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          const roles = this.accountsService.getByID(collection[0].userID).roles;
          if (roles?.length) {
            const role = collection[0]?.roles?.length ? collection[0]?.roles[0] : roles[0];
            label = _.get(this.roleService.getRoleById(role), 'name');
          }
        }
        return label;
      }
    },
    {
      id: 'owner',
      description: this.translate.instant('SHARED.Owner'),
      label: this.translate.instant('SHARED.Owner'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          label = this.userService.getFullname(collection[0].ownerID);
        }
        return label;
      }
    },
    {
      id: 'creatorTeam',
      description: this.translate.instant('SHARED.Creator_Team'),
      label: this.translate.instant('SHARED.Creator_Team'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          const u = this.accountsService.getAccount(collection[0].userID);
          if (u?.primaryGroup) {
            label = this.teamsService.teamNameByID(u.primaryGroup);
          } else if (u?.groups && u.groups[0]) {
            label = this.teamsService.teamNameByID(u.groups[0]);
          }
        }
        return label;
      }
    },
    {
      id: 'ownerTeam',
      description: this.translate.instant('REPORTING.EDIT_Owner_Team'),
      label: this.translate.instant('REPORTING.EDIT_Owner_Team'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          const u = this.accountsService.getAccount(collection[0].ownerID);
          if (u?.primaryGroup) {
            label = this.teamsService.teamNameByID(u.primaryGroup);
          } else if (u && u.groups && u.groups[0]) {
            label = this.teamsService.teamNameByID(u.groups[0]);
          }
        }
        return label;
      }
    },
    {
      id: 'notes',
      description: this.translate.instant('MGMT_DETAILS.Notes'),
      label: this.translate.instant('MGMT_DETAILS.Notes'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          const notes = collection[0].notes;
          if (notes && notes.length) {
            label = _.join(_.map(notes, 'value'), '<br />');
          }
        }
        return label;
      }
    },
    {
      id: 'dateCreated',
      description: this.translate.instant('SHARED.Date_Created'),
      label: this.translate.instant('SHARED.Date_Created'),
      cellType: 'string',
      showWhen: true,
      func: (collection, row, type) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          label = this.utils.dateTimeFormat(collection[0].created, null, false);

          if (type === 'sort') {
            label = collection[0].created;
          }

          if (type === 'export') {
            label = this.detailReportTableService.getExcelFormatDate(collection?.[0]?.created);
          }
        }
        return label;
      }
    },
    {
      id: 'dateFixed',
      description: this.translate.instant('SHARED.Date_Fixed'),
      label: this.translate.instant('SHARED.Date_Fixed'),
      cellType: 'string',
      showWhen: true,
      func: (collection, row, type) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          const fixed = _.find(collection[0].history, {activity: 'fixed'});
          if (fixed) {
            label = this.utils.dateTimeFormat(_.get(fixed, 'time'), null, false);

            if (type === 'sort') {
              label = _.get(fixed, 'time');
            }

            if (type === 'export') {
              label = this.detailReportTableService.getExcelFormatDate( _.get(fixed, 'time'));
            }
          }
        }
        return label;
      }
    },
    {
      id: 'dateClosed',
      description: this.translate.instant('SHARED.Date_Closed'),
      label: this.translate.instant('SHARED.Date_Closed'),
      cellType: 'string',
      showWhen: true,
      func: (collection, row, type) => {
        let label = this.translate.instant('SHARED.None');
        if (collection[0]) {
          const resolved = _.find(collection[0].history, {activity: 'resolved'});
          if (resolved) {
            label = this.utils.dateTimeFormat(_.get(resolved, 'time'), null, false);

            if (type === 'sort') {
              label = _.get(resolved, 'time');
            }

            if (type === 'export') {
              label = this.detailReportTableService.getExcelFormatDate( _.get(resolved, 'time'));
            }
          }
        }
        return label;
      }
    },
    {
      id: 'categories',
      description: this.translate.instant('SHARED.Categories'),
      label: this.translate.instant('SHARED.Categories'),
      cellType: 'string',
      showWhen: true,
      func: (collection) => {
        let label: string = '';
        if (collection[0]) {
          const categories = collection[0].categories;
          if (categories && categories.length) {
            const typeMap = {
              [ObservationTypes.Condition]: 'categories',
              [ObservationTypes.Behavior]: 'behaviors',
              [ObservationTypes.Quality]: 'qualityCats',
              [ObservationTypes.PI]: 'piCats',
              [ObservationTypes.Compliment]: 'compliments'
            };
            _.forEach(categories, (id: number) => {
              const category = this.settingsService.getItem(typeMap[collection[0].type], id);
              if (category) {
                label += category?.messageTitle + '<br />';
              }
            });
          }
        }
        return label || this.translate.instant('SHARED.None');
      }
    }
  ];
  private fieldOptions = [
    {
      id: 'observationID',
      description: this.translate.instant('SHARED.Response_ID'),
      label: this.translate.instant('SHARED.Response_ID'),
      fieldName: 'observationID',
      fieldType: 'string',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        const title = val;
        const obs = this.observations.getObservation(ref.observationID);
        // if (obs && obs.title) {
        //   title = obs.title;
        // }
        return [val, title];
      }
    },
    {
      id: 'categories',
      description: this.translate.instant('SHARED.Category'),
      label: this.translate.instant('SHARED.Category'),
      fieldName: 'categories',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        let l = null;
        let b = null;
        if (val === null) {
          val = 0;
          l = 'None Assigned';
        } else {
          if (ref.type === 'condition') {
            b = this.settingsService.categories.data;
          } else if (ref.type === 'behavior') {
            b = this.settingsService.behaviors.data;
          } else if (ref.type === 'compliment') {
            b = this.settingsService.compliments.data;
          } else if (ref.type === 'quality') {
            b = this.settingsService.qualityCats.data;
          } else if (ref.type === 'pi') {
            b = this.settingsService.piCats.data;
          }
          const r: any = _.find(b, {messageID: val});
          if (r) {
            l = r.messageTitle;
          } else {
            // l = 'Missing Category ' + val;
            // val = 0;
            return null;
          }
        }
        return [val, l];
      }
    },
    {
      id: 'type',
      description: this.translate.instant('SHARED.Observation_Type'),
      label: 'Type',
      fieldName: 'type',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        let label = val;
        if (val === 'condition') {
          label = this.translate.instant('SHARED.Condition');
        } else if (val === 'quality') {
          label = this.translate.instant('SHARED.Quality');
        } else if (val === 'pi') {
          if (ref.subtype === 'waiting') {
            label = this.translate.instant('SHARED.Waiting');
          } else {
            label = this.translate.instant('SHARED.General_Improvement');
          }
        } else if (val === 'compliment') {
          label = this.translate.instant('SHARED.Thumbs_Up');
        } else if (val === 'behavior') {
          label = this.translate.instant('SHARED.Coaching');
        } else if (val === 'ca') {
          label = this.translate.instant('SHARED.Corrective_Actions');
        } else if (val === 'si') {
          label = this.translate.instant('SHARED.Submission');
        }

        return [val, label];
      }
    },
    {
      id: 'state',
      description: this.translate.instant('SHARED.Status'),
      label: this.translate.instant('SHARED.Status'),
      fieldName: 'state',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        let label = val;
        if ((ref.type === 'pi' && ref.subtype !== 'general') || ref.type === 'coaching' || ref.type === 'compliment' || ref.type === 'behavior') {
          val = 'closed';
          label = this.translate.instant('SHARED.Closed');
        } else {
          if (val === 'new' || val === 'escalated') {
            val = 'open';
            label = this.translate.instant('SHARED.Open');
          } else if (val === 'workorder') {
            label = this.translate.instant('SHARED.Work_Order');
          } else if (val === 'fixed') {
            label = this.translate.instant('SHARED.Fixed');
          } else if (val === 'resolved') {
            label = this.translate.instant('SHARED.Closed');
          }
        }
        return [val, label];
      }
    },
    {
      id: 'zone',
      description: this.translate.instant('SHARED.Zone'),
      label: this.translate.instant('SHARED.Zone'),
      fieldName: 'zoneID',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        // find the zone in the location
        const zsig = ref.locationID + ':' + ref.zoneID;
        const locRef = this.userService.findLocation(ref.locationID);
        const zoneRef = this.userService.findAnyZone(locRef, ref.zoneID);
        if (zoneRef) {
          return [zsig, `${locRef.name}: ${zoneRef.name}`];
        } else {
          if (locRef) {
            return [zsig, locRef.name + '/Site-wide'];
          } else {
            return [zsig, 'Site-wide'];
          }
        }
      }
    },
    {
      id: 'creator',
      description: this.translate.instant('SHARED.Creator'),
      label: this.translate.instant('SHARED.Creator'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val) => [val, this.userService.getFullname(val)]
    },
    {
      id: 'creatorTeam',
      description: this.translate.instant('SHARED.Creator_Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val) => {
        const u = this.accountsService.getAccount(val);
        if (u?.primaryGroup) {
          return [u.primaryGroup, this.teamsService.teamNameByID(u.primaryGroup)];
        } else if (u.groups && u.groups[0]) {
          return [u.groups[0], this.teamsService.teamNameByID(u.groups[0])];
        } else {
          return [0, this.translate.instant('SHARED.None')];
        }
      }
    },
    {
      id: 'owner',
      description: this.translate.instant('SHARED.Helper'),
      label: this.translate.instant('SHARED.Helper'),
      fieldName: 'ownerID',
      fieldType: 'integer',
      fieldFunc: (val) => {
        let n = 'unassigned';
        if (val) {
          n = this.userService.getFullname(val);
        }
        return [val, n];
      }
    },
    {
      id: 'ownerTeam',
      description: this.translate.instant('SHARED.Helper_Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'ownerID',
      fieldType: 'integer',
      fieldFunc: (val) => {
        const u = this.accountsService.getAccount(val);
        if (u?.primaryGroup) {
          return [u.primaryGroup, this.teamsService.teamNameByID(u.primaryGroup)];
        } else if (u.groups && u.groups[0]) {
          return [u.groups[0], this.teamsService.teamNameByID(u.groups[0])];
        } else {
          return [0, this.translate.instant('SHARED.None')];
        }
      }
    },
    {
      id: 'locationID',
      description: this.translate.instant('SHARED.Location'),
      label: 'Location',
      fieldName: 'locationID',
      fieldType: 'number',
      fieldFunc: (locationId: number) => {
        let locationName: string = _.get(this.userService.findLocation(locationId), 'name');

        if (!locationName) {
          locationId = 0;
          locationName = this.translate.instant('DASHPAGES.NA');
        }
        return [locationId, locationName];
      }
    },
    {
      id: 'fixer',
      description: this.translate.instant('SHARED.Fixer'),
      label: this.translate.instant('SHARED.Fixer'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        let v = 0;
        let n = 'n/a';
        let p: any;
        if (ref.type === 'quality') {
          if (ref.subtype === 'receiving') {
            // receiving just go straight to closed; use the creator
            p = _.find(ref.history, {activity: 'created'});
          } else if (ref.subtype === 'production') {
            // production can go to dropped; pretend that is 'fixed'
            p = _.find(ref.history, {activity: 'fixed'});
            if (!p) {
              p = _.find(ref.history, {activity: 'dropped'});
            }
          }
        } else if (ref.type === 'pi') {
          if (ref.subtype === 'waiting') {
            p = _.find(ref.history, {activity: 'created'});
          } else {
            p = _.find(ref.history, {activity: 'fixed'});
          }
        } else {
          p = _.find(ref.history, {activity: 'fixed'});
        }

        // we still have no pointer - let's look for resolved
        if (!p) {
          p = _.find(ref.history, {activity: 'resolved'});
        }

        if (p) {
          v = p.userID;
          n = this.userService.getFullname(p.userID);
        }
        return [v, n];
      }
    },
    {
      id: 'fixerTeam',
      description: this.translate.instant('SHARED.Fixer_Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        let v = val;
        let n = 'n/a';
        let p: any;
        if (ref.type === 'quality') {
          if (ref.subtype === 'receiving') {
            // receiving just go straight to closed; use the creator
            p = _.find(ref.history, {activity: 'created'});
          } else if (ref.subtype === 'production') {
            // production can go to dropped; pretend that is 'fixed'
            p = _.find(ref.history, {activity: 'fixed'});
            if (!p) {
              p = _.find(ref.history, {activity: 'dropped'});
            }
          }
        } else if (ref.type === 'pi') {
          if (ref.subtype === 'waiting') {
            p = _.find(ref.history, {activity: 'created'});
          } else {
            p = _.find(ref.history, {activity: 'fixed'});
          }
        } else {
          p = _.find(ref.history, {activity: 'fixed'});
        }

        // we still have no pointer - let's look for resolved
        if (!p) {
          p = _.find(ref.history, {activity: 'resolved'});
        }
        if (p) {
          const u = this.accountsService.getAccount(v);
          if (u?.primaryGroup) {
            return [u.primaryGroup, this.teamsService.teamNameByID(u.primaryGroup)];
          } else if (u.groups && u.groups[0]) {
            return [u.groups[0], this.teamsService.teamNameByID(u.groups[0])];
          } else {
            return [0, this.translate.instant('SHARED.None')];
          }
        }
        return [v, n];
      }
    },
    {
      id: 'closer',
      description: this.translate.instant('SHARED.Closer'),
      label: this.translate.instant('SHARED.Closer'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        let v = val;
        let n = 'n/a';
        const p: any = _.find(ref.history, {activity: 'resolved'});
        if (p) {
          v = p.userID;
          n = this.userService.getFullname(p.userID);
          return [v, n];
        }
      }
    },
    {
      id: 'team',
      description: this.translate.instant('SHARED.Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'groupID',
      fieldType: 'integer',
      fieldFunc: (val) => [val, this.teamsService.teamNameByID(val)]
    },
    {
      id: 'tag',
      description: this.translate.instant('SHARED.Tag'),
      label: this.translate.instant('SHARED.Tag'),
      fieldName: 'tags',
      fieldType: 'array',
      fieldFunc: (val) => {
        const tag: any = _.get(_.find(this.settingsService.customTags.data, <any>{tagID: +val}), 'tag', null);
        return tag ? [val, tag] : null;
      }
    },
    {
      id: 'creator_shift',
      description: this.translate.instant('SHARED.Creator_Shift'),
      label: this.translate.instant('SHARED.Creator_Shift'),
      fieldName: 'creatorShift',
      fieldType: 'string',
      fieldFunc: (val) => [val, this.shift.name(val)]
    },
    {
      id: 'helper_shift',
      description: this.translate.instant('SHARED.Helper_Shift'),
      label: this.translate.instant('SHARED.Helper_Shift'),
      fieldName: 'ownerShift',
      fieldType: 'string',
      fieldFunc: (val) => [val, this.shift.name(val)]
    },
  ];

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private accountsService: AccountsService,
    private shift: ShiftService,
    private teamsService: TeamsService,
    private settingsService: SettingsService,
    private observations: ObservationService,
    private utils: UtilsService,
    private roleService: RolesService,
    private detailReportTableService: DetailReportTableService
  ) {
  }

  public getColumns() {
    const columns = _.filter(_.cloneDeep(this.columnOptions), (opt) => opt.showWhen);
    return columns;
  }

  public getFieldOptions() {
    return this.fieldOptions;
  }

  private toDays(seconds, precision: number = 0) {
    let f = seconds;
    if (f) {
      f = _.round(f / (60 * 60 * 24), precision);
    }
    return f;
  }

}
