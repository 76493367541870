import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { NotePopupComponent } from '@modules/observation/components';

interface ObservationNote {
  data: string;
  name: string;
  time: string;
  userImg: string;
}

export interface NoteListConfig {
  title: string;
  notes: ObservationNote[];
  observationId: number;
  titleColorClass: string;
  newNoteType: string;
  showEmptyMessage?: boolean;
}

@Component({
  selector: 'app-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss'],
})
export class NoteListComponent {

  @Input() config: NoteListConfig;

  constructor(private popover: PopoverController) {
  }

  public addNote() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.config.observationId,
        msgType: this.config.newNoteType
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

}
