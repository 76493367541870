import { Injectable } from '@angular/core';

import { Feature, Module, SubscriberService } from '@services/subscriber/subscriber.service';
import { AccountTypes } from '@services';
import { UserdataService } from '@services/userdata/userdata.service';
import { PermissionsService } from '@services/permissions/permissions.service';
import * as _ from 'lodash';

export interface IModuleAccess {
  module: Module;
  feature?: Feature;
  checkRelatedFeatures?: boolean;
  subscriberAccess?: IModuleSubscriberAccess;
}

export interface IModuleSubscriberAccess {
  user?: string;
  account?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ModuleAccessService {

  public static readonly allowedAccounts: AccountTypes[] = [AccountTypes.Dedicated, AccountTypes.Shared];

  constructor(
    private subscriberService: SubscriberService,
    private userDataService: UserdataService,
    private permissions: PermissionsService,
  ) {
  }

  public isModuleEnabled(moduleAlias: Module): boolean {
    return moduleAlias ? this.subscriberService.usesModule(moduleAlias.toString()) : true;
  }

  public isFeatureEnabled(featureAlias: Feature, checkRelatedFeatures?: boolean): boolean {
    return featureAlias ? this.subscriberService.usesFeature(featureAlias, checkRelatedFeatures) : true;
  }

  public hasSubscriberAccess(subscriberAccess: IModuleSubscriberAccess): boolean {
    if (_.isEmpty(subscriberAccess)) {
      return true;
    }

    const usersIds: string[] = _.get(this.subscriberService.subInfo, `features.${subscriberAccess.user}`, []);
    const userAccessByIdAllowed: boolean = usersIds.length > 0 && _.includes(usersIds, this.userDataService.userID);

    const accountAccess: AccountTypes[] = [
      ..._.get(this.subscriberService.subInfo, `features.${subscriberAccess.account}`, []),
      ...ModuleAccessService.allowedAccounts
    ];
    const userAccessByAccountAllowed: boolean = _.includes(accountAccess, this.userDataService.type);

    return userAccessByAccountAllowed || userAccessByIdAllowed;
  }

  public isAllowedAccount(): boolean {
    return _.includes(ModuleAccessService.allowedAccounts, this.userDataService.type);
  }

  public isAccountPermitted(subscriberAccess: IModuleSubscriberAccess) {
    const permissions = _.get(this.subscriberService.subInfo, `features.${subscriberAccess.account}`, []);
    return _.some(permissions, (permission) => this.permissions.canView(permission));
  }
}
