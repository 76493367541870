<div class="header">
  <button (click)="close()" class="popup-close-button" tabindex="0"></button>
  <h2 class="title">{{ "DASHBOARD.DATE_PICKER_POPUP_title" | translate }}</h2>
</div>
<div class="body">
  <app-date-range-picker (onChanged)="onDateChange($event)"
                         *ngIf="dateRangeConfig"
                         [dateRange]="dateRangeConfig"
                         [endLabel]="'DASHBOARD.DATE_PICKER_POPUP_description_end'"
                         [startLabel]="'DASHBOARD.DATE_PICKER_POPUP_description'"></app-date-range-picker>
</div>
<div class="button-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ "SHARED.Cancel" | translate }}</ion-button>
  <ion-button (click)="save()" [disabled]="!dateRangeConfig" class="page-button"
              color="secondary">{{ "SHARED.OK" | translate }}</ion-button>
</div>
