import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { NguCarouselModule } from '@ngu/carousel';

import { SelectLangPipeModule } from '@modules/management/pages/details/abstractDetails/selectLang.module';
import {
  LocationDashboardPage,
  ObservationHomeDashboardPage,
  CoachingTablePage,
  ConditionTablePage,
  QualityTablePage,
  ProcessTablePage,
  ComplimentTablePage,
  HomePage,
  ConditionDetailPage,
  ComplimentDetailPage,
  CoachingDetailPage,
  ProcessDetailPage,
  QualityDetailPage,
  ChangingTypePage,
  AppCarousel,
  UnknownObservationPage,
  EditQualityFieldsPage,
  CaTablePage,
  CaDetailPage,
  CheckDetailPage,
  UnableToPerformReasonPage,
  CheckResultTablePage,
  IssueDetailPage,
  MarkResolvedPage,
  ExternalDataDetailPage
} from './pages';
import {
  SiteHealthComponent,
  IconMenuComponent,
  TabTablesComponent,
  DateSelectorComponent,
  ChartWidgetComponent,
  ClosingNotesComponent,
  ComplianceDetailsTablePopupComponent,
  ClosingDetailsComponent,
} from './components';
import { MarkNoteDirective } from './directives/mark-note.directive';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from './../shared/shared.module';
import { ObservationComponentsModule, } from '@modules/observation/components/components.module';
import { ObservationDetailComponentsModule } from '@modules/observation/pages/insights/pages/detail/components/components.module';

import { Select2Module } from '@shared/components/ng2-select2/ng2-select2.module';
import { MomentModule } from 'ngx-moment';
import { MarkdownModule } from 'ngx-markdown';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

const PAGES = [
  LocationDashboardPage,
  ObservationHomeDashboardPage,
  CoachingTablePage,
  ConditionTablePage,
  QualityTablePage,
  ProcessTablePage,
  ComplimentTablePage,
  HomePage,
  ChartWidgetComponent,
  ComplimentDetailPage,
  CoachingDetailPage,
  ProcessDetailPage,
  QualityDetailPage,
  ConditionDetailPage,
  ChangingTypePage,
  AppCarousel,
  UnknownObservationPage,
  EditQualityFieldsPage,
  CaTablePage,
  CaDetailPage,
  CheckDetailPage,
  UnableToPerformReasonPage,
  CheckResultTablePage,
  IssueDetailPage,
  MarkResolvedPage,
  ExternalDataDetailPage
];

const ENTRTY_COMPONENTS = [
  ComplianceDetailsTablePopupComponent
];

const COMPONENTS = [
  SiteHealthComponent,
  IconMenuComponent,
  TabTablesComponent,
  DateSelectorComponent,
  ChartWidgetComponent,
  TabTablesComponent,
  ClosingNotesComponent,
  ClosingDetailsComponent,
  ...ENTRTY_COMPONENTS
];

@NgModule({
  declarations: [
    ...PAGES,
    ...COMPONENTS,
    MarkNoteDirective
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardRoutingModule,
    Select2Module,
    NgSelectModule,
    FormsModule,
    IonicModule.forRoot(),
    NguCarouselModule,
    MomentModule,
    ObservationComponentsModule,
    ObservationDetailComponentsModule,
    TranslateModule.forChild(),
    SelectLangPipeModule,
    MarkdownModule.forChild()
  ],
  entryComponents: [
    ...ENTRTY_COMPONENTS
  ]
})
export class DashboardModule {
}
