import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

import * as _ from 'lodash';
import * as convert from 'convert-units';
import * as compareVersions from 'compare-versions';
import { Feature, SubscriberService } from '@services/subscriber/subscriber.service';
import { Permission, PermissionsService } from '@services/permissions/permissions.service';
import { AccountTypes, StorageService, UtilsService } from '@services';
import { CommsService } from '@services/comms/comms.service';
import { environment } from '@env';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@store/IAppState';
import { resetStore, updateUserLocations } from '@store/store.actions';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  public updatesSubject: Subject<void> = new Subject<void>();
  public onUpdatesChanges: Observable<void> = this.updatesSubject.asObservable().pipe(share());

  public showCarousel = false;
  public lastVersion = '';
  public serverVersion = '';

  public UserName: string;
  public shortName: string;
  public Registered: number;
  public Password: string;
  public Remember: number;
  public Token: string;
  public fullname: string;
  public userID: string;
  public type: any;
  public teams: Array<number> = [];
  public primaryGroup: number;
  public units = {};
  public gear: Array<number> = [];
  public locations: Array<number> = [];
  public certifications: Array<number> = [];
  public Permissions: any;
  public preferences: any;
  public observations: any = null;
  public compliance: any = null;
  public points: any = null;
  public ranks: any = null;
  public powerPoints = 0;
  public userScores: any = {};
  public consented = false;
  public consentDate = 0;
  public consentWithdrawnDate = 0;
  public isFirstLogin = 0;
  public lastLogin: number = null;
  public securityAnswer: string;

  constructor(
    private subscriber: SubscriberService,
    private permissionsService: PermissionsService,
    private store: Store<{AppStateReducer: IAppState}>,
    private comms: CommsService,
    private translate: TranslateService
  ) {
  }

  public fetch(): Promise<boolean> {
    return new Promise((resolve) => {
      const msg = {
        cmd: 'getUserInfo',
        userID: this.userID,
        includeShared: 1
      };
      return this.comms.sendMessage(msg, false, false).then((data) => {
        if (data.reqStatus === 'OK') {
          // the authentication worked
          this.handleAccountData(data.result.users[0]);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public updatePermissions(): void {
    this.permissionsService.updateAccessLevel(this.Permissions);
  }

  public newerVersion(theVersion: string): boolean {
    if (theVersion !== '' && this.lastVersion !== '') {
      // we have a previous version AND there is a version to check against
      if (compareVersions(theVersion, this.lastVersion) > 0) {
        return true;
      }
    }
    return false;
  }

  public needsOnboarding(): boolean {
    let ret = false;
    if (this.isFirstLogin) {
      // might need onboarding
      if (this.subscriber.usesFeature(Feature.FIRST_PASSWORD_UPDATE) || this.subscriber.usesFeature(Feature.SECURITY_QUESTION_RECOVERY)) {
        ret = true;
      }
    }
    return ret;
  }

  /**
   * handleAccountData - the account data from the backend and insert it
   *
   * @param userInfo - user data from backend
   */
  handleAccountData(userInfo: any): void {
    if (userInfo.fullname) {
      this.fullname = userInfo.fullname;
    } else if (userInfo.firstname) {
      this.fullname = userInfo.firstname + ' ' + userInfo.lastname;
    }

    if (userInfo.lastname) {
      this.shortName = userInfo.firstname + ' ' + UtilsService.charAtIdx(userInfo.lastname, 0);
    } else {
      this.shortName = userInfo.firstname;
    }

    if (userInfo.token) {
      this.Token = userInfo.token;
    }
    if (userInfo.primaryGroup) {
      this.primaryGroup = userInfo.primaryGroup;
    }
    this.userID = userInfo.userID;
    this.type = userInfo.type;
    this.teams = userInfo.groups || this.teams;
    this.gear = userInfo.gear;
    if (!this.gear) {
      if (userInfo.registeredGear) {
        this.gear = userInfo.registeredGear;
      } else {
        this.gear = [];
      }
    }

    this.certifications = _.get(userInfo, 'certfications', []);
    this.observations = _.get(userInfo, 'observations', null);
    this.compliance = _.get(userInfo, 'compliance', null);
    this.points = _.get(userInfo, 'points', null);
    this.ranks = _.get(userInfo, 'ranks', null);
    this.powerPoints = _.get(userInfo, 'powerPoints', 0);

    if (userInfo.registeredGear) {
      userInfo.gear = userInfo.registeredGear;
    }

    this.Permissions = _.get(userInfo, 'permissions', {worker: 1});

    this.preferences = _.get(userInfo, 'preferences', {});
    this.units = _.get(userInfo, 'units', {});

    this.permissionsService.updateAccessLevel(this.Permissions);
    if (_.has(this.Permissions, Permission.SuperAdmin)) {
      this.locations = [];
    } else {
      this.locations = _.get(userInfo, 'locations', []);
    }

    // override the locations list if there is a overriding setting

    const f = this.subscriber.getFeature('all_location_access');
    if (f && Array.isArray(f)) {
      // there is a list of options
      _.each(f, (item) => {
        if (this.Permissions[item]) {
          this.locations = [];
        }
      });
    }

    this.consentDate = _.get(userInfo, 'consentDate', 0);
    this.consentWithdrawnDate = _.get(userInfo, 'consentWithdrawnDate', 0);
    if (!this.consentWithdrawnDate && this.consentDate) {
      this.consented = true;
    }
    if (_.has(userInfo, 'isFirstLogin')) {
      // this is only supplied by a call to authenticate
      this.isFirstLogin = userInfo.isFirstLogin;
    }
    this.updatesSubject.next();

    this.store.dispatch(updateUserLocations({locations: _.cloneDeep(this.locations)}));
  }

  /**
   *
   * @param fData - the form data to send
   *
   * NOTE: This function assumes the data is already JSON encoded
   */
  public save(fData: any): Promise<any> {
    fData.cmd = 'updateUser';
    fData.userID = this.userID;
    return this.comms.sendMessage(fData, false, false);
  }

  public getUserID(): string {
    if (this.userID) {
      return `${this.subscriber.subInfo.subscriberID}-${this.userID}`;
    } else {
      return `${this.subscriber.subInfo.subscriberID}-0`;
    }
  }

  public getPreference(preference: string): any {
    return _.get(this.preferences, preference);
  }

  public savePreference(preference: string, setting: any): Promise<any> {
    this.preferences[preference] = setting;
    const p = _.cloneDeep(this.preferences);
    const fData = {
      preferences: JSON.stringify(p),
      userID: this.userID
    };
    return this.save(fData);
  }

  /**
   * @returns the users' preferred units; metric or imperial
   */
  public getUnits(type: string = 'measurement'): string {
    const u = _.get(this.units, type, this.subscriber.getUnits(type));
    return u;
  }

  public getLanguage(): string {
    // NOTE: This will need to be revisited when users have associated locations
    const l = _.get(this.preferences, 'defaultLanguage', this.subscriber.getLanguage());
    return l;
  }

  public enableLanguage(): void {
    this.translate.use(this.getLanguage());
    moment.locale(this.getLanguage());
    this.subscriber.updateCustomTerms();
  }

  /**
   *
   * @param temp - a temperature in Celcius
   *
   * @returns the temperature in the units the user prefers.
   */
  public temperature(temp: number, prec: number = 0): number {
    if (this.getUnits('measurement') === 'metric') {
      return _.round(temp, prec);
    } else {
      return _.round(convert(temp).from('C').to('F'), prec);
    }
  }

  public clear(): void {
    this.UserName = null;
    this.shortName = null;
    this.Registered = null;
    this.Password = null;
    this.Remember = null;
    this.Token = null;
    this.fullname = null;
    this.primaryGroup = null;
    this.userID = null;
    this.type = null;
    this.teams = null;
    this.units = null;
    this.gear = null;
    this.certifications = null;
    this.Permissions = null;
    this.preferences = null;
    this.observations = null;
    this.ranks = null;
    this.compliance = null;
    this.points = null;
    this.powerPoints = 0;
    this.showCarousel = false;
    this.consented = false;
    this.consentDate = 0;
    this.consentWithdrawnDate = 0;
    this.securityAnswer = null;
    this.clearRunning();
    this.store.dispatch(resetStore());
  }

  public getCachedUserData(): any {
    return JSON.parse(StorageService.getItem(`userdata${environment.baseHref}`)) || {};
  }

  public setRunning(): void {
    StorageService.setItem(`isRunning${environment.baseHref}`, '1');
  }

  public isRunning(): boolean {
    const t = StorageService.getItem(`isRunning${environment.baseHref}`);
    return !!t;
  }

  public clearRunning(): void {
    StorageService.removeItem(`isRunning${environment.baseHref}`);
  }

  public hasConsented(): boolean {
    let ret = false;

    const usesConsent = this.subscriber.getFeature(Feature.CONSENT);
    if (!usesConsent) {
      ret = true; // we don't care
    } else {
      ret = this.consented;
    }
    return ret;
  }

  public updateConsent(state: boolean): Promise<any> {
    const fData = {
      consent: state === true ? 1 : 0,
      userID: this.userID
    };
    return this.save(fData);
  }

  public hasAccess(): boolean {
    const permissionKeys: string[] = _.keys(this.Permissions);

    if (this.isNoAccessUser()) {
      return false;
    } else if (this.isNoPermissionsUser()) {
      return true;
    } else {
      return _.some(permissionKeys, (level) => !_.includes(this.permissionsService.blockedPermissions, level));
    }
  }

  public isNoPermissionsUser(): boolean {
    const noPermissionUserAccounts: AccountTypes[] = [AccountTypes.Reporting, AccountTypes.Viewer];
    return _.includes(noPermissionUserAccounts, this.type);
  }

  public isNoAccessUser(): boolean {
    const noAccessUserAccounts: AccountTypes[] = [AccountTypes.Observation];
    return _.includes(noAccessUserAccounts, this.type);
  }


  public setLanguage(lang: string): void {
    this.preferences.defaultLanguage = lang;
    StorageService.setItem('defaultLanguage', lang);
  }

  public setStoreDataByKey(storeDataKey: string, key: string, value: any): void {
    const settings: any = Object.assign({}, this.getPreference(storeDataKey), {
      [key]: value
    });
    this.savePreference(storeDataKey, settings);
  }

  public sendPrefs(): Promise<any> {
    if (this.Token) {
      const eData = {
        token: this.Token,
        cmd: 'preferences',
        config: JSON.stringify(this.preferences)
      };
      return this.comms.sendMessage(eData, false, false);
    } else {
      return new Promise((resolve) => {
        resolve('NoAuth');
      });
    }
  }

  public getStoreDataByKey(storeDataKey: string, key: string): any {
    return _.get(this.getPreference(storeDataKey), key);
  }

  public getPowerPoints(): Promise<any> {
    return new Promise((resolve, reject) =>
      this.comms.sendMessage({
        cmd: 'calculateScores',
        userID: this.userID
      }, false).then((response: any = {}) => {
        if (response.result && response.result.status === 'OK' && response.result.userScores) {
          if (_.has(response.result.userScores, this.userID)) {
            this.userScores = response.result.userScores[this.userID];
            resolve(this.userScores);
          } else {
            reject();
          }
        } else {
          reject();
        }
      })
    );
  }

}
