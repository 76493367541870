import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Events } from '@services/events/events.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
  DashboardHeaderIcon, FilterElementService,
  IHeaderIcon,
  LoadingService,
  ObservationDashboardUIService,
  ObservationIcon,
  PermissionsService,
  UserdataService,
  TableService,
  SubscriberService
} from '@services';
import { FilterMenuFormField, FilterMenuService } from '@shared/modules/menu-filter/services/filter-menu.service';
import { IHeaderSetting } from '@services/observationDashboardUI/observation-dashboard-ui.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnDestroy {

  @Input() showFilterBody: any;
  @Input() filterComps: any;
  @Input() filterCustomFields: any[];
  @Input() filterObject: any;
  @Input() extendFieldsConfig: any;
  @Input() searchField: any;
  @Output() onSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('dashboardMenuDropdown') dashboardMenuDropdown: ElementRef;
  public headerSetting: IHeaderSetting;
  public icons: IHeaderIcon[] = [];
  public observationIcons: ObservationIcon[] = [];
  public menuOpen = false;
  public Add_Filters = this.translate.instant('SHARED.Add_Filters');
  public Adjust_Filters = this.translate.instant('SHARED.Adjust_Filters');
  public isCustomButtonActive = true;
  private filterInit = false;
  private delayTimer;

  constructor(
    private router: Router,
    public permissions: PermissionsService,
    private events: Events,
    private userDataService: UserdataService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private observationDashboardUIService: ObservationDashboardUIService,
    private filterMenuService: FilterMenuService,
    private filterElementService: FilterElementService,
    private tableService: TableService,
    private subscriberService: SubscriberService
  ) {
    this.events.subscribe('ccs:clearMenu', this.onChangeFilter);
    this.events.subscribe('ccs:filterObject', this.onChangeFilter);
  }

  @Input('headerSetting')
  set settings(settings: IHeaderSetting) {
    if (settings) {
      this.headerSetting = settings;
      this.icons = _.map(this.headerSetting.icon, (icon: DashboardHeaderIcon) => this.observationDashboardUIService.getIconByType(icon));

      _.each(this.icons, (icon: IHeaderIcon) => {
        const isActive: boolean = this.router.isActive(icon.routeUrl, false);

        if (isActive) {
          icon.routeUrl = null;
          icon.src = icon.activeSrc;
        } else {
          icon.src = icon.inactiveSrc;
        }
      });

      if (settings.selectDropDown?.data?.length) {
        this.observationIcons = settings.selectDropDown.data.map(icon => ({
          ...icon,
          disabled: icon.disabled,
          routeUrl: icon.id,
          label: icon.text
        }));
      } else {
        this.getNavItemsByKey();
      }
      if (settings.customButton?.module) {
        this.isCustomButtonActive = this.subscriberService.usesModule(settings.customButton.module);
      }
    }
  }

  ngOnChanges(change) {
    const searchString = change.filterObject?.currentValue?.searchString;
    if (searchString) {
      this.searchField = searchString;
    }
  }

  get getCurrentDashboardTitle() {
    return this.icons.find(icon => icon.routeUrl === null) ? this.icons.find(icon => icon.routeUrl === null).label : '';
  }

  @HostListener('document:click', ['$event']) hostClicked(evt) {
    if (!evt.target.matches('.dropbtn-arrow') && !evt.target.matches('.dropbtn-arrow img')) {
      const dropdowns = document.getElementsByClassName('dropdown-content');
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        const openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }

  public customClick(event: any): void {
    if (this.headerSetting.customButton) {
      this.headerSetting.customButton.handler(event);
    }
  }

  public navChange(event: any) {
    this.loadingService.enable().then(() => {
      this.router.navigate([event.value]);
      $('.tableSelect select').val(null).trigger('change.select2');
      $('.observation-search-field').val(null);
    });
  }

  public hasAnyFilter() {
    return !!_.find(this.filterComps, filter => filter?.value?.length > 0);
  }

  public async openFilter() {
    const extendedFieldConfig: { [field: string]: any } = _.assign({}, this.extendFieldsConfig);
    this.clearFilterObject(this.filterObject);
    this.encodeTimespan(this.filterObject, extendedFieldConfig);
    this.encodeSeverityLikelihood(this.filterObject);

    const fields: FilterMenuFormField[] = this.filterCustomFields || _.map(this.filterComps, 'id');

    const resetForm = !this.filterInit;
    this.filterInit = true;

    const filterData = await this.filterMenuService.open({
      fields,
      data: this.filterObject,
      extendedFieldConfig,
      resetForm
    });

    this.buildFilterComponents(filterData);
    this.decodeTimespan(filterData);
    this.decodeSeverityLikelihood(filterData);
    this.clearFilterObject(filterData);

    _.assign(this.filterObject, filterData);
    this.events.publish('ccs:clearMenu', true);
    this.events.publish('ccs:filterObject', this.filterObject);
  }

  public buildFilterComponents(filterData): void {
    if (this.filterCustomFields?.length) {
      const fieldsMap = {
        custom: [],
        predefinedByKey: []
      };
      const predefinedKeyMap = {
        locations: 'location',
        zones: 'zone',
        groups: 'team',
        roles: 'role',
        certifications: 'certification',
        timespan: 'range',
        users: 'user'
      };

      _.each(this.filterCustomFields, (field) => {
        if (_.isString(field)) {
          fieldsMap.predefinedByKey.push(predefinedKeyMap[field] || field);
        } else {
          fieldsMap.custom.push(field);
        }
      });

      this.filterComps = [
        ...this.filterElementService.buildFilterCustomObjects(fieldsMap.custom, filterData),
        ...this.filterElementService.buildFilterObjects(fieldsMap.predefinedByKey, filterData, false)
      ];
    }
  }

  toggleFilterBody(val) {
    this.menuOpen = true;
    // this.headerSetting.filter.isActive  = !val;
    this.searchField = null;
    this.filterObject.searchString = null;
    if (this.headerSetting.title === 'SHARED.Dashboard') {
      this.headerSetting.showSearchField = false;
    } else {
      this.headerSetting.showSearchField = true;
    }
    this.events.publish('ccs:filterObject', this.filterObject);
    this.onSelected.emit(val);
    this.events.publish('ccs:filterClosed', false);
  }

  closeFilterBody(val) {
    this.menuOpen = false;
    this.onSelected.emit(false);
    if (this.headerSetting.title === 'SHARED.Dashboard') {
      this.headerSetting.showSearchField = false;
    } else {
      this.headerSetting.showSearchField = true;
    }
    this.events.publish('ccs:filterClosed', true);
  }

  filterString() {
    clearTimeout(this.delayTimer);

    this.delayTimer = setTimeout(() => {
      this.filterObject.searchString = _.toLower(this.tableService.quoteEncoding(this.searchField));
      this.events.publish('ccs:searchString', this.filterObject);
    }, 250);
  }

  ngOnDestroy() {
    this.searchField = null;
    this.events.unsubscribe('ccs:clearMenu', this.onChangeFilter);
    this.events.unsubscribe('ccs:filterObject', this.onChangeFilter);
  }

  public navigateTo(url: string): void {
    if (url) {
      this.router.navigate([url]);
      // this.userDataService.savePreference('lastDashboardType', url);
    }
  }

  navigateToObservationPage(url: string): void {
    if (url) {
      this.router.navigate([url]);
    }
  }

  openDashboardMenu() {
    if (this.dashboardMenuDropdown) {
      this.getNavItemsByKey();
      this.dashboardMenuDropdown.nativeElement.classList.toggle('show');
    }
  }

  clearSearch() {
    this.searchField = null;
    this.filterObject.searchString = null;
    this.events.publish('ccs:searchString', this.filterObject);
  }

  breadCrumbNav() {
    this.navigateToObservationPage(this.headerSetting.breadCrumbObject.backNavUrl);
  }

  private onChangeFilter = () => {
    this.filterInit = false;
  };

  private clearFilterObject(filterData: any) {
    _.each(this.filterObject, (value, key) => {
      if (_.isUndefined(filterData[key]) || (_.isArray(this.filterObject[key]) && _.isEmpty(this.filterObject[key]))) {
        delete this.filterObject[key];
      }
    });
  }

  private encodeSeverityLikelihood(data) {
    const keyMap = {
      severities: 'S',
      likelihoods: 'L'
    };

    _.each(keyMap, (value, key) => {
      _.each(data[key], (item) => {
        if (!data.sl) {
          data.sl = [];
        }

        data.sl.push(`${item}-${value}`);
      });
    });
  }

  private decodeSeverityLikelihood(filterData) {
    const keyMap = {
      S: 'severities',
      L: 'likelihoods'
    };

    _.each(filterData.sl, (severityItem) => {
      const [value, key] = _.split(severityItem, '-');
      const currentKey = keyMap[key];

      if (currentKey) {
        if (!filterData[currentKey]) {
          filterData[currentKey] = [];
        }
        filterData[keyMap[key]].push(value);
      }
    });

    delete filterData.sl;
  }

  private decodeTimespan(filterData) {
    if (filterData?.timespan) {
      const timespan = filterData.timespan;
      filterData.timespan = timespan.type;
      _.assign(filterData, timespan.range);
    }
  }

  private encodeTimespan(data, extendedFieldConfig: { [field: string]: any }) {
    if (data?.timespan) {
      data.timespan = {
        type: data.timespan,
        range: {
          startTime: data.startTime,
          endTime: data.endTime
        }
      };

      if (data.timespan.type === 'all') {
        delete data.timespan;
      }
    }

    const timespan: { [key: string]: any } = _.find(this.filterComps, {id: 'timespan'});
    if (timespan) {
      extendedFieldConfig.timespan = {
        'inputs.dateConfig.types': _.filter(_.map(timespan.dropDownOptions, 'id'))
      };
    }
  }

  private getNavItemsByKey() {
    if (this.headerSetting?.selectDropDown?.dataNavMenuKey) {
      const navItems = this.observationDashboardUIService.getMenuNavItems(this.headerSetting.selectDropDown.dataNavMenuKey);
      if (this.headerSetting.selectDropDown.dataNavMenuKey === 'observations') {
        if (navItems.length <= 1) {
          this.headerSetting.hideSelectionArrow = true;
        }
      }
      this.observationIcons = _.map(navItems, (icon) => ({
        ...icon,
        disabled: icon.disabled,
        routeUrl: icon.id,
        label: icon.text
      }));
    }
  }

}
