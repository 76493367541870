import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { CommsService } from '@services/comms/comms.service';
import { ICheck, IResponse } from '@modules/management/pages/details/check/models';
import * as _ from 'lodash';
import { DeploymentService } from '@modules/management/pages/details/check/services/deployment/deployment.service';
import { AssetsService } from '@services/assets/assets.service';
import { UserService } from '@services/user/user.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { Events } from '@services/events/events.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { ChecksService } from '@services/checks/checks.service';
import { UtilsService } from '@services/utils/utils.service';
import { StorageService } from '@services/storage/storage.service';
import { TeamsService } from '@services/teams/teams.service';
import { TranslateService } from '@ngx-translate/core';
import { Permission, PermissionsService } from '@services/permissions/permissions.service';
import { awaitHandler } from '@utils/awaitHandler';

@Injectable({
  providedIn: 'root'
})
export class CheckResponseService {

  public responses: { data: IResponse[]; lastRequest: number } = {
    data: null,
    lastRequest: null
  };

  public responseFilterObject: any = {};
  public lastStorageTime = 0;

  private responseCacheData: {
    startTime: number;
    endTime: number;
  } = {startTime: null, endTime: null};

  private responseItemCache: any = {};

  private initialCacheFill = false;
  private partialLoad = null;
  private originalLastRequest = null;

  private updatingCounter = 0;
  private refreshing = false;
  private lastChunkSize = 0;
  private chunkSize = 50000;

  constructor(
    private logger: NGXLogger,
    private commsService: CommsService,
    private deployment: DeploymentService,
    private assetsService: AssetsService,
    private userService: UserService,
    private userDataService: UserdataService,
    private accountsService: AccountsService,
    private events: Events,
    private utils: UtilsService,
    private checkService: ChecksService,
    private storageService: StorageService,
    private translate: TranslateService,
    private teamsService: TeamsService,
    private permissions: PermissionsService,
  ) {
  }

  public refresh(updated: Number = 0) {
    // return Promise.resolve(this.responses.data);
    if (this.responses && !_.isEmpty(this.responses.data) && this.responses.lastRequest) {
      // new logic - only do a refresh if we have some responses cached.  This will keep the
      // local collection up to date.
      return new Promise(async (resolve, reject) => {
        const r = {
          locations: JSON.stringify(this.userDataService.locations),
          status: JSON.stringify(['available', 'inProgress', 'complete', 'groupsCompleted', 'skipped', 'notCompleted', 'missed', 'cancelled']),
          trim: 1,
          lastRequest: this.responses.lastRequest,
          changesOnly: 1
        };
        await this.getResponses(r, true);
        resolve(true);
      });
    } else {
      return Promise.resolve(this.responses.data);
    }
  }

  public async updateCache(data, honorLastRequest: boolean = true) {
    // is this an incremental update?
    const responses = _.values(data.result.responses);

    if (this.responses.data && this.responses.lastRequest) {
      if (_.has(data.result, 'removed')) {
        if (_.has(data.result.removed, 'abandoned')) {
          _.each(data.result.removed.abandoned, (item) => {
            delete this.responses.data[item];
          });
        }
      }
      await this.utils.iterateWithDelay(responses, (item) => {
        const deployment = this.deployment.getDeployment(item.deploymentID);
        const check = this.checkService.getCheck(deployment.checkID) || <ICheck>{};
        const concatedVar = _.defaults(item, deployment);

        concatedVar.checkType = check.checkType;
        concatedVar.deploymentAssignedTo = deployment.assignedTo;
        this.responses.data[item.responseID] = concatedVar;
      });
      if (honorLastRequest) {
        this.responses.lastRequest = data.result.timestamp;
      }
    } else {
      if (honorLastRequest) {
        this.responses.lastRequest = data.result.timestamp;
      }
      await this.utils.iterateWithDelay(responses, (item) => {
        const deployment = this.deployment.getDeployment(item.deploymentID);
        const check = this.checkService.getCheck(deployment.checkID) || <ICheck>{};

        item = _.defaults(item, deployment);
        item.checkType = check.checkType;
        item.deploymentAssignedTo = deployment.assignedTo;
      });
      this.responses.data = data.result.responses;
    }
    this.logger.log('Now we have ' + _.keys(this.responses.data).length + ' responses cached');
    if (this.updatingCounter) {
      this.updatingCounter--;
      if (this.updatingCounter) {
        this.logger.log(`There are still ${this.updatingCounter} pending updates`);
      }
    }

    // if (updateStorage && !this.updatingCounter) {
    //   if (!this.lastStorageTime || this.responses.lastRequest > this.lastStorageTime + 60 * 1000 * 1000) {
    //     this.lastStorageTime = this.responses.lastRequest;
    //     this.storageService.store('responses', this.responses, 'data', 'responseID', 50000);
    //   } else {
    //     this.logger.log('too soon for cache update');
    //   }
    // }
  }

  public clearCache() {
    this.responses.data = null;
    this.responses.lastRequest = null;
    this.lastStorageTime = 0;
    this.originalLastRequest = null;
    this.partialLoad = null;
    this.responseItemCache = {};
    this.updatingCounter = 0;
    this.responseCacheData.startTime = null;
  }

  public async getResponseAsync(id: string): Promise<IResponse | null> {
    return new Promise((resolve) => {
      let m = _.find(this.responses.data, {responseID: id}) as IResponse | null;
      if (m) {
        resolve(m);
      } else {
        this.getResponses({responses: JSON.stringify([id]), status: '[]'})
          .then((data) => {

            m = _.find(data, {responseID: id}) as IResponse | null;
            if (m) {
              if (!this.responses.data) {
                this.responses.data = [];
              }
              this.responses.data[m.responseID] = m;
              resolve(m);
            }
          }).catch(err => {
          resolve(null);
        });
      }
    });
  }

  public async getDeploymentByResponseID(rid: string): Promise<string> {
    const respObj: any = await this.getResponseAsync(rid);
    const deployment: any = this.deployment.getDeployment(respObj.deploymentID);
    if (deployment) {
      return deployment.title;
    } else {
      return '';
    }
  }

  public getDeploymentByID(did: string): string {
    const deployment: any = this.deployment.getDeployment(did);
    if (deployment) {
      return deployment.title;
    } else {
      return '';
    }
  }

  public async getResponseByObservationID(oid: number) {
    const ridObj: any = this.findRespItemByObsID(oid);
    if (!ridObj) {
      return null;
    }

    const respObj: any = await this.getResponseAsync(ridObj.responseID);
    if (!respObj) {
      return null;
    }
    const deployment: any = this.deployment.getDeployment(respObj.deploymentID);
    const splitSig = _.split(respObj.targetSignature, ':');
    // Translate these stuff
    let targStr = '';
    if (splitSig[0] == 'loc') {
      targStr = 'Zone' + ': ' + this.userService.findAnyZoneNoLoc(splitSig[2]).name;
    } else if (splitSig[0] == 'asset') {
      const targObj = this.assetsService.getAssetById(+splitSig[1]);
      targStr = 'Asset' + ': ' + targObj[0].name;
    } else if (splitSig[0] == 'worker') {
      targStr = 'Worker' + ': ' + this.accountsService.fullname(+splitSig[1]);
    }
    ridObj.checkID = respObj.checkID;
    ridObj.owner = respObj.owner;
    ridObj.targetSignature = targStr;
    ridObj.deployment = deployment ? deployment.title : '';
    return ridObj;
  }


  public getAssigneeNames(assignedTo: any, deployment?: any, dereferenceSupervisors?: boolean, targetSignature?: string): string {
    let assigned = '';
    let separate = false;

    if (_.get(assignedTo, 'teams.length')) {
      const teams: string[] = [];
      assignedTo.teams.forEach((teamID) => {
        if (teamID === -1) {
          teams.push(this.translate.instant('MGMT_DETAILS.Workers_Primary_Team'));
        } else {
          teams.push(this.teamsService.teamNameByID(teamID));
        }
      });
      if (assignedTo.teams.length === 1) {
        assigned += this.translate.instant('SHARED.Team') + ': ' + _.map(teams).join(', ');
      } else {
        assigned += this.translate.instant('SHARED.TEAMS') + ': ' + _.map(teams).join(', ');
      }
      separate = true;
    } else if (assignedTo.teams || (deployment && deployment.target.targetType === 'workers')) {
      assigned += separate ? '<hr>' : '';
      assigned += this.translate.instant('SHARED.TEAMS') + ': ' + this.translate.instant('SHARED.Any_Team');
      separate = true;
    }

    if (assignedTo.users && assignedTo.users.length) {
      const users: string[] = [];
      assignedTo.users.forEach((userID) => {
        if (userID === -1) {
          users.push(this.translate.instant('MGMT_DETAILS.Self_Check'));
        } else if (userID === -2) {
          if (dereferenceSupervisors && targetSignature && targetSignature.match(/^worker/)) {
            const t = targetSignature.split(/:/);
            const u = this.accountsService.getAccount(+t[1]);
            if (u) {
              if (u.supervisorID) {
                users.push(this.userService.getFullname(u.supervisorID));
              } else {
                users.push(this.translate.instant('MGMT_DETAILS.User_Supervisor'));
              }
            } else {
              users.push(this.translate.instant('MGMT_DETAILS.User_Supervisor'));
            }
          } else {
            users.push(this.translate.instant('MGMT_DETAILS.User_Supervisor'));
          }
        } else {
          users.push(this.userService.getFullname(userID));
        }
      });
      assigned += separate ? '<hr>' : '';
      if (users.length === 1) {
        assigned += this.translate.instant('SHARED.USER') + ': ' + _.map(users).join(', ');
      } else {
        assigned += this.translate.instant('SHARED.USERS') + ': ' + _.map(users).join(', ');
      }
      separate = true;
    }

    if (_.get(assignedTo, 'permissions.length')) {
      const permissions: string[] = [];
      assignedTo.permissions.forEach((permID) => {
        const perm: any = _.find(this.permissions.permissions.data, {id: permID});
        permissions.push(this.translate.instant(perm.description));
      });
      assigned += separate ? '<hr>' : '';
      if (assignedTo.permissions.length === 1) {
        assigned += this.translate.instant('MGMT_LIST.Permission') + ': ' + _.map(permissions).join(', ');
      } else {
        assigned += this.translate.instant('SHARED.EDIT_Permissions') + ': ' + _.map(permissions).join(', ');
      }
    } else if (assignedTo.permissions || (deployment && deployment.target.targetType === 'workers')) {
      assigned += separate ? '<hr>' : '';
      assigned += this.translate.instant('SHARED.EDIT_Permissions') + ': ' + this.translate.instant('SHARED.Any_Permissions');
    }
    return assigned || this.translate.instant('SHARED.None');
  }

  public getTargetInfo(targetSignature) {
    if (!targetSignature) {
      return;
    }
    const splitSig = _.split(targetSignature, ':');
    // Translate these stuff
    let targStr = '';
    if (splitSig[0] === 'loc') {
      targStr = this.getTargetZoneName(+splitSig[1], +splitSig[2]);
    } else if (splitSig[0] === 'asset') {
      const targObj = this.assetsService.getAssetById(+splitSig[1]);
      targStr = this.translate.instant('SHARED.Asset') + ': ' + _.toLower(targObj[0].name);
    } else if (splitSig[0] === 'worker') {
      targStr = this.translate.instant('SHARED.Worker') + ': ' + _.toLower(this.accountsService.getCompactName(+splitSig[1]));
    }
    return targStr;
  }

  // @@@@@@@@@ making everything async
  public async getAllCheckResponses(range, teams: any = null, dontFilter: boolean = false) {
    // make sure all in range are loaded

    const startTime = this.utils.toSeconds(range.startTime);
    // s -= 30 * 24 * 60 * 60;
    const items = await this.getResponses({
      locations: JSON.stringify(this.userDataService.locations),
      status: JSON.stringify(['available', 'inProgress', 'complete', 'groupsCompleted', 'skipped', 'notCompleted', 'missed', 'cancelled']),
      trim: 1,
      startTime,
      endTime: 2000000000,
      useUpdateTime: 1
    }, true);
    if (dontFilter) {
      return items;
    } else {
      const filteredResponses = this.filterResponsesByDate(items, range.startTime, range.endTime);
      if (!teams) {
        return filteredResponses;
      } else {
        return this._filterResponsesByVisibility(filteredResponses, teams);
      }
    }
  }

  public async getAvailableChecksResponses(range, teams) {
    if (range.startTime && range.endTime) {
      return [];
    }

    const availableResponses = await this.getResponses({}, false);

    return this._filterResponsesByVisibility(availableResponses, teams);
  }

  public async getCheckResponseByCheckIDStatus(checkType: number, range, teams: any, targetSignature: string, status?: string) {

    const s = this.utils.toSeconds(range.startTime);
    const e = this.utils.toSeconds(range.endTime);
    const responses: IResponse[] = await this.getAllCheckResponses({startTime: s, endTime: e}, teams);

    if (!status) {
      // first filter by teams
      const filtered = this._filterResponsesByVisibility(_.filter(responses, {checkType, targetSignature}), teams);

      if (s && e) { // this means yesterday
        const yesterdayFilter = _.filter(filtered, (data: any) => {
          if (data.status === 'available' || data.status === 'inProgress' || data.status === 'pending') {
            return false;
          }
          //check if completed
          if (data.completionTime) {
            if (data.completionTime > s && data.completionTime < e) {
              return true;
            } // did it expire on its own?
          } else if (data.expiresTime > s && data.expiresTime < e) {
            return true;
          }
        });
        return yesterdayFilter;
      } else if (s && !e) {
        const timeFiltered = _.filter(filtered, (data: any) => {
          if (data.status === 'available') {
            return true;
          } else {
            if (data.completionTime > s) {
              return true;
            } else if (data.expiresTime > s) {
              return true;
            }
          }
        });
        return timeFiltered;
      } else {
        return filtered;
      }
    } else {

      const filtered = this._filterResponsesByVisibility(_.filter(responses, {
        checkType,
        status,
        targetSignature
      }), teams);
      if (s && e) { // this means yesterday
        const yesterdayFilter = _.filter(filtered, (data: any) => {
          //check if completed
          if (data.completionTime) {
            if (data.completionTime > s && data.completionTime < e) {
              return true;
            } // did it expire on its own?
          } else if (data.expiresTime > s && data.expiresTime < e) {
            return true;
          }
        });
        return yesterdayFilter;
      } else if (s && !e) {
        const timeFiltered = _.filter(filtered, (data: any) => {
          if (data.completionTime > s) {
            return true;
          } else if (data.expiresTime > s) {
            return true;
          }
        });
        return timeFiltered;
      } else {
        return filtered;
      }
    }
  }

  public checkResponse(ref, opts) {
    const type = ref.type;
    const target = ref.target;
    const result = ref.result;
    const assignedTo = ref.assignedTo;
    const owner = ref.owner;
    // ownerData is empty if there is no owner
    const ownerData: any = owner ? this.accountsService.getAccount(ref.owner) : {};

    if (!opts.includingAll) {
      const primary = opts.primary;
      const secondary = opts.secondary;
      const observationCount = _.includes(opts.extraColumns, 'observationCount');
      const issueCount = _.includes(opts.extraColumns, 'issueCount');
      const includingList: string[] = ['responder', 'responderTeam', 'responder_shift'];
      if (_.includes(includingList, primary) || _.includes(includingList, secondary)) {
        if (!owner) {
          // this has no owner / responder and we are organizing by responder, so bail out
          return false;
        }
      }
      if (observationCount && !_.get(ref, 'observationCount') || issueCount && !_.get(ref, 'issueCount')) {
        return false;
      }
    }
    if (opts.obstype && opts.obstype.length) {
      if (!_.includes(opts.obstype, type)) {
        return false;
      }
    }

    if (opts.checkStatus && opts.checkStatus.length) {
      if (!_.includes(opts.checkStatus, ref.instanceStatus || ref.status)) {
        return false;
      }
    }

    if (opts.checkResults && opts.checkResults.length) {
      if (!_.includes(opts.checkResults, result)) {
        return false;
      }
    }

    if (opts.checkType && opts.checkType.length) {
      if (_.has(ref, 'checkType')) {
        if (!_.includes(opts.checkType, ref.checkType.toString())) {
          return false;
        }
      }
    }

    if (opts.checkName && opts.checkName.length) {
      if (!_.includes(opts.checkName, ref.checkID)) {
        return false;
      }
    }

    if (opts.checkDetailName && opts.checkDetailName.length) {
      if (!_.includes(opts.checkDetailName, ref.checkID)) {
        return false;
      }
    }

    if (opts.deploymentType && opts.deploymentType.length) {
      if (!_.includes(opts.deploymentType, target.targetType)) {
        return false;
      }
    }

    if (opts.deploymentName && opts.deploymentName.length) {
      if (!_.includes(opts.deploymentName, ref.deploymentID)) {
        return false;
      }
    }

    if (opts.targetLocations && opts.targetLocations.length) {
      const targetTypes = opts.targetLocations.map(i => Number(i));
      if (ref.locationID) {
        if (_.indexOf(targetTypes, ref.locationID) === -1) {
          return false;
        }
      } else {
        if (!_.intersection(targetTypes, target.locations).length) {
          return false;
        }
      }
    }

    if (opts.targetZones && opts.targetZones.length) {
      const targetZones = opts.targetZones.map(i => Number(i));
      if (!_.intersection(targetZones, target.zones).length) {
        return false;
      }
    }

    if (opts.targetAssetType && opts.targetAssetType.length) {
      const assetTypes = opts.targetAssetType.map(i => Number(i));
      if (!_.intersection(assetTypes, target.assetTypes).length) {
        return false;
      }
    }

    if (opts.targetAssets && opts.targetAssets.length) {
      const assets = opts.targetAssets.map(i => Number(i));
      const targetSignature: string = ref.targetSignature;
      let assetsID = null;
      if (targetSignature) {
        const splitSig = _.split(targetSignature, ':');
        if (splitSig[0] == 'asset') {
          assetsID = +splitSig[1];
        }
      }
      if (assetsID) {
        if (!_.includes(assets, assetsID)) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.targetTeams && opts.targetTeams.length) {
      const targetTeams = opts.targetTeams.map(i => Number(i));
      const targetSignature: string = ref.targetSignature;
      let userID = null;
      let user = null;
      let groups = [];
      if (targetSignature) {
        const splitSig = _.split(targetSignature, ':');
        // eslint-disable-next-line eqeqeq
        if (splitSig[0] == 'worker') {
          userID = +splitSig[1];
          if (userID) {
            user = this.accountsService.getByID(userID);
            groups = user ? user.groups : [];
          }
        }
      }
      if (userID && user && groups.length) {
        if (!_.intersection(targetTeams, groups).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.targetPermissions && opts.targetPermissions.length) {
      if (!_.intersection(opts.targetPermissions, target.permissions).length) {
        return false;
      }
    }

    if (opts.targetUsers && opts.targetUsers.length) {
      const targetUsers = opts.targetUsers.map(i => Number(i));
      const targetSignature: string = ref.targetSignature;
      let userID = null;
      if (targetSignature) {
        const splitSig = _.split(targetSignature, ':');
        if (splitSig[0] == 'worker') {
          userID = +splitSig[1];
        }
      }
      if (userID) {
        if (!_.includes(targetUsers, userID)) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.users && opts.users.length && ref.assignedTo.users && ref.assignedTo.users.length) {
      if (assignedTo.users && assignedTo.users.length) {
        if (!_.intersection(opts.users, assignedTo.users).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.assigned && opts.assigned.length) {
      if (assignedTo.users && assignedTo.users.length) {
        if (assignedTo.users[0] === -1) {
          const splitSig = _.split(ref.targetSignature, ':');
          if (+splitSig[1]) {
            assignedTo.users[0] = +splitSig[1];
          }
        } else if (assignedTo.users[0] === -2) {
          const splitSig = _.split(ref.targetSignature, ':');
          const userObj = this.accountsService.getAccount(+splitSig[1]);
          if (userObj.supervisorID) {
            assignedTo.users[0] = userObj.supervisorID;
          }
        }
        if (!_.intersection(opts.assigned, assignedTo.users).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.assignedTeam && opts.assignedTeam.length) {
      if (assignedTo.teams && assignedTo.teams.length) {
        const assignedTeam = opts.assignedTeam.map(i => Number(i));
        if (!_.intersection(assignedTeam, assignedTo.teams).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.assignedPerms && opts.assignedPerms.length) {
      if (assignedTo.permissions && assignedTo.permissions.length) {
        if (!_.intersection(opts.assignedPerms, assignedTo.permissions).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.permissions && opts.permissions.length) {
      if (owner) {
        // there is an owner, so look at that person
        if (!_.intersection(opts.permissions, Object.keys(ownerData.permissions)).length) {
          return false;
        }
      } else if (ref.assignedTo.permissions && ref.assignedTo.permissions.length) {
        // no owner - are there explicit assignment permissions we can test?
        if (!_.intersection(opts.permissions, ref.assignedTo.permissions).length) {
          return false;
        }
      }
    }

    if (opts.groups && opts.groups.length) {
      if (ownerData && ownerData.groups && ownerData.groups.length) {
        if (!_.intersection(opts.groups, ownerData.groups).length) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (opts.detailTags && opts.detailTags.length) {
      let detailTags = [];
      if (ref.questionsObjs && ref.questionsObjs.length) {
        _.forEach(ref.questionsObjs, (qObj, counter) => {
          if (qObj && qObj.closingTags && qObj.closingTags.length) {
            detailTags = [
              ...detailTags,
              qObj.closingTags
            ];
          }
        });
      }
      if (!_.intersection(opts.detailTags, detailTags).length) {
        return false;
      }
    }

    if (opts.questionCategory && opts.questionCategory.length) {
      const qCategory = [];
      if (ref.questionsObjs && ref.questionsObjs.length) {
        _.forEach(ref.questionsObjs, (qObj, counter) => {
          if (qObj && qObj.closingCategory) {
            qCategory.push(qObj.closingCategory);
          }
        });
      }
      if (!_.intersection(opts.questionCategory, qCategory).length) {
        return false;
      }
    }


    return true;
  }

  public async getResponses(filters: any, useCache: boolean = false): Promise<IResponse[]> {
    return new Promise(async (resolve, reject) => {
      const e = _.clone(filters);
      e.cmd = 'getAvailableResponses';
      e.sendTime = Date.now();
      if (!_.has(e, 'status')) {
        e.status = JSON.stringify(['available']);
      }
      if (e.startTime) {
        e.startTime = this.utils.toSeconds(e.startTime);
      }
      if (useCache && this.responses.data) {
        if (!e.lastRequest && e.startTime && this.responseCacheData.startTime) {
          if (this.responseCacheData.startTime <= e.startTime) {
            // the data is already here
            this.logger.debug('using cached responses');
            return resolve(this.responses.data);
          } else {
            // only retrieve up to here - we have the rest
            e.endTime = this.responseCacheData.startTime;
          }
          //e.omit = JSON.stringify(rowList);
          // e.lastRequest = this.responses.lastRequest;
        }
      }
      // we got this far and are going to start pulling data
      let data;
      let err;
      if (useCache) {
        this.refreshing = true;
      }
      if (!_.has(e, 'lastRequest')) {
        // chunked load will deal with cachevalid
        [data, err] = await awaitHandler(this.chunkedLoad(e));
      } else {
        [data, err] = await awaitHandler(this.commsService.sendMessage(e));
        // roll this up into an array
        if (useCache && _.has(data.result, 'removed')) {
          if (_.has(data.result.removed, 'abandoned')) {
            _.each(data.result.removed.abandoned, (item) => {
              delete this.responses.data[item];
            });
          }
        }
      }
      if (data.reqStatus === 'OK') {
        this.responses.lastRequest = data.result.timestamp;
        const rows: IResponse[] = data.result.responses;
        this.fillResponses(rows);
        if (useCache) {
          if (!this.responses.data) {
            this.responses.data = rows;
          } else {
            // update the rows with the new data
            _.each(rows, item => {
              this.responses.data[item.responseID] = item;
            });
          }
          if (e.startTime) {
            this.responseCacheData.startTime = this.utils.toSeconds(e.startTime);
          }
        }
        if (useCache) {
          this.events.publish('ccs:checkResponseUpdate', true);
          this.refreshing = false;
          resolve(this.responses.data);
        } else {
          resolve(rows);
        }
      } else if (useCache && data && data.reqStatus === 'CACHEVALID') {
        this.logger.debug('Response fetch returned CACHEVALID');
        this.refreshing = false;
        resolve(this.responses.data);
      } else {
        this.logger.error(`getAvailableResponses request failed: ${data.reqStatus}`);
        this.refreshing = false;
        reject(err);
      }
    });
  }

  getFlagIssue(flagIDArray: number[]): Promise<any> {
    const reqObj = {
      cmd: 'getResponseIssues',
      issues: JSON.stringify(flagIDArray)
    };
    return new Promise((resolve, reject) => {
      this.commsService.sendMessage(reqObj, false, false).then(data => {
        if (data && data.reqStatus === 'OK') {
          resolve(data.result.issues);
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  getTargetZoneName(locId: number, zoneId: number): string {
    const zone = this.userService.findAnyZoneNoLoc(zoneId);

    const location = this.userService.getLocation(locId) || {
      name: this.translate.instant('SHARED.Unknown')
    };

    const zoneTitle = this.translate.instant('SHARED.Zone');

    const zoneWithLocation = _.toLower(location.name) + ' / ' + _.toLower(zone.name);

    return `${zoneTitle}: ${zoneWithLocation}`;
  }

  private async chunkedLoad(query: any) {
    this.refreshing = true;
    return new Promise(async (resolve, reject) => {
      let lastReq = null;
      let complete = false;
      let lastRow = null;
      const ret = {
        reqStatus: null,
        result: {
          timestamp: null,
          responses: null,
        }
      };

      while (!complete) {
        const e = _.clone(query);
        e.limit = 50000;
        if (lastRow) {
          e.startAfter = lastRow;
          this.logger.debug('Fetching another chunk of responses');
        } else {
          delete e.startAfter;
          this.logger.debug('Starting a response request');
        }
        const [data, err] = await awaitHandler(this.commsService.sendMessage(e, false, false));
        if (data && data.reqStatus === 'OK') {
          this.logger.debug(`Response fetch completed; retrieved ${data.result.numResponses} responses`);
          // only update the storage on the last fetch
          if (!_.has(data.result, 'numResponses')) {
            complete = true;
            ret.reqStatus = data.reqStatus;
            ret.result.responses = data.result.responses;
          } else {
            // we loaded some...
            if (data.result.numResponses < 50000) {
              // we ran out of items to fetch
              complete = true;
              this.initialCacheFill = false;
              // put the last request back to where we started so we can get anything that has been updated since then!
              if (lastReq) {
                ret.result.timestamp = lastReq;
              } else {
                ret.result.timestamp = data.result.timestamp;
              }
              // this.logger.log(`We have fetched all of the available responses; doing a final refresh to get anything changed since ${this.responses.lastRequest}`);
              // await this.refresh(undefined);
            } else {
              lastRow = data.result.lastResponse;
              if (!lastReq) {
                lastReq = data.result.timestamp;
              }
            }
            if (!ret.result.responses) {
              ret.result.responses = data.result.responses;
            } else {
              _.assign(ret.result.responses, data.result.responses);
            }
            if (!ret.reqStatus) {
              ret.reqStatus = data.reqStatus;
            }
          }
        } else if (data && data.reqStatus === 'CACHEVALID') {
          this.logger.debug('chunked request responded with CACHEVALID');
          ret.reqStatus = 'CACHEVALID';
        }
      }
      resolve(ret);
    });
  }

  private fillResponses(responses: IResponse[]) {
    _.each(responses, item => {
      const deployment = this.deployment.getDeployment(item.deploymentID);
      const check = this.checkService.getCheck(deployment.checkID) || {} as ICheck;

      item = _.defaults(item, deployment);
      item.checkID = check.checkID;
      item.checkType = check.checkType;
      item.deploymentAssignedTo = deployment.assignedTo;
    });
  }

  private findRespItemByObsID(oid: number) {
    let respItemRef = null;
    if (!_.isEmpty(this.responseItemCache[oid])) {
      return this.responseItemCache[oid];
    }
    _.each(this.responses.data, (ref: any) => {
      if (!_.isEmpty(ref.answers)) {
        const r: any = _.find(ref.answers, {observationID: oid});
        if (r) {
          r.responseID = ref.responseID;
          respItemRef = r;
          return false;
        }
      }
    });
    if (respItemRef) {
      this.responseItemCache[oid] = respItemRef;
    }
    return respItemRef;
  }

  private filterResponsesByDate(responses, startTime: number, endTime: number) {
    let filteredResponses;
    const s = this.utils.toSeconds(startTime);
    const e = this.utils.toSeconds(endTime);

    if (s && e) { // this means yesterday
      filteredResponses = _.filter(responses, (data: any) => {
        if (data.status === 'available' || data.status === 'inProgress' || data.status === 'pending') {
          return false;
        }
        //check if completed
        if (data.completionTime) {
          if (data.completionTime > s && data.completionTime < e) {
            return true;
          } // did it expire on its own?
        } else if (data.expiresTime > s && data.expiresTime < e) {
          return true;
        }
      });
    } else if (s && !e) {
      filteredResponses = _.filter(responses, (data: any) => {
        if (data.status === 'available') {
          return true;
        } else {
          if (data.completionTime > s) {
            return true;
          } else if (data.expiresTime > s) {
            return true;
          }
        }
      });
    } else {
      filteredResponses = responses;
    }

    return filteredResponses;
  }

  private _filterResponsesByVisibility(collection, teams) {
    const currentUser = this.accountsService.getAccount(this.userDataService.userID);
    let teamIds: number[];
    if (teams) {
      teamIds = teams.currentId === -1 ? teams.ids : [teams.currentId];
    }

    const getAccount = _.memoize(id => this.accountsService.getAccount(id));

    return _.filter(collection, (data: any) => {
      // 1. is the deploymentAssignedTo specific to a team?  If yes, then skip ones that are not assigned to my selected teams

      const intArry = _.map(data.deploymentAssignedTo.teams, Number);
      if (intArry.length && !_.intersection(intArry, teamIds).length) {
        return false;
      }

      // 2. is the deploymentAssignedTo specific to individual users (or to self, which means it is the same as the target worker)?  If yes, determine their primary teams and check the intersection of those teams with my selected teams.  Skip if the intersection set is empty (no overlapping teams)

      const t = _.split(data.targetSignature, ':');
      const targetUser = t[0] === 'worker' ? getAccount(+t[1]) : null;

      if (_.has(data.deploymentAssignedTo, 'users') && data.deploymentAssignedTo.users.length) {
        let match = 0;
        _.each(data.deploymentAssignedTo.users, user => {
          if (user && user > -2) {
            // user -1 means assignee is the target user
            const uObj = (user === -1) ? targetUser : getAccount(+user);
            if (+user === currentUser.userID) {
              match = 1;
            } else if (uObj && uObj.primaryGroup) {
              if (_.indexOf(teamIds, uObj.primaryGroup) > -1) {
                // this is assigned to someone on a team we are watching
                // am I an admin? If so then we should always show the info
                if (this.permissions.canView(Permission.Admin)) {
                  match = 1;
                // am I THEIR supervisor
                // 2.1. if it is an overlapping team, and I am a supervisor, am I the supervisor for any of the individual users?  If not, then skip.
                } else if (uObj && uObj.supervisorID) {
                  if (uObj.supervisorID === currentUser.userID) {
                    match = 1;
                  }
                } else {
                  // they dont have a supervisor and are on a team I am looking at
                  match = 1;
                }
              }
            } else if (uObj) {
              // they have no primary group; am I their supervisor?
              if (uObj && uObj.supervisorID) {
                if (uObj.supervisorID === currentUser.userID) {
                  match = 1;
                }
              }
            }
          } else if (user === -2) {
            // it is assigned to a supervisor.  is this person on a team we are watching?
            // 4. is the target a worker and does the deploymentAssignedTo users property include -2 (supervisor).  If so, and if I am not that user's supervisor, skip.
            if (targetUser && targetUser.primaryGroup && _.indexOf(teamIds, targetUser.primaryGroup) > -1) {
              if (targetUser.supervisorID && targetUser.supervisorID === currentUser.userID) {
                match = 1;
              }
            }
          }
        });

        if (!match) {
          return false;
        }
      }

      // 3. is the target a worker?   If so, then what is that worker's primary team?   Skip if that team is not one of my selected teams
      if (t[0] === 'worker' && targetUser && targetUser.primaryGroup && _.indexOf(teamIds, targetUser.primaryGroup) < 0) {
        return false;
      }
      // 5. If we get this far, this is one of my team's relevant available responses.  Show it.

      // if it is "All teams" then we should check "checks" that have empty deploymentAssignedTo
      /* if (teams.currentId === -1) {
        if (!intArry.length) {
          if (data.status === 'available') {
            return true;
          }
        }
      } */

      /* if (intArry.length) {
        const matchesTeam = _.intersection(teamIds, intArry).length
        if (matchesTeam) {
          // now check for status
          if (data.status === 'available') {
            return true;
          }
        }
      } */
      return true;
    });
  }

}
