import { ComponentRef } from '@angular/core';

import { QuestionFormService } from '@modules/management/pages/details/check/services/question/question-form.service';
import {
  QuestionFormGroupComponent,
  QuestionFormGroupListComponent
} from '@modules/management/pages/details/check/components';
import { AbstractFieldsModel } from './abstractFieldsModel';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { FormField } from '@services';

export class MultipleChoiceFieldsModel extends AbstractFieldsModel {

  private options: any[] = [];
  protected formConfig: any = {
    containers: true,
    prefix: 'choice',
    fields: [
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'label',
        title: 'MGMT_DETAILS.Option_Label',
        type: 'text',
        size: 20,
        required: true
      },
      {
        containerClass: 'question-field',
        inputtype: 'verbatim',
        name: 'translation_label',
        title: this.translateService.instant('MGMT_DETAILS.Option_Label_Translations'),
        type: 'textTranslations',
        size: 20,
        maxlength: 180
      },
      {
        containerClass: 'question-field singleSelect',
        name: 'choiceInfluence',
        title: 'MGMT_DETAILS.Choice_Influence',
        type: 'selectmenu',
        multiple: false,
        searchable: false,
        canClear: false,
        required: true,
        originalOrder: true,
        default: 'positiveImpact',
        options: [
          {
            id: 'positiveImpact',
            description: 'MGMT_DETAILS.Positive_Impact_Option'
          },
          {
            id: 'negativeImpact',
            description: 'MGMT_DETAILS.Negative_Impact_Option'
          },
          {
            id: 'neutralImpact',
            description: 'MGMT_DETAILS.Neutral_Impact_Option'
          }
        ],
        onChange: (value: any, $form: JQuery) => {
          this.markFieldAsChanged($form.attr('id'), 'choiceInfluence', value);
        },
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['negative-impact-option'],
              valueFunction: (val) => val !== 'negativeImpact'
            }
          ]
        }
      },
      {
        containerClass: 'question-field validation-subtitle singleSelect',
        title: 'MGMT_DETAILS.Choice_Validation',
        type: 'subtitle'
      },
      {
        containerClass: 'question-field singleSelect',
        title: 'MGMT_DETAILS.Validation_Notes',
        name: 'notes_validation',
        type: 'flipswitch',
        value: 1,
        default: 0,
        onChange: (value, formPrefix) => {
          const getValue = (!value || !this.getValidationValue(formPrefix, 'photo'));
          const elementRef = this.formGroupComponent.getGroupElementRefByFormId(`${formPrefix}Form`);
          this.questionFormService.failChoiceValidation(elementRef.nativeElement, 'required_validation_field', getValue, this.options[1]);
          this.markFieldAsChanged(`${formPrefix}Form`, 'notes_validation', value);
        },
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['issue-type-fail', 'required-validation-field-divider-fail'],
              valueFunction: (val, attribs) => {
                return !((val || this.getValidationValue(attribs.prefix, 'photo')) && this.isNegativeImpact(attribs.prefix));
              }
            }
          ]
        }
      },
      {
        containerClass: 'question-field singleSelect',
        title: 'MGMT_DETAILS.Photo_Validation',
        name: 'photo_validation',
        type: 'flipswitch',
        value: 1,
        default: 0,
        onChange: (value, formPrefix) => {
          const getValue = (!value || !this.getValidationValue(formPrefix));
          const elementRef = this.formGroupComponent.getGroupElementRefByFormId(`${formPrefix}Form`);
          this.questionFormService.failChoiceValidation(elementRef.nativeElement, 'required_validation_field', getValue, this.options[1]);
          this.markFieldAsChanged(`${formPrefix}Form`, 'photo_validation', value);
        },
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['issue-type-fail', 'required-validation-field-divider-fail'],
              valueFunction: (val, attribs) => {
                return !((val || this.getValidationValue(attribs.prefix)) && this.isNegativeImpact(attribs.prefix));
              }
            }
          ]
        }
      },
      {
        containerClass: 'question-field form-group-checkbox singleSelect',
        name: 'required_validation_field',
        title: '',
        disabled: true,
        type: 'checkbox',
        options: {
          checked: {
            description: 'MGMT_DETAILS.Validation_Type_Above',
            id: 1
          }
        },
        onChange: (value, $form: JQuery) => {
          this.markFieldAsChanged($form.attr('id'), 'required_validation_field', value);
        }
      },
      {
        containerClass: 'question-field required-validation-field-divider-fail negative-impact-option',
        type: 'divider'
      },
      {
        containerClass: 'question-field issue-type-fail negative-impact-option',
        title: 'MGMT_DETAILS.Documentation_Options',
        type: 'subtitle'
      },
      _.merge({}, this.questionFormService.sharedFields.attachCommentOption, {
        containerClass: 'question-field issue-type-fail negative-impact-option',
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('issueTypeFail_Comment', value, this.options[1]);
        }
      }),
      _.merge({}, this.questionFormService.sharedFields.correctiveOption, {
        containerClass: 'question-field issue-type-fail negative-impact-option',
        onChange: (value) => {
          this.questionFormService.markFieldAsChanged('issueTypeFail_Corrective', value, this.options[1]);
        }
      })
    ]
  };
  private fields = [
    {
      containerClass: 'question-type-field multipleChoice',
      title: 'MGMT_DETAILS.Choice_Details',
      type: 'subtitle'
    },
    {
      containerClass: 'question-type-field multipleChoice',
      title: 'MGMT_DETAILS.Choices_Options',
      type: 'subtitle'
    },
    {
      containerClass: 'question-field question-type-field multipleChoice',
      name: 'selectionType',
      title: this.translateService.instant('MGMT_DETAILS.Selection_Type'),
      type: 'selectmenu',
      multiple: false,
      searchable: false,
      canClear: false,
      originalOrder: true,
      default: 'singleSelect',
      options: [
        {
          id: 'singleSelect',
          description: 'MGMT_DETAILS.Single_Select_Option'
        },
        {
          id: 'multiSelect',
          description: 'MGMT_DETAILS.Multi_Select_Option'
        }
      ],
      onChange: (value: any) => {
        this.onSelectionTypeChange(value);
      }
    },
    {
      type: 'subtitle',
      containerClass: 'question-type-field multipleChoice form-group-title choices-groups'
    }
  ];

  constructor(
    protected questionFormService: QuestionFormService,
    private translateService: TranslateService
  ) {
    super(questionFormService);
  }

  public getFields(): any[] {
    return this.fields;
  }

  public showGroupList() {
    if (this.questionFormService.isModelAvailable('multipleChoice')) {
      const choicesGroupsElement = this.questionFormService.getElementBy(`.choices-groups`);

      if (choicesGroupsElement && !this.formGroupComponentRef) {
        this.questionFormService.dynamicComponentCreatorService.create(
          QuestionFormGroupListComponent,
          (component, componentRef) => this.onFormGroupComponentInit(component, componentRef),
          choicesGroupsElement
        );
      }
    }
  }

  public getData(): any[] {
    const settingsHandler = (data) => {
      if (data.issueTypeFail_Comment && data.issueTypeFail_Corrective) {
        data.issueType = '2';
      } else if (data.issueTypeFail_Corrective) {
        data.issueType = '1';
      } else if (data.issueTypeFail_Comment) {
        data.issueType = '0';
      }

      return _.omit(data, [
        'issueTypeFail_Comment',
        'issueTypeFail_Corrective'
      ]);
    };

    const settingsFields: string[] = [
      'photo_validation',
      'notes_validation',
      'required_validation_field',
      'choiceInfluence',
      'issueTypeFail_Comment',
      'issueTypeFail_Corrective'
    ];
    return this.getFormData(settingsFields, settingsHandler);
  }

  public onDestroy() {
    this.formGroupComponentRef && this.formGroupComponentRef.destroy();
    this.formGroupComponentRef = null;
  }

  private onFormGroupComponentInit(component: QuestionFormGroupListComponent, componentRef: ComponentRef<QuestionFormGroupListComponent>) {
    component.title = this.translateService.instant('MGMT_DETAILS.Choice');
    component.addButtonName = this.translateService.instant('MGMT_DETAILS.Add_Choice_Button');
    component.enableRemoval = true;

    this.onFormGroupComponentCreate(component, componentRef);

    this.syncCheckType();
    this.formGroupComponent.onNewGroup.subscribe(() => {
      this.syncCheckType();
    });
  }

  private onSelectionTypeChange(value: string) {
    const isSingleSelect: boolean = value === 'singleSelect';

    if (this.formGroupComponent) {
      this.formGroupComponent.questionFormGroupComponentList.forEach((groupComponent: QuestionFormGroupComponent) => {
        const elements: HTMLElement[] = (<any>groupComponent).elementRef.nativeElement.querySelectorAll('.singleSelect');
        _.each(elements, (element) => {
          element.hidden = !isSingleSelect;
          this.questionFormService.disableFieldByElement('input', element, !isSingleSelect);
          this.questionFormService.disableFieldByElement('select', element, !isSingleSelect);
        });
      });
    }
  }

  private syncCheckType() {
    const formId: string = this.questionFormService.questionPage.formConfig.prefix;

    setTimeout(() => {
      const selectionTypeElement: HTMLFormElement = this.questionFormService.getElementBy(`#${formId}selectionType`);
      this.onSelectionTypeChange(_.get(selectionTypeElement, 'value'));
    });
  }

  private getValidationValue(prefix: string, field: 'notes' | 'photo' = 'notes'): any {
    const targetForm = _.find(this.formGroupComponent.groups, (group) => {
      return group.formConfig.prefix === prefix;
    });
    const validationField = _.find(targetForm.formConfig.fields, {name: `${field}_validation`}) as FormField;
    return validationField && validationField.getValue();
  }

  private isNegativeImpact(prefix: string) {
    const targetForm = _.find(this.formGroupComponent.groups, (group) => {
      return group.formConfig.prefix === prefix;
    });
    const choiceInfluenceField = _.find(targetForm.formConfig.fields, {name: 'choiceInfluence'}) as FormField;
    const fieldValue = choiceInfluenceField && choiceInfluenceField.getValue();

    return fieldValue === 'negativeImpact'
  }

}
