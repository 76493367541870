<div class="note-list-header">
  <div [ngClass]="config.titleColorClass" class="note-title">{{ config.title | translate }}</div>
  <ion-button (click)="addNote()" class="page-button" color="secondary">{{ "SHARED.Add_Note" | translate }}</ion-button>
</div>

<app-observation-card *ngFor="let note of config.notes"
                      [avatarUrl]="note.userImg"
                      [name]="note.name"
                      [text]="note.data"
                      [time]="note.time"></app-observation-card>

<div *ngIf="config.showEmptyMessage && config.notes.length === 0"
     class="empty-message">{{ 'SHARED.Be_The_First_To_Add_Note' | translate }}</div>
