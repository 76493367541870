import { Injectable } from '@angular/core';

import { ObservationLinkGuard } from '@modules/observation/services/observationLink/observation-link.guard';
import { ReportLinkGuard } from '@modules/reporting/services/reportLink/report-link.guard';

import { find } from 'lodash';

interface DeepLinkHandler {
  parseUrlId: (url: string) => string;
  open: (id: string) => void;
}

export interface DeepLinkGuard {
  parseUrlId: (url: string) => string;
}

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {

  private nextId: string;
  private deepLinkHandlers: DeepLinkHandler[] = [
    {
      parseUrlId: (url) => this.observationLinkGuard.parseUrlId(url),
      open: (id) => this.observationLinkGuard.navigateToObservationPage(id)
    },
    {
      parseUrlId: (url) => this.reportLinkGuard.parseUrlId(url),
      open: (id) => this.reportLinkGuard.navigateToReportPage(id)
    }
  ];
  private currentHandler: DeepLinkHandler;

  constructor(
    private observationLinkGuard: ObservationLinkGuard,
    private reportLinkGuard: ReportLinkGuard
  ) {}

  public recognizeAndSaveLink(url: string): void {
    if (!this.nextId) {
      this.currentHandler = find(this.deepLinkHandlers, (handler) => {
        const id = handler.parseUrlId(url);
        if (id) {
          this.nextId = id;
          return this.hasNextId();
        }
      });
    }
  }

  public hasNextId(): boolean {
    return !!this.nextId;
  }

  public applyNextId(): void {
    if (this.hasNextId() && this.currentHandler) {
      const id: string = this.nextId;
      this.nextId = null;
      this.currentHandler.open(id);
    }
  }
}
