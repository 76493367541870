import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from '@angular/router';

import { HeaderComponent, LayoutComponent, LoadingComponent, MenuComponent } from './components';
import { GuardGuard } from '@services/guard/guard.guard';
import { AccountGuard } from '@services/accountGuard/account.guard';
import {
  AccountTypes,
  ChangeDetectionDeactivator,
  Feature,
  IModuleAccess,
  Module,
  ModuleGuard,
  Permission
} from '@services';
import {
  FullscreenLeaderboardDeactivateGuard,
  FullscreenLeaderboardGuard
} from '@modules/observation/pages/leaderboard/services';

import { TranslateModule } from '@ngx-translate/core';
import { MenuFilterModule } from '@shared/modules/menu-filter/menu-filter.module';

const COMPONENTS = [
  LayoutComponent,
  HeaderComponent,
  MenuComponent,
  LoadingComponent
];

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [GuardGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../observation/dashboard.module').then(m => m.DashboardModule),
        canActivate: [GuardGuard]
      },
      {
        path: 'login',
        loadChildren: () => import('../login/login.module').then(m => m.LoginModule),
        canActivate: [GuardGuard]
      },
      {
        path: 'site-management',
        loadChildren: () => import('../site-management/site-management.module').then(m => m.SiteManagementModule),
        canActivate: [GuardGuard, AccountGuard],
        data: {
          canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
        }
      },
      {
        path: 'reporting',
        loadChildren: () => import('../reporting/reporting.module').then(m => m.ReportingModule),
        canActivate: [AccountGuard, GuardGuard],
        data: {
          canViewExcept: [AccountTypes.Viewer]
        }
      },
      {
        path: 'management',
        loadChildren: () => import('../management/management.module').then(m => m.ManagementModule),
        canActivate: [GuardGuard, AccountGuard],
        data: {
          permission: Permission.Admin,
          canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
        }
      },
      {
        path: 'management/add-topic',
        loadChildren: () => import('./../management/pages/details/add-topic/add-topic.module').then(m => m.AddTopicModule),
        canDeactivate: [ChangeDetectionDeactivator],
        canActivate: [GuardGuard],
        data: {
          permission: Permission.Supervisor,
          headerConfig: {
            isHidden: true
          }
        }
      },
      {
        path: 'management/learning-teams',
        loadChildren: () => import('./../management/pages/list/learning-teams/learning-teams.module').then(m => m.LearningTeamsModule),
        canActivate: [GuardGuard, ModuleGuard],
        data: {
          moduleAccess: <IModuleAccess>{
            module: Module.LEARNING_TEAMS,
          },
          permission: Permission.Supervisor
        },
      },
      {
        path: 'management/explore',
        loadChildren: () => import('./../management/pages/list/explore/explore.module').then(m => m.ExplorePageModule),
        canActivate: [GuardGuard, ModuleGuard],
        data: {
          moduleAccess: <IModuleAccess>{
            module: Module.EXPLORE,
          },
          permission: Permission.Supervisor
        },
      },
      {
        path: 'management/word-cloud-table',
        loadChildren: () => import('./../management/pages/details/word-cloud-table/word-cloud-table.module').then(m => m.WordCloudTableModule),
        canDeactivate: [ChangeDetectionDeactivator],
        canActivate: [GuardGuard],
        data: {
          headerConfig: {
            isHidden: true
          },
          permission: Permission.Supervisor
        }
      },
      {
        path: 'management/learning-teams/team',
        loadChildren: () => import('./../management/pages/details/learning-team/learning-team.module').then(m => m.LearningTeamPageModule),
        data: {
          moduleAccess: <IModuleAccess>{
            module: Module.LEARNING_TEAMS
          },
          permission: Permission.Supervisor
        },
        canDeactivate: [ChangeDetectionDeactivator],
        canActivate: [GuardGuard, ModuleGuard]
      },
      {
        path: 'management/topics',
        loadChildren: () => import('./../management/pages/list/topics/topics.module').then(m => m.TopicsPageModule),
        canActivate: [GuardGuard, ModuleGuard],
        data: {
          permission: Permission.Supervisor,
          moduleAccess: <IModuleAccess>{
            module: Module.TOPICS
          }
        }
      },
      {
        path: 'management/content',
        loadChildren: () => import('../management/pages/list/content/content.module').then(m => m.ContentPageModule),
        data: {
          permission: Permission.Supervisor
        },
        canActivate: [GuardGuard]
      },
      {
        path: 'communications-list',
        loadChildren: () => import('../communications/communications.module').then(m => m.CommunicationsModule),
        canActivate: [GuardGuard, AccountGuard],
        data: {
          canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
        }
      },
      {
        path: 'work-notes',
        loadChildren: () => import('../work-notes/work-notes.module').then(m => m.WorkNotesModule),
        canActivate: [GuardGuard, AccountGuard, ModuleGuard],
        data: {
          canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting],
          permission: Permission.Helper,
          moduleAccess: <IModuleAccess>{
            module: Module.WORK_NOTES,
            feature: Feature.WORK_NOTES,
            checkRelatedFeatures: true
          }
        }
      },
      {
        path: 'subscriber',
        loadChildren: () => import('../subscriber/subscriber.module').then(m => m.SubscriberModule),
        canActivate: [GuardGuard, AccountGuard],
        data: {
          permission: Permission.SuperAdmin,
          canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
        }
      },
      {
        path: 'plugins',
        loadChildren: () => import('../../plugins/plugins.module').then(m => m.PluginsModule),
        canActivate: [GuardGuard, AccountGuard],
        data: {
          canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
        }
      },
      {
        path: 'profile',
        loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule),
        canActivate: [GuardGuard, AccountGuard],
        data: {
          permission: Permission.Supervisor,
          canViewExcept: [AccountTypes.Viewer, AccountTypes.Reporting]
        }
      },
      {
        path: 'leaderboard',
        loadChildren: () => import('../observation/pages/leaderboard/pages/preview/preview.module').then(m => m.PreviewPageModule),
        data: {
          headerConfig: {
            isHidden: true
          },
          disableFullScreenAlert: true
        },
        canActivate: [FullscreenLeaderboardGuard],
        canDeactivate: [FullscreenLeaderboardDeactivateGuard]
      },
      {
        path: 'access',
        loadChildren: () => import('../access/access.module').then(m => m.AccessPageModule),
        canActivate: [GuardGuard]
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: 'dashboard'
      }
    ]
  }
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    LayoutComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    RouterModule.forChild(routes),
    IonicModule.forRoot(),
    TranslateModule.forChild(),
    MenuFilterModule
  ]
})
export class LayoutModule {
}
