import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { UserService } from '@services/user/user.service';
import { SettingsService } from '@services/settings/settings.service';
import { GearService } from '@services/gear/gear.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { CertificationsService } from '@services/certifications/certifications.service';
import { SiteAwarenessMessagingService } from '@services/siteAwarenessMessaging/site-awareness-messaging.service';
import { TeamsService } from '@services/teams/teams.service';
import { ObservationService } from '@services/observations/observation.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { StorageService } from '@services/storage/storage.service';

import { ShiftService } from '@services/shift/shift.service';
import { TierService } from '@services/tier/tier.service';
import { CommsService } from '@services/comms/comms.service';
import { ChecksService } from '@services/checks/checks.service';
import { CheckResponseService } from '@services/checkResponse/check-response.service';
import { DeploymentService } from '@modules/management/pages/details/check/services/deployment/deployment.service';

import { environment } from '@env';
import { MessagesService } from '@services/messages/messages.service';
import { AssetsService } from '@services/assets/assets.service';
import { RulesService } from '@services/rules/rules.service';
import { ConsentService } from '@services/consent/consent.service';
import { RolesService } from '@services/roles/roles.service';
import { HierarchyGroupingService } from '@services/hierarchyGrouping/hierarchy-grouping.service';

import * as _ from 'lodash';
import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill';
import { CollectionsService } from '@services/collections/collections.service';
import { FeedbackService } from '@services/feedback/feedback.service';
import { PoolService } from '@services/pool/pool.service';
import { PdcaService } from '@services/pdca/pdca.service';
import { TasksService } from '@services/tasks/tasks.service';
import { AccessTokenService } from '@services/access-token/access-token.service';
import { DisclaimerService } from '@services/disclaimer/disclaimer.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  public usesSSE = false;
  public lastRefresh = 0;
  public isListening = false;
  public isRefreshing = false;

  private es: EventSource;
  private lastEventId: Number = 0;
  private lastFetch: Number = 0;
  private lastHash = '';

  private tableList: Array<string> = [
    'assets',
    'certifications',
    'checks',
    'deployments',
    'gear',
    'groups',
    'locations',
    'message_templates',
    // 'messages',
    'notification_rules',
    'observations',
    'participants',
    'questionOptions',
    'questions',
    'response_items',
    'responses',
    'roles',
    'shifts',
    'site-awareness-messaging',
    'subscribers',
    'subscriber_preferences',
    'subscriber_settings',
    'tags',
    'tiers',
    'zones',
    'motd',
    'folders',
    'collections',
    'pools',
    'feedback',
    'pdca',
    'pdca_items',
    'pdca_causes',
    'tasks',
    'task_states',
    'task_types',
    'api_keys',
    'disclaimers',
    'module_terms'
  ];

  private fetchAllMap: any = {
    assets: (data) => this.assetsService.updateCache(data),
    certifications: (data) => this.certificationsService.updateCache(data),
    checks: (data) => this.checkService.updateCache(data),
    deployments: (data) => this.deploymentService.updateCache(data),
    disclaimers: (data) => this.disclaimerService.updateCache(data),
    gear: (data) => this.gearService.updateCache(data),
    groups: (data) => this.teamService.updateCache(data),
    locations: (data) => this.userService.updateLocationCache(data),
    message_templates: (data) => this.settings.updateCache(data),
    motd: null,
    rules: (data) => this.rulesService.updateCache(data),
    notification_rules: (data) => this.rulesService.updateCache(data),
    questionOptions: (data) => this.checkService.updateCache(data),
    question_options: null,
    questions: (data) => this.checkService.updateCache(data),
    roles: (data) => this.roleService.updateCache(data),
    shifts: (data) => this.shift.updateCache(data),
    'site-awareness-messaging': (data) => this.siteAwarenessMessagingService.updateCache(data),
    tags: (data) => this.settings.updateTagCache(data),
    tiers: (data) => this.tierService.updateCache(data),
    cause_types: (data) => this.pdcaService.updateCauseTypesCache(data),
    task_states: (data) => this.tasksService.updateTaskStatesCache(data),
    task_types: (data) => this.tasksService.updateTaskTypesCache(data),
    zones: null,
    // pools: (data) => this.poolService.updateCache(data),
    ///'folders': (data) => this.hierarchyService.updateCache(data)
  };

  private sseTimer = null;

  private THROTTLE_INTERVAL = 10000; // 10 seconds between invocations
  private tableQueue = {};
  private throttle = _.throttle(this.processRefreshQueue, this.THROTTLE_INTERVAL, {leading: true, trailing: true});

  constructor(
    private logger: NGXLogger,
    private userService: UserService,
    private settings: SettingsService,
    private gearService: GearService,
    private teamService: TeamsService,
    private accountService: AccountsService,
    private certificationsService: CertificationsService,
    private siteAwarenessMessagingService: SiteAwarenessMessagingService,
    private observationService: ObservationService,
    private subscriberService: SubscriberService,
    private shift: ShiftService,
    private tierService: TierService,
    private comms: CommsService,
    private checkService: ChecksService,
    private deploymentService: DeploymentService,
    private messages: MessagesService,
    private checkResponseService: CheckResponseService,
    private assetsService: AssetsService,
    private rulesService: RulesService,
    private consentService: ConsentService,
    private roleService: RolesService,
    private storageService: StorageService,
    private hierarchyService: HierarchyGroupingService,
    private collectionsService: CollectionsService,
    private poolService: PoolService,
    private feedbackService: FeedbackService,
    private pdcaService: PdcaService,
    private tasksService: TasksService,
    private tokensService: AccessTokenService,
    private disclaimerService: DisclaimerService
  ) {}

  public listenForEvents(): boolean {
    if (!_.has(environment.data, 'SSEURL') || !environment.data.SSEURL) {
      this.usesSSE = false;
      this.logger.log('this subscriber does not use SSE');
      return false;
    }
    // let r = this.comms.serviceURL + '?cmd=sse';
    const subID = this.comms.subscriberID;

    if (!subID) {
      return false;
    }

    if (this.es && this.es.readyState !== this.es.CLOSED) {
      return false;
    }

    const EventSource = NativeEventSource || EventSourcePolyfill;

    let r = environment.data.SSEURL + '/events';

    r += `?subscriberID=${subID}`;
    r += `&token=${this.comms.token}`;
    r += '&tables=' + _.join(this.tableList, ',');
    if (this.lastEventId) {
      r += `&lastEventId=${this.lastEventId}`;
    }

    this.es = new EventSource(r);
    this.es.addEventListener('open', (ev) => {
      this.logger.log(`SSE event listener is open: monitoring tables ` + _.join(this.tableList, ','));
      this.isListening = true;
      this.usesSSE = true;
      // be sure to tell any services that might poll not to bother
      this.messages.usingSSE = true;
      this.observationService.usingSSE = true;
      // establish the keepalive timer
      this.sseKeepAlive();
    });
    this.es.addEventListener('error', (ev) => {
      this.logger.log('SSE event listener threw an error');
      this.isListening = false;
    });
    this.es.addEventListener('needsRefresh', (ev: MessageEvent) => {
      this.handleEvent(ev);
      this.sseKeepAlive();
    });
    this.es.addEventListener('NoAuth', (ev: MessageEvent) => {
      if (this.comms.noAuthHandler) {
        this.comms.noAuthHandler();
      }
      this.sseKeepAlive();
    });
    this.es.addEventListener('message', (ev: MessageEvent) => {
      this.messages.refreshMessages();
      this.sseKeepAlive();
    });
    this.es.addEventListener('observation', (ev: MessageEvent) => {
      this.observationService.updateObservations();
      this.sseKeepAlive();
    });
    this.es.addEventListener('heartbeat', (ev: MessageEvent) => {
      // this.logger.log('got an SSE heartbeat event');
      // reset the keep alive timer
      this.sseKeepAlive();
    });
    this.sseKeepAlive();
    return true;
  }

  public stopListening(): void {
    if (this.es) {
      this.es.close();
      this.es = null;
      this.usesSSE = false;
      // clear the flag for any services that might poll
      this.messages.usingSSE = false;
      this.observationService.usingSSE = false;
    }
  }

  handleEvent(theEvent: MessageEvent) {
    const e = theEvent.type;
    const id = theEvent.lastEventId;
    const origin = theEvent.origin;
    const data = theEvent.data;

    // we got an event - act on it
    if (e === 'needsRefresh') {
      if (data !== '') {
        // the data attribute should be a JSON list
        let theList = {};
        try {
          const l = JSON.parse(data);
          if (l && _.isObject(l)) {
            theList = l;
          }
        } catch (err) {
          this.logger.error('Error parsing event data: ' + data + '; ' + err);
        }
        if (Object.keys(theList).length) {
          // add everything to the queue
          _.each(theList, (ref, key) => {
            if (!_.has(this.tableQueue, key)) {
              this.tableQueue[key] = ref;
            }
          });
          // call the refresher, but throttle it
          this.throttle();
        }
      }
    } else if (e === 'ping') {
      this.logger.debug('received an SSE ping event');
    }
  }

  async refreshTables(tables: any): Promise<any> {
    const theTables = [];
    const theTimes = [];
    _.each(tables, (time, name) => {
      theTables.push(name);
      theTimes.push(time);
      // this.logger.debug(`updating table ${name} with time of ${time}`);
    });
    return this.refresh(theTables, false, theTimes);
  }

  async refresh(tables: Array<string> = [], force: boolean = false, times: Array<Number> = []): Promise<any> {
    return new Promise((resolve, reject) => {

      const p = [];

      if (!_.isArray(tables) || tables.length === 0 || force) {
        tables = [
          'zones',
          'gear',
          'certifications',
          'site-awareness-messaging',
          'message_templates',
          'groups',
          'participants',
          'observations',
          'checks',
          'questions',
          'questionOptions',
          'deployments',
          'tiers',
          'tags',
          'responses',
          'assets',
          'notifications',
          'subscriber_settings',
          'folders',
          'collections',
          'pools',
          'feedback',
          'pdca',
          'pdca_items',
          'pdca_causes',
          'tasks',
          'task_states',
          'task_types'
        ];
      }
      let didLocations = false;
      let didChecks = false;
      let didResponses = false;
      const didSettings = false;
      // we need to do an initialize just in case something changed there
      // this has a sideeffect that lastRefresh is updated too
      if (tables && Array.isArray(tables)) {
        this.logger.log('some tables are out of date: ' + tables.join(','));
        const doTableUpdates = () => {
          const usesFetchall = (_.indexOf(tables, 'fetchAll') > -1);
          _.each(tables, (theTable, idx) => {
            const time = times.length ? times[idx] : 0;
            if (time) {
              this.logger.log(`update time is ${time}`);
            }

            if (usesFetchall && _.has(this.fetchAllMap, theTable)) {
              this.logger.debug(`ignoring ${theTable} because we are using fetchAll`);
            } else {
              if ((theTable === 'zones' || theTable === 'locations') && !didLocations) {
                didLocations = true;
                this.logger.debug(`updating ${theTable}`);
                p.push(this.userService.getLocations(time));
              }
              if (theTable === 'tags') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.settings.getCustomTags());
              }
              if (theTable === 'shifts') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.shift.refresh());
              }
              if (theTable === 'observations') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.observationService.updateObservations(time));
              }
              if (theTable === 'module_terms') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.subscriberService.updateCustomTerms());
              }
              if (theTable === 'subscriber_settings') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.consentService.refresh());
              }
              if (theTable === 'gear') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.gearService.refresh(time));
              }
              if (theTable === 'tiers') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.tierService.refresh(time));
              }
              if (theTable === 'certifications') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.certificationsService.refresh(time));
              }
              if (_.includes(['site-awareness-messaging', 'motd'], theTable)) {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.siteAwarenessMessagingService.refresh(time));
              }
              if (theTable === 'message_templates') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.settings.refresh(time));
              }
              if (theTable === 'groups') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.teamService.refresh(time));
              }
              if (theTable === 'participants') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.accountService.refresh(time));
              }
              if (theTable === 'subscribers') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.subscriberService.refresh());
              }
              if (!didChecks && _.includes(['checks', 'questions', 'questionOptions'], theTable)) {
                didChecks = true;
                this.logger.debug(`updating ${theTable}`);
                p.push(this.checkService.refresh(time));
              }
              if (theTable === 'deployments') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.deploymentService.refresh(time));
              }
              if (theTable === 'messages') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.messages.refreshMessages());
              }
              if (!didResponses && (theTable === 'responses')) { // } || theTable === 'response_items')) {
                didResponses = true;
                this.logger.debug(`updating ${theTable}`);
                p.push(this.checkResponseService.refresh(time)); // need this when issues are updated
                // p.push(this.checkResponseService.fetchCaTableRespInfo()); // this when Cas are updated
              }
              if (theTable === 'assets') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.assetsService.refresh(time));
              }
              if (_.includes(['notifications', 'notification_rules'], theTable)) {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.rulesService.getRules(time));
              }
              if (!usesFetchall && theTable === 'fetchAll') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.fetchAll());
              }
              if (theTable === 'roles') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.roleService.refresh(time));
              }
              if (theTable === 'roles') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.roleService.refresh(time));
              }
              if (theTable === 'folders') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.hierarchyService.refresh(time));
              }
              if (theTable === 'feedback') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.feedbackService.refresh());
              }
              if (theTable === 'collections') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.collectionsService.refresh(time));
              }
              if (theTable === 'pools') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.poolService.refresh());
              }
              if (_.includes(['pdca', 'pdca_items'], theTable)) {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.pdcaService.refresh());
              }
              if (theTable === 'pdca_causes') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.pdcaService.getCauses());
              }
              if (theTable === 'tasks') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.tasksService.refresh());
              }
              if (theTable === 'task_states') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.tasksService.getTaskStates());
              }
              if (theTable === 'task_types') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.tasksService.getTaskTypes());
              }
              if (theTable === 'api_keys') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.tokensService.refresh());
              }
              if (theTable === 'disclaimers') {
                this.logger.debug(`updating ${theTable}`);
                p.push(this.disclaimerService.refresh());
              }
            }
          });
          Promise.all(p)
            .then((theResults) => {
              this.logger.debug('All the cache refresh promises have resolved!');
              resolve(true);
            });
        };
        if (_.indexOf(tables, 'subscribers') > -1 || _.indexOf(tables, 'subscriber_preferences') > -1 || _.indexOf(tables, 'subscriber_settings') > -1) {
          this.subscriberService.refresh().then(() => {
            doTableUpdates();
          }).catch((err) => {
            // route to initfailed
            // utils.showpage('initfailed');
            reject(err);
          });
        } else {
          // we don't need to initialize; the subscriber data did not change
          doTableUpdates();
        }
      }
    });

  }

  fetchAll(): Promise<any> {
    const requestParams = {
      cmd: 'fetchAll',
      sendTime: Date.now(),
      lastHash: this.lastHash,
      lastRequest: this.lastFetch
    };

    return this.comms.sendMessage(requestParams, false, false).then(data => {
      if (data && data.reqStatus === 'OK') {
        // we have a response from fetchAll; populate everything
        this.updateCache(data);
      }
    })
      .catch(err => {
        this.logger.log(`fetchAll failed: ${err}`);
      });
  }

  public updateCache(data) {
    this.lastFetch = data.result.timestamp;
    this.lastHash = data.result.datahash;
    const tables = data.result.tables;
    _.each(tables, (item) => {
      if (_.has(this.fetchAllMap, item)) {
        const r = this.fetchAllMap[item];
        if (r && typeof (r) === 'function') {
          this.logger.log(`fetchAll data for ${item} being loaded`);
          r(data);
        } else {
          this.logger.log(`Cannot update cache for ${item}`);
        }
      } else {
        this.logger.log(`No cache update entry for table ${item}`);
      }
    });
    this.storageService.store('fetchAll', data.result);
  }

  clear(outOfDate: boolean = false, loggingOut: boolean = false) {
    if (outOfDate) {
      // the backend says we are out of date.  Only clear
      // the things that contract says need refreshing
    } else {
      if (this.sseTimer) {
        window.clearTimeout(this.sseTimer);
        this.sseTimer = null;
      }
      // ensure cached items are wiped
      _.each([this.assetsService, this.certificationsService, this.checkResponseService, this.checkService, this.deploymentService, this.gearService, this.teamService, this.userService, this.settings, this.rulesService, this.roleService, this.shift, this.siteAwarenessMessagingService, this.tierService], (ref: any) => {
        if (_.hasIn(ref, 'clearCache') && typeof (ref.clearCache) === 'function') {
          ref.clearCache();
        }
      });
      this.lastFetch = 0;
      this.lastHash = '';
    }
  }

  clearTableSearch() {
    _.each([this.accountService, this.assetsService, this.certificationsService, this.checkService, this.gearService, this.teamService, this.settings, this.rulesService, this.roleService], (ref: any) => {
      if (_.hasIn(ref, 'filterObject')) {
        ref.filterObject = {};
      }
    });
  }

  private sseKeepAlive() {
    if (this.sseTimer) {
      window.clearTimeout(this.sseTimer);
      this.sseTimer = null;
    }
    if (this.usesSSE) {
      // if this timer fires, attempt to re-establish the SSE connection
      this.sseTimer = window.setTimeout(() => {
        if (!this.es || this.es.readyState === this.es.CLOSED) {
          this.logger.debug('sseKeepAlive timer fired and connection was closed; restarting');
          // there is no connection or it is closed
          if (!this.listenForEvents()) {
            // if the listen method returns false, then it did work but we are supposed to be using SSE; restart
            // the timer and try again later
            this.sseKeepAlive();
          }
          // that method will restart the keepAlive
        } else if (this.es && this.es.readyState !== this.es.CLOSED) {
          // timer fired but the connection seems to be open... reset the timer.
          this.logger.debug('sseKeepAlive timer fired but the connection was open');
          this.sseKeepAlive();
        }
      }, 25000);
    }
  }

  private processRefreshQueue() {
    const myList = _.cloneDeep(this.tableQueue);
    this.tableQueue = {};
    if (Object.keys(myList).length) {
      this.logger.log('Processing refresh queue');
      // we are running and we own the queue - run it
      this.refreshTables(myList)
        .then((res) => {
          if (res) {
            this.logger.log('Refresh completed successfully after event');
          }
          this.isRefreshing = false;
        })
        .catch(err => {
          this.logger.error(`refresh failed: ${err}`);
          this.isRefreshing = false;
        });
    }
  }
}
