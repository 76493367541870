import { Component, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  IHeaderSetting,
  ObservationDashboardUIService,
  TableService,
  FilterElementService,
  PermissionsService,
  UserdataService,
  SubscriberService,
  DashboardHeaderIcon,
  AccountsService, Module, UserService, ILocation
} from '@services';
import { Events } from '@services/events/events.service';
import { DashboardChartServiceService } from '@services/dashboardChartService/dashboard-chart-service.service';
import { FullhealthTableComponent } from '../../components/fullhealth-table/fullhealth-table.component';
import { ObservationTableService } from '@services/observationTableService/observation-table-service.service';
import * as _ from 'lodash';
import { PermissionPipe } from '@shared/pipes';


@Component({
  selector: 'app-location-dashboard',
  templateUrl: './location-dashboard.page.html',
  styleUrls: ['./location-dashboard.page.scss'],
  providers: [PermissionPipe]
})
export class LocationDashboardPage {

  @ViewChild('locationLineCanvas') locationLineCanvas: ElementRef;
  @ViewChild('locationBehaviorBar') locationBehaviorBar: ElementRef;
  public locationID: number = null;
  public locationData: any = {};
  public tableId = 'zoneHealthTable';
  public locationLineData = false;
  public lineChartLabel: any = [];
  public behaviorChart = false;
  public complimentAvaiable = false;
  public showFilterBody = false;
  public module = Module;
  public headerSetting: IHeaderSetting = {
    title: 'SHARED.Location_Detail',
    filter: {
      showFilter: true,
      isActive: true
    },
    icon: [
      DashboardHeaderIcon.SiteAwareness,
      DashboardHeaderIcon.Observations,
      DashboardHeaderIcon.SupervisorDashboard,
      DashboardHeaderIcon.Leaderboards,
      DashboardHeaderIcon.WorkStatus,
      DashboardHeaderIcon.WorkZone,
      DashboardHeaderIcon.FrontlineCulture
    ],
    showBreadCrumb: true,
    breadCrumbObject: {
      pageTitle: this.translate.instant('LAYOUT.Acitivity_Detail'),
      backNavTitle: this.translate.instant('LAYOUT.Observation_Activity'),
      backNavUrl: '/pages/dashboard/main'
    }
  };
  public tabs = [{title: this.translate.instant('SHARED.Conditions'), key: 'condition'}];
  public activeTab = _.find(this.tabs, {key: this.getStoredDataByKey('activeTimeTab')}) || this.tabs[0];
  public zoneHealthDropDownByModule = [{
    id: 'zoneCondition',
    text: this.translate.instant('DASHPAGES.COACHING_TABLE_text0'),
  }, {
    id: 'zoneCA',
    text: this.translate.instant('SHARED.Corrective_Actions')
  }, {
    id: 'zoneQuality',
    text: this.translate.instant('SHARED.Quality_Issues'),
    module: Module.QUALITY,
    checkSite: false
  }, {
    id: 'zonePI',
    text: this.translate.instant('SHARED.Process_Improvements'),
    module: Module.PROCESS_IMPROVEMENT,
    checkSite: true
  }, {
    id: 'zoneCoaching',
    text: this.translate.instant('DASHPAGES.COACHING_TABLE_text1'),
    module: Module.BEHAVIOR,
    checkSite: true
  }, {
    id: 'zoneCompliment',
    text: this.translate.instant('DASHPAGES.COACHING_TABLE_text4'),
    module: Module.COMPLIMENT,
    checkSite: true
  }];
  public zoneHealthDropDown = [];

  //  public filterObject = this.dashboardUiService.buildFilterObjects(['location', 'zone', 'user', 'timespan']);
  public activeZoneHealth = _.find(this.zoneHealthDropDown, {id: this.getStoredDataByKey('zoneHealth')}) || this.zoneHealthDropDown[0];
  public activeTableData: any[] = [];
  public filterComps: any[] = [];
  public options = {
    minimumResultsForSearch: Infinity,
    sorter: (data) => data
  };
  private location: ILocation;
  private storeDataKey = 'locationDashboard';
  private lineChartReference: any = {};
  private menuItem: any[] = ['location', 'team', 'range'];

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    public dashboardUiService: ObservationDashboardUIService,
    private tableService: TableService,
    private chartService: DashboardChartServiceService,
    private filterElem: FilterElementService,
    private events: Events,
    private popover: PopoverController,
    private zone: NgZone,
    private obsTableService: ObservationTableService,
    private permissions: PermissionsService,
    private userDataService: UserdataService,
    private subscriberService: SubscriberService,
    private translate: TranslateService,
    private userService: UserService,
    private permissionPipe: PermissionPipe,
    private accountsService: AccountsService
  ) {
    this.initLocationId();
  }

  initLocationId() {
    const incomingID = this.activeRoute.snapshot.queryParamMap.get('locationID');

    if (incomingID) {         // Then this mean, this is a navigation from main dashboard page, bearing a location ID,
      this.locationID = Number(incomingID);
      this.location = this.userService.getLocation(this.locationID);
    }
    this.setDropdownsData();
  }

  ionViewWillLeave() {
    this.events.unsubscribe('ccs:filterObject', this.filterListener);
    this.events.unsubscribe('ccs:clearMenu', this.clearMenuListener);
    this.events.unsubscribe('ccs:observationUpdate', this.observationListener);
    this.events.unsubscribe('ccs:subscriptionUpdate', this.subscriberListener);
  }

  public zoneHealthChange(event) {
    this.activeTableData = this.locationData.zoneHealth[this.activeZoneHealth.id];
    this.buildTableElements();
    this.setStoredDataByKey('zoneHealth', this.activeZoneHealth.id);
  }

  public viewAllZoneHealth() {
    this.popover.create({
      component: FullhealthTableComponent,
      animated: false,
      componentProps: {
        activeZoneHealth: this.activeZoneHealth,
        activeTableData: this.activeTableData
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public toggleTab(tab) {
    this.activeTab = tab;
    this.setStoredDataByKey('activeTimeTab', tab.key);
  }

  tabClass(tab) {
    if (this.activeTab.key === tab.key) {
      if (tab.key === 'condition') {
        return 'active-tab left-round';
      } else {
        return 'active-tab right-round';
      }
    } else {
      if (tab.key === 'condition') {
        return 'left-round';
      } else {
        return 'right-round';
      }
    }
  }

  ionViewWillEnter() {
    this.initLocationId();

    if (this.locationID) {         // Then this mean, this is a navigation from main dashboard page, bearing a location ID,
      // Prepare the filter object from dashboardUiService, empty it out
      this.dashboardUiService.locationDashboardFilter = {};
      this.dashboardUiService.locationDashboardFilter.locations = [this.locationID];
      this.locationData = this.dashboardUiService.prepareLocationDetailDataSet();
    } else { // this is normal navigation,
      this.locationData = this.dashboardUiService.prepareLocationDetailDataSet();
    }
    // register filter subscriber
    this.events.subscribe('ccs:filterObject', this.filterListener);

    // register clear menu subscriber
    this.events.subscribe('ccs:clearMenu', this.clearMenuListener);

    //register to incoming changes
    this.events.subscribe('ccs:observationUpdate', this.observationListener);
    this.events.subscribe('ccs:subscriptionUpdate', this.subscriberListener);

  }

  ionViewDidEnter() {
    this.buildElements();
    // filter Components should be built WRT filter objec being passed
    this.filterComps = this.filterElem.buildFilterObjects(this.menuItem, this.dashboardUiService.locationDashboardFilter, false);
  }

  buildElements() {
    this.buildTableElements();
    this.buildLineChart();
    this.buildBarChart();
    this.buildThumbsUpElements();
  }

  buildThumbsUpElements() {
    if (!this.locationData.counts.compliment.topCompliment) {
      this.complimentAvaiable = false;
    } else {
      this.complimentAvaiable = true;
      if (this.locationData.counts.compliment.topCompliment.length > 5) {
        this.locationData.counts.compliment.topCompliment = this.locationData.count.compliment.topCompliment.splice(0, 5);
      }

      this.locationData.counts.compliment.topCompliment = this.locationData.counts.compliment.topCompliment.map(data => ({
        ...data,
        fullName: this.accountsService.fullUserName(data.userID)
      }));
    }
  }

  buildTableElements() {
    this.activeTableData = this.locationData.zoneHealth[this.activeZoneHealth.id];

    // order elements by risk index
    this.activeTableData = _.sortBy(this.activeTableData, data => Number(data.riskIndex));

    if (this.activeZoneHealth.id === 'zoneCondition') {
      this.activeTableData.reverse();
    }
    // let's build that table.
    const tableElement = this.dashboardUiService.buildZoneTableAttrs(this.activeZoneHealth);
    if (($.fn.dataTable.isDataTable(`#${this.tableId}`))) {
      $(`#${this.tableId}`).DataTable().clear().draw();
      $(`#${this.tableId}`).DataTable().destroy();
    }

    if (this.activeTableData.length > 4) {
      this.tableService.showTable(`#${this.tableId}`, tableElement, this.activeTableData.slice(0, 4));
    } else {
      this.tableService.showTable(`#${this.tableId}`, tableElement, this.activeTableData);
    }
    this.dashboardUiService.initDataTable(`#${this.tableId}`, true);
    $(`#${this.tableId}` + ' td').css({'text-align': 'left !important'});
    $(`#${this.tableId}`).removeClass('no-footer');
    $(`#${this.tableId}` + ' thead th .sort-icon').removeClass('sort-icon');

    $(document).on('click', `.${tableElement.class}`, (ev) => {
      ev.preventDefault();
      const ref = $(ev.target).closest('tr');
      if (ref.length && ref[0].hasAttribute('data-lid')) {
        const locZone = ref.attr('data-lid');
        this.zoneTableNav(tableElement.class, locZone);
        this.popover.dismiss();

      }
    });
  }

  public lineChartLegend(index) {
    if (this.lineChartReference) {
      const ci = this.lineChartReference;
      const meta = ci.getDatasetMeta(index);
      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
      ci.update();
    }
  }

  public toggleFilterBody(val) {
    this.showFilterBody = val;
  }

  public navToQuality() {
    this.obsTableService.filterObject = _.cloneDeep(this.dashboardUiService.locationDashboardFilter);
    this.router.navigate(['pages/dashboard/' + 'quality-table']);
  }

  public navToCondition() {
    this.obsTableService.filterObject = _.cloneDeep(this.dashboardUiService.locationDashboardFilter);
    this.router.navigate(['pages/dashboard/' + 'condition-table']);
  }

  private setDropdownsData() {
    this.tabs = [{title: this.translate.instant('SHARED.Conditions'), key: 'condition'}];

    if (this.permissionPipe.transform(Module.QUALITY)) {
      this.tabs.push({title: this.translate.instant('SHARED.Quality'), key: 'quality'});
    }
    this.activeTab = _.find(this.tabs, {key: this.getStoredDataByKey('activeTimeTab')}) || this.tabs[0];
    this.activeTab = this.activeTab || this.tabs[0];

    this.zoneHealthDropDown = _.filter(this.zoneHealthDropDownByModule,
      item => {
        if (item.module) {
          const locationID = item.checkSite ? this.locationID : undefined;
          return this.permissionPipe.transform(item.module, locationID);
        }
        return true;
      }
    );
    const prevSelect = _.find(this.zoneHealthDropDown, {id: this.getStoredDataByKey('zoneHealth')});
    this.activeZoneHealth = prevSelect || this.zoneHealthDropDown[0];
  }

  /**
   *  This method navigates to the observation table page from locationDashboard zonehealth table
   *
   * @param pathName - the URL for the table to nav.
   * @param zoneLoc - string of LID:ZID
   */
  private zoneTableNav(pathName, zoneLoc) {
    const zoneObj = zoneLoc.split(':');
    const location = Number(zoneObj[0]);
    const zone = Number(zoneObj[1]);
    const filterObject: any = {};

    if (zone === 0) {
      filterObject.zones = [zoneLoc];
    } else {
      filterObject.locations = [location];
      filterObject.zones = [zone.toString()];
    }
    this.obsTableService.filterObject = _.cloneDeep(filterObject);
    this.router.navigate(['pages/dashboard/' + pathName]);
  }

  private buildLineChart() {
    if (!this.locationLineCanvas) {
      return false;
    }

    if (this.locationData.lineData.datasets && ((this.locationData.lineData.datasets.length) > 0)) {
      this.locationLineData = true;
    } else {
      this.locationLineData = false;
    }
    this.lineChartReference = this.chartService.lineChart(this.locationLineCanvas, this.locationData.lineData);
    this.lineChartLabel = this.dashboardUiService.generateLabel(this.lineChartReference);

  }

  private buildBarChart() {
    if (!this.locationBehaviorBar) {
      return false;
    }
    if (this.locationData.counts.coaching && ((this.locationData.counts.coaching.count) > 0)) {
      this.behaviorChart = true;
      this.chartService.barChart(this.locationBehaviorBar, '', this.locationData.counts.coaching.topObject);

    } else {
      this.behaviorChart = false;
    }
  }

  private getStoredDataByKey(key: string): any {
    return this.userDataService.getStoreDataByKey(this.storeDataKey, key);
  }

  private setStoredDataByKey(key: string, value: any): any {
    return this.userDataService.setStoreDataByKey(this.storeDataKey, key, value);
  }

  private filterListener = (data) => {
    this.dashboardUiService.locationDashboardFilter = data; // get filter elem
    // if the incoming filter is empty, then make sure not all location is shown
    // filter by users location
    if (_.isEmpty(data)) {
      if (this.userDataService.locations.length) {
        this.dashboardUiService.locationDashboardFilter.locations = this.userDataService.locations;
      }
    }

    this.locationData = this.dashboardUiService.prepareLocationDetailDataSet(); // get filtered data set
    this.buildElements();
  };

  private clearMenuListener = (data) => {
    if (data) {
      this.zone.run(() => {
        // tslint:disable-next-line:max-line-length
        this.filterComps = this.filterElem.buildFilterObjects(this.menuItem, this.dashboardUiService.locationDashboardFilter, false);
        let checkFilterActive = false;
        _.each(this.filterComps, comp => {
          if (comp.value && comp.value.length > 0) {
            checkFilterActive = true;
            return false;
          }
        });
        this.headerSetting.filter.isActive = checkFilterActive;
      });
    }
  };

  private observationListener = (data) => {
    if (data) {
      this.locationData = this.dashboardUiService.prepareLocationDetailDataSet(); // get filtered data set
      this.buildElements();
      this.setDropdownsData();
    }
  };

  private subscriberListener = (data) => {
    if (data) {
      this.observationListener(data);
      this.setDropdownsData();
    }
  };
}
