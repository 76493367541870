import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent {

  @Input() value: string | number = 0;
  @ViewChild('currencyMask', {static: true}) itemListComponent: ElementRef;
  public currencyMaskOptions: any = {
    align: 'left',
    allowNegative: false,
    prefix: '$'
  };

  constructor() {
  }

  @Input() set nameAttribute(value: string) {
    if (value) {
      this.itemListComponent.nativeElement.setAttribute('name', value);
    }
  }

}
