import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { CommsService } from '@services/comms/comms.service';
import { ObjectsService } from '@services/objects/objects.service';
import { UtilsService } from '@services/utils/utils.service';

import * as _ from 'lodash';
import { UserdataService } from '@services/userdata/userdata.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamsService } from '@services/teams/teams.service';
import { Events } from '@services/events/events.service';
import { StorageService } from '@services/storage/storage.service';

export enum AccountTypes {
  Dedicated = 'dedicated',
  Shared = 'shared',
  Reporting = 'reporting',
  Viewer = 'viewer',
  Coordinator = 'coordinator',
  Wristband = 'wristband',
  Observation = 'observation',
  Observer = 'observer'
}

export interface IAccount {
  active?: number;
  avatarID?: number;
  avatarPath?: string;
  cell?: string;
  certifications?: Array<number>;
  compliance?: any;
  consentDate?: number;
  consentWithdrawnDate?: number;
  deviceID?: string;
  disabledAt?: number;
  disabledBy?: number;
  email?: string;
  firstname: string;
  groups: Array<number>;
  hireDate?: number;
  lastGear?: Array<number>;
  lastLocation?: number;
  lastLogin?: number;
  lastUpdate?: number;
  lastZone?: number;
  lastname: string;
  ldapServer?: number;
  locations: Array<number>;
  nfcID?: string;
  nodeSelections?: Array<string | number>;
  observations?: any;
  permissions?: any;
  points?: any;
  powerPoints?: number;
  preferences?: any;
  primaryGroup: number;
  ranks?: any;
  registeredGear?: Array<number>;
  roles?: Array<number>;
  shift?: string;
  supervisorID: number;
  terminationDate?: number;
  type: AccountTypes;
  userID: number;
  username: string;
}

@Injectable({
  providedIn: 'root'
})

export class AccountsService {

  public filterObject: any = {};

  accounts: any = {
    lastHash: null,
    lastRequest: null,
    data: []
  };

  constructor(
    private logger: NGXLogger,
    private events: Events,
    private comms: CommsService,
    private object: ObjectsService,
    private utils: UtilsService,
    private userdata: UserdataService,
    private teamsService: TeamsService,
    private translate: TranslateService,
    private storageService: StorageService
  ) {
  }

  public refresh(updated: Number = 0): Promise<Array<any>> {
    if (updated && updated < this.accounts.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.accounts.lastRequest}`);
      return Promise.resolve(this.accounts.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.comms.sendMessage({
          cmd: 'getUsers',
          includeDisabled: 1,
          includeShared: 1,
          lastRequest: this.accounts.lastRequest,
          lastHash: this.accounts.lastHash,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.accounts.lastHash = data.result.datahash;
            this.accounts.lastRequest = data.result.timestamp;
            this.accounts.data = _.orderBy(data.result.users, [user => user.firstname.toLowerCase()], ['asc']);
            this.storageService.store('accounts', this.accounts);
            // update the user's data
            this.userdata.handleAccountData(this.getByID(+this.userdata.userID));
            this.events.publish('ccs:accountsUpdate', this.accounts.data);
          } else if (data && data.reqStatus === 'CACHEVALID') {
            this.logger.log('getUsers returned CACHEVALID');
          }
          resolve(this.accounts.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  /**
   * @param userID the ID of the user
   * @returns the account record for the matching account, or null if it is not found
   */
  public getByID(userID: number): IAccount {
    if (userID === -1) {
      // special anonymous user
      return {
        type: AccountTypes.Dedicated,
        userID: -1,
        firstname: 'Anonymous',
        lastname: 'User',
        groups: [],
        primaryGroup: 0,
        locations: [],
        username: '',
        supervisorID: 0
      };
    } else {
      return _.find(this.accounts.data, {userID}) as any;
    }
  }

  public getByUserName(userName: string): any {
    return _.find(this.accounts.data, {username: userName}) as any;
  }

  public getUserLocation(userID: number): number {
    const u = this.getByID(userID);
    if (u) {
      if (_.has(u, 'lastLocation') && u.lastLocation) {
        return 0 + u.lastLocation;
      } else if (_.has(u, 'locations') && u.locations.length) {
        return 0 + u.locations[0];
      } else {
        return null;
      }
    }
  }

  public createAccountObject(items: any): IAccount {
    const rec = {
      type: AccountTypes.Dedicated,
      userID: 0,
      firstname: '',
      lastname: '',
      groups: [],
      primaryGroup: 0,
      locations: [],
      username: '',
      supervisorID: 0
    };

    return _.assign(rec, items);
  }

  /**
   * avatar - return the value for the avatar of a user
   *
   * @param userID - the ID of the user
   * @param [size] - an optional size for the image in pixels
   * @param [pictureOnly] - an optional flag that means only return a value if there is an actual picture
   *
   * @returns A string to use in the src attribute of an img element.
   *
   */
  public avatar(userID: number, size: number = 64, pictureOnly: boolean = false, useThumbnail: boolean = false): string {
    let ret = null;

    if (userID === 0) {
      return 'assets/images/user_icon.png';
    }

    if (size === null) {
      size = 64;
    }
    const uRef = this.getByID(userID);

    if (uRef) {
      // this is a known user... does it have a reference
      if (uRef.avatarID && uRef.avatarPath !== null) {
        // use the one from the backend if there is one
        ret = this.object.URI(uRef.avatarID, useThumbnail);
      } else if (!pictureOnly) {
        ret = 'assets/images/user_icon.png';
        let l = '';
        if (userID === -1) {
          ret = 'assets/images/avatars/anonymous.png';
        } else if (uRef) {
          if (uRef.firstname !== '') {
            l = UtilsService.charAtIdx(uRef.firstname, 0);
          } else if (uRef.lastname !== '') {
            l = UtilsService.charAtIdx(uRef.lastname, 0);
          }
        }
        if (l !== '' && l.match(/[a-zA-Z]/)) {
          ret = 'assets/images/avatars/' + l.toUpperCase() + '.svg';
        }
      }
    }
    return ret;
  }

  public isActive(userID: number): boolean {
    const user: any = this.getByID(userID);
    let ret = true;
    if (user.disabledAt || !user.active) {
      ret = false;
    }
    return ret;
  }

  public isExternalAvatarUrl(userID: number): boolean {
    const user: any = this.getByID(userID);
    return user && user.avatarID && user.avatarPath;
  }

  /**
   * fullname - return the fullname for a user
   *
   * @param userID - the ID for the user
   *
   * @returns the fullname of the user or null if the user does not exist.
   */
  fullname(userID: number): string {
    if (userID === 0) {
      return this.translate.instant('SHARED.Unassigned');
    }
    if (userID === -1) {
      return 'Anonymous';
    }
    const rec = this.getByID(userID);
    let ret = null;
    if (rec) {
      ret = rec.firstname + ' ' + rec.lastname;
    } else {
      return 'unknown';
    }
    return ret;
  }


  /**
   * getCompactName - return a compact version of the name for an account
   *
   * @param userID - the userID associated with the account
   *
   * @returns The first name and last initial if there is one.
   */
  getCompactName(userID: number): string {
    if (userID === 0) {
      return 'unassigned';
    }
    if (userID === -1) {
      return 'Anonymous';
    }
    const rec = this.getByID(userID);
    let ret = null;
    if (rec) {
      ret = rec.firstname;
      if (rec.lastname && rec.lastname.length > 0) {
        ret += ' ' + UtilsService.charAtIdx(rec.lastname, 0) + '.';
      }
    } else {
      return 'unknown';
    }
    return ret;
  }

  /**
   * getHelpers - return a list of helper userIDs
   *
   * @returns List of userIDs that have the helper permission
   *
   */
  getHelpers(): Array<number> {
    const hList = [];

    _.each(this.accounts.data, (acct) => {
      acct = this.decode(acct);
      if (_.get(acct.permissions, 'helper', 0)) {
        hList.push(acct.userID);
      }
    });

    return hList;
  }

  /**
   * getSupervisors - return a list of supervisor userIDs
   *
   * @returns List of userIDs that have the supervisor permission.
   *
   */
  getSupervisors(locations: any[] = []): Array<number> {
    const sList = [];

    _.each(this.accounts.data, (acct, i) => {
      if (acct.disabledAt === 0 && acct.active) {
        acct = this.decode(acct);
        if (_.get(acct.permissions, 'supervisor', 0)) {
          // this user is a supervisor - do we have a locations list
          if (locations.length) {
            if (acct.hasOwnProperty('locations') && acct.locations.length) {
              const overlap = _.intersection(locations, acct.locations);
              if (overlap.length) {
                sList.push(acct.userID);
              }
            } else {
              sList.push(acct.userID);
            }
          } else {
            sList.push(acct.userID);
          }
        }
      }
    });

    return sList;
  }

  getSupervisorsAccounts(): any[] {
    return _.filter(this.accounts.data, (acc) => {
      acc = this.decode(acc);
      const isSuper = acc.permissions?.supervisor === 1
        || acc.permissions?.admin === 1
        || acc.permissions?.sadmin === 1
        || acc.permissions?.corvex === 1;
      const isActive = (acc.disabledAt === 0 && acc.active === 1);

      return isSuper && isActive;
    });
  }

  public filterData(data): Array<IAccount> {
    if (_.isEmpty(this.filterObject)) {
      return data;
    } else {
      const r = [];
      _.each(data, uObj => {
        if (this.checkFilter(uObj)) {
          r.push(uObj);
        }
      });
      return r;
    }
  }


  public username(userID: number): string {
    const account: any = this.getByID(userID);

    if (account && account.firstname) {
      return account.lastname ? `${account.firstname} ${UtilsService.charAtIdx(account.lastname, 0)}.` : account.firstname;
    }
  }

  public fullUserName(userID: number): string {
    const account: any = this.getByID(userID);

    if (account && account.firstname) {
      return account.lastname ? `${account.firstname} ${account.lastname}` : account.firstname;
    }
  }

  /**
   * decode - unpack the JSON for an account
   *
   * @param  acctData - an account data object as returned from the backend
   * @returns an account data object where any stringified JSON is expanded
   */
  decode(acctData: any): any {
    const r: any = {};
    _.each(acctData, (val, key) => {
      if (typeof val === 'string' &&
        (key === 'roles' || key === 'certifications' || key === 'groups')) {
        try {
          if (val.indexOf('[') !== 0) {
            r[key] = JSON.parse('[ ' + val + ']');
          } else {
            r[key] = JSON.parse(val);
          }
        } catch (err) {
          this.logger.log(err);
          r[key] = {};
        }
      } else if (typeof val === 'string' &&
        (key === 'permissions' || key === 'preferences')) {
        try {
          if (val.indexOf('{') !== 0) {
            r[key] = JSON.parse('{ ' + val + '}');
          } else {
            r[key] = JSON.parse(val);
          }
        } catch (err) {
          this.logger.log(err);
          r[key] = {};
        }
      } else if (key === 'pid' && !acctData.hasOwnProperty('userID')) {
        r.userID = acctData.pid;
      } else {
        r[key] = val;
      }
    });
    return r;
  }

  encode(theAccount: IAccount): any {
    const r: any = {};
    _.each(theAccount, (val, key) => {
      let v;
      let o;
      // these are lists of IDs
      if (key === 'certifications' || key === 'roles' || key === 'groups' || key === 'locations') {
        v = val;
        o = [];
        if (Array.isArray(v)) {
          _.each(v, (item) => {
            o.push(parseInt(item, 10));
          });
        } else {
          o[0] = parseInt(v, 10);
        }
        r[key] = JSON.stringify(o);
      } else if ((key === 'permissions' || key === 'preferences') && typeof val !== 'string') {
        // preferences used to be in here too - but those are not managed on the dashboard
        // these are JSON objects that need to be a string
        v = val;
        o = {};
        if (_.isArray(v)) {
          _.each(v, (item) => {
            o[item] = 1;
          });
        } else {
          o[v] = 1;
        }
        r[key] = JSON.stringify(o);
      } else {
        r[key] = val;
      }
    });
    return r;
  }

  /**
   * getAccount - get account information for a user
   *
   * @param {number} userID - the ID of the account
   *
   * @returns {Object} a reference to the account object, or null if it is not found.
   */

  public getAccount(userID): IAccount {
    return this.getByID(userID);
  }

  /**
   * getAccounts - pull in the account data
   *
   * @returns {Promise} a promise that resolves with the account data when the request is complete.
   */

  public getAccounts() {
    return new Promise((resolve, reject) => {
      const when = Date.now();
      this.comms.sendMessage({
        cmd: 'getUsers',
        includeDisabled: 1,
        includeShared: 1,
        lastHash: this.accounts.lastHash,
        lastRequest: this.accounts.lastRequest,
        sendTime: when
      }, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            this.accounts.data = data.result.users;
            this.utils.sortArray(this.accounts.data, [
              {name: 'firstname', function: this.utils.toLowerCase},
              {name: 'lastname', function: this.utils.toLowerCase}
            ]);
            this.accounts.lastRequest = data.result.timestamp;
            this.accounts.lastHash = data.result.datahash;
          }
          resolve(this.accounts.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param filters array of functions that are called with the user recored as a parameter.  The function returns true if the item should be filtered out of the user list.
   */
  public filterUserList(filters: any) {
    const r = [];
    _.each(this.accounts.data, (ref) => {
      let matched = true;
      if (Array.isArray(filters)) {
        _.each(filters, (test) => {
          if (test(ref)) {
            matched = false;
          }
        });
      }
      if (matched) {
        r.push(ref);
      }
    });
    return r;
  }

  public getUserlist(locations: Array<number>): IAccount[] {
    return this.filterUserList([
      (user) => {
        // if there are no locations in the list, then this user has access to all locations
        if (user.locations.length === 0 || locations.length === 0) {
          return false;
        }
        const overlap = _.intersection(locations, user.locations);
        return !overlap.length;
      }
    ]);
  }

  public buildUserMenu(locations?: any, includeDisabled: boolean = true) {
    const userArray = [];
    const theData = locations !== undefined ? this.getUserlist(locations) : this.accounts.data;
    _.each(theData, data => {
      if (!includeDisabled && (theData.disabledAt || !theData.active)) {
        // do nothing
      } else {
        userArray.push({
          id: data.userID,
          text: (data.disabledAt || !data.active) ? data.firstname + ' ' + data.lastname + ' (' + this.translate.instant('SHARED.Deactivated') + ')' : data.firstname + ' ' + data.lastname
        });
      }
    });
    return {
      name: 'User',
      dropDownOptions: userArray,
    };
  }


  public primaryTeam(user: any, includeDisabled: boolean = false): number {
    let uRef = null;
    if (typeof (user) === 'object') {
      uRef = user;
    } else {
      uRef = this.getAccount(+user);
    }
    let ret = 0;
    if (uRef) {
      if (uRef.primaryGroup) {
        ret = uRef.primaryGroup;
      } else if (uRef.groups.length) {
        ret = uRef.groups[0];
      }
      if (ret && !includeDisabled) {
        const t = this.teamsService.get(ret);
        if (t && t.disabledAt) {
          ret = 0;
        }
      }
    }
    return ret;
  }

  /**
   *
   * @param locList - a list of locations
   * @param includeDisabled  - boolean to include disabled accounts. Default false.
   * @param includeTeams - boolean to include the primary teams at each included location. Default false.
   * @param filter - An array of filters to apply via the filterUserList method.  Defaults to none.
   *
   * @returns an object where the keys are location IDs and the values are the locationID, a list of users for that location
   *          Optionally includes a teams property that is an object where the keys are teamIDs and the values are an object with
   *          the teamID and a list of members of that team.  NOTE: Only primaryTeams are considered.
   */
  public byLocation(locList: Array<number>, includeDisabled: boolean = false, includeTeams: boolean = false, filters: Array<any> = []): any {
    const ret = {};
    _.each(locList, loc => {
      ret[loc] = {
        locationID: loc,
        users: []
      };
      if (includeTeams) {
        ret[loc].teams = {};
      }
    });

    const theList = this.filterUserList(filters);

    _.each(theList, user => {
      if ((includeDisabled || (user.disabledAt === 0 && user.active)) && _.has(user, 'locations')) {

        const team = this.primaryTeam(user, includeDisabled);
        if (user.locations.length === 0) {
          _.each(ret, (ref: any) => {
            ref.users.push(user.userID);
            if (includeTeams) {
              if (!_.has(ref.teams, team)) {
                ref.teams[team] = {teamID: team, members: []};
              }
              ref.teams[team].members.push(user.userID);
            }
          });
        } else {
          _.each(user.locations, locID => {
            if (_.has(ret, locID)) {
              ret[locID].users.push(user.userID);
              if (includeTeams) {
                if (!_.has(ret[locID].teams, team)) {
                  ret[locID].teams[team] = {teamID: team, members: []};
                }
                ret[locID].teams[team].members.push(user.userID);
              }
            }
          });
        }
      }
    });

    return ret;
  }

  private checkFilter(uObj) {
    let matched = true;

    //string match
    if (this.filterObject.searchString) {
      let matched = false;
      _.each(uObj.searchIndex, (index: string) => {
        if (_.includes(index, this.filterObject.searchString)) {
          matched = true;
        }
      });
      if (matched === false) {
        return;
      }

    }

    // account type
    if (this.filterObject.accountType && this.filterObject.accountType.length) {
      if (!_.includes(this.filterObject.accountType, uObj.type)) {
        matched = false;
      }
    }

    // permission
    if (this.filterObject.permissionLevel && this.filterObject.permissionLevel.length) {
      let match = false;
      _.each(this.filterObject.permissionLevel, level => {
        if (uObj.permissions[level]) {
          match = true;
          return false;
        }
      });
      if (!match) {
        matched = false;
      }
    }

    // location
    if (this.filterObject.locations && this.filterObject.locations.length) {

      // condition for check blank user location array (All Location if array is blank)
      // assumed blank Loacation as 0
      if (uObj.locations.length === 0) {
        uObj.locations.push(0);
      }
      // pushed 0 key for match All location key
      const chkfilterObject = this.filterObject.locations.includes(0);
      if (!chkfilterObject) {
        this.filterObject.locations.push(0);
      }

      if (!_.intersection(this.filterObject.locations, uObj.locations).length) {
        matched = false;
      }
    }

    // shift
    if (!_.isEmpty(this.filterObject.shift)) {
      if (!_.includes(this.filterObject.shift, uObj.shift)) {
        matched = false;
      }
    }

    // teams
    if (this.filterObject.groups && this.filterObject.groups.length) {
      if (!_.intersection(this.filterObject.groups, uObj.groups).length) {
        matched = false;
      }
    }

    // active
    if (this.filterObject.active && this.filterObject.active.length) {
      let tempVal = 'inactive';
      if (uObj.active) {
        tempVal = 'active';
      }
      if (!_.includes(this.filterObject.active, tempVal)) {
        matched = false;
      }
    }

    return matched;
  }
}
