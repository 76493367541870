import { NGXLogger } from 'ngx-logger';
import { Component, ElementRef, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

import { IObjectStringKeyMap } from '@shared/models';
import { AuthService, Feature, LoadingService, Module, ObjectsService, SubscriberService, UserdataService } from '@services';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';

enum FormUserField {
  UserName = 'username',
  Password = 'password',
  RememberMe = 'rememberMe'
}

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  public usesLoginFooter = false;
  public theFooter = '';
  public usesAuth0 = false;
  public formGroup: FormGroup;

  public brand = 'corvex';
  public isHOP = false;
  public isCorvex = true;
  public rightLogo = '';

  public preferredLanguage = 'en';
  public disableStatus = true;

  public isSubmitted = false;

  public randomBackground = ['slide-1.jpg', 'slide-2.jpg', 'slide-3.jpg', 'slide-4.jpg'];

  public defaultLogo = 'assets/images/logo_corvex.png';
  public theLogo = this.defaultLogo;
  public defaultBackground;
  public showLogo = false;
  public feature: typeof Feature = Feature;

  constructor(
    private logger: NGXLogger,
    private authService: AuthService,
    private loadingService: LoadingService,
    private alertController: AlertController,
    private router: Router,
    private userDataUser: UserdataService,
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private subscriberService: SubscriberService,
    private object: ObjectsService,
  ) {
  }

  ionViewWillEnter() {
    this.usesAuth0 = this.authService.usesAuth0();
    if (this.usesAuth0) {
      this.authService.auth0CheckAuth();
    }
    this.usesLoginFooter = this.subscriberService.usesModule(Module.LOGIN_FOOTER);
    if (this.usesLoginFooter) {
      this.theFooter = this.subscriberService.getFeature('login_footer_text');
    }
    // once it initializes we might have a logo
    const logo = this.subscriberService.getPreference('logo');
    if (logo) {
      this.theLogo = this.object.URI(logo);
      // tslint:disable-next-line: no-console
      this.logger.debug(`subscriber logo URL is ${this.theLogo}`);
      this.showLogo = true;
    } else {
      // tslint:disable-next-line: no-console
      this.logger.debug('no subscriber logo; using the default');
      this.showLogo = true;
    }
    const d = this.subscriberService.brandInfo();
    this.brand = d.brand;
    if (d.brand === 'corvex') {
      this.isCorvex = true;
      this.isHOP = false;
      this.rightLogo = d.logo;
    } else if (d.brand === 'hop') {
      this.isHOP = true;
      this.isCorvex = false;
      this.rightLogo = d.logo;
      this.randomBackground = d.backgrounds;
    } else if (d.brand === 'inteliforz') {
      this.isHOP = false;
      this.isCorvex = false;
      this.rightLogo = d.logo;
      this.randomBackground = d.backgrounds;
    }
    const r = _.toInteger((Math.random() * (d.backgrounds?.length || 1)));
    this.defaultBackground = `url(assets/images/${this.brand}/slides/${this.randomBackground[r]})`;
  }


  ionViewDidEnter() {
    const username = this.elementRef.nativeElement.querySelector(`#username`);
    const password = this.elementRef.nativeElement.querySelector(`#password`);

    if (username.value !== '') {
      this.disableStatus = false;
    }

    const x = new XMLHttpRequest();
    x.timeout = 15000;
    x.open('GET', this.defaultLogo);
    x.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          this.showLogo = true;
        } else {
          this.showLogo = false;
        }
      }
    }.bind(this);
    x.send();

  }

  ngOnInit() {
    const username = this.elementRef.nativeElement.querySelector(`#username`);
    const password = this.elementRef.nativeElement.querySelector(`#password`);

    username.addEventListener('keyup', (e) => {
      if (e.which === 32 && !username.value.length) {
        this.disableStatus = true;
        e.preventDefault();
      } else if (username.value !== '') {
        this.disableStatus = false;
      } else {
        this.disableStatus = true;
      }
    });


    const r = _.toInteger((Math.random() * 4));

    this.defaultBackground = `url(assets/brand/${this.brand}/slides/${this.randomBackground[r]})`;

    if (window.navigator.hasOwnProperty('language')) {
      this.preferredLanguage = window.navigator.language;
    }

    const userData: any = this.userDataUser.getCachedUserData();
    let userName = '';
    let userPassword = '';

    if (_.get(userData, 'Remember') === 1) {
      userName = userData.UserName;
      userPassword = userData.Password;
    }

    const formGroup: IObjectStringKeyMap<AbstractControl> = {
      [FormUserField.UserName]: new FormControl(userName, Validators.required),
      [FormUserField.Password]: new FormControl(userPassword),
      [FormUserField.RememberMe]: new FormControl(true)
    };

    this.formGroup = new FormGroup(formGroup);
  }

  public popupAuthenticate(): void {
    this.logger.debug('Starting auth0 popup');
    this.authService.auth0Login()
      .then((data) => {
        this.logger.debug(data);
        // okay the user authenticated and we have redirected back here.  now what?
        const userModel: any = {
          UserName: data.email,
          Password: '',
          remember: '',
          authToken: this.authService.auth0Token()
        };
        this.authService.handleSignin(userModel)
          .then(() => {
            this.isSubmitted = false;
            this.router.navigate(['pages/login/preparing']);
          })
          .catch((error: any) => {
            this.isSubmitted = false;
            if (error) {
              this.alertController.create({
                header: this.translateService.instant('SHARED.Whoops'),
                // tslint:disable-next-line:max-line-length
                message: this.translateService.instant('AUTH.LOGIN_ERROR_MESSAGE_AUTH0'),
                cssClass: 'custom-alert',
                buttons: [{text: this.translateService.instant('SHARED.Try_Again')}]
              }).then((alert: any) => {
                alert.present();
                setTimeout(() => {
                  alert.dismiss();
                }, 5000);
              });
            }
          });
      })
      .catch((err) => {
        this.alertController.create({
          header: this.translateService.instant('SHARED.Whoops'),
          // tslint:disable-next-line:max-line-length
          message: this.translateService.instant('AUTH.LOGIN_ERROR_MESSAGE'),
          cssClass: 'custom-alert',
          buttons: [{text: this.translateService.instant('SHARED.Try_Again')}]
        }).then((alert: any) => {
          alert.present();
          setTimeout(() => {
            alert.dismiss();
          }, 5000);
        });
      });
  }

  public onSubmit(): void {
    this.isSubmitted = true;
    const userModel: any = {
      UserName: this.formGroup.value[FormUserField.UserName],
      Password: this.formGroup.value[FormUserField.Password],
      remember: this.formGroup.value[FormUserField.RememberMe]
    };

    if (this.formGroup.valid) {
      this.authService.handleSignin(userModel).then(() => {
        this.isSubmitted = false;
        this.router.navigate(['pages/login/preparing']);
      }).catch((error: any) => {
        this.isSubmitted = false;
        if (error) {
          this.alertController.create({
            header: this.translateService.instant('SHARED.Whoops'),
            // tslint:disable-next-line:max-line-length
            message: this.translateService.instant('AUTH.LOGIN_ERROR_MESSAGE'),
            cssClass: 'custom-alert',
            buttons: [{text: this.translateService.instant('SHARED.Try_Again')}]
          }).then((alert: any) => {
            alert.present();
            setTimeout(() => {
              alert.dismiss();
            }, 5000);
          });
        }
      });
    }
  }

  public isInvalid(key: string): boolean {
    return this.isSubmitted && this.formGroup.controls[key]
      && this.formGroup.controls[key].errors && (<any>this.formGroup).controls[key].errors.required;
  }

  public goToForgotPassword() {
    this.router.navigate(['pages/login/forgot-password']);
  }

}
