<div class="card-user">
  <img [src]="avatarUrl">

  <div class="card-details">
    <div class="card-details-header">
      <span class="card-user-name">{{ name }}</span>
      <span *ngIf="time" class="card-date"> | {{ time }}</span>
    </div>
    <div>{{ text }}</div>
  </div>
</div>
