import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CheckDetailService } from '@services/checkDetail/check-detail.service';
import { SettingsService, LoadingService, CheckResponseService, UtilsService } from '@services';
import { ObservationTableService } from '@services/observationTableService/observation-table-service.service';
import { CheckTooltipComponent } from '@modules/observation/pages/supervisor-dashboard/components/check-tooltip/check-tooltip.component';
import { Events } from '@services/events/events.service';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-check-type-table',
  templateUrl: './check-type-table.page.html',
  styleUrls: ['./check-type-table.page.scss'],
})
export class CheckTypeTablePage implements OnInit {

  @ViewChild(CheckTooltipComponent) statusTooltipElement: CheckTooltipComponent;
  public typeSelectConfig: any = {
    data: [],
    options: {
      minimumResultsForSearch: Infinity,
      sorter: (data) => data,
      closeOnSelect: true
    },
    value: null
  };
  public filterConfig: any = {
    title: 'SHARED.Check_Results',
    selectConfig: {
      label: this.translate.instant('SHARED.Timespan'),
      config: {
        data: [
          {id: 'day', text: 'SHARED.Today'},
          {id: 'yesterday', text: 'SHARED.Yesterday'},
          {id: 'week', text: 'SHARED.This_Week'},
          {id: 'month', text: 'SHARED.This_Month'},
          {id: 'quarter', text: 'SHARED.This_Quarter'},
          {id: '30days', text: 'SHARED.30_days'},
          {id: '60days', text: 'SHARED.60_days'},
          {id: '90days', text: 'SHARED.90_days'}
        ],
        value: this.checkDetailService.statusPageTimeRange.id,
        options: {
          minimumResultsForSearch: Infinity,
          sorter: (data) => data,
          closeOnSelect: true
        }
      }
    }
  };
  public colorLegend = [
    {name: 'SHARED.No_Problems', class: 'success-block'},
    {name: 'MGMT_DETAILS.Issues_Action_Small', class: 'danger-block'},
    {name: 'MGMT_DETAILS.Unable_To_Perform', class: 'light-shade-block'},
    {name: 'SHARED.Missed', class: 'dark-shade-block'}
  ];
  private currentFilter: any;
  private currentTeam: any;
  private checkID: number;
  private responses = [];

  constructor(
    private utils: UtilsService,
    private checkDetailService: CheckDetailService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private observationTableService: ObservationTableService,
    private loadingService: LoadingService,
    private events: Events,
    private checkResponseService: CheckResponseService
  ) {
  }

  ngOnInit() {
    this.checkID = +this.activatedRoute.snapshot.paramMap.get('id');
  }

  async ionViewDidEnter() {
    this.filterConfig.selectConfig.config.value = this.checkDetailService.statusPageTimeRange.id;
    this.currentFilter = this.checkDetailService.statusPageTimeRange.currentFilter;

    if (!this.currentTeam) {
      this.currentTeam = this.checkDetailService.statusPageTimeRange.currentTeam;
    }

    if (!this.filterConfig.selectConfig.config.value) {
      if (this.checkDetailService.statusPageTimeRange.id) {
        this.filterConfig.selectConfig.config.value = this.checkDetailService.statusPageTimeRange.id;
      } else {
        this.applyTimeFilterPeriodByType('day');
        this.filterConfig.selectConfig.config.value = this.checkDetailService.statusPageTimeRange.id;
      }
    }

    await this.getAllCheckResponses();
    await this.buildCheckStatusGrid();
    await this.buildCheckTypeDropDown();
    this.loadingService.disable();
  }

  ionViewWillLeave() {
    this.events.unsubscribe('ccs:checkResponseUpdate', this.checkObservationListener);
  }

  ionViewWillEnter() {
    this.events.subscribe('ccs:checkResponseUpdate', this.checkObservationListener);
  }

  public superTaskBack() {
    this.filterConfig.selectConfig.config.value = null; // clear out drop down
    this.router.navigate([`pages/dashboard/supervisor-dashboard`]);
  }

  public async onPeriodChange(periodOption) {
    if (periodOption.data.length && periodOption.data[0].selected) {
      await this.loadingService.enable();

      this.applyTimeFilterPeriodByType(periodOption.data[0].id);
      await this.getAllCheckResponses();
      await this.buildCheckStatusGrid();
      await this.buildCheckTypeDropDown(this.checkID);

      setTimeout(() => {
        $('.drop-select select').val(this.checkID).trigger('change.select2');
      }, 100);
      this.loadingService.disable();
    }
  }

  public async onTypeChange(event) {
    if (_.get(event, 'data[0].selected') && this.checkID !== +event.data[0].id) {
      await this.loadingService.enable();
      this.checkID = +event.data[0].id;
      await this.getAllCheckResponses();
      await this.buildCheckStatusGrid();
      this.loadingService.disable();
    }
  }

  private checkObservationListener = async () => {
    await this.loadingService.enable();
    await this.getAllCheckResponses();
    await this.buildCheckStatusGrid();
    this.loadingService.disable();
  };

  private async buildCheckStatusGrid() {
    const tableId = 'type_result_table';
    const offset: number = _.get($(`#${tableId}`).closest('.table-container').offset(), 'top') || 0;
    const scrollY: number = $(window).height() - offset - 150;
    const gridData = await this.checkDetailService.getCheckTypeProgress(this.currentFilter, this.currentTeam, this.checkID, this.responses);
    // let's capture targets for drop-down menu for next page
    this.checkDetailService.checkResultTableTargetItems = _.map(gridData, 'targetSignature');
    const dataColumn = this.checkDetailService.buildColumnCheckResult(true);

    if ($(`#${tableId} thead`).length) {
      const table: any = $(`#${tableId}`).DataTable();
      table.clear().rows.add(gridData);
      setTimeout(() => {
        table.columns.adjust().draw(false);
      });
      return;
    }


    // build that table
    this.observationTableService.initDataTable(`#${tableId}`, gridData, {id: tableId}, '', {
      stateSave: true,
      paging: true,
      searching: false,
      buttons: [],
      // scrollY: '275px',
      scrollY: `${scrollY}px`,
      columns: dataColumn,
      info: false,
      deferRender: true,
      scroller: {
        displayBuffer: 30,
      },
      columnDefs: [{
        visible: false,
        targets: []
      }],
      language: {
        emptyTable: this.translate.instant('SHARED.No_Results'),
        sZeroRecords: this.translate.instant('SHARED.sZeroRecords')
      },
      drawCallback: () => {
        $('.newInnerClass').off().on('mouseover', (event) => {
          this.createTooltip(event);
        });
      },
      onClick: async (observation) => {
        //  this.checkDetailService.statusPageTimeRange.id = this.getStoreDataByKey('sort');
        this.checkDetailService.statusPageTimeRange.currentTeam = this.currentTeam; // to filter stuff inside check result table
        await this.loadingService.enable();
        this.router.navigate([`pages/dashboard/check-result-table/${observation.id}/${observation.targetSignature}`]);
      }
    }, true);
  }

  /**
   *Build checktype menu if those checktype has data in given time frame.
   */
  private async buildCheckTypeDropDown(checkID?: any) {
    const validCheckTypes: any = await this.checkDetailService.notEmptyCheckDropDown(this.currentFilter, this.currentTeam, this.responses);
    const activeChecks: any[] = _.reject(this.settingsService.getSettingSync('checkType'), 'disabledAt');
    if (checkID) {
      this.typeSelectConfig.value = +checkID;
      const checkObj: any = _.filter(activeChecks, {messageID: checkID});
      const menuObj = {
        id: checkObj[0].messageID,
        text: checkObj[0].messageTitle
      };
      //check if this is included in drop-down or not
      if (!_.find(validCheckTypes, {id: checkID})) {
        validCheckTypes.push(menuObj);
      }
    } else {
      this.typeSelectConfig.value = +this.checkID;
    }
    this.typeSelectConfig.data = validCheckTypes;
  }

  private applyTimeFilterPeriodByType(type): void {
    this.checkDetailService.statusPageTimeRange.id = type;
    const times = this.utils.timespan(type);
    if (type !== 'yesterday') {
      this.currentFilter.startTime = times.startTime;
      this.currentFilter.endTime = null;
    } else if (type === 'yesterday') {
      this.currentFilter.startTime = times.startTime;
      this.currentFilter.endTime = times.endTime;
    } else {
      this.currentFilter = _.omit(this.currentFilter, ['startTime', 'endTime']);
    }
  }

  private createTooltip(event) {
    const targetElement = $(event.target);

    targetElement.off('mousemove').on('mousemove', (event) => {
      this.statusTooltipElement.setPosition(event);
    });
    targetElement.off('mouseout').on('mouseout', () => {
      this.hideToolTip(event.target);
    });

    this.statusTooltipElement.show(event);
  }

  private hideToolTip(target?: JQuery<HTMLElement>) {
    this.statusTooltipElement.hide();

    if (target) {
      $(target).off('mouseout').off('mousemove');
    }
  }

  private async getAllCheckResponses() {
    this.responses = await this.checkResponseService.getAllCheckResponses(this.currentFilter, this.currentTeam);
  }
}
