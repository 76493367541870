import { NGXLogger } from 'ngx-logger';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ObservationService, SubscriberService } from '@services';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-quality-fields',
  templateUrl: './quality-fields.component.html',
  styleUrls: ['./quality-fields.component.scss'],
})
export class QualityFieldsComponent implements OnInit {

  public observationId: string;
  public nextActivated = true;
  public dataArray: any = [];
  public customQualityField: any = {
    data: null
  };
  private observation: any;
  private defaultFields: any = {
    addButton: true,
    data: [{
      type: 'barcode',
      required: true,
      activateNext: false,
      name: this.translate.instant('SHARED.Job_Number'),
      value: null,
      canAdd: false,
    },
      {
        type: 'barcode',
        required: false,
        activateNext: false,
        name: this.translate.instant('SHARED.Part_Number'),
        value: null,
        canAdd: true
      }]
  };
  private observationType: string;

  constructor(
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private observationsService: ObservationService,
    private subscriber: SubscriberService,
    private translate: TranslateService
  ) {
  }

  @Input('observationType')
  set reportData(type: string) {
    if (type) {
      this.observationType = type;
      this.ngOnInit();
      this.modelCheck();
    }
  }

  ngOnInit() {
    this.observationId = this.route.snapshot.paramMap.get('id');
    this.observation = this.observationsService.observations.data[this.observationId];
    let tempInfo: any = {};
    if (this.observation.value) {
      tempInfo = JSON.parse(this.observation.value);
    }
    //  this.logger.log('available value', tempInfo);
    // need to clean null values
    const jobInfo = _.filter(tempInfo, data => {
      if (data && data.uuid) {
        return data;
      }
    });

    const obsSubtype = this.observationType || this.observationsService.getProperty(this.observation, 'subtype');

    const fieldType = (obsSubtype === 'production' ? 'qualityProductionFields' : 'qualityReceivingFields');

    // get form settings
    const res = this.subscriber.getPreference(fieldType);
    let tempField: any = null;
    if (res && res.hasOwnProperty('addButton') && res.hasOwnProperty('data')) {
      tempField = _.cloneDeep(res);
    } else {
      tempField = _.cloneDeep(this.defaultFields);
    }

    this.customQualityField.addButton = tempField.addButton;

    // prepare data set with respect to observation values.
    const customTempDataSet: any = [];

    // remove duplicated addButtons
    const duplicateAddButtons: any = {};
    _.each(jobInfo, (obj: any) => {
      if (!obj) {
        return;
      }
      const formElement: any = _.find(tempField.data, ['uuid', obj.uuid]);
      if (!formElement) {
        return;
      }
      const tempClone = _.cloneDeep(formElement);
      tempClone.value = obj.value;
      tempClone.needsLabel = true;

      if (!duplicateAddButtons[obj.uuid]) {
        duplicateAddButtons[obj.uuid] = 1;
      } else {
        duplicateAddButtons[obj.uuid]++;
      }
      if (duplicateAddButtons[obj.uuid] > 1) {
        // this means there are multiple form fields for same UUID
        const duplicationArray = _.filter(customTempDataSet, ['uuid', obj.uuid]);
        _.each(duplicationArray, (dupObj, ind) => {
          dupObj.canAdd = false;
        });
        tempClone.duplicate = true;
        tempClone.needsLabel = false;
      }

      customTempDataSet.push(tempClone);
    });

    // need to make sure that default numbers are there
    if (customTempDataSet.length < tempField.data.length) {
      // need to see what is missing.
      _.each(tempField.data, fields => {
        const missingItem = _.filter(customTempDataSet, ['uuid', fields.uuid]);
        if (missingItem.length === 0) {
          fields.needsLabel = true;
          customTempDataSet.push(fields);
        }
      });
    }


    // if jobInfo is null, then let's assing what we got as default field
    if (!_.isEmpty(customTempDataSet)) {
      this.customQualityField.data = customTempDataSet;
    } else {
      _.each(tempField.data, data => {
        data.needsLabel = true;
      });
      this.customQualityField.data = tempField.data;
    }
    // pre-assign data for saving later
    this.dataArray = _.cloneDeep(this.customQualityField.data);
  }

  addMore(ev) {
    // figure out which one we are extending
    const ref = $(ev.currentTarget);
    const idx = ref.attr('data-index');

    // insert it into the datastructure, adding to the end of the current item
    const n = [];
    let c = 0;
    _.each(this.customQualityField.data, (ref, i) => {
      n.push(_.cloneDeep(ref));
      if (idx === String(i)) {
        // this is the one we are adding
        n[c].canAdd = false;
        // add in another one
        const another = _.cloneDeep(ref);
        another.value = '';
        another.needsLabel = false;
        n.push(another);
        c++;
      }
      c++;
    });
    this.customQualityField.data = n;
  }

  modelCheck() {
    let activated = true;
    _.each(this.customQualityField.data, val => {
      if (!val.repeated && !val.duplicate) { // ignore repeated values
        if (val.required && !val.value) {
          activated = false;
        }
      }
    });
    this.nextActivated = activated;
  }

}
