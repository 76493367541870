<div class="parent-list">
  <ion-item (click)="openDetailPage(contentItem.objectID)" *ngFor="let contentItem of parentContentItems | orderBy: currentOrderByObject.parentOrder"
            lines="full">
    <img [src]="contentItem.iconName">
    <div class="description">{{ contentItem | translateBy: 'description' }}</div>
  </ion-item>
</div>

<div class="form-divider-title headline-small">{{ 'MGMT_DETAILS.Attached_Content' | translate }}</div>

<div *ngIf="prompt && !contentItems.length" class='text-center'>{{ prompt }}</div>

<ion-reorder-group (ionItemReorder)="doReorder($event)" [disabled]="!draggable">
  <div
    *ngFor="let contentItem of (currentOrderByObject.order?.length ? (contentItems | orderBy: currentOrderByObject.order) : contentItems); let index = index">
    <ion-item (click)="openDetailPage(contentItem.objectID)" lines="full">
      <ion-item class="check-box-item" lines="none">
        <ion-checkbox (click)="$event.stopPropagation()" (ionChange)="toggleSelection()" [(ngModel)]="contentItem.selected"
                      slot="start"></ion-checkbox>
      </ion-item>
      <img [src]="contentItem.iconName">
      <div class="description">{{ contentItem | translateBy: 'description' }}</div>
      <ion-button (click)="removeById(contentItem.objectID)" *ngIf="!draggable" class="page-button" color="secondary"
                  slot="end">{{ "SHARED.Remove" | translate }}</ion-button>
      <ion-reorder slot="end">
        <div class="reorder-icon"></div>
      </ion-reorder>
    </ion-item>
  </div>
</ion-reorder-group>

<div class="add-block">
  <ion-button (click)="addContent()" class="page-button"
              color="secondary">{{ "MGMT_DETAILS.Select_Add_Content" | translate }}</ion-button>
</div>

<ion-footer *ngIf="panelConfig.count" no-border>
  <app-removal-panel (onRemove)="onRemove()" [removeButtonTitle]="panelConfig.removeTitle" [subTitle]="panelConfig.subTitle"
                     [title]="panelConfig.title"></app-removal-panel>
</ion-footer>
