import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import {
  ObservationTitleComponent,
  DashboardHeaderComponent,
  FilterComponent,
  QualityFieldsComponent,
  RiskWidgetComponent,
  RiskProfileComponent,
  DetailRiskProfileComponent,
  DetailControlsComponent,
  DetailHeaderComponent,
  ObservationHistoryComponent,
  ObservationCardComponent,
  TagListComponent,
  DetailAddressControlsComponent
} from '@modules/observation/components';

import { Select2Module } from '@shared/components/ng2-select2/ng2-select2.module';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { SharedModule } from '@shared/shared.module';
import { ObservationImageGalleryComponent } from '@modules/observation/components/image-gallery/image-gallery.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { NgSelectModule } from '@ng-select/ng-select';

const COMPONENTS = [
  ObservationTitleComponent,
  DashboardHeaderComponent,
  FilterComponent,
  QualityFieldsComponent,
  RiskWidgetComponent,
  RiskProfileComponent,
  DetailRiskProfileComponent,
  DetailControlsComponent,
  DetailHeaderComponent,
  ObservationImageGalleryComponent,
  ObservationHistoryComponent,
  ObservationCardComponent,
  TagListComponent,
  DetailAddressControlsComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    ...COMPONENTS
  ],
  imports: [
    IonicModule,
    Select2Module,
    CommonModule,
    FormsModule,
    MarkdownModule.forChild(),
    TranslateModule.forChild(),
    SharedModule,
    SlickCarouselModule,
    DeferLoadModule,
    NgSelectModule
  ]
})
export class ObservationComponentsModule {
}
