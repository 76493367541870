import { Component, Input, OnInit } from '@angular/core';
import { UserdataService } from '@services';

import * as _ from 'lodash';

@Component({
  selector: 'app-account-can-view-except',
  templateUrl: './account-can-view-except.component.html',
  styleUrls: ['./account-can-view-except.component.scss'],
})
export class AccountCanViewExceptComponent implements OnInit {

  @Input() canViewExcept: string[] = [];

  public isCanView: boolean;

  constructor(private userDataService: UserdataService) {
  }

  ngOnInit(): void {
    this.isCanView = !_.includes(this.canViewExcept, this.userDataService.type);
  }

}
