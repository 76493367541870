<ion-header no-border>
  <app-close-button (onClicked)="superTaskBack()"></app-close-button>
</ion-header>
<ion-content>
  <div class="header-content">
    <div class="headline-large">
      {{"SHARED.Check_Type" | translate}}
    </div>
    <div class="filter-block left-spacer drop-select">
      <select2
        (valueChanged)="onTypeChange($event)"
        [data]="typeSelectConfig.data | selectLangPipe"
        [options]="typeSelectConfig.options"
        [value]="typeSelectConfig.value"></select2>
    </div>
  </div>
  <!-- time range-->
  <div class="filter-block float-right">
    <span class="descriptor upcase">{{filterConfig.selectConfig.label  | translate}} &nbsp;</span>
    <select2
      (valueChanged)="onPeriodChange($event)"
      [data]="filterConfig.selectConfig.config.data | selectLangPipe"
      [options]="filterConfig.selectConfig.config.options"
      [value]="filterConfig.selectConfig.config.value"></select2>
  </div>
  <!-- time range end-->

  <div class="table-container">
    <table class="display" id="type_result_table"></table>
    <!-- <div class="legend-div">
    <ion-grid class="legend-container">
      <ion-row>
        <ion-col class="col-flex"  size-md="3" *ngFor = "let legend of colorLegend" >
          <div [ngClass]="legend.class"></div><div class='legend-text'>{{legend.name | translate}}</div>
          </ion-col>
        </ion-row>
      </ion-grid>
      </div> -->
  </div>

</ion-content>

<app-check-tooltip></app-check-tooltip>
