import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { CommsService } from '@services/comms/comms.service';
import { Events } from '@services/events/events.service';
import { BaseService } from './../abstract-base-service/abstract-base-service.service';
import * as _ from 'lodash';
import { ObservationService } from '@services/observations/observation.service';
import { PoolService } from '@services/pool/pool.service';
import { FeedbackService } from '@services/feedback/feedback.service';
import { awaitHandler } from '@utils/awaitHandler';
import { DataTableNavigationService } from '@services/data-table-navigation/data-table-navigation.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';

export enum CollectionType {
  Topic = 'topic',
  LearningTeam = 'learningTeam',
  WatchList = 'watchList'
}

export enum CollectionItemType {
  Observation = 'observation',
  Message = 'message',
  ProblemStatement = 'problemStatement',
  Feedback = 'feedback',
  Reply = 'reply',
  External = 'pool',
  ExternalItem = 'poolItem'
}

export interface ICollectionItemData {
  itemID?: number;
  item: string;
  source: CollectionItemType;
  type: string;   // the type within the source
  state: string;  // the state of the item
  zone: number;
  notes: string[] | number[] | string | number;
  locationID: number;
  groupID: number;
  addedBy?: number;
  addedAt?: number;
  lastUpdate?: number;
  reference?: string;
  tagIDs: number[];
  value?: string | number;
  attachments?: number[];
  uuid?: string;
  created?: number;
  id?: string;
  uniqueID?: string;
}

export interface ICollectionItem {
  itemID?: number;
  collectionID?: number;
  type: CollectionItemType;
  item: string;
  addedBy?: number;
  addedAt?: number;
  lastUpdate?: number;
  disabledAt?: number;
  disabledBy?: number;
  value?: string | number;
  translations?: any;
}

export interface ICollectionHistory {
  activity: string;
  notes: string;
  time: number;
  userID: number;
}

export interface ICollectionInfo {
  active: number;
  collectionID: number;
  type: CollectionType;
  folderID: number;
  title: string;
  description: string;
  items: ICollectionItem[];
  locations: number[];
  nodeSelections: string[];
  translations: any;
  votes: any;
  creator: number;
  created: number;
  lastUpdate: number;
  disabledAt: number;
  disabledBy: number;
  state: string;
  itemOrder?: number[];
  history: ICollectionHistory[];
  permissions?: string[];
  teams?: string[];
}

export interface ICollectionData {
  [key: number]: ICollectionInfo;
}

interface ICollections {
  data: ICollectionData;
  lastRequest: number;
}

export type CollectionItemKey = `${CollectionItemType}:${number}`;

@Injectable({
  providedIn: 'root'
})
export class CollectionsService extends BaseService {

  public topicFilterObject: any = {};
  public teamsFilterObject: any = {};
  /**
   * Use this to save list of items from explore page which will be forwarded to backend once collectionID is established.
   *
   * @type {ICollectionItem[]}
   * @memberof CollectionsService
   */
  public itemCollection: ICollectionItem[];

  public collections: ICollections = {
    data: {},
    lastRequest: 0
  };

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private events: Events,
    private observations: ObservationService,
    private pools: PoolService,
    private feedback: FeedbackService,
    private dataTableNavigationService: DataTableNavigationService,
    private router: Router,
    private translate: TranslateService
  ) {
    super(commsService);
  }


  public refresh(updated: Number = 0): Promise<any> {
    if (updated && updated < this.collections.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.collections.lastRequest}`);
      return Promise.resolve(this.collections.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.commsService.sendMessage({
          cmd: 'getCollections',
          includeItems: 1,
          lastRequest: this.collections.lastRequest,
          sendTime: when
        }, false, false).then(data => {
          if (data && data.reqStatus === 'OK') {
            this.updateCache(data);
          }
          resolve(this.collections.data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public getTopicList(): any {
    const r = [];
    _.each(this.collections.data, uObj => {
      r.push({id: uObj.collectionID, description: uObj.title});
    });
    return r;
  }

  public buildTopicList(): any {
    const r = [];
    const types = [CollectionType.Topic, CollectionType.LearningTeam, CollectionType.WatchList];
    _.map(_.pick(_.groupBy(this.collections.data, 'type'), types), (data, type) => {
      let key = '';
      if (type === 'topic') {
        key = this.translate.instant('SHARED.Learning_Topics');
      } else if (type === 'learningTeam') {
        key = this.translate.instant('SHARED.Learning_Teams');
      } else {
        key = type;
      }
      const children = [];
      _.each(data, uObj => {
        if (type !== CollectionType.LearningTeam || (type === CollectionType.LearningTeam && uObj.state !== 'improved')) {
          const childObj = {
            id: uObj.collectionID,
            text: uObj.title
          };
          children.push(childObj);
        }
      });
      if (key) {
        r.push({
          text: key,
          children,
          id: key
        });
      }
    });
    return r;
  }

  public filterData(data): any {
    if (_.isEmpty(this.topicFilterObject)) {
      return data;
    } else {
      const r = [];
      _.each(data, uObj => {
        if (this.checkFilter(uObj)) {
          r.push(uObj);
        }
      });
      return r;
    }
  }

  public updateCache(data: any): void {
    const collectionData = _.orderBy(data.result.collections, [element => element.title.toLowerCase()], ['asc']);
    this.collections.lastRequest = data.result.timestamp;
    this.collections.data = collectionData;
    this.events.publish('ccs:collectionsUpdate');
  }

  public clearCache(): void {
    this.collections.lastRequest = null;
    this.collections.data = null;
  }

  public handleAddCollection(fData): any {
    fData.cmd = 'addCollection';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  public handleUpdateCollection(fData): any {
    fData.cmd = 'updateCollection';
    fData.sendTime = Date.now();
    return this.handleRequest(fData);
  }

  handleDeleteCollection(fData): any {
    fData.cmd = 'deleteCollection';
    return this.handleRequest(fData);
  }

  public handleTagItems(items: string[], tags: number[]): Promise<any> {
    return new Promise((resolve, reject) => {
      // cut the items up into buckets
      const buckets = {};
      _.each(items, theItem => {
        const l = _.split(theItem, ':');
        if (!_.has(buckets, l[0])) {
          buckets[l[0]] = [];
        }
        buckets[l[0]].push(l[1]);
      });

      const p = [];
      // queue updates for all the requests
      _.each(buckets, (theItems, theType) => {
        // queue up the promise for each type
        if (theType === CollectionItemType.Observation) {
          p.push(this.handleAddObservationTags({tagIDs: tags, observations: theItems}));
        }
        if (theType === CollectionItemType.Feedback) {
          p.push(this.feedback.handleTagRequests(theItems, tags));
        }
        if (theType === CollectionItemType.ExternalItem || theType === CollectionItemType.External) {
          p.push(this.pools.handleTagItems({tags, items: theItems}));
        }
      });

      // wait for all to resolve, then resolve
      Promise.all(p).then(res => {
        resolve({
          reqStatus: 'OK',
          reqStatusText: 'All promises resolved'
        });
      }).catch(err => {
        this.logger.error('There was some error tagging');
        resolve({
          reqStatus: 'ERROR',
          reqStatusText: err.toString
        });
      });
    });
  }

  public removeCollection(collectionID: number): Promise<any> {
    const cmd = {cmd: 'deleteCollection', collectionID};
    return this.handleRequest(cmd);
  }

  public addItemsToCollection(items: ICollectionItem[], collectionID: number): Promise<any> {
    const cmd = {
      cmd: 'addCollectionItems',
      items: JSON.stringify(items),
      collectionID
    };
    return this.handleRequest(cmd);
  }

  public getCollectionsByType(type: CollectionType, locations?: number[]): ICollectionData {
    const ret = _.filter(this.collections.data, {type});
    if (locations && locations.length) {
      return this.getCollectionsByLocation(locations, ret);
    } else {
      return ret;
    }
  }

  public getCollectionsByLocation(locationIds: number[] = [], theList = this.collections.data): ICollectionData {
    if (locationIds.length) {
      return _.filter(theList, (item) => {
        if (item.locations.length === 0) {
          return true;
        } else {
          return !!_.intersection(_.map(item.locations, Number), locationIds).length;
        }
      });
    } else {
      return theList;
    }
  }

  public getSortData(type) {
    if (type === 'NewestTopic') {
      return _.orderBy(this.collections.data, ['created'], ['desc']);
    } else if (type === 'OldestTopic') {
      return _.orderBy(this.collections.data, ['created'], ['asc']);
    } else if (type === 'RecentActivity') {
      return _.orderBy(this.collections.data, ['lastUpdate'], ['desc']);
    } else {
      return _.orderBy(this.collections.data, [element => element.title.toLowerCase()], ['asc']);
    }
  }

  public async getCollectionSelectList(collectionID: number, keys: string[]) {
    const itemObjects = await this.buildItemObjects(collectionID);

    return _.filter(_.map(keys, (key) => _.find(itemObjects, {id: key})));
  }

  handleDeleteCollectionItems(fData) {
    fData.cmd = 'deleteCollectionItems';
    return this.handleRequest(fData);
  }

  public handleAddObservationTags(fData) {
    fData.cmd = 'tagObservations';
    return this.handleRequest(fData);
  }

  public updateItemsToCollection(fData) {
    fData.cmd = 'updateCollectionItems';
    return this.handleRequest(fData);
  }

  public getCollection(id: number): ICollectionInfo {
    return _.find(this.collections.data, {collectionID: id});
  }

  public getVoteList(collectionID: any) {
    let votes: any = [];
    const data = _.find(this.collections.data, {collectionID: Number(collectionID)}).votes;
    votes = data.yes;
    votes = votes.concat(data.no);
    return votes;
  }

  /**
   *
   * @param collection - a collectionID or a reference to a list of ICollectionItem objects
   * @returns a promise that resolves to a list of ICollectionItemData objects with sufficient information to display a list of collection items.
   */
  public async buildItemObjects(collection: number | ICollectionItem[]): Promise<ICollectionItemData[]> {

    const ret: ICollectionItemData[] = [];
    const colItems: ICollectionItem[] = _.isNumber(collection) ? this.getCollection(+collection)?.items : collection as ICollectionItem[];
    if (!colItems) {
      return ret;
    }

    // tease out a list of each type in the collection
    const buckets = {};
    _.each(colItems, item => {
      const t = item.type;
      const id = item.item;
      if (!_.has(buckets, t)) {
        buckets[t] = [];
      }
      buckets[t].push(id);
    });

    // request that data

    if (_.has(buckets, CollectionItemType.ProblemStatement)) {
      // this data is local
      _.each(buckets[CollectionItemType.ProblemStatement], id => {
        const p = _.find(colItems, {item: id});

        const ref = p as ICollectionItem;
        if (ref) {
          ret.push({
            source: CollectionItemType.ProblemStatement,
            type: CollectionItemType.ProblemStatement,
            state: 'active',
            item: `${id}`,
            zone: 0,
            locationID: 0,
            notes: ref.value as string,
            groupID: 0,
            addedAt: ref.addedAt,
            addedBy: ref.addedBy,
            created: ref.addedAt,
            lastUpdate: ref.lastUpdate,
            id: CollectionItemType.ProblemStatement + ':' + id,
            tagIDs: []
          });

          // for "when" for poolIem

        }
      });
    }
    if (_.has(buckets, CollectionItemType.Observation)) {
      const items = this.observations.getCollectionData(buckets[CollectionItemType.Observation]);
      ret.push(...items);
    }
    if (_.has(buckets, CollectionItemType.Feedback)) {
      const items = this.feedback.getRequestCollectionData(buckets[CollectionItemType.Feedback]);
      ret.push(...items);
    }
    if (_.has(buckets, CollectionItemType.Reply)) {
      const items = this.feedback.getReplyCollectionData(buckets[CollectionItemType.Reply]);
      ret.push(...items);
    }
    if (_.has(buckets, CollectionItemType.External)) {
      const [items] = await awaitHandler(this.pools.getCollectionData(buckets[CollectionItemType.External]));
      ret.push(...items);
    }
    if (_.has(buckets, CollectionItemType.ExternalItem)) {
      const [items] = await awaitHandler(this.pools.getCollectionData(buckets[CollectionItemType.ExternalItem]));
      ret.push(...items);
    }

    return ret;
  }

  public openDetailPageByItem(item: ICollectionItemData, collectionId?: number, collectionItemKey?: CollectionItemKey) {
    const collectionItemType: string = collectionItemKey ? _.split(collectionItemKey, ':')[0] : null;
    const url = this.getDetailUrlByItem(item);
    const queryParams = {
      queryParams: {
        headerIsHidden: true,
        tableNav: true,
        collectionId,
        collectionItemType
      }
    };
    this.dataTableNavigationService.lastActiveUrl = this.router.url;

    if (url) {
      this.router.navigate([url], queryParams);
    }
  }

  public async getFooterData(collectionItemId: number, collectionId?: number, collectionItemType?: string) {
    const previousItemConfig = {url: null, qparam: null};
    const nextItemConfig = {url: null, qparam: null};

    if (collectionId) {
      const queryParam = {headerIsHidden: true, collectionId};
      const currentIds: string[] = _.map(this.dataTableNavigationService.currentIdsList, String);
      const itemObjects = _.map(await this.buildItemObjects(+collectionId), (object) => {
        object.item = object.item.toString();
        return object;
      });
      const currentCollectionItem = _.find(itemObjects, {item: collectionItemId.toString()});
      const relatedItems = _.filter(_.map(currentIds, (id) => _.find(itemObjects, {id})));

      const nextId: string = <string>this.dataTableNavigationService.getNextId(currentCollectionItem?.id, currentIds);
      const previousId: string = <string>this.dataTableNavigationService.getPreviousId(currentCollectionItem?.id, currentIds);

      const nextItem = _.find(relatedItems, {id: nextId});
      const previousItem = _.find(relatedItems, {id: previousId});

      previousItemConfig.url = this.getDetailUrlByItem(previousItem);
      previousItemConfig.qparam = queryParam;
      nextItemConfig.url = this.getDetailUrlByItem(nextItem);
      nextItemConfig.qparam = queryParam;
    } else if (collectionItemType) {
      const queryParam = {headerIsHidden: true};
      const currentIds: string[] = this.dataTableNavigationService.currentIdsList;
      const collectionItemKey = `${collectionItemType}:${collectionItemId}`;
      const getCollectionItemDataBy = (collectionKey: string) => {
        if (collectionKey) {
          const [type, id] = _.split(collectionKey, ':');
          let uuid: string;

          if (type === CollectionItemType.Observation) {
            uuid = this.observations.getObservationById(+id)?.uuid;
          }

          return this.getDetailUrlByItem(<ICollectionItemData>{source: type, item: id, uuid});
        } else {
          return null;
        }
      };

      const nextCollectionItemKey = <string>this.dataTableNavigationService.getNextId(collectionItemKey, currentIds);
      const previousCollectionItemKey = <string>this.dataTableNavigationService.getPreviousId(collectionItemKey, currentIds);

      previousItemConfig.url = getCollectionItemDataBy(previousCollectionItemKey);
      previousItemConfig.qparam = _.assign({}, queryParam, {
        collectionItemType: _.split(previousCollectionItemKey, ':')[0]
      });

      nextItemConfig.url = getCollectionItemDataBy(nextCollectionItemKey);
      nextItemConfig.qparam = _.assign({}, queryParam, {
        collectionItemType: _.split(nextCollectionItemKey, ':')[0]
      });
    }

    return {
      nav: this.getListUrlByCollectionId(collectionId),
      name: this.translate.instant('SHARED.All_Entries'),
      next: {
        disabled: _.isNull(nextItemConfig.url),
        data: nextItemConfig.url,
        qparam: nextItemConfig.qparam
      },
      prev: {
        disabled: _.isNull(previousItemConfig.url),
        data: previousItemConfig.url,
        qparam: previousItemConfig.qparam
      }
    };
  }

  public getAttachmentOrReply(attachments: any = [], row: any = {}) {

    const length = attachments?.length || 0;
    const linkTitle = length === 1 ? `${length} ${this.translate.instant('SHARED.Attachment')}` : `${length} ${this.translate.instant('SHARED.Attachments')}`;
    const cellClass = length ? 'row-link' : '';
    let linkElement = $(`<span class="${cellClass}">${linkTitle}</span>`);

    if (row.source === CollectionItemType.Feedback) {
      const fb: any = this.feedback.getFeedbackRequest(row.item);
      const replyLength = fb?.replies?.length || 0;
      let replyTitle = `${replyLength} ${this.translate.instant('SHARED.Replies')}`;
      if (replyLength === 1) {
        replyTitle = `${replyLength} ${this.translate.instant('WORK_NOTES.Reply')}`;
      }
      linkElement = $(`<span>${replyTitle}</span>`);
    }
    return linkElement;
  }

  private checkFilter(uObj) {
    let matched = true;
    //string match
    if (this.topicFilterObject.searchString) {
      let matched = false;
      if (_.includes(uObj.title, this.topicFilterObject.searchString)) {
        matched = true;
      }
      if (matched === false) {
        return;
      }
    }
    // account type
    if (this.topicFilterObject.accountType && this.topicFilterObject.accountType.length) {
      if (!_.includes(this.topicFilterObject.accountType, uObj.type)) {
        matched = false;
      }
    }
    // permission
    if (this.topicFilterObject.permissionLevel && this.topicFilterObject.permissionLevel.length) {
      let match = false;
      _.each(this.topicFilterObject.permissionLevel, level => {
        if (uObj.permissions[level]) {
          match = true;
          return false;
        }
      });
      if (!match) {
        matched = false;
      }
    }

    // location
    if (this.topicFilterObject.locations && this.topicFilterObject.locations.length) {

      // condition for check blank user location array (All Location if array is blank)
      // assumed blank Loacation as 0
      if (uObj.locations.length === 0) {
        uObj.locations.push(0);
      }
      // pushed 0 key for match All location key
      const chkfilterObject = this.topicFilterObject.locations.includes(0);
      if (!chkfilterObject) {
        this.topicFilterObject.locations.push(0);
      }

      if (!_.intersection(this.topicFilterObject.locations, uObj.locations).length) {
        matched = false;
      }
    }

    // shift
    if (!_.isEmpty(this.topicFilterObject.shift)) {
      if (!_.includes(this.topicFilterObject.shift, uObj.shift)) {
        matched = false;
      }
    }

    // teams
    if (this.topicFilterObject.groups && this.topicFilterObject.groups.length) {
      if (!_.intersection(this.topicFilterObject.groups, uObj.groups).length) {
        matched = false;
      }
    }
    return matched;
  }

  private getDetailUrlByItem(item: ICollectionItemData): string {
    let url = null;

    if (item?.source === CollectionItemType.Feedback) {
      url = `pages/dashboard/feedback/detail/${item.item}`;
    } else if (item?.source === CollectionItemType.Observation) {
      const observation = this.observations.getObservationById(+item.item);
      const detailUrl = ObservationDetailService.dataNavMap[this.observations.getProperty(observation, 'type')];
      url = `${detailUrl}${item.uuid}`;
    } else if (item?.source === CollectionItemType.External) {
      url = `/pages/dashboard/external-detail/${item.item}`;
    }

    return url;
  }

  public getSourceDataByItem(item: ICollectionItemData): any {
    if (item?.source === CollectionItemType.Feedback) {
      return this.feedback.getFeedbackById(+item.item);
    } else if (item?.source === CollectionItemType.Observation) {
      return this.observations.getObservationById(+item.item);
    }
  }

  private getListUrlByCollectionId(id?: number): string {
    const urlMap = {
      [CollectionType.LearningTeam]: '/pages/management/learning-teams/team',
      [CollectionType.Topic]: '/pages/management/topics/topic-details'
    };
    const collection = this.getCollection(id);
    const defaultUrl = '/pages/management/explore';
    const pageUrlByType = urlMap[collection?.type];

    return this.dataTableNavigationService.lastActiveUrl || (pageUrlByType ? `${pageUrlByType}/${id}` : defaultUrl);
  }
}
