import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { UtilsService } from '@services/utils/utils.service';
import { UserService } from '@services/user/user.service';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@services/events/events.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';

@Injectable({
  providedIn: 'root'
})
export class RulesService {

  public filterObject: any = {};

  public rules = {
    lastRequest: 0,
    data: []
  };

  public zones = {
    data: []
  };

  public ruleTypes = [
    {
      id: 'observation',
      description: 'SHARED.Observation',
    },
    {
      id: 'beacon',
      description: this.translate.instant('COMMONT_SERVICE.Missing_Beacon'),
    },
    /* {
      id: 'proximity',
      description: this.translate.instant('SHARED.Location_Worker_Proximity'),
      tooltip: this.translate.instant('SHARED.Location_Worker_Proximity')
    }, */
    /* {
      id: "obsdets",
      description: "Observation Detail",
      tooltip: "A report that displays information about individual observations"
    },*/
    /* {
      id: "team",
      description: "Time by Team"
    },
    {
      id: "worker",
      description: "Time Summary",
      tooltip: "A report that shows information about how time was spent"
    }
    */
  ];

  public likelihoods = [
    {
      id: 'medium',
      description: this.translate.instant('SHARED.Low'),
      class: 'severity-medium'
    },
    {
      id: 'high',
      description: this.translate.instant('SHARED.Medium'),
      class: 'severity-high'
    },
    {
      id: 'highest',
      description: this.translate.instant('SHARED.High'),
      class: 'severity-highest'
    }
  ];

  public severities = [
    {
      id: 'medium',
      description: this.translate.instant('SHARED.Low'),
      class: 'severity-medium'
    },
    {
      id: 'high',
      description: this.translate.instant('SHARED.Medium'),
      class: 'severity-high'
    },
    {
      id: 'highest',
      description: this.translate.instant('SHARED.High'),
      class: 'severity-highest'
    }
  ];

  constructor(
    private logger: NGXLogger,
    private comms: CommsService,
    private utils: UtilsService,
    private userService: UserService,
    private utilsService: UtilsService,
    protected translate: TranslateService,
    private events: Events,
    private subscriber: SubscriberService
  ) {
  }

  public getRules(updated: Number = 0) {
    if (updated && updated < this.rules.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.rules.lastRequest}`);
      return Promise.resolve(this.rules.data);
    } else {
      return new Promise((resolve, reject) => {
        const when = Date.now();
        this.comms.sendMessage({
          cmd: 'getRules',
          lastRequest: this.rules.lastRequest,
          sendTime: when
        }, false, true)
          .then((data) => {
            if (data && data.reqStatus === 'OK') {
              this.updateCache(data);
            }
            resolve(this.rules.data);
          }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public updateCache(data) {
    this.rules.lastRequest = data.result.timestamp;
    this.rules.data = data.result.rules;
    this.utils.sortArray(this.rules.data, [
      {
        name: 'name',
        function: (item) => {
          if (item && typeof item === 'string') {
            return item.toLowerCase();
          } else {
            return item;
          }
        }
      }
    ]);
    this.events.publish('ccs:rulesUpdate');
  }

  public clearCache() {
    this.rules.lastRequest = null;
    this.rules.data = null;
  }

  ruleTypeName(type: string) {
    const r = _.find(this.ruleTypes, {id: type});
    if (r) {
      return this.translate.instant(r.description);
    } else {
      return 'unknown';
    }
  }

  public ruleNameByID(ruleID) {
    let ret = null;
    $.each(this.rules.data, (i, comp) => {
      if (comp.ruleID === +ruleID) {
        ret = comp;
        return false;
      }
    });
    return this.ruleName(ret);
  }

  public ruleName(item) {
    const ret = item.name;
    return ret;
  }

  public colorDot(val): string {
    return `assets/images/${val}Dot.svg`;
  }

  handleAddRule(fData) {
    if (fData.type === 'observation') {
      fData.selectors = {
        obstype: this.utilsService.makeArray(fData.obstype),
        states: this.utilsService.makeArray(fData.states),
        hasWorkorder: (fData.hasWorkorder === undefined || fData.hasWorkorder === '') ? 0 : 1,
        locations: this.utilsService.makeArray(fData.locations, parseInt),
        zones: this.utils.makeArray(fData.zones, zone => zone),
        severities: this.utilsService.makeArray(fData.severities),
        likelihoods: this.utilsService.makeArray(fData.likelihoods),
        creators: this.utilsService.makeArray(fData.creators, parseInt),
        owners: this.utilsService.makeArray(fData.owners, parseInt),
        groups: this.utilsService.makeArray(fData.groups, parseInt),
        targetGroups: this.utilsService.makeArray(fData.targetGroups, parseInt),
        recipients: this.utilsService.makeArray(fData.recipients, parseInt)
      };
    } else if (fData.type === 'beacon') {
      fData.selectors = {
        locations: this.utilsService.makeArray(fData.locations, parseInt),
        zones: this.utils.makeArray(fData.zones, zone => zone),
      };
    } else if (fData.type === 'proximity') {
      fData.selectors = {
        locations: this.utilsService.makeArray(fData.locations, parseInt),
        zones: this.utils.makeArray(fData.zones, zone => zone),
        groups: this.utilsService.makeArray(fData.groups, parseInt),
        workers: this.utilsService.makeArray(fData.workers, parseInt)
      };
    }

    fData.interval = this.toSeconds(fData.interval, fData.interval_unit);
    fData.frequency = this.toSeconds(fData.frequency, fData.frequency_unit);
    fData.recipientSelectors = {
      targets: this.utilsService.makeArray(fData.targets),
      targetUsers: this.utilsService.makeArray(fData.targetUsers, parseInt),
      sendToGroups: this.utilsService.makeArray(fData.sendToGroups, parseInt),
      onlyActive: fData.onlyActive !== '' ? 1 : 0
    };

    if (fData.active === '') {
      fData.active = 0;
    }

    fData.cmd = 'addRule';
    fData.sendTime = Date.now();
    return this._updateAndRefresh(this.encodeRule(fData), () => this.getRules());
  }

  toSeconds(number, unit) {
    if (number === undefined || !number) {
      return 0;
    }
    if (unit === undefined) {
      return number;
    }
    if (unit === 'weeks') {
      return number * 604800;
    } else if (unit === 'days') {
      return number * 86400;
    } else if (unit === 'hours') {
      return number * 3600;
    } else if (unit === 'minutes') {
      return number * 60;
    } else {
      return number;
    }
  }

  encodeRule(theRule) {
    const r = {};
    Object.keys(theRule).forEach((key) => {
      let v;
      let o;
      // selectors is an
      if ((key === 'selectors' || key === 'recipientSelectors') && typeof theRule[key] !== 'string') {
        // preferences used to be in here too - but those are not managed on the dashboard
        // these are JSON objects that need to be a string
        v = theRule[key];
        o = {};
        if (typeof (v) === 'object') {
          if (Array.isArray(v)) {
            $.each(v, (i, item) => {
              o[item] = 1;
            });
          } else {
            o = v;
          }
        }
        r[key] = JSON.stringify(o);
      } else {
        r[key] = theRule[key];
      }
    });
    return r;
  }

  handleUpdateRule(fData) {
    if (fData.type === 'observation') {
      fData.selectors = {
        obstype: this.utilsService.makeArray(fData.obstype),
        states: this.utilsService.makeArray(fData.states),
        hasWorkorder: (fData.hasWorkorder === undefined || fData.hasWorkorder === '') ? 0 : 1,
        locations: this.utilsService.makeArray(fData.locations, parseInt),
        zones: this.utils.makeArray(fData.zones, zone => zone),
        severities: this.utilsService.makeArray(fData.severities),
        likelihoods: this.utilsService.makeArray(fData.likelihoods),
        creators: this.utilsService.makeArray(fData.creators, parseInt),
        owners: this.utilsService.makeArray(fData.owners, parseInt),
        groups: this.utilsService.makeArray(fData.groups, parseInt)
      };
    } else if (fData.type === 'beacon') {
      fData.selectors = {
        locations: this.utilsService.makeArray(fData.locations, parseInt),
        zones: this.utils.makeArray(fData.zones, zone => zone),
      };
    } else if (fData.type === 'proximity') {
      fData.selectors = {
        locations: this.utilsService.makeArray(fData.locations, parseInt),
        zones: this.utils.makeArray(fData.zones, zone => zone),
        groups: this.utilsService.makeArray(fData.groups, parseInt),
        workers: this.utilsService.makeArray(fData.workers, parseInt),
        limit: +fData.limit
      };
    }
    fData.interval = this.toSeconds(fData.interval, fData.interval_unit);
    fData.frequency = this.toSeconds(fData.frequency, fData.frequency_unit);
    fData.recipientSelectors = {
      targets: this.utilsService.makeArray(fData.targets),
      targetUsers: this.utilsService.makeArray(fData.targetUsers, parseInt),
      sendToGroups: this.utilsService.makeArray(fData.sendToGroups, parseInt),
      onlyActive: fData.onlyActive === '' ? 0 : 1
    };
    if (fData.active === '') {
      fData.active = 0;
    }
    fData.cmd = 'updateRule';
    fData.sendTime = Date.now();
    return this._updateAndRefresh(this.encodeRule(fData), () => this.getRules());
  }

  handleDeleteRule(fData) {
    fData.cmd = 'deleteRule';
    return this._updateAndRefresh(fData, () => this.getRules());
  }

  private _updateAndRefresh(fData, updateHandler): Promise<any> {
    const cmdOpts = _.cloneDeep(fData);

    return new Promise((resolve, reject) => {
      this.comms.sendMessage(cmdOpts, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            updateHandler()
              .then((ores) => {
                resolve(data);
              })
              .catch((oerr) => {
                this.logger.error('update failed: ' + oerr);
              });
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          this.logger.error('update failed: ' + err);
          reject(err);
        });
    });
  }

}
