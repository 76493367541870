import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { CommsService } from '@services/comms/comms.service';
import { CacheService } from '@services/cache/cache.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HeartbeatService {

  private heartbeatTimer: number = null;
  private lastRefresh = 0;
  private heartbeatStarted = false;

  constructor(
    private logger: NGXLogger,
    private comms: CommsService,
    private cache: CacheService,
    private subscriber: SubscriberService,
    protected translate: TranslateService
  ) {
  }

  public isRunning() {
    if (this.cache.usesSSE) {
      return true;
    } else {
      return this.heartbeatStarted;
    }
  }

  public doHeartbeat(initialLoad: boolean = false) {
    // this.logger.log("in doHeartbeat");
    this.startHeartbeat();
    return new Promise((resolve, reject) => {
      if (this.comms.token) {
        const eData: any = {
          cmd: 'heartbeat',
          location: '-,-',
          lastRefresh: this.lastRefresh
        };
        this.comms.sendMessage(eData, false, false)
          .then((data: any) => {
            if (data.reqStatus === 'OK') {
              if ((initialLoad || !this.cache.usesSSE) && data.result.refreshCache) {
                this.logger.log('Backend says our cache is out of date');
                // if we have never updated before, force the cache update
                this.lastRefresh = data.result.timestamp;
                this.cache.refresh(data.result.refreshCache)
                  .then(() => {
                    resolve(true);
                  });
              } else {
                this.lastRefresh = data.result.timestamp;
                // there was no cache update - just complete the request
                resolve(true);
              }
            } else {
              // sending a message failed
              reject(this.translate.instant('COMMONT_SERVICE.replied_with_an_error') + data.reqStatus);
            }
          })
          .catch((err: any) => {
            this.logger.log('Error sending heartbeat: ' + JSON.stringify(err));
            resolve(err);
          });
      } else {
        // the user isn't logged in - don't do anything just cycle
        resolve(true);
      }
    });
  }

  public startHeartbeat() {
    this.stopHeartbeat();
    // only set up automatic hardbeats if the cache service is NOT listening for data
    this.heartbeatStarted = true;
    if (!this.cache.isListening) {
      this.heartbeatTimer = window.setTimeout(() => {
        this.doHeartbeat()
          .then(check => {

          })
          .catch(err => {
            this.logger.log('doHeartbeat failed: ' + err);
          });
      }, this.subscriber.HEARTBEAT_TIME * 1000);
    }
  }


  public stopHeartbeat() {
    this.heartbeatStarted = false;
    if (this.heartbeatTimer) {
      window.clearTimeout(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
  }

  public clearCache() {
    this.lastRefresh = 0;
  }
}
