import { Component, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

import { LoadingService, ViewerService } from '@services';
import { NotePopupComponent, MessagePopupComponent } from '@modules/observation/components';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { AbstractObservationDetailPage } from '@modules/observation/pages/abstract-observation-detail-page/abstract-observation-detail-page';

@Component({
  selector: 'app-quality-detail',
  templateUrl: './quality-detail.page.html',
  styleUrls: ['./quality-detail.page.scss', './../../directives/mark-note.scss'],
})
export class QualityDetailPage extends AbstractObservationDetailPage implements OnDestroy {

  // THESE DATA ARE DISPLAYED ON FRONT END
  public uuid: string = null;
  public oid: number = null; // oid
  public bottomComponentData: any; // bottom back/front nav, table nav
  public headerData: any = {}; // data for top component, loc, zone, user etc
  public closingData: any = {}; // data for closing component
  public images = []; // observation images
  public notes: any = {}; // observation notes, audio + test
  public footerButtons = []; // button displayed on footer
  public qualityDetail: any = {}; // production,job, part number etcetra..
  public bottomNav = false;
  public footerData: any = {};
  public headerIsHidden: string;

  private isImageLoading = false;

  constructor(
    private observationDetailService: ObservationDetailService,
    private popover: PopoverController,
    private viewer: ViewerService,
    private loadingService: LoadingService,
    private router: Router,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnDestroy() {
    this.events.unsubscribe('ccs:noteUpdate', this.noteListener);
    this.events.unsubscribe('ccs:assignUpdate', this.assignListener);
  }

  ionViewDidEnter() {
    this.loadingService.disable();
  }

  ionViewWillEnter() {
    // get observations
    this.uuid = this.activatedRoute.snapshot.paramMap.get('id');
    this.headerIsHidden = this.activatedRoute.snapshot.queryParamMap.get('headerIsHidden');

    if (this.activatedRoute.snapshot.queryParamMap.get('tableNav') === 'true') {
      this.bottomNav = true;
    }

    this.events.subscribe('ccs:noteUpdate', this.noteListener);

    this.events.subscribe('ccs:assignUpdate', this.assignListener);
    this.events.subscribe('ccs:observationUpdate', this.updateObservationListener);

    // to make sure this observation actually exists, lets make a call for this observation.
    this.updateObservationListener();


  }

  ionViewWillLeave() {
    this.events.unsubscribe('ccs:observationUpdate', this.updateObservationListener);
  }

  public addOpenNote() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'created'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public addClosedNote() {
    this.popover.create(<any>{
      component: NotePopupComponent,
      animated: false,
      componentProps: {
        observationID: this.oid,
        msgType: 'fixed'
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public zoomThis(src: string): void {
    if (!this.isImageLoading) {
      this.isImageLoading = true;
      this.loadingService.enable();

      const img: HTMLImageElement = new Image();
      img.src = src.replace('/thumb', '');
      img.onload = () => {
        this.loadingService.disable();
        this.isImageLoading = false;

        this.viewer.show(img);
      };
    }
  }

  public editQualityField() {
    this.router.navigate([`pages/dashboard/edit-quality-field/${this.oid}`]);
  }

  private noteListener = (data) => {
    if (data) {
      this.notes = this.observationDetailService.getNotes(this.oid);
      this.closingData = this.observationDetailService.getClosingNoteData(this.oid);
    }
  };

  private assignListener = (data) => {
    if (data) {
      this.headerData = this.observationDetailService.getTopComponentData(this.oid);
    }
  };

  private updateObservationListener = () => {
    this.observationService.getObservationByUUID(this.uuid).then(data => {
      if (data && data.observationID) {
        this.oid = data.observationID;
        this.observationDetailService.checkObservationType(this.oid, 'quality');
        this.headerData = this.observationDetailService.getTopComponentData(this.oid);
        this.images = this.observationDetailService.getImages(this.oid);
        this.notes = this.observationDetailService.getNotes(this.oid);
        this.closingData = this.observationDetailService.getClosingNoteData(this.oid);
        this.qualityDetail = this.observationDetailService.getQualityDetailData(this.oid);

        if (this.headerData.buttonPanel) {
          this.footerButtons = this.headerData.buttons;
        }
        // if coming from table then only show prev-back nav.
        // blue nav bar depending whether its coming from the table list.
        if (this.bottomNav) {
          this.checkFooterData();
        }
      } else {
        this.observationDetailService.checkObservationType(null, 'quality');
      }
    }).catch(() => {
      this.observationDetailService.checkObservationType(null, 'quality');
    });
  };
}
