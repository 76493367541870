import { Injectable } from '@angular/core';

import { AccountsService, AccountTypes } from '@services/accounts/accounts.service';
import { ObservationService, ObservationTypes } from '@services/observations/observation.service';
import { TeamsService } from '@services/teams/teams.service';
import { UserService } from '@services/user/user.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { UtilsService } from '@services/utils/utils.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { PermissionsService } from '@services/permissions/permissions.service';

import * as _ from 'lodash';
import * as moment from 'moment';

interface Interval {
  end: number;
  endSecs: number;
  label: string;
  start: number;
  startSecs: number;
}

interface RowInterval {
  [period: string]: {
    numCreators: number;
    numAccounts: number;
    participation: number;
  };
}

export interface ParticipationIntervalsLocations {
  intervals: Interval[];
  primaryKeys: { [locationId: number]: number };
  rows: { [locationId: number]: { intervals: RowInterval } };
}

@Injectable({
  providedIn: 'root'
})
export class CultureService {

  constructor(
    private observation: ObservationService,
    private accounts: AccountsService,
    private teams: TeamsService,
    private utils: UtilsService,
    private userService: UserService,
    private userdataService: UserdataService,
    private subscriber: SubscriberService,
    private PermissionsService: PermissionsService
  ) {
  }

  /**
   * This method is designed to pull in all of the culture score-related data organized by
   * location.  Within each locations data, there is also a breakdown by team so that detailed reporting
   * can be done.
   *
   * @param timespan is the period being extracted. See utils.timespan for a list.
   * @param dateRange is a date range
   *
   * @returns participation data
   *
   */
  public participationByLocation(timespan: string = 'thisweek', dateRange?: { startTime: number; endTime: number }) {
    const span = this.utils.timespan(timespan);
    let previousSpan = this.utils.timespan(timespan, span.startTime);
    // filtering observations in similar fashion to observation tables
    let obsLoc = null;
    if (this.userdataService.locations.length > 0) {
      const locations = this.userService.getUserLocations(this.userdataService.locations, false);
      obsLoc = _.map(locations, 'locationID');
    }
    // getting list of all valid locations for data collection.
    const locations = this.userService.getUserLocations(this.userdataService.locations, false);
    const locList = _.map(locations, 'locationID');

    if (timespan === 'custom' && dateRange) {
      previousSpan = dateRange;
    }

    // break down participation by observations by location

    const opts = this.observationOpts(timespan, previousSpan);

    opts.locations = obsLoc;

    const popts = {
      startTime: previousSpan.startTime,
      endTime: previousSpan.endTime,
      locations: obsLoc,
      obstype: opts.obstype
    };

    const primary = {
      fieldName: 'locationID',
      fieldRequired: true
    };


    // we have all the location data; calculate percentages

    const collection = this.observation.findInIntervals(opts, primary);
    let current = null;
    if (collection && Object.keys(collection).length) {
      current = this.calculateParticipation(collection, locList, true);
    }
    const prevCollection = this.observation.findInIntervals(popts, primary);
    let previous = null;
    if (prevCollection && Object.keys(prevCollection).length) {
      previous = this.calculateParticipation(prevCollection, locList);
    }

    return {current, previous};
  }

  public calculateAccountabilityAging(): any {
    const locations = this.userService.getUserLocations(this.userdataService.locations, false);
    const locList = _.map(locations, 'locationID');
    const opts = {
      startTime: null,
      endTime: null,
      period: 'none',
      timespan: 'all',
      locations: locList,
      obstype: ['condition', 'quality', 'ca'],
      states: ['open', 'workorder', 'escalated']
    };
    const grouping = {
      fieldName: 'locationID',
      fieldRequired: true
    };
    const collection = this.observation.findInIntervals(opts, grouping);

    const ret = {
      all: {
        '30days': {
          condition: 0,
          quality: 0,
          ca: 0
        },
        '60days': {
          condition: 0,
          quality: 0,
          ca: 0
        },
        '90days': {
          condition: 0,
          quality: 0,
          ca: 0
        },
        '91days': {
          condition: 0,
          quality: 0,
          ca: 0
        }
      }
    };

    _.each(locList, locID => {
      ret[locID] = {
        '30days': {
          condition: 0,
          quality: 0,
          ca: 0,
          teams: {}
        },
        '60days': {
          condition: 0,
          quality: 0,
          ca: 0,
          teams: {}
        },
        '90days': {
          condition: 0,
          quality: 0,
          ca: 0,
          teams: {}
        },
        '91days': {
          condition: 0,
          quality: 0,
          ca: 0,
          teams: {}
        }
      };
    });

    _.each(collection.primaryKeys, (loc) => {
      // go over the locations
      const items = collection.rows[loc].items;
      _.each(items, obs => {
        const t = this.observation.openTime(obs);
        const b = this._bucketName(t);
        const loc = obs.locationID;
        const type = obs.type;
        ret.all[b][type]++;
        const ref = ret[loc][b];
        ref[type]++;
        if (!_.has(ref.teams, obs.groupID)) {
          ref.teams[obs.groupID] = {
            '30days': {
              condition: 0,
              quality: 0,
              ca: 0
            },
            '60days': {
              condition: 0,
              quality: 0,
              ca: 0
            },
            '90days': {
              condition: 0,
              quality: 0,
              ca: 0
            },
            '91days': {
              condition: 0,
              quality: 0,
              ca: 0
            }
          };
        }
        ref.teams[obs.groupID][b][type]++;
      });
    });

    return ret;
  }

  public participationIntervalsByLocation(timespan: string = 'thismonth', range?: { startTime: number; endTime: number }): ParticipationIntervalsLocations {
    const locations = this.userService.getUserLocations(this.userdataService.locations, false);
    const locList = _.map(locations, 'locationID');
    // get the users by location
    const users = this.participationUsers(locList);

    if (timespan !== 'custom') {
      range = null;
    }

    // get the observations for the locations
    const opts = this.observationOpts(timespan, range);
    opts.locations = locList;

    const primary = {
      fieldName: 'locationID',
      fieldRequired: true
    };

    const collection = this.observation.findInIntervals(opts, primary);

    const ret = {
      intervals: collection.intervals,
      primaryKeys: collection.primaryKeys,
      rows: {
        all: {
          intervals: {}
        }
      }
    };
    _.each(collection.intervals, (interval, id) => {
      const theInterval = `p${id}`;
      ret.rows.all.intervals[theInterval] = {
        creators: {},
        numCreators: 0,
        numAccounts: users[0].users.length,
        participation: 0
      };
    });

    // iterate over the locations

    const numIntervals = collection.intervals.length;
    _.each(collection.rows, (locData, location) => {

      // add an entry for this location
      ret.rows[location] = {
        intervals: {}
      };

      // iterate over the intervals

      for (let i = 0; i < numIntervals; i++) {
        const theInterval = `p${i}`;
        ret.rows[location].intervals[theInterval] = {
          // initialize this as if there are no results
          numCreators: 0,
          numAccounts: 0,
          participation: 0
        };
        const aggregateInterval = ret.rows.all.intervals[theInterval];

        const idata = locData.intervals[theInterval].items;
        const created = {};
        let numCreators = 0;
        let numAccounts = 0;

        if (_.has(users, location) && _.has(users[location], 'users')) {
          numAccounts = users[location].users.length;
        }
        if (numAccounts) {
          // build a structure of created by since there are some users here
          _.each(idata, row => {
            const creator = row.userID;
            if (_.indexOf(users[location].users, creator) > -1) {
              // this creator is at this location
              if (!_.has(created, creator)) {
                created[creator] = 1;
                numCreators++;
              }
              if (!_.has(aggregateInterval.creators, creator)) {
                aggregateInterval.creators[creator] = 1;
              }
            }
          });
          // calculate percentage against users by location
          ret.rows[location].intervals[theInterval] = {
            numCreators,
            numAccounts,
            participation: (numCreators / numAccounts) * 100
          };
        }
      }
    });

    // roll everything up into an overall
    for (let i = 0; i < numIntervals; i++) {
      const theInterval = `p${i}`;
      const ref = ret.rows.all.intervals[theInterval];
      if (ref.numAccounts) {
        const numCreators = Object.keys(ref.creators).length;
        ref.participation = (numCreators / ref.numAccounts) * 100;
      }
    }

    return ret;

  }

  public participationIntervalsByTeam(timespan: string = 'thismonth', locationID: number, range?: { startTime: number; endTime: number }): any {
    const locList = [locationID];
    // get the users by location
    const users = this.participationUsers(locList);

    if (timespan !== 'custom') {
      range = null;
    }

    // get the observations for the locations
    const opts = this.observationOpts(timespan, range);
    opts.locations = locList;

    const primary = {
      fieldName: 'groupID',
      fieldRequired: true
    };

    const collection = this.observation.findInIntervals(opts, primary);

    const ret = {
      intervals: collection.intervals,
      primaryKeys: collection.primaryKeys,
      rows: {}
    };

    // iterate over the locations

    const numIntervals = collection.intervals.length;
    _.each(collection.rows, (teamData, team) => {

      // add an entry for this location
      ret.rows[team] = {
        intervals: {}
      };

      // iterate over the intervals

      for (let i = 0; i < numIntervals; i++) {
        const theInterval = `p${i}`;
        ret.rows[team].intervals[theInterval] = {
          // initialize this as if there are no results
          numCreators: 0,
          numAccounts: 0,
          participation: 0
        };
        const idata = teamData.intervals[theInterval].items;
        const created = {};
        let numCreators = 0;
        let numAccounts = 0;

        if (_.has(users[locationID].teams, team) && _.has(users[locationID].teams[team], 'members')) {
          numAccounts = users[locationID].teams[team].members.length;
        }
        if (numAccounts) {
          const members = users[locationID].teams[team].members;
          // build a structure of created by since there are some users here
          _.each(idata, row => {
            const creator = row.userID;
            if (!_.has(created, creator) && _.indexOf(members, creator) !== -1) {
              created[creator] = 1;
              numCreators++;
            }
          });
          // calculate percentage against users by location
          ret.rows[team].intervals[theInterval] = {
            numCreators,
            numAccounts,
            participation: (numCreators / numAccounts) * 100
          };
        }
      }
    });

    return ret;
  }

  private _bucketName(time: number): string {
    if (time < 30 * 24 * 60 * 60) {
      return '30days';
    } else if (time < 60 * 24 * 60 * 60) {
      return '60days';
    } else if (time < 90 * 24 * 60 * 60) {
      return '90days';
    } else {
      return '91days';
    }
  }

  private calculateParticipation(collection: any, locList: any, updateIntervals: boolean = false): any {

    const partPrefs = this.subscriber.getPreference('cultureParticipation');

    const usersByLocation = this.participationUsers(locList);
    _.each(usersByLocation, (locdata, location) => {
      locdata.creators = {};
      if (location) {
        _.each(locdata.teams, tref => {
          // a bucket in each team at each location to capture creators
          tref.creators = {};
          tref.types = {};
          tref.total = 0;
        });
      }
    });

    const sentimentData = {
      compliments: {
        total: 0,
        count: 0,
        commentCount: 0,
        locations: {}
      },
      behaviors: {
        total: 0,
        count: 0,
        commentCount: 0,
        locations: {}
      }
    };
    const accountabilityData = {
      open: {
        totalTime: 0,
        count: 0
      },
      unassigned: {
        totalTime: 0,
        count: 0,
      },
      toClose: {
        totalTime: 0,
        count: 0,
      },
      types: {},
      locations: {}
    };


    // score it

    _.each(collection.rows, (obsList: any, location) => {
      // iterate over the locations

      // we can grab the sentiment data while we are looking
      sentimentData.compliments.locations[location] = {
        total: 0,
        count: 0,
        commentCount: 0,
        teams: {}
      };

      sentimentData.behaviors.locations[location] = {
        total: 0,
        count: 0,
        commentCount: 0,
        teams: {}
      };

      // and we can get the accountability data for the table too
      accountabilityData.locations[location] = {
        open: {
          totalTime: 0,
          count: 0
        },
        unassigned: {
          totalTime: 0,
          count: 0,
        },
        toClose: {
          totalTime: 0,
          count: 0,
        },
        types: {},
      };

      // look at every observation for this location
      _.each(obsList.items, (obs: any) => {
        const creator = obs.userID;
        const obsType = this.observation.getProperty(obs, 'type');

        // get the interesting data about this observation
        // ensure disabled teams are NOT included
        const team = this.observation.creatorTeam(obs, false);

        // first, get the accountability data
        if (_.includes(['condition', 'quality', 'ca'], obsType)) {
          // if we have not seen this location before, add a structure for it

          // if we have not seen this type before, add a structure for it
          if (!_.has(accountabilityData.types, obsType)) {
            // overall breakdown by type
            accountabilityData.types[obsType] = {
              open: {
                totalTime: 0,
                count: 0,
              },
              unassigned: {
                totalTime: 0,
                count: 0,
              },
              toClose: {
                totalTime: 0,
                count: 0,
              },
            };
          }
          if (!_.has(accountabilityData.locations[location].types, obsType)) {
            // per location break down by type
            accountabilityData.locations[location].types[obsType] = {
              open: {
                totalTime: 0,
                count: 0,
                teams: {}
              },
              unassigned: {
                totalTime: 0,
                count: 0,
                teams: {}
              },
              toClose: {
                totalTime: 0,
                count: 0,
                teams: {}
              },
            };
          }

          // calculate the time periods for this observation
          const o = this.observation.openTime(obs);
          const u = this.observation.unassignedTime(obs);
          const f = this.observation.whenFixed(obs);
          let c = this.observation.whenClosed(obs);

          // push that data into each relevant data structure
          _.each([accountabilityData, accountabilityData.types[obsType], accountabilityData.locations[location], accountabilityData.locations[location].types[obsType]], (ref, idx) => {
            if (idx === 3) {
              if (!_.has(ref.toClose.teams, team)) {
                ref.toClose.teams[team] = {totalTime: 0, count: 0};
                ref.unassigned.teams[team] = {totalTime: 0, count: 0};
                ref.open.teams[team] = {totalTime: 0, count: 0};
              }
            }
            if (f) {
              if (!c) {
                c = Date.now() / 1000;
              }
              // calculate the time to close
              ref.toClose.totalTime += (c - f);
              ref.toClose.count++;
              if (idx === 3) {
                ref.toClose.teams[team].totalTime += (c - f);
                ref.toClose.teams[team].count++;
              }
            }

            if (u) {
              ref.unassigned.totalTime += u;
              ref.unassigned.count++;
              if (idx === 3) {
                ref.unassigned.teams[team].totalTime += u;
                ref.unassigned.teams[team].count++;
              }
            }
            // we are counting this observation
            // this does not depend on the time open,
            // if (o)
            {
              ref.open.totalTime += o;
              ref.open.count++;
              if (idx === 3) {
                ref.open.teams[team].totalTime += o;
                ref.open.teams[team].count++;
              }
            }
          });
        }

        // now the sentiment data
        if (obsType === 'compliment' || obsType === 'behavior') {
          const s = this.observation.sentiment(obs);
          if (obsType === 'compliment') {
            const locRef = sentimentData.compliments.locations[location];
            if (!_.has(locRef.teams, team)) {
              locRef.teams[team] = {total: 0, count: 0, commentCount: 0};
            }
            locRef.count++;
            locRef.teams[team].count++;
            sentimentData.compliments.count++;
            // was there a real sentiment score for this?
            if (s !== -999 && s !== 999) {
              sentimentData.compliments.total += s;
              sentimentData.compliments.commentCount++;
              locRef.total += s;
              locRef.commentCount++;
              locRef.teams[team].total += s;
              locRef.teams[team].commentCount++;
            }
          } else if (obsType === 'behavior') {
            const locRef = sentimentData.behaviors.locations[location];
            if (!_.has(locRef.teams, team)) {
              locRef.teams[team] = {total: 0, count: 0, commentCount: 0};
            }
            locRef.count++;
            locRef.teams[team].count++;
            sentimentData.behaviors.count++;
            if (s !== -999 && s !== 999) {
              sentimentData.behaviors.total += s;
              locRef.total += s;
              locRef.commentCount++;
              sentimentData.behaviors.commentCount++;
              locRef.teams[team].total += s;
              locRef.teams[team].commentCount++;
            }
          }
        }

        // update the location data structure to include observation counts
        // and team data and counts for participation data

        // first, is this a type of observation we care about
        if (!partPrefs.any && (!_.has(partPrefs, obsType) || partPrefs[obsType] === 0)) {
          // no - this type is NOT in the list
          return;
        }

        // is this user in the list
        if (!_.has(usersByLocation[0], 'users') || _.indexOf(usersByLocation[0].users, +creator) === -1) {
          // this user is NOT in our list
          return;
        }

        _.each([0, location], locID => {
          const locdata = usersByLocation[locID];
          // is this creator at this location?
          if (locdata && _.has(locdata, 'users') && _.indexOf(locdata.users, creator) > -1) {
            // have we already seen this user?
            if (!_.has(locdata.creators, creator)) {
              locdata.creators[creator] = {types: {}, total: 0};
            }
            locdata.creators[creator].total++;

            if (!_.has(locdata.creators[creator].types, obsType)) {
              locdata.creators[creator].types[obsType] = [];
            }
            locdata.creators[creator].types[obsType].push(obs.observationID);
            if (_.has(locdata, 'teams') && _.has(locdata.teams, team)) {
              // this observation has a team - let's accumulate the data in a team bucket for the location too
              if (!_.has(locdata.teams[team].creators, creator)) {
                locdata.teams[team].creators[creator] = {types: {}, total: 0};
              }
              // count it
              locdata.teams[team].creators[creator].total++;

              if (!_.has(locdata.teams[team].creators[creator].types, obsType)) {
                locdata.teams[team].creators[creator].types[obsType] = [];
              }
              locdata.teams[team].creators[creator].types[obsType].push(obs.observationID);

              // and the team overall
              locdata.teams[team].total++;
              if (!_.has(locdata.teams[team].types, obsType)) {
                locdata.teams[team].types[obsType] = [];
              }
              locdata.teams[team].types[obsType].push(obs.observationID);
            }
          }
        });
      });
    });

    const ret = {
      participation: 0,
      locations: {},
      locationUsers: {},
      locationTeams: {},
      accountability: accountabilityData,
      sentiment: sentimentData
    };

    _.each(usersByLocation, locdata => {
      ret.locations[locdata.locationID] = Object.keys(locdata.creators).length / locdata.users.length;
      ret.locationTeams[locdata.locationID] = {};
      ret.locationUsers[locdata.locationID] = locdata.users;
      _.each(locdata.teams, teamdata => {
        ret.locationTeams[locdata.locationID][teamdata.teamID] = teamdata;
        ret.locationTeams[locdata.locationID][teamdata.teamID].participation = teamdata.members.length ? Object.keys(teamdata.creators).length / teamdata.members.length : 0;
      });
    });
    ret.participation = ret.locations[0];

    return ret;
  }

  private observationOpts(timespan: string = 'thismonth', dateRange?: { startTime: number; endTime: number }): any {
    let period = 'months';
    if (timespan.match(/week/)) {
      // it is one of the weeks
      period = 'days';
    } else if (timespan.match(/month/)) {
      period = 'weeks';
    }
    let s = this.utils.timespan(timespan);
    const t = this.participationTypes();

    if (timespan === 'custom' && dateRange) {
      s = dateRange;
      const startTimeMoment = moment(dateRange.startTime);
      const endTimeMoment = moment(dateRange.endTime);

      const monthsDiff: number = Math.abs(startTimeMoment.diff(endTimeMoment, 'months'));
      if (monthsDiff >= 2) {
        period = 'months';
        // s.startTime = moment(dateRange.startTime).startOf('month').valueOf();
      } else {
        // s.startTime = moment(dateRange.startTime).startOf('week').valueOf();
        period = 'weeks';
      }
    }
    return {
      // excludeDisabledTeams: true, // creates mismatch with the observation data count.
      startTime: s.startTime,
      endTime: s.endTime,
      period,
      timespan,
      obstype: t
    };
  }


  private participationUsers(locList: Array<number>): any {
    // we only want to consider some types of observations and users for participation
    const partPrefs = this.subscriber.getPreference('cultureParticipation');
    const userPermissions = _.get(partPrefs, 'include_users', []);
    const prefFilter = (user: any) => {
      if (user.disabledAt || !user.active) {
        return true;
      }
      // If this is an observer user, and observer is not included, then skip this user
      if (user.type === AccountTypes.Observer && _.indexOf(userPermissions, AccountTypes.Observer) === -1) {
        // filter this one out
        return true;
      }
      // OPS-121 check the intersection of the user's permissions and the
      // const level = this.PermissionsService.accessLevel(user.permissions);

      if (!_.intersection(Object.keys(user.permissions), userPermissions).length) {
        return true;
      }
      return false;
    };

    const usersByLocation = this.accounts.byLocation(locList, false, true, [prefFilter]);

    let allUsers = [];
    _.each(usersByLocation, locdata => {
      allUsers = _.union(allUsers, locdata.users);
    });

    // use location 0 as the overall
    usersByLocation[0] = {
      locationID: 0,
      users: allUsers,
    };
    return usersByLocation;
  }

  private participationTypes() {

    const partPrefs = this.subscriber.getPreference('cultureParticipation');

    // what types of observations are we supposed to use?
    const t = ['condition', 'behavior', 'compliment', 'pi', 'quality', 'ca'];
    if (partPrefs.any === 0) {
      _.each(ObservationTypes, type => {
        if (_.has(partPrefs, type) && partPrefs[type]) {
          t.push(type);
        }
      });
    }
    return t;
  }

}
