<ion-header no-border>
  <app-close-button (onClicked)="superTaskBack()"></app-close-button>
</ion-header>

<ion-content fullscreen>
  <div class="wide">
    <app-detail-header [headerData]="headerData"></app-detail-header>

    <!-- control header elements-->
    <div *ngIf="headerData.buttons && headerData.buttons.length>0" [appAccountCanViewExcept]="['viewer']"
         class="detail-controls-as-viewer">
      <hr class="hr-dashed top-margin">
      <div class="button-block">
        <ion-button (click)="butts.click(headerData.rid)" *ngFor="let butts of headerData.buttons" [class]="butts.class"
                    [color]="butts.color">{{butts.name | translate}}</ion-button>
      </div>
      <div class="clearfix"></div>
      <div>
        <hr class="hr-dashed">
      </div>
    </div>
    <!-- control header elements end-->

    <!-- Check Detail Block -->
    <div *ngIf="unableBody" class="detail-page-section">
      <div class="adjustment-grid">
        <ion-grid class="adjustment-grid-ion-grid">
          <ion-row>
            <ion-col class="col-border-left" size="4">
              <div class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ "SHARED.Reason" | translate }}</div>
                <div class="adjustment-grid-item">
                  <div class="adjustment-grid-value">
                    {{bodyDetail.reason}}
                  </div>
                </div>
              </div>
            </ion-col>
            <ion-col class="col-border-left" size="8">
              <div class="adjustment-grid-item-wrap">
                <div class="descriptor">{{ "OTable.NOTES" | translate }}</div>
                <div class="adjustment-grid-item">
                  <div class="body-copy">
                    {{bodyDetail.note.value}}
                  </div>
                </div>
                <!-- audio notes if any-->
                <div class="adjustment-grid-item spacer">
                  <audio *ngIf="audio" class="observation-audio" controls="" preload="metadata">
                    <source [src]="audio" type="audio/mp4">
                    <p>{{ "DASHPAGES.COACHING_DETAIL_note" | translate }}</p>
                  </audio>
                </div>
                <!-- end audio notes-->
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
    <!-- Check Detail Block End-->
  </div>
</ion-content>

<ion-footer *ngIf="unableBody && !skipFooter" [appAccountCanViewExcept]="['viewer']" no-border transparent>
  <ion-toolbar>
    <app-detail-footer [footerData]="footerData"></app-detail-footer>
  </ion-toolbar>
</ion-footer>
