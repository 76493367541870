import { Injectable } from '@angular/core';

import PromiseWorker from 'promise-worker';

@Injectable({
  providedIn: 'root'
})
export class WebWorkerService {

  private worker: PromiseWorker;
  private stringWorker: PromiseWorker;

  constructor() {
  }

  public create() {
    if (typeof Worker !== 'undefined' && !this.worker) {
      const worker = new Worker(new URL('./../../workers/compress.worker', import.meta.url));
      this.worker = new PromiseWorker(worker);
      const stringWorker = new Worker(new URL('./../../workers/string.compress.worker', import.meta.url));
      this.stringWorker = new PromiseWorker(stringWorker);
    }
  }

  public compressData(data: any): Promise<any> {
    if (this.worker) {
      return this.worker.postMessage(data);
    } else {
      Promise.resolve(null);
    }
  }

  public compressString(data: string): Promise<any> {
    if (this.stringWorker) {
      return this.stringWorker.postMessage(data);
    } else {
      Promise.resolve(null);
    }
  }
}
