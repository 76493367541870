import { NGXLogger } from 'ngx-logger';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import { environment } from '@env';
import { SubscriberService } from './../subscriber/subscriber.service';
import { UserdataService } from './../userdata/userdata.service';
import { CommsService } from './../comms/comms.service';
import { WebWorkerService } from './../web-worker/web-worker.service';

import * as _ from 'lodash';
import CryptoHelper from '@utils/cryptoHelper';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public isRestored = false;

  private auth0data: any = null;
  private stateCache = null;

  constructor(
    private logger: NGXLogger,
    private userdataService: UserdataService,
    private subscriberService: SubscriberService,
    private commsService: CommsService,
    private webWorker: WebWorkerService,
    private storage: Storage
  ) {
  }

  public init() {
    this.storage.create();
    this.webWorker.create();
  }

  public clearState() {
    StorageService.clear();
    this.storage.clear();
    this.isRestored = false;
    this.stateCache = null;
  }

  public auth0ClearCache(): void {
    this.auth0data = null;
  }

  public auth0Cache(data?: any): void {
    if (data) {
      this.auth0data = data;
    }
  }

  public storeState(): boolean { // TODO [azyulikov] move in app service
    // if (localStorage && app.initialized && !app.dontStoreState && app.userData.Token) { TODO [azyulikov] add missed variables
    if (this.userdataService.Token) {
      const state = {
        // config: app.config,
        subInfo: this.subscriberService.subInfo,
        userData: {
          UserName: this.userdataService.UserName,
          Registered: this.userdataService.Registered,
          Password: this.userdataService.Password,
          Remember: this.userdataService.Remember,
          Token: this.userdataService.Token,
          fullname: this.userdataService.fullname,
          userID: this.userdataService.userID,
          type: this.userdataService.type,
          teams: this.userdataService.teams,
          consented: this.userdataService.consented,
          consentDate: this.userdataService.consentDate
        },
        auth0: {}
      };
      if (this.auth0data) {
        state.auth0 = this.auth0data;
      }

      StorageService.setItem(`state${environment.baseHref}`, JSON.stringify(state));
      return true;
    } else {
      return false;
    }
  }

  public restoreToken(): void {
    try {
      const state = JSON.parse(StorageService.getItem(`state${environment.baseHref}`));
      if (state) {
        this.commsService.token = state.userData.Token;
      }
    } catch (err) {
      this.logger.log('Failed to retrieve state: ' + err);
    }

    return;
  }

  public getState(): any {
    let ret = null;
    if (this.isRestored && this.stateCache) {
      ret = this.stateCache;
    } else {
      // we tried to load - even if it fails, it counts
      this.isRestored = true;
      try {
        const state = JSON.parse(StorageService.getItem(`state${environment.baseHref}`));
        if (state) {
          ret = this.stateCache = state;
          // app.initialized = true;
          // app.config = state.config;
          if (_.isEmpty(this.subscriberService.subInfo)) {
            this.subscriberService.subInfo = state.subInfo;
          }
          if (_.isEmpty(this.userdataService.type)) {
            this.userdataService.type = state.userData.type;
          }
          this.userdataService.UserName = state.userData.UserName;
          this.userdataService.Registered = state.userData.Registered;
          this.userdataService.Password = state.userData.Password;
          this.userdataService.Remember = state.userData.Remember;
          this.userdataService.Token = state.userData.Token;
          this.userdataService.fullname = state.userData.fullname;
          this.userdataService.userID = state.userData.userID;
          this.userdataService.consented = state.userData.consented;
          this.userdataService.consentDate = state.userData.consentDate;

          if (_.isEmpty(this.userdataService.teams)) {
            this.userdataService.teams = state.userData.teams;
          }

          this.commsService.token = state.userData.Token;

          // if (state.comms) {
          // comms.setState(state.comms);
          // }

          // if (state.utils) {
          //   utils.setState(state.utils);
          // }
          // if (state.admin) {
          //   admin.setState(state.admin);
          // }
        }
      } catch (err) {
        this.logger.log('Failed to retrieve state: ' + err);
      }
    }
    return ret;
  }

  public async store(alias: string, data: any, splitField?: string, keyField?: string, bucketSize?: number) {
    if (splitField) {
      const storeItem = {
        _buckets: [],
        _splitField: splitField,
        _keyField: keyField,
        _isObject: false
      };
      // capture all of the items that are not splitField into our new structure
      _.each(data, (value, key) => {
        if (key !== splitField) {
          storeItem[key] = value;
        }
      });
      // default to 20000 items
      if (!bucketSize) {
        bucketSize = 20000;
      }

      // if this is an array, just use it
      let dRef = data[splitField];
      // if it is not an array, we will need to create an ordered list to iterate on
      if (!_.isArray(dRef)) {
        storeItem._isObject = true;
        dRef = _.map(dRef, (val) => val);
      }
      const count = dRef.length;
      let start = 0;
      let end = (start + bucketSize > count) ? count : start + bucketSize;

      while (start < count) {
        // slice up the collection
        const r = _.slice(dRef, start, end);
        try {
          const str = JSON.stringify(r);
          let compressedStr = await this.webWorker.compressString(str);
          compressedStr = CryptoHelper.encrypt(compressedStr, StorageService.encryptKey);
          if (compressedStr) {
            // push the compressed data
            this.logger.log(`compressed data from ${start} to ${end}`);
            storeItem._buckets.push(compressedStr);
            start = end;
            end = (start + bucketSize > count) ? count : start + bucketSize;
          } else {
            this.logger.log('compressed data empty!');
            throw new Error('compressed data empty!');
          }
        } catch (err) {
          this.logger.error('stringify failed: ' + JSON.stringify(err));
          // reduce the bucket by a lot
          bucketSize -= 1000;
          if (bucketSize <= 0) {
            this.logger.error('bucketSize went to 0 and still failing - giving up');
            throw new Error('errors forced bucketSize to 0: ' + JSON.stringify(err));
          } else {
            end = (start + bucketSize > count) ? count : start + bucketSize;
          }
        }
      }
      await this.storage.set(alias, storeItem);
    } else {
      const bytes = await this.webWorker.compressData(data);
      const encryptData = CryptoHelper.encryptByteArray(bytes, StorageService.encryptKey);
      this.storage.set(alias, encryptData);
    }
  }

  public async getIonicStorageKeys() {
    return await this.storage.keys();
  }

  public async getIonicStorageItem(key: string) {
    const encryptData = await this.storage.get(key);
    return CryptoHelper.decryptByteArray(encryptData, StorageService.encryptKey);
  }

  static encryptKey = 'hEKzxIOA0BSOTdbUE8yElBkmD8';

  static setItem(key: string, data: string) {
    const encodedData = CryptoHelper.encrypt(data, StorageService.encryptKey);
    localStorage.setItem(key, encodedData);
  }

  static getItem(key: string): string {
    const encodedData = localStorage.getItem(key);
    if (encodedData) {
      try {
        return CryptoHelper.decrypt(encodedData, StorageService.encryptKey);
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  static clear() {
    localStorage.clear();
  }
}
